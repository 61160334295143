import { gql } from "@apollo/client";
import { CABANG_FRAGMENT } from "./CabangAction";
import { GUDANG_FRAGMENT } from "./GudangAction";
import { GOODS_AND_SERVICE_FRAGMENT } from "./BarangAction";
import { SATUAN_FRAGMENT } from "./SatuanBarangAction";
// import { SUPPLIER_FRAGMENT } from "./SupplierAction";

export const RECEIVE_DETAIL_FRAGMENT = gql`
  fragment PurchaseDetail on mPenerimaanItem {
    item_penerimaan_id
    barang {
      ...GoodsAndService
    }
    qty
    satuan {
      ...Satuan
    }
    keterangan
    gudang_penerima {
      ...Gudang
    }
  }
  ${GOODS_AND_SERVICE_FRAGMENT}
  ${GUDANG_FRAGMENT}
  ${SATUAN_FRAGMENT}
`;

export const RECEIVE_ORDER_FRAGMENT = gql`
  fragment ReceiveOrder on mPenerimaanBarang {
    input_dt
    update_dt
    nomor_penerimaan
    nomor_terima
    nomor_pesanan
    tgl_terima
    tgl_kirim
    pemasok {
      kode_supplier
      nama
      id_pemasok
    }
    status
    keterangan
    cabang {
      ...Cabang
    }
    gudang_penerima {
      ...Gudang
    }
    alamat_penerima
    detail_item {
      ...PurchaseDetail
    }
  }
  ${CABANG_FRAGMENT}
  ${GUDANG_FRAGMENT}
  ${RECEIVE_DETAIL_FRAGMENT}
`;

export const RECEIVE_ORDER_LIST = gql`
  query getReceiveOrderList {
    PenerimaanBarang {
      findAll {
        ...ReceiveOrder
      }
    }
  }
  ${RECEIVE_ORDER_FRAGMENT}
`;

export const CREATE_RECEIVE_ORDER = gql`
  mutation createReceiveOrder($input: NewPenerimaanBarangInput!) {
    PenerimaanBarang {
      create(input: $input) {
        ...ReceiveOrder
      }
    }
  }
  ${RECEIVE_ORDER_FRAGMENT}
`;

export const UPDATE_RECEIVE_ORDER = gql`
  mutation editReceiveOrder($input: UpdatePenerimaanBarangInput!) {
    PenerimaanBarang {
      update(input: $input) {
        ...ReceiveOrder
      }
    }
  }
  ${RECEIVE_ORDER_FRAGMENT}
`;

export const DELETE_RECEIVE_ORDER = gql`
  mutation deleteReceiveOrder($input: String!) {
    PenerimaanBarang {
      delete(input: $input)
    }
  }
`;
