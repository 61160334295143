import { gql } from "@apollo/client";

export const GUDANG_FRAGMENT = gql`
  fragment Gudang on mGudang {
    kode_gudang
    nama_gudang
    deskripsi
    alamat
    kota
    provinsi
    kodepos
    is_gudang_rusak
    is_non_aktif
    kode_cabang
  }
`;

export const GUDANG_LIST = gql`
  query getGudangList($input: GudangPaginationInput!) {
    Gudang {
      findAll(input: $input) {
        ...Gudang
      }
    }
  }
  ${GUDANG_FRAGMENT}
`;

export const CREATE_GUDANG = gql`
  mutation createGudang($input: CreateGudangInput!) {
    gudang {
      create(input: $input) {
        ...Gudang
      }
    }
  }
  ${GUDANG_FRAGMENT}
`;

export const UPDATE_GUDANG = gql`
  mutation editGudang($input: UpdateGudangInput!) {
    gudang {
      update(input: $input) {
        ...Gudang
      }
    }
  }
  ${GUDANG_FRAGMENT}
`;

export const DELETE_GUDANG = gql`
  mutation deleteGudang($input: String!) {
    gudang {
      delete(input: $input) {
        ...Gudang
      }
    }
  }
  ${GUDANG_FRAGMENT}
`;
