import React, { useEffect } from "react";
import { useState } from "react";
import DropdownInput from "../../common/dropdownInput";
import { useRef } from "react";

const CoaList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    coaTypeList,
    dataCoa,
    loading,
    getCoaList,
  } = props;
  const wrapperRef = useRef(null);

  const theadOption = ["Kode Perkiraan", "Name", "Tipe Akun", "Saldo"];

  const [showStatus, setShowStatus] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [name, setName] = useState("");
  const [choosenTh, setChoosenTh] = useState([
    "Kode Perkiraan",
    "Name",
    "Tipe Akun",
    "Saldo",
  ]);
  const [choosenStatus, setChoosenStatus] = useState();
  const [choosenType, setChoosenType] = useState([]);

  const coaList = dataCoa?.coa.findAll;

  useEffect(() => {
    getCoaList({
      variables: {
        input: {
          namaCoa: name || null,
          statusCoa: choosenStatus !== undefined ? choosenStatus === 1 : null,
          tipeCoa: choosenType.length > 0 ? choosenType : null,
        },
      },
    });
  }, [name, choosenStatus, choosenType]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSetting(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleOpenDetail = (choosen) => {
    if (tabs.find((tab) => tab.id === choosen.coa_name)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: choosen.coa_name,
          type: "detail",
          id: choosen.coa_name,
        },
      ]);
    }
    setActive(choosen.coa_name);
    setChoosenData([...choosenData, { idTab: choosen.coa_name, ...choosen }]);
  };

  const handleCheckbox = (option) => {
    const existedThead = choosenTh.find((thead) => thead === option);

    if (existedThead) {
      const deletedThead = choosenTh.filter((thead) => thead !== option);
      setChoosenTh(deletedThead);
    } else {
      setChoosenTh([...choosenTh, option]);
    }
  };

  const handleTypeCheckbox = (option) => {
    const existedType = choosenType.find((type) => type === option);

    if (existedType) {
      const deletedType = choosenType.filter((type) => type !== option);
      setChoosenType(deletedType);
    } else {
      setChoosenType([...choosenType, option]);
    }
  };

  // const handleFilter = () => {
  //   getCoaList({
  //     variables: {
  //       input: {
  //         namaCoa: name || null,
  //         statusCoa: choosenStatus !== undefined ? choosenStatus === 1 : null,
  //         tipeCoa: choosenType.length > 0 ? choosenType : null,
  //       },
  //     },
  //   });
  // };

  return (
    <>
      <div class="content_nav_module">
        <form id="" class="">
          <div class="form-wrap">
            <DropdownInput title="Non-Aktif" setOpen={(e) => setShowStatus(e)}>
              {showStatus && (
                <div class="popup-wrap gudang-wrap">
                  <div class="m-b-10">
                    <span class="reset-list" onClick={() => setChoosenStatus()}>
                      Reset Filter
                    </span>
                  </div>

                  <label class="radio-wrap">
                    Ya
                    <input
                      type="radio"
                      name="radio"
                      checked={choosenStatus === 0}
                      onChange={() => setChoosenStatus(0)}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-wrap">
                    Tidak
                    <input
                      type="radio"
                      name="radio"
                      checked={choosenStatus === 1}
                      onChange={() => setChoosenStatus(1)}
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              )}

              {choosenStatus === undefined ? (
                <input
                  name=""
                  type="text"
                  placeholder="Pilih di sini"
                  value=""
                  onClick={() => setShowStatus(!showStatus)}
                />
              ) : (
                <ul
                  class="input-label"
                  onClick={() => setShowStatus(!showStatus)}
                >
                  <li>
                    <label>Lihat Filter</label>
                  </li>
                </ul>
              )}
            </DropdownInput>
          </div>

          <div class="form-wrap">
            <DropdownInput title="Tipe Akun" setOpen={(e) => setShowType(e)}>
              {showType && (
                <div class="popup-wrap gudang-wrap">
                  {coaTypeList.map((type, i) => (
                    <label key={i} class="checkbox">
                      {type.name}
                      <input
                        type="checkbox"
                        checked={choosenType.find(
                          (choosen) => choosen === type.id
                        )}
                        onChange={() => handleTypeCheckbox(type.id)}
                      />
                      <span class="checkmark"></span>
                    </label>
                  ))}
                </div>
              )}

              {choosenType.length > 0 ? (
                choosenType.length === coaTypeList.length ? (
                  <ul
                    class="input-label"
                    onClick={() => setShowType(!showType)}
                  >
                    <li>
                      <label>Semua</label>
                    </li>
                  </ul>
                ) : (
                  <ul
                    class="input-label"
                    onClick={() => setShowType(!showType)}
                  >
                    <li>
                      <label>Lihat Filter</label>
                    </li>
                  </ul>
                )
              ) : (
                <input
                  name=""
                  type="text"
                  placeholder="Pilih di sini"
                  value=""
                  onClick={() => setShowType(!showType)}
                />
              )}
            </DropdownInput>
          </div>

          <div class="nav-setting-wrap">
            <div class="list-setting">
              <div class="setting-btn">
                <span class="icon-filter_ic"></span>
              </div>
            </div>
            {/* <div class="list-setting">
              <div class="setting-btn">
                <span class="icon-import_ic"></span>
              </div>
            </div>
            <div class="list-setting">
              <div class="setting-btn">
                <span class="icon-cetaik_ic"></span>
              </div>
            </div> */}
            <div ref={wrapperRef} class="list-setting">
              <div
                class="setting-btn"
                onClick={() => setShowSetting(!showSetting)}
              >
                <span class="icon-pengatura_ic"></span>
              </div>
              {/* <!--POPUP MENU--> */}
              {showSetting && (
                <div class="popup-setting pengaturan">
                  {theadOption.map((opt, i) => (
                    <label
                      class="checkbox"
                      key={i}
                      onChange={() => handleCheckbox(opt)}
                    >
                      {opt}
                      <input
                        type="checkbox"
                        checked={choosenTh?.find((th) => th === opt)}
                      />
                      <span class="checkmark"></span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div class="form-right-side">
            <div class="form-wrap">
              <div class="form-container">
                <div class="title">Pencarian</div>
                <input
                  id=""
                  class="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <div class="icon-form-date icon-search_ic"></div>
              </div>
            </div>
          </div>

          <div class="clear"></div>
        </form>
      </div>

      <div className="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <tr>
              <th
              // style={
              //   choosenTh.find((choosen) => choosen === "Kode Perkiraan") && {
              //     display: "none",
              //   }
              // }
              >
                Kode Perkiraan
              </th>
              <th>Nama</th>
              <th>Tipe Akun</th>
              <th>Saldo</th>
            </tr>
            {loading ? (
              <p>Loading...</p>
            ) : coaList?.length === 0 ? (
              <tr>
                <td>Tidak ada data.</td>
              </tr>
            ) : (
              coaList?.map((coa, i) => (
                <>
                  <tr onClick={() => handleOpenDetail(coa)}>
                    <td className="ktg-utama">{coa.coa_code}</td>
                    <td className="ktg-utama">{coa.coa_name}</td>
                    <td>
                      {
                        coaTypeList?.find((type) => type.id === coa.coa_type)
                          ?.name
                      }
                    </td>
                    <td className="txt-right">
                      {(coa.coa_balance || 0).toLocaleString("id", {
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      })}
                    </td>
                  </tr>
                  {coa.child?.length > 0 &&
                    coa.child.map((childCoa) => (
                      <>
                        <tr onClick={() => handleOpenDetail(childCoa)}>
                          <td
                            className={`${
                              childCoa.child.length > 0
                                ? "sub-ktg ktg-utama"
                                : "sub-ktg"
                            }`}
                          >
                            {childCoa.coa_code}
                          </td>
                          <td
                            className={`${
                              childCoa.child.length > 0
                                ? "sub-ktg ktg-utama"
                                : "sub-ktg"
                            }`}
                          >
                            {childCoa.coa_name}
                          </td>
                          <td>
                            {
                              coaTypeList?.find(
                                (type) => type.id === childCoa.coa_type
                              )?.name
                            }
                          </td>
                          <td className="txt-right">
                            {(childCoa.coa_balance || 0).toLocaleString("id", {
                              currency: "IDR",
                              maximumFractionDigits: 0,
                            })}
                          </td>
                        </tr>
                        {childCoa.child?.length > 0 &&
                          childCoa.child.map((grandChild) => (
                            <tr onClick={() => handleOpenDetail(grandChild)}>
                              <td className="sub-sub-ktg">
                                {grandChild.coa_code}
                              </td>
                              <td className="sub-sub-ktg">
                                {grandChild.coa_name}
                              </td>
                              <td>
                                {
                                  coaTypeList?.find(
                                    (type) => type.id === grandChild.coa_type
                                  )?.name
                                }
                              </td>
                              <td className="txt-right">
                                {(grandChild.coa_balance || 0).toLocaleString(
                                  "id",
                                  {
                                    currency: "IDR",
                                    maximumFractionDigits: 0,
                                  }
                                )}
                              </td>
                            </tr>
                          ))}
                      </>
                    ))}
                </>
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default CoaList;
