import React from "react";
import { useState } from "react";
import SearchInput from "../../common/searchInput";
import { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  CATEGORY_LIST,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} from "../../../actions/KategoriBarangAction";
import { COA_LIST } from "../../../actions/CoaBarangAction";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";

const AddNewCategory = (props) => {
  const {
    tabOpen,
    setTabOpen,
    detail,
    tabs,
    setTabs,
    dataPerTabs,
    setChoosenData,
    choosenData,
    refetch,
  } = props;

  const [isHaveAccount, setIsHaveAccount] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nama barang"),
    code: Yup.string().required("Kode kategori barang"),
    parent_id: Yup.string(),
    coa_persediaan: isHaveAccount
      ? Yup.string().required("Akun persediaan")
      : Yup.string().nullable(),
    coa_beban: isHaveAccount
      ? Yup.string().required("Akun beban")
      : Yup.string().nullable(),
    coa_penjualan: isHaveAccount
      ? Yup.string().required("Akun penjualan")
      : Yup.string().nullable(),
    coa_retur_penjualan: isHaveAccount
      ? Yup.string().required("Akun retur penjualan")
      : Yup.string().nullable(),
    coa_diskon_penjualan: isHaveAccount
      ? Yup.string().required("Akun diskon penjualan")
      : Yup.string().nullable(),
    coa_barang_terkirim: isHaveAccount
      ? Yup.string().required("Akun barang terkirim")
      : Yup.string().nullable(),
    coa_beban_pokok_penjualan: isHaveAccount
      ? Yup.string().required("Akun beban pokok penjualan")
      : Yup.string().nullable(),
    coa_retur_pembelian: isHaveAccount
      ? Yup.string().required("Akun retur pembelian")
      : Yup.string().nullable(),
    coa_pembelian_belum_tagih: isHaveAccount
      ? Yup.string().required("Akun pembelian belum tertagih")
      : Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      code: "",
      parent_id: "",
      coa_persediaan: "",
      coa_beban: "",
      coa_penjualan: "",
      coa_retur_penjualan: "",
      coa_diskon_penjualan: "",
      coa_barang_terkirim: "",
      coa_beban_pokok_penjualan: "",
      coa_retur_pembelian: "",
      coa_pembelian_belum_tagih: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [tab, setTab] = useState(0);
  const [isDefault, setIsDefault] = useState(false);

  const [isSubCategory, setIsSubCategory] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = dataPerTabs.filter(
      (tab) => tab.idTab !== choosenData.idTab
    );
    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  const { data: dataCoa } = useQuery(COA_LIST);

  const { data: dataCategory } = useQuery(CATEGORY_LIST);

  const [createCategory, { loading }] = useMutation(CREATE_CATEGORY, {
    onCompleted: (resp) => {
      toast.success("Kategori berhasil dibuat");
      setTabOpen(0);
      refetch();
    },
    onError: (resp) => {
      toast.error(resp.message);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: [
    //           ...list.barang.getKategoriBarang,
    //           data.barang.createKategoriBarang,
    //         ],
    //       },
    //     },
    //   });
    // },
  });

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   const updatedList = list.barang.getKategoriBarang.filter(
    //     (category) =>
    //       category.kategori_barang_id !== choosenData.kategori_barang_id
    //   );
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: updatedList,
    //       },
    //     },
    //   });
    // },
  });

  const [ubahCategory, { loading: loadingUpdate }] = useMutation(
    UPDATE_CATEGORY,
    {
      onCompleted: (resp) => {
        setTabOpen(0);
        toast.success("Sukses mengubah data");
        setTabs(tabs.filter((tab) => tab.id !== tabOpen));
        handleUpdateTabData();
        refetch();
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  const parentCategoriesData = detail
    ? dataCategory?.barang.getKategoriBarang.filter(
        (category) =>
          category.kategori_barang_id !== choosenData?.kategori_barang_id
      )
    : dataCategory?.barang.getKategoriBarang;
  const parentCategoriesOption = parentCategoriesData
    ?.filter((category) => category.parent_id === 0)
    ?.map((category) => ({
      id: category.kategori_barang_id.toString(),
      name: category.category_name,
    }));

  useEffect(() => {
    if (detail) {
      setValue("name", choosenData.category_name);
      setValue("code", choosenData.default_prefix);
      setValue(
        "parent_id",
        choosenData.parent_id === 0 ? "" : choosenData.parent_id.toString()
      );
      setValue("coa_persediaan", choosenData.coa_persediaan);
      setValue("coa_beban", choosenData.coa_beban);
      setValue("coa_penjualan", choosenData.coa_penjualan);
      setValue("coa_retur_penjualan", choosenData.coa_retur_penjualan);
      setValue("coa_diskon_penjualan", choosenData.coa_diskon_penjualan);
      setValue("coa_barang_terkirim", choosenData.coa_barang_terkirim);
      setValue(
        "coa_beban_pokok_penjualan",
        choosenData.coa_bahan_pokok_penjualan
      );
      setValue("coa_retur_pembelian", choosenData.coa_retur_pembelian);
      setValue(
        "coa_pembelian_belum_tagih",
        choosenData.coa_pembelian_belum_tagih
      );
      setIsDefault(choosenData.is_default);
      setIsHaveAccount(choosenData.is_have_coa);
      setIsSubCategory(choosenData.parent_id !== 0);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const handleSwitch = () => {
    if (parentCategoriesOption.length > 0) {
      setIsSubCategory(!isSubCategory);
    } else {
      alert("belum ada data kategori utama");
    }
  };

  const coaList = dataCoa?.coa.findAll.map((coa) => ({
    id: coa.coa_code.toString(),
    name: coa.coa_name,
  }));

  const onSubmit = (data) => {
    const inputs = {
      namaKategori: data.name,
      isDefault,
      isHaveCoa: isHaveAccount,
      headerKategori: parseFloat(data.parent_id || 0),
      coaPersediaan: data.coa_persediaan || null,
      coaBeban: data.coa_beban || null,
      coaPenjualan: data.coa_penjualan || null,
      coaReturPenjualan: data.coa_retur_penjualan || null,
      coaDiskonPenjualan: data.coa_diskon_penjualan || null,
      coaBarangTerkirim: data.coa_barang_terkirim || null,
      coaBahanPokokPenjualan: data.coa_beban_pokok_penjualan || null,
      coaReturPembelian: data.coa_retur_pembelian || null,
      coaPembelianBelumTagiha: data.coa_pembelian_belum_tagih || null,
      prefix: data.code,
    };
    if (detail) {
      ubahCategory({
        variables: {
          input: {
            id: parseFloat(choosenData.kategori_barang_id),
            ...inputs,
          },
        },
      });
    } else {
      createCategory({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteCategory({
      variables: {
        input: parseFloat(choosenData.kategori_barang_id),
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="left-content-module">
          <div className="main-content-module detail-page full-tab">
            {/* <!--menu tab tambahkan active class pada div yg sedang terbuka--> */}
            <div className="flex-center">
              <div
                className={`tab-list ${tab === 0 && "active"}`}
                onClick={() => setTab(0)}
              >
                Kategori Barang
              </div>
              {isHaveAccount && (
                <div
                  className={`tab-list ${tab === 1 && "active"}`}
                  onClick={() => setTab(1)}
                >
                  Akun
                </div>
              )}
            </div>

            {/* <!--detail menu tab pertama--> */}
            {tab === 0 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="form-wrap">
                    <div className="form-container">
                      <div className="title">Nama Barang</div>

                      <div className="auto-form-container">
                        <input
                          required
                          type="text"
                          placeholder="Masukkan nama"
                          {...register("name", { required: true })}
                        />
                      </div>
                    </div>

                    <div className="form-container">
                      <div className="title">Inisial Kode Kategori Barang</div>

                      <div className="auto-form-container">
                        <input
                          required
                          type="text"
                          placeholder="Masukkan Kode Barang ex: ABC dll."
                          {...register("code", { required: true })}
                        />
                      </div>
                    </div>

                    <div className="form-container flex-center w-100">
                      <div className="ppn-desc">
                        <div>Kategori Default</div>
                        {isDefault ? <div>Ya</div> : <div>Tidak</div>}
                      </div>
                      <div
                        className={`toggle-btn ${
                          isDefault ? "enable" : "disable"
                        }`}
                        onClick={() => setIsDefault(!isDefault)}
                      ></div>
                    </div>

                    <div className="form-container flex-center w-100">
                      <div className="ppn-desc">
                        <div>Sub Kategori</div>
                        {isSubCategory ? <div>Ya</div> : <div>Tidak</div>}
                      </div>
                      <div
                        className={`toggle-btn ${
                          isSubCategory ? "enable" : "disable"
                        }`}
                        onClick={handleSwitch}
                      ></div>
                    </div>

                    <div className="form-container flex-center w-100">
                      <div className="ppn-desc">
                        <div>Tambahkan Akun Perkiraan?</div>
                        {isHaveAccount ? <div>Ya</div> : <div>Tidak</div>}
                      </div>
                      <div
                        className={`toggle-btn ${
                          isHaveAccount ? "enable" : "disable"
                        }`}
                        onClick={() => setIsHaveAccount(!isHaveAccount)}
                      ></div>
                    </div>

                    {isSubCategory && (
                      <SearchInput
                        title="Kategori Utama"
                        placeholder="Cari kategori utama"
                        value={watch("parent_id")}
                        setValue={(e) => setValue("parent_id", e)}
                        searchData={parentCategoriesOption}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* <!--detail menu tab kedua--> */}
            {tab === 1 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <SearchInput
                        title="Persediaan"
                        placeholder="Cari akun persediaan"
                        value={watch("coa_persediaan")}
                        setValue={(e) => setValue("coa_persediaan", e)}
                        searchData={coaList}
                      />

                      <SearchInput
                        title="Beban"
                        placeholder="Cari akun beban"
                        value={watch("coa_beban")}
                        setValue={(e) => setValue("coa_beban", e)}
                        searchData={coaList}
                      />

                      <SearchInput
                        title="Penjualan"
                        placeholder="Cari akun penjualan"
                        value={watch("coa_penjualan")}
                        setValue={(e) => setValue("coa_penjualan", e)}
                        searchData={coaList}
                      />

                      <SearchInput
                        title="Retur Penjualan"
                        placeholder="Cari akun retur penjualan"
                        value={watch("coa_retur_penjualan")}
                        setValue={(e) => setValue("coa_retur_penjualan", e)}
                        searchData={coaList}
                      />

                      <SearchInput
                        title="Diskon Penjualan"
                        placeholder="Cari akun diskon penjualan"
                        value={watch("coa_diskon_penjualan")}
                        setValue={(e) => setValue("coa_diskon_penjualan", e)}
                        searchData={coaList}
                      />

                      <SearchInput
                        title="Barang Terkirim"
                        placeholder="Cari akun barang terkirim"
                        value={watch("coa_barang_terkirim")}
                        setValue={(e) => setValue("coa_barang_terkirim", e)}
                        searchData={coaList}
                      />

                      <SearchInput
                        title="Beban Pokok Penjualan"
                        placeholder="Cari akun beban pokok penjualan"
                        value={watch("coa_beban_pokok_penjualan")}
                        setValue={(e) =>
                          setValue("coa_beban_pokok_penjualan", e)
                        }
                        searchData={coaList}
                      />

                      <SearchInput
                        title="Retur Pembelian"
                        placeholder="Cari akun retur pembelian"
                        value={watch("coa_retur_pembelian")}
                        setValue={(e) => setValue("coa_retur_pembelian", e)}
                        searchData={coaList}
                      />

                      <SearchInput
                        title="Pembelian Belum Tertagih"
                        placeholder="Cari akun pembelian belum tertagih"
                        value={watch("coa_pembelian_belum_tagih")}
                        setValue={(e) =>
                          setValue("coa_pembelian_belum_tagih", e)
                        }
                        searchData={coaList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${
                (loading || loadingUpdate) && "disabled"
              }`}
              onClick={handleSubmit(onSubmit)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>

            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Kategori{" "}
              {choosenData?.category_name}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewCategory;
