import React, { useState } from "react";
import { useEffect } from "react";
import Status from "../../common/status";
import { format } from "date-fns";

const ReceiveOrderList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    loading,
    dataReceiveOrder,
  } = props;

  const [search, setSearch] = useState("");
  const [filteredCategory, setFilteredCategory] = useState([]);

  useEffect(() => {
    if (search.length > 0) {
      const filtered = dataReceiveOrder?.PenerimaanBarang.findAll.filter(
        (purchase) =>
          purchase.nomor_penerimaan.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredCategory(filtered);
    } else {
      setFilteredCategory(dataReceiveOrder?.PenerimaanBarang.findAll);
    }
  }, [search, dataReceiveOrder]);

  const receiveOrderList = filteredCategory;

  const handleOpenDetail = (choosen) => {
    if (tabs.find((tab) => tab.id === choosen.nomor_penerimaan)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: choosen.nomor_penerimaan,
          type: "detail",
          id: choosen.nomor_penerimaan,
        },
      ]);
    }
    setActive(choosen.nomor_penerimaan);
    setChoosenData([
      ...choosenData,
      { idTab: choosen.nomor_penerimaan, ...choosen },
    ]);
  };

  return (
    <>
      <div className="content_nav_module">
        <form id="" className="">
          <div className="nav-setting-wrap">
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-filter_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-import_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-cetaik_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-pengatura_ic"></span>
              </div>
            </div>
          </div>

          <div className="form-right-side">
            <div className="form-wrap">
              <div className="form-container">
                <div className="title">Pencarian</div>
                <input
                  id=""
                  className="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="icon-form-date icon-search_ic"></div>
              </div>
            </div>

            <div class="data-total-wrap">
              <div>TOTAL DATA</div>
              <div class="value">{filteredCategory?.length}</div>
            </div>
          </div>

          <div className="clear"></div>
        </form>
      </div>
      <div className="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <thead>
              <tr>
                <th>Nomor</th>
                <th>No. Terima</th>
                <th>Tanggal</th>
                <th>Pemasok</th>
                <th>Keterangan</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <p>Loading...</p>
              ) : receiveOrderList?.length === 0 ? (
                <tr>
                  <td>Tidak ada data.</td>
                </tr>
              ) : (
                receiveOrderList?.map((purchase, i) => (
                  <tr key={i} onClick={() => handleOpenDetail(purchase)}>
                    <td>{purchase.nomor_penerimaan}</td>
                    <td>{purchase.nomor_terima}</td>
                    <td>
                      {format(new Date(purchase.tgl_terima), "dd/MM/yyyy")}
                    </td>
                    <td>{purchase.pemasok.nama}</td>
                    <td>{purchase.keterangan}</td>
                    <td>
                      <Status isPaid={purchase.status} />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ReceiveOrderList;
