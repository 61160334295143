import { gql } from "@apollo/client";

export const ACCOUNT_GROUP_FRAGMENT = gql`
  fragment AccountGroup on mGroupAkun {
    kode_group
    deskripsi
    all_cabang
    all_menu
  }
`;

export const ACCOUNT_FRAGMENT = gql`
  fragment Account on mAkun {
    nama_user
    username
  }
`;

export const ACCOUNT_LIST = gql`
  query accountList {
    akun {
      findAll {
        ...Account
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const ACCOUNT_GROUP_LIST = gql`
  query accountGroupList {
    groupakun {
      findAll {
        ...AccountGroup
      }
    }
  }
  ${ACCOUNT_GROUP_FRAGMENT}
`;
