import React, { useState } from "react";
import { useEffect } from "react";
import Status from "../../common/status";
import { format } from "date-fns";

const RequestOrderList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    loading,
    dataRequestOrder,
  } = props;

  const [search, setSearch] = useState("");
  const [filteredCategory, setFilteredCategory] = useState([]);

  useEffect(() => {
    if (search.length > 0) {
      const filtered = dataRequestOrder?.PermintaanBarang.findAll.filter(
        (rqst) =>
          rqst.nomor_permintaan.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredCategory(filtered);
    } else {
      setFilteredCategory(dataRequestOrder?.PermintaanBarang.findAll);
    }
  }, [search, dataRequestOrder]);

  const requestOrderList = filteredCategory;

  const handleOpenDetail = (choosen) => {
    if (tabs.find((tab) => tab.id === choosen.nomor_permintaan)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: choosen.nomor_permintaan,
          type: "detail",
          id: choosen.nomor_permintaan,
        },
      ]);
    }
    setActive(choosen.nomor_permintaan);
    setChoosenData([
      ...choosenData,
      { idTab: choosen.nomor_permintaan, ...choosen },
    ]);
  };

  return (
    <>
      <div className="content_nav_module">
        <form id="" className="">
          <div className="nav-setting-wrap">
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-filter_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-import_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-cetaik_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-pengatura_ic"></span>
              </div>
            </div>
          </div>

          <div className="form-right-side">
            <div className="form-wrap">
              <div className="form-container">
                <div className="title">Pencarian</div>
                <input
                  id=""
                  className="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="icon-form-date icon-search_ic"></div>
              </div>
            </div>

            <div class="data-total-wrap">
              <div>TOTAL DATA</div>
              <div class="value">{filteredCategory?.length}</div>
            </div>
          </div>

          <div className="clear"></div>
        </form>
      </div>
      <div className="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <tr>
              <th>Nomor</th>
              <th>Tanggal</th>
              <th>Tipe Permintaan</th>
              <th>Keterangan</th>
              <th>Status</th>
              <th>Cabang</th>
            </tr>
            {loading ? (
              <p>Loading...</p>
            ) : requestOrderList?.length === 0 ? (
              <tr>
                <td>Tidak ada data.</td>
              </tr>
            ) : (
              requestOrderList?.map((rqst, i) => (
                <tr key={i} onClick={() => handleOpenDetail(rqst)}>
                  <td>{rqst.nomor_permintaan}</td>
                  <td>{format(new Date(rqst.tgl_permintaan), "dd/MM/yyyy")}</td>
                  <td>{rqst.tipe_permintaan}</td>
                  <td>{rqst.keterangan}</td>
                  <td>
                    <Status isPaid={rqst.status} />
                  </td>
                  <td>{rqst.cabang.nama_cabang}</td>
                </tr>
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default RequestOrderList;
