import React from "react";

const Container = (props) => {
  return (
    <div
      className="content-module-wrap"
      style={props.withoutTab && { marginTop: 0 }}
    >
      {props.children}
    </div>
  );
};

export default Container;
