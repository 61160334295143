import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchInput from "../../common/searchInput";
import { useMutation, useQuery } from "@apollo/client";
import { SATUAN_LIST } from "../../../actions/SatuanBarangAction";
import { GOODS_AND_SERVICE_LIST } from "../../../actions/BarangAction";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import { toast } from "react-toastify";
import { GUDANG_LIST } from "../../../actions/GudangAction";
import { formatRupiah, preventZero } from "../../utils";
import { SYARAT_PEMBAYARAN_LIST } from "../../../actions/SyaratPembayaranAction";
import { COA_LIST } from "../../../actions/CoaBarangAction";
import { SUPPLIER_LIST } from "../../../actions/SupplierAction";
import {
  CREATE_PURCHASE_INVOICE,
  DELETE_PURCHASE_INVOICE,
  PURCHASE_INVOICE_FIND_ORDER_LIST,
  UPDATE_PURCHASE_INVOICE,
} from "../../../actions/PurchaseInvoiceAction";
import { format } from "date-fns";
import { PURCHASE_ORDER_LIST } from "../../../actions/PurchaseOrderAction";
import { RECEIVE_ORDER_LIST } from "../../../actions/ReceiveOrderAction";
import DropdownInput from "../../common/dropdownInput";
import { useStateContext } from "../../../context";
import { menuList } from "../../utils/MenuList";

const AddNewPurchaseInvoice = (props) => {
  const {
    detail,
    choosenData,
    dataPerTabs,
    setChoosenData,
    tabs,
    setTabs,
    tabOpen,
    setTabOpen,
    refetch,
  } = props;

  const datePickerRef = useRef(null);
  const datePickerFakturRef = useRef(null);
  const datePickerSendRef = useRef(null);
  const hiddenButtonRef = useRef(null);

  const redirectingData = JSON.parse(localStorage.getItem("redirectingData"));

  const [state, setState] = useStateContext();

  const [tab, setTab] = useState(0);
  const [modalTab, setModalTab] = useState(0);
  const [bebanModalTab, setBebanModalTab] = useState(0);
  const [copyPesananTab, setCopyPesananTab] = useState(0);
  const [choosenPermintaanData, setChoosenPermintaanData] = useState({});
  const [show, setShow] = useState({
    menu: false,
    bebanModal: false,
    modal: false,
    ambilPesanan: false,
    ambilPenerimaan: false,
    ambilOption: false,
    proses: false,
  });
  const [productToAdd, setProductToAdd] = useState({
    code: "",
    name: "",
    quantity: 0,
    satuan: null,
    price: 0,
    percent: 0,
    flat: 0,
    kenaPajak: false,
    remarks: "",
    gudang: null,
    isPPN: false,
    mode: "c",
  });
  const [bebanToAdd, setBebanToAdd] = useState({
    code: "",
    name: "",
    jumlah: 0,
    mode: "c",
  });
  const [productPhoto, setProductPhoto] = useState(null);
  const [productList, setProductList] = useState([]);
  const [permintaanList, setPermintaanList] = useState([]);
  const [pesananBebanList, setPesananBebanList] = useState([]);
  const [bebanList, setBebanList] = useState([]);
  const [choosenId, setChoosenId] = useState(0);
  const [choosenBebanId, setChoosenBebanId] = useState(0);
  const [editProduct, setEditProduct] = useState(false);
  const [editBeban, setEditBeban] = useState(false);
  const [satuanOption, setSatuanOption] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [separatedDiscount, setSeparatedDiscount] = useState(0);
  const [showType, setShowType] = useState(false);
  const [showType2, setShowType2] = useState(false);
  const [redirectMode, setRedirectMode] = useState(false);

  const validationSchema = Yup.object().shape({
    noFakturFisik: Yup.string().required("Nomor Faktur Fisik"),
    nomorPermintaan: Yup.string().nullable(),
    pemasok: Yup.string().required("Pemasok"),
    tglFaktur: Yup.string().required("Tanggal Faktur"),
    syartPembayaran: Yup.string().required("Syarat Pembayaran"),
    gudangPenerima: Yup.string().required("Gudang Penerima"),
    alamatPenerima: Yup.string().required("Alamat Penerima"),
    keterangan: Yup.string().nullable(),
    isKenaPajak: Yup.boolean().default(false),
    isTotPajak: Yup.boolean().default(false),
    jenisDokumen: Yup.string().when("isTotPajak", {
      is: true,
      then: (schema) => schema.required("Jenis Dokumen"),
      otherwise: (schema) => schema.nullable(),
    }),
    jenisTransaksi: Yup.string().when("isTotPajak", {
      is: true,
      then: (schema) => schema.required("Jenis Transaksi"),
      otherwise: (schema) => schema.nullable(),
    }),
    tglFakturPajak: Yup.string().when("isTotPajak", {
      is: true,
      then: (schema) => schema.required("Tanggal Faktur Pajak"),
      otherwise: (schema) => schema.nullable(),
    }),
    nomorFakturPajak: Yup.string().when("isTotPajak", {
      is: true,
      then: (schema) => schema.required("Nomor Faktur Pajak"),
      otherwise: (schema) => schema.nullable(),
    }),
    tglPengiriman: Yup.string().required("Tanggal Pengiriman"),
    detail:
      productList.length > 0
        ? Yup.string().nullable()
        : Yup.string().required("Detail barang"),
    beban: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      parent_id: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = [...dataPerTabs];
    updatedList.splice(tabOpen, 1);

    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (productList.length === 0) {
      setValue("nomorPermintaan", "");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    $(datePickerRef.current).datepicker({
      onSelect: (dateText) => {
        setValue("tglFaktur", dateText);
      },
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tab === 1) {
      $(datePickerFakturRef.current).datepicker({
        onSelect: (dateText) => {
          setValue("tglFakturPajak", dateText);
        },
      });
    }
    // eslint-disable-next-line
  }, [tab]);

  useEffect(() => {
    if (tab === 1) {
      $(datePickerSendRef.current).datepicker({
        onSelect: (dateTexts) => {
          setValue("tglPengiriman", dateTexts);
        },
      });
    }
    // eslint-disable-next-line
  }, [tab]);
  const { data: satuanData } = useQuery(SATUAN_LIST, {
    variables: {
      input: {
        nama: "",
      },
    },
  });
  const { data: gudangData } = useQuery(GUDANG_LIST, {
    variables: {
      input: {},
    },
  });
  const { data: supplierData } = useQuery(SUPPLIER_LIST, {
    variables: {
      input: {},
    },
  });
  const { data: dataBarangJasa } = useQuery(GOODS_AND_SERVICE_LIST);
  const { data: dataSyarat } = useQuery(SYARAT_PEMBAYARAN_LIST);
  const { data: dataCoa } = useQuery(COA_LIST);
  const { data: dataReceiveOrder } = useQuery(RECEIVE_ORDER_LIST);
  const { data: dataPurchaseOrder } = useQuery(PURCHASE_ORDER_LIST);
  const { data: dataAmbilOrder } = useQuery(PURCHASE_INVOICE_FIND_ORDER_LIST);
  const [createPurchaseInvoice, { loading }] = useMutation(
    CREATE_PURCHASE_INVOICE,
    {
      onCompleted: (resp) => {
        const createdData = resp.FakturPembelian.create;
        toast.success("Faktur pembelian berhasil dibuat");
        refetch();

        if (redirectMode) {
          let menus = state.menuTabs;

          menus.push(menuList[5].child.row2[2]);

          setState({
            ...state,
            menuTabs: [...menus],
            idTabModule: 15,
          });

          localStorage.setItem(
            "redirectingData",
            JSON.stringify({
              kodeSupplier: createdData.pemasok.kode_supplier,
              detailFaktur: {
                itemId: 0,
                noForm: createdData.noFakturFisik,
                noFaktur: createdData.noFaktur,
                tglFaktur: format(
                  new Date(createdData.tglFaktur),
                  "MM/dd/yyyy"
                ),
                totalFaktur:
                  createdData.totalTerbayar + createdData.totalHutang,
                terhutang: createdData.totalHutang,
                bayar: "0",
                diskon: [],
              },
            })
          );
        } else {
          setTabOpen(0);
        }
      },
      onError: (resp) => {
        toast.error(resp.message);
      },
      // update(cache, { data }) {
      //   const list = cache.readQuery({
      //     query: CATEGORY_LIST,
      //   });
      //   cache.writeQuery({
      //     query: CATEGORY_LIST,
      //     data: {
      //       barang: {
      //         getKategoriBarang: [
      //           ...list.barang.getKategoriBarang,
      //           data.barang.createKategoriBarang,
      //         ],
      //       },
      //     },
      //   });
      // },
    }
  );

  const [deleteRequestInvoice] = useMutation(DELETE_PURCHASE_INVOICE, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   const updatedList = list.barang.getKategoriBarang.filter(
    //     (category) =>
    //       category.kategori_barang_id !== choosenData.kategori_barang_id
    //   );
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: updatedList,
    //       },
    //     },
    //   });
    // },
  });

  const [ubahRequestInvoice, { loading: loadingUpdate }] = useMutation(
    UPDATE_PURCHASE_INVOICE,
    {
      onCompleted: (resp) => {
        const createdData = resp.FakturPembelian.create;
        toast.success("Sukses mengubah data");
        refetch();

        if (redirectMode) {
          let menus = state.menuTabs;

          menus.push(menuList[5].child.row2[2]);

          setState({
            ...state,
            menuTabs: [...menus],
            idTabModule: 15,
          });

          localStorage.setItem(
            "redirectingData",
            JSON.stringify({
              kodeSupplier: createdData.pemasok.kode_supplier,
              detailFaktur: {
                itemId: 0,
                noForm: createdData.noFakturFisik,
                noFaktur: createdData.noFaktur,
                tglFaktur: format(
                  new Date(createdData.tglFaktur),
                  "MM/dd/yyyy"
                ),
                totalFaktur:
                  createdData.totalTerbayar + createdData.totalHutang,
                terhutang: createdData.totalHutang,
                bayar: "0",
                diskon: [],
              },
            })
          );
        } else {
          setTabOpen(0);
          setTabs(tabs.filter((tab) => tab.id !== tabOpen));
          handleUpdateTabData();
        }
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  const supplierList = supplierData?.Pemasok.findAll.map((pemasok) => ({
    id: pemasok.kode_supplier,
    name: `${pemasok.nama}`,
    desc: pemasok.kode_supplier,
  }));
  const dataSatuan = satuanData?.barang.getSatuanBarang.data.map((satuan) => ({
    id: satuan.uom_id,
    name: `${satuan.uom_alias} - ${satuan.uom_name}`,
    alias: satuan.uom_name,
  }));
  const barangJasaList = dataBarangJasa?.barang.findAll.data.map((barang) => ({
    id: barang.kode_barang,
    name: barang.nama_barang,
    ...barang,
  }));
  const dataGudang = gudangData?.Gudang.findAll.map((gudang) => ({
    id: gudang.kode_gudang,
    name: `Gudang ${gudang.nama_gudang}`,
    desc: gudang.alamat,
    ...gudang,
  }));
  const syaratPembayaranList = dataSyarat?.Pemasok.listSyarat?.map(
    (syarat) => ({
      id: syarat.syarat_pembayaran_id,
      name: syarat.nama_syarat_pembayaran,
    })
  );
  const allowedCoaTypes = [
    "1060",
    "6011",
    "4010",
    "5012",
    "5010",
    "4011",
    "5011",
  ];
  const coaList = dataCoa?.coa.findAll
    .filter((coa) => allowedCoaTypes.includes(coa.coa_type))
    .map((coa) => ({
      id: coa.coa_code.toString(),
      name: coa.coa_name,
      desc: coa.coa_code,
      type: coa.coa_type,
    }));
  const receiveOrderList = dataReceiveOrder?.PenerimaanBarang.findAll.map(
    (receive) => ({
      id: receive.nomor_penerimaan,
      name: receive.nomor_penerimaan,
      desc: `${format(new Date(receive.tgl_terima), "dd/MM/yyyy")} - ${
        receive.cabang.nama_cabang
      }`,
      detail: receive.detail_item,
      ...receive,
    })
  );
  const purchaseOrderList = dataPurchaseOrder?.PesananPembelian.findAll.map(
    (purchase) => ({
      id: purchase.nomor_pesanan,
      name: purchase.nomor_pesanan,
      desc: `${format(new Date(purchase.tgl_pesanan), "dd/MM/yyyy")} - ${
        purchase.cabang.nama_cabang
      }`,
      detail: purchase.detail_item,
      beban: purchase.beban,
      ...purchase,
    })
  );
  const findOrderList = show.ambilPenerimaan
    ? dataAmbilOrder?.FakturPembelian.findRI
    : dataAmbilOrder?.FakturPembelian.findPO;

  useEffect(() => {
    if (redirectingData !== null && dataAmbilOrder) {
      const choosenRi = dataAmbilOrder?.FakturPembelian.findRI.find(
        (row) => row.noBatch === redirectingData.nomorPenerimaan
      );

      const selectedDetail = choosenRi.detail;
      const selectedBiaya = choosenRi.beban;
      setValue("nomorPermintaan", redirectingData.nomorPenerimaan);

      const detailBarang = selectedDetail
        .filter((detail) => detail.pcs !== 0)
        .map((detail) => ({
          selected: false,
          code: detail.kodeBarang,
          name: detail.namaBarang,
          quantity: detail.pcs,
          satuan: detail.uom,
          price: detail.harga ?? 0,
          percent: detail.persenDiskon ?? 0,
          flat: detail.nilaiDiskon ?? 0,
          kenaPajak: detail.kenaPajak ?? false,
          remarks: detail.remarks,
          gudang: choosenRi.gudangPenerima.kode_gudang,
          mode: "c",
        }));
      const detailBiaya = selectedBiaya.map((biaya) => ({
        selected: false,
        code: biaya.kodeCoa,
        name: coaList?.find((coa) => coa.id === biaya.kodeCoa).name,
        jumlah: biaya.nilai,
      }));

      setProductList(detailBarang);
      setBebanList(detailBiaya);
      setValue("pemasok", choosenRi.pemasok.kode);
      setValue(
        "syartPembayaran",
        choosenRi.syartPembayaran.syarat_pembayaran_id
      );
      setValue(
        "alamatPenerima",
        `${choosenRi.gudangPenerima.alamat}, ${choosenRi.gudangPenerima.kota}, ${choosenRi.gudangPenerima.provinsi} \n${choosenRi.gudangPenerima.kodepos}`
      );
      setValue("gudangPenerima", choosenRi.gudangPenerima.kode_gudang);
      setValue("isTotPajak", choosenRi.isTotPajak);
      setValue(
        "tglPengiriman",
        format(new Date(choosenRi.tglPengiriman), "MM/dd/yyyy")
      );
    }
    // eslint-disable-next-line
  }, [dataAmbilOrder]);

  useEffect(() => {
    if (detail && choosenData) {
      setValue("noFakturFisik", choosenData.noFakturFisik);
      setValue("pemasok", choosenData.pemasok.kode_supplier);
      setValue(
        "tglFaktur",
        format(new Date(choosenData.tglFaktur), "MM/dd/yyyy")
      );
      setValue(
        "syartPembayaran",
        choosenData.syartPembayaran.syarat_pembayaran_id
      );
      setValue("gudangPenerima", choosenData.gudangPenerima.kode_gudang);
      setValue("alamatPenerima", choosenData.alamatPenerima);
      setValue("keterangan", choosenData.keterangan);
      setValue("jenisDokumen", choosenData.jenisDokumen);
      setValue("jenisTransaksi", choosenData.jenisTransaksi);
      setValue(
        "tglFakturPajak",
        format(new Date(choosenData.tglFakturPajak), "MM/dd/yyyy")
      );
      setValue("nomorFakturPajak", choosenData.nomorFakturPajak);
      setValue(
        "tglPengiriman",
        format(new Date(choosenData.tglPengiriman), "MM/dd/yyyy")
      );
      setValue("isTotPajak", choosenData.isTotPajak);
      setProductList(
        choosenData.detail.map((item) => ({
          code: item.kodeBarang,
          name: item.namaBarang,
          quantity: item.pcs,
          satuan: item.uom,
          price: item.harga,
          percent: item.persenDiskon,
          flat: item.nilaiDiskon,
          kenaPajak: item.kenaPajak === undefined ? false : item.kenaPajak,
          remarks: item.remarks,
          mode: "e",
        }))
      );
      setBebanList(
        choosenData.beban.map((beban) => ({
          code: beban.kodeCoa,
          name: coaList?.find((coa) => coa.id === beban.kodeCoa).name,
          jumlah: beban.nilai,
          mode: "e",
        }))
      );
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const handleCheckbox = (value, i) => {
    let temp = productList[i];
    temp.kenaPajak = value;
    productList[i] = temp;
    setProductList([...productList]);
  };

  const handleRequestCheckbox = (value, i) => {
    let temp = permintaanList[i];
    temp.selected = value;
    permintaanList[i] = temp;
    setPermintaanList([...permintaanList]);
  };

  const handleExpenseOrderCheckbox = (value, i) => {
    let temp = pesananBebanList[i];
    temp.selected = value;
    pesananBebanList[i] = temp;
    setPesananBebanList([...pesananBebanList]);
  };

  const handleGetSatuanOption = (selectedBarang) => {
    let tempSatuanOption = selectedBarang.detail_harga.map((harga) => ({
      id: harga.uom.uom_id,
      name: `${harga.uom.uom_alias} - ${harga.uom.uom_name}`,
      alias: harga.uom.uom_name,
    }));
    if (!tempSatuanOption.includes(selectedBarang.base_uom)) {
      tempSatuanOption.push({
        id: selectedBarang.base_uom.uom_id,
        name: `${selectedBarang.base_uom.uom_alias} - ${selectedBarang.base_uom.uom_name}`,
        alias: selectedBarang.base_uom.uom_name,
      });
    }
    setSatuanOption(tempSatuanOption);
  };

  const handleOpenBebanDetail = (index) => {
    const choosenBeban = bebanList[index];
    setBebanToAdd({
      code: choosenBeban.code,
      name: choosenBeban.name,
      jumlah: choosenBeban.jumlah,
      mode: choosenBeban.mode,
    });
    setShow({ ...show, bebanModal: true });
    setEditBeban(true);
    setChoosenBebanId(index);
  };

  const handleOpenDetail = (index) => {
    const choosenProduct = productList[index];
    const selectedBarang = barangJasaList?.find(
      (barang) => barang.id === choosenProduct.code
    );
    handleGetSatuanOption(selectedBarang);
    setProductToAdd({
      code: choosenProduct.code,
      name: choosenProduct.name,
      quantity: choosenProduct.quantity,
      satuan: choosenProduct.satuan,
      price: choosenProduct.price,
      percent: choosenProduct.percent,
      flat: choosenProduct.flat,
      kenaPajak: choosenProduct.kenaPajak,
      remarks: choosenProduct.remarks,
      gudang: choosenProduct.gudang,
      isPPN: choosenProduct.isPPN,
      mode: choosenProduct.mode,
    });
    setShow({ ...show, modal: true });
    setEditProduct(true);
    setChoosenId(index);

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}barang/upload/${choosenData.code}`
    )
      .then((res) => res.blob())
      .then((data) => setProductPhoto(data));
  };

  const handleAddTableBeban = () => {
    if (editBeban) {
      const temp = bebanList;
      bebanList[choosenBebanId] = bebanToAdd;
      setBebanList([...temp]);
    } else {
      setBebanList([...bebanList, bebanToAdd]);
    }
    setBebanModalTab(0);
    setBebanToAdd({
      code: "",
      name: "",
      jumlah: 0,
      mode: "c",
    });
    setShow({ ...show, bebanModal: false });
    setEditBeban(false);
  };

  const handleDeleteTableBeban = () => {
    const temp = [...bebanList];
    temp.splice(choosenBebanId, 1);
    setBebanList([...temp]);
    setBebanModalTab(0);
    setBebanToAdd({
      code: "",
      name: "",
      jumlah: 0,
      mode: "c",
    });
    setShow({ ...show, bebanModal: false });
    setEditBeban(false);
  };

  const handleAddTableProduct = () => {
    if (editProduct) {
      const temp = productList;
      productList[choosenId] = productToAdd;
      setProductList([...temp]);
    } else {
      setProductList([...productList, productToAdd]);
    }
    setModalTab(0);
    setProductToAdd({
      code: "",
      name: "",
      quantity: 0,
      satuan: null,
      price: 0,
      percent: null,
      flat: null,
      kenaPajak: false,
      remarks: "",
      gudang: null,
      isPPN: false,
      mode: "c",
    });
    setSatuanOption([]);
    setShow({ ...show, modal: false });
    setEditProduct(false);
  };

  const handleDeleteTableProduct = () => {
    const temp = [...productList];
    temp.splice(choosenId, 1);
    setProductList([...temp]);
    setEditProduct(false);
    setModalTab(0);
    setProductToAdd({
      code: "",
      name: "",
      quantity: 0,
      satuan: null,
      price: 0,
      percent: null,
      flat: null,
      kenaPajak: false,
      remarks: "",
      gudang: null,
      isPPN: false,
      mode: "c",
    });
    setSatuanOption([]);
    setShow({ ...show, modal: false });
  };

  const handleAmbilPermintaan = () => {
    if (permintaanList.length > 0) {
      const {
        pemasok,
        syaratPembayaran,
        alamatKirim,
        gudang,
        termasukPajak,
        tglKirim,
      } = choosenPermintaanData;
      const products = productList.concat(
        permintaanList.filter((request) => request.selected)
      ); // barang
      const expenses = bebanList.concat(
        pesananBebanList.filter((request) => request.selected)
      ); // beban
      setProductList(products);
      setBebanList(expenses);
      setShow({
        ...show,
        ambilPesanan: false,
        ambilPenerimaan: false,
      });
      setValue("pemasok", pemasok);
      setValue("syartPembayaran", syaratPembayaran);
      setValue("alamatPenerima", alamatKirim);
      setValue("gudangPenerima", gudang);
      setValue("isTotPajak", termasukPajak);
      setValue("tglPengiriman", tglKirim);
      setPermintaanList([]);
      setPesananBebanList([]);
      setChoosenPermintaanData({});
    } else {
      toast.error("Pilih salah satu nomor permintaan untuk melanjutkan");
    }
  };

  const handleSaveRequest = (dataSubmit) => {
    const inputs = {
      pemasok: dataSubmit.pemasok,
      noFakturFisik: dataSubmit.noFakturFisik,
      nomorPesanan: dataSubmit.nomorPermintaan
        ? dataSubmit.nomorPermintaan.slice(0, 2) === "PO"
          ? dataSubmit.nomorPermintaan
          : null
        : null,
      nomorPenerimaan: dataSubmit.nomorPermintaan
        ? dataSubmit.nomorPermintaan.slice(0, 2) === "RI"
          ? dataSubmit.nomorPermintaan
          : null
        : null,
      tglFaktur: format(new Date(dataSubmit.tglFaktur), "yyyy/MM/dd"),
      syartPembayaran: parseFloat(dataSubmit.syartPembayaran),
      gudangPenerima: dataSubmit.gudangPenerima,
      alamatPenerima: dataSubmit.alamatPenerima,
      keterangan: dataSubmit.keterangan,
      isKenaPajak: dataSubmit.isKenaPajak,
      isTotPajak: dataSubmit.isTotPajak,
      jenisDokumen: dataSubmit.jenisDokumen ? dataSubmit.jenisDokumen : null,
      jenisTransaksi: dataSubmit.jenisTransaksi
        ? dataSubmit.jenisTransaksi
        : null,
      tglFakturPajak: dataSubmit.tglFakturPajak
        ? format(new Date(dataSubmit.tglFakturPajak), "yyyy/MM/dd")
        : null,
      nomorFakturPajak: dataSubmit.nomorFakturPajak
        ? dataSubmit.nomorFakturPajak
        : null,
      tglPengiriman: format(new Date(dataSubmit.tglPengiriman), "yyyy/MM/dd"),
      detail: productList.map((product) => ({
        kodeBarang: product.code,
        pcs: parseFloat(product.quantity),
        uom: parseFloat(product.satuan),
        harga: parseFloat(product.price),
        persenDiskon: parseFloat(product.percent),
        nilaiDiskon: parseFloat(product.flat),
        kenaPajak: product.kenaPajak,
        remarks: product.remarks,
      })),
      beban:
        bebanList.length > 0
          ? bebanList.map((beban) => ({
              kodeBeban: beban.code,
              nilai: parseFloat(beban.jumlah),
            }))
          : [],
    };
    if (detail) {
      ubahRequestInvoice({
        variables: {
          input: {
            ...inputs,
            noFaktur: choosenData.noFaktur,
          },
        },
      });
    } else {
      createPurchaseInvoice({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteRequestInvoice({
      variables: {
        input: choosenData.noFaktur,
      },
    });
  };

  const subTotalCount = (
    price,
    quantity,
    flat,
    percent,
    kenaPajak,
    includeTax
  ) => {
    const taxMultiplier = includeTax ? 1.11 : 1;

    if (percent === 0 && flat === 0) {
      const subtotal = kenaPajak
        ? (price * quantity) / taxMultiplier
        : price * quantity;
      return Math.round(subtotal);
    }

    const discountMultiplier = 1 - percent / 100;
    const subtotalBeforeTax =
      flat === 0
        ? price * quantity * discountMultiplier
        : price * quantity - flat;

    const subtotal = kenaPajak
      ? subtotalBeforeTax / taxMultiplier
      : subtotalBeforeTax;

    return Math.round(subtotal);
  };

  const bebanValue =
    bebanList.length > 0
      ? bebanList?.reduce((total, beban) => total + parseFloat(beban.jumlah), 0)
      : 0;
  const subTotalValue =
    productList.length > 0
      ? productList?.reduce(
          (total, product) =>
            total +
            subTotalCount(
              product.price,
              product.quantity,
              product.flat,
              product.percent,
              product.kenaPajak,
              watch("isTotPajak")
            ),
          0
        )
      : 0;
  const subTotalWoTaxValue =
    productList.length > 0
      ? productList
          ?.filter((product) => product.kenaPajak === false)
          .reduce(
            (total, product) =>
              total +
              subTotalCount(
                product.price,
                product.quantity,
                product.flat,
                product.percent,
                product.kenaPajak,
                watch("isTotPajak")
              ),
            0
          )
      : 0;
  const ppnValue = parseInt(
    Math.ceil(
      subTotalValue - subTotalWoTaxValue === 0
        ? 0
        : (subTotalValue - subTotalWoTaxValue - separatedDiscount) * 0.11
    )
  );

  const handleItemClick = () => {
    setRedirectMode(true);
    if (hiddenButtonRef.current) {
      hiddenButtonRef.current.click();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleSaveRequest)}>
        <div className="left-content-module">
          <div className="content-nav-module">
            <button
              ref={hiddenButtonRef}
              type="submit"
              style={{ display: "none" }}
            >
              Hidden Button
            </button>
            <form>
              <div className="form-wrap">
                <SearchInput
                  title="Pemasok"
                  placeholder="Cari/Pilih Pemasok"
                  value={watch("pemasok")}
                  setValue={(e) => {
                    setValue("pemasok", e);
                  }}
                  searchData={supplierList}
                />
              </div>

              <div className="form-wrap">
                <div className="form-container">
                  <div className="title">Tanggal</div>
                  <input
                    ref={datePickerRef}
                    className="form-date-input"
                    type="text"
                    placeholder="Pilh tanggal"
                    value={watch("tglFaktur")}
                    onChange={(e) => setValue("tglFaktur", e.target.value)}
                  />
                  <div className="icon-form-date icon-date_ic"></div>
                </div>
              </div>

              <div className="form-right-side">
                {/* <!--RIGHT SIDE--> */}
                <div class="form-wrap">
                  <div class="form-container w-250px">
                    <div class="title">Nomor#</div>
                    <div class="auto-form-container disable w-fluid">
                      <input
                        name=""
                        type="text"
                        placeholder="Cari gudang"
                        value={watch("nomorPermintaan") || "-"}
                      />
                    </div>
                  </div>
                </div>

                <div className="nav-setting-wrap">
                  <div className="list-setting">
                    <div
                      class="setting-btn more-btn"
                      onClick={() =>
                        setShow({
                          ...show,
                          ambilOption: !show.ambilOption,
                          proses: false,
                        })
                      }
                    >
                      Ambil
                      <span class="icon-down_ic"></span>
                    </div>

                    <div
                      class="setting-btn more-btn"
                      onClick={() =>
                        setShow({
                          ...show,
                          proses: !show.proses,
                          ambilOption: false,
                        })
                      }
                    >
                      Proses <span class="icon-down_ic"></span>
                    </div>

                    {show.proses && (
                      <div class="popup-setting popup-right">
                        <ul>
                          <li
                            style={{
                              display: "block",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              cursor: "pointer",
                            }}
                            onClick={handleItemClick}
                          >
                            Pembayaran
                          </li>
                        </ul>
                      </div>
                    )}

                    {show.ambilOption && (
                      <div class="popup-setting popup-right">
                        <ul>
                          <li
                            style={{
                              display: "block",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setShow({
                                ...show,
                                ambilPesanan: true,
                                ambilOption: false,
                              })
                            }
                          >
                            Pesanan
                          </li>
                          <li
                            style={{
                              display: "block",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setShow({
                                ...show,
                                ambilPenerimaan: true,
                                ambilOption: false,
                              })
                            }
                          >
                            Penerimaan
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                {(show.ambilPesanan || show.ambilPenerimaan) && (
                  <div class="window-overlay">
                    <div class="window-container">
                      <div class="window-nav">
                        <div class="title flex-center">
                          Salin dari{" "}
                          {show.ambilPesanan
                            ? "pesanan pembelian"
                            : "penerimaan barang"}
                        </div>
                        <div class="close-btn flex-center">
                          <span
                            class="icon icon-close_ic"
                            onClick={() => {
                              setShow({
                                ...show,
                                ambilPesanan: false,
                                ambilPenerimaan: false,
                              });
                              setPermintaanList([]);
                              setPesananBebanList([]);
                              setChoosenPermintaanData({});
                            }}
                          ></span>
                        </div>
                      </div>

                      <div class="window-content">
                        <form id="" class="">
                          <div class="form-wrap no-float">
                            <SearchInput
                              title={
                                show.ambilPesanan ? "Pesanan" : "Penerimaan"
                              }
                              placeholder={
                                show.ambilPesanan
                                  ? "Cari/Pilih Nomor Pesanan"
                                  : "Cari/Pilih Nomor Penerimaan"
                              }
                              setValue={(e) => {
                                const selectedData = findOrderList.find(
                                  (requestInvoice) =>
                                    requestInvoice.noBatch === e
                                );

                                const selectedDetail = selectedData.detail;
                                const selectedBiaya = selectedData.beban;
                                setValue("nomorPermintaan", e);

                                const detailBarang = selectedDetail
                                  .filter((detail) => detail.pcs !== 0)
                                  .map((detail) => ({
                                    selected: false,
                                    code: detail.kodeBarang,
                                    name: detail.namaBarang,
                                    quantity: detail.pcs,
                                    satuan: detail.uom,
                                    price: detail.harga ?? 0,
                                    percent: detail.persenDiskon ?? 0,
                                    flat: detail.nilaiDiskon ?? 0,
                                    kenaPajak: detail.kenaPajak ?? false,
                                    remarks: detail.remarks,
                                    gudang:
                                      selectedData.gudangPenerima.kode_gudang,
                                    mode: "c",
                                  }));
                                const detailBiaya = selectedBiaya.map(
                                  (biaya) => ({
                                    selected: false,
                                    code: biaya.kodeCoa,
                                    name: coaList?.find(
                                      (coa) => coa.id === biaya.kodeCoa
                                    ).name,
                                    jumlah: biaya.nilai,
                                  })
                                );
                                setPermintaanList(detailBarang);
                                setPesananBebanList(detailBiaya);
                                setChoosenPermintaanData({
                                  pemasok: selectedData.pemasok.kode,
                                  syaratPembayaran:
                                    selectedData.syartPembayaran
                                      .syarat_pembayaran_id,
                                  alamatKirim: `${selectedData.gudangPenerima.alamat}, ${selectedData.gudangPenerima.kota}, ${selectedData.gudangPenerima.provinsi} \n${selectedData.gudangPenerima.kodepos}`,
                                  gudang:
                                    selectedData.gudangPenerima.kode_gudang,
                                  termasukPajak: selectedData.isTotPajak,
                                  tglKirim: format(
                                    new Date(selectedData.tglPengiriman),
                                    "MM/dd/yyyy"
                                  ),
                                });
                              }}
                              searchData={
                                show.ambilPesanan
                                  ? purchaseOrderList.filter((poItem) =>
                                      findOrderList
                                        .map((ford) => ford.noBatch)
                                        .includes(poItem.id)
                                    )
                                  : receiveOrderList.filter((roItem) =>
                                      findOrderList
                                        .map((ford) => ford.noBatch)
                                        .includes(roItem.id)
                                    )
                              }
                            />
                            <div class="clear"></div>
                          </div>
                        </form>

                        {show.ambilPesanan && (
                          <div
                            class="tab_content_module"
                            style={{
                              position: "relative",
                              marginBottom: "20px",
                            }}
                          >
                            {["List Barang", "Biaya Lainnya"].map((tab, i) => (
                              <div
                                class={`tab_menu_content ${
                                  copyPesananTab === i && "active"
                                }`}
                                onClick={() => setCopyPesananTab(i)}
                              >
                                {tab}
                              </div>
                            ))}
                          </div>
                        )}

                        <div class="main-content-module">
                          {copyPesananTab === 0 && (
                            <div class="">
                              <table id="all-data-table" class="detail-page">
                                <thead>
                                  <tr>
                                    <th>
                                      <label class="checkbox">
                                        <input
                                          type="checkbox"
                                          checked={
                                            permintaanList?.filter(
                                              (request) => request.selected
                                            ).length > 0
                                          }
                                          onClick={() => {
                                            let temp = permintaanList;
                                            let a = temp.map((request) => ({
                                              ...request,
                                              selected: !request.selected,
                                            }));
                                            setPermintaanList([...a]);
                                          }}
                                        />
                                        <span class="checkmark"></span>
                                      </label>
                                    </th>
                                    <th>Kode #</th>
                                    <th>Nama Barang</th>
                                    <th>Kuantitas</th>
                                    <th>Satuan</th>
                                    <th>Keterangan</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {permintaanList?.map((request, i) => (
                                    <tr>
                                      <td>
                                        <label class="checkbox">
                                          <input
                                            type="checkbox"
                                            checked={request.selected}
                                            onClick={() =>
                                              handleRequestCheckbox(
                                                !request.selected,
                                                i
                                              )
                                            }
                                          />
                                          <span class="checkmark"></span>
                                        </label>
                                      </td>
                                      <td>{request.code}</td>
                                      <td>{request.name}</td>
                                      <td>{request.quantity}</td>
                                      <td>
                                        {
                                          dataSatuan?.find(
                                            (satuan) =>
                                              satuan.id === request.satuan
                                          ).name
                                        }
                                      </td>
                                      <td>{request.remarks}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}

                          {copyPesananTab === 1 && (
                            <div class="">
                              <table id="all-data-table" class="detail-page">
                                <thead>
                                  <tr>
                                    <th>
                                      <label class="checkbox">
                                        <input
                                          type="checkbox"
                                          checked={
                                            pesananBebanList?.filter(
                                              (request) => request.selected
                                            ).length > 0
                                          }
                                          onClick={() => {
                                            let temp = pesananBebanList;
                                            let a = temp.map((request) => ({
                                              ...request,
                                              selected: !request.selected,
                                            }));
                                            setPesananBebanList([...a]);
                                          }}
                                        />
                                        <span class="checkmark"></span>
                                      </label>
                                    </th>
                                    <th>Kode #</th>
                                    <th>Nama</th>
                                    <th>Jumlah</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {pesananBebanList?.map((request, i) => (
                                    <tr>
                                      <td>
                                        <label class="checkbox">
                                          <input
                                            type="checkbox"
                                            checked={request.selected}
                                            onClick={() =>
                                              handleExpenseOrderCheckbox(
                                                !request.selected,
                                                i
                                              )
                                            }
                                          />
                                          <span class="checkmark"></span>
                                        </label>
                                      </td>
                                      <td>{request.code}</td>
                                      <td>{request.name}</td>
                                      <td>{formatRupiah(request.jumlah)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="window-bottom">
                        <div
                          class="button btn-blue flex-center"
                          onClick={handleAmbilPermintaan}
                        >
                          Lanjutkan
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="clear"></div>
            </form>
          </div>
          <div className="main-content-module detail-page full-side-tab">
            <div className="detail-data-wrap">
              <div className="wrap">
                <div
                  className={`tab-menu ${tab === 0 && "active"}`}
                  onClick={() => setTab(0)}
                >
                  <span className="icon-input_ic"></span>
                </div>
                <div
                  className={`tab-menu ${tab === 1 && "active"}`}
                  onClick={() => setTab(1)}
                >
                  <span className="icon-info_ic"></span>
                </div>
                <div
                  className={`tab-menu ${tab === 2 && "active"}`}
                  onClick={() => setTab(2)}
                >
                  <span className="icon-biaya_lain_ic"></span>
                </div>
                {detail && (
                  <div
                    className={`tab-menu ${tab === 3 && "active"}`}
                    onClick={() => setTab(3)}
                  >
                    <span class="icon-doc_ic"></span>
                  </div>
                )}
              </div>

              {tab === 0 && (
                <div className="content-wrap">
                  <div className="content-nav-module">
                    <form id="" className="">
                      <div className="form-wrap">
                        <SearchInput
                          title="Barang &amp; Jasa"
                          placeholder="Cari/Pilih Barang & Jasa"
                          setValue={(e) => {
                            const selectedBarang = barangJasaList?.find(
                              (barang) => barang.id === e
                            );
                            handleGetSatuanOption(selectedBarang);
                            setProductToAdd({
                              code: selectedBarang.id,
                              name: selectedBarang.name,
                              quantity: 0,
                              satuan: selectedBarang.base_uom.uom_id,
                              price: 0,
                              percent: 0,
                              flat: 0,
                              kenaPajak: false,
                              remarks: "",
                              gudang: null,
                              isPPN: false,
                              mode: "c",
                            });
                            setShow({ ...show, modal: true });
                            fetch(
                              `${process.env.REACT_APP_BACKEND_URL}barang/upload/${selectedBarang.id}`
                            )
                              .then((res) => res.blob())
                              .then((data) => setProductPhoto(data));
                          }}
                          searchData={barangJasaList}
                        />
                      </div>

                      <div className="form-right-side">
                        <div className="form-wrap"></div>
                      </div>

                      <div className="clear"></div>
                    </form>
                  </div>
                  <div className="main-content-module">
                    <table id="all-data-table" class="detail-page m-b-30">
                      <tr>
                        <th width="45">PJK</th>
                        <th>Nama Barang</th>
                        <th>Kode #</th>
                        <th>Kuantitas</th>
                        <th>Satuan</th>
                        <th>@Harga</th>
                        <th>Diskon</th>
                        <th>Total Harga</th>
                      </tr>
                      {productList.map((row, i) => (
                        <tr key={i}>
                          <td>
                            <label
                              class="checkbox"
                              onChange={() => handleCheckbox(!row.kenaPajak, i)}
                            >
                              <input type="checkbox" checked={row.kenaPajak} />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {row.name}
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {row.code}
                          </td>
                          <td
                            class="txt-right"
                            onClick={() => handleOpenDetail(i)}
                          >
                            {row.quantity}
                          </td>
                          <td
                            class="txt-right"
                            onClick={() => handleOpenDetail(i)}
                          >
                            {
                              dataSatuan?.find(
                                (satuan) => satuan.id === row.satuan
                              ).name
                            }
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {formatRupiah(row.price)}
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {formatRupiah(
                              row.flat ||
                                (row.price * row.quantity * row.percent) / 100
                            )}
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {formatRupiah(
                              row.price * row.quantity -
                                (row.flat ||
                                  (row.price * row.quantity * row.percent) /
                                    100)
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>

                  {show.modal && (
                    <>
                      {/* <!--POPUP PRODUCT --> */}
                      <div class="window-overlay">
                        <div class="window-container product-window">
                          <div class="window-nav">
                            {/* <!--CONTENT TAB--> */}
                            <div class="tab_content_module">
                              {["Rincian Barang", "Keterangan"].map(
                                (tab, i) => (
                                  <div
                                    class={`tab_menu_content ${
                                      modalTab === i && "active"
                                    }`}
                                    onClick={() => setModalTab(i)}
                                  >
                                    {tab}
                                  </div>
                                )
                              )}
                            </div>
                            <div
                              class="close-btn flex-center"
                              onClick={() => {
                                setShow({ ...show, modal: false });
                                setProductToAdd({
                                  code: "",
                                  name: "",
                                  tglDiminta: "",
                                  quantity: 0,
                                  satuan: null,
                                  remarks: "",
                                  gudang: null,
                                  isPPN: false,
                                  mode: "c",
                                });
                              }}
                            >
                              <span class="icon icon-close_ic"></span>
                            </div>
                          </div>
                          <div class="window-content">
                            {modalTab === 0 && (
                              <>
                                <div className="product-img">
                                  {productPhoto !== null && (
                                    <img
                                      src={URL.createObjectURL(productPhoto)}
                                      alt={`${productToAdd.code} - ${productToAdd.name}`}
                                    />
                                  )}
                                </div>

                                <div class="form-side">
                                  <form id="" class="">
                                    <div class="form-wrap">
                                      <div className="form-container lg">
                                        <div class="title">Kode#</div>

                                        <b className="text-desc">
                                          {productToAdd.code}
                                        </b>
                                      </div>

                                      <div class="form-container lg">
                                        <div class="title">Nama</div>
                                        <div class="auto-form-container disable">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            disabled
                                            value={productToAdd.name}
                                          />
                                        </div>
                                      </div>

                                      <div class="form-container md">
                                        <div class="title">Kuantitas</div>
                                        <div class="auto-form-container">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            value={productToAdd.quantity}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                quantity: preventZero(e),
                                              })
                                            }
                                          />
                                        </div>
                                        <div class="icon-form-date icon-penjualan_ic"></div>
                                      </div>

                                      <SearchInput
                                        className="sm padding-l"
                                        title="Satuan"
                                        placeholder="Cari satuan"
                                        value={productToAdd.satuan}
                                        setValue={(e) =>
                                          setProductToAdd({
                                            ...productToAdd,
                                            satuan: e,
                                          })
                                        }
                                        searchData={satuanOption}
                                      />

                                      <div class="form-container lg">
                                        <div class="title">Harga</div>
                                        <div class="auto-form-container fi">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Masukkan Harga"
                                            value={formatRupiah(
                                              productToAdd.price
                                            )}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                price: preventZero(e),
                                              })
                                            }
                                          />
                                        </div>
                                        <div class="fi-icon">Rp</div>
                                        <div class="icon-form-date icon-biaya_lain_ic"></div>
                                      </div>
                                      <div class="form-container hlf padding-r">
                                        <div class="title">Diskon</div>
                                        <div class="auto-form-container fi">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            value={productToAdd.percent}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                percent: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                        <div class="fi-icon">%</div>
                                      </div>
                                      <div class="form-container hlf">
                                        <div class="title"></div>
                                        <div class="auto-form-container fi">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Masukkan harga diskon"
                                            value={
                                              parseFloat(productToAdd.flat) !==
                                              0
                                                ? formatRupiah(
                                                    productToAdd.flat || 0
                                                  )
                                                : formatRupiah(
                                                    (productToAdd.price *
                                                      productToAdd.quantity *
                                                      productToAdd.percent) /
                                                      100
                                                  )
                                            }
                                            onChange={(e) => {
                                              setProductToAdd({
                                                ...productToAdd,
                                                flat: preventZero(e),
                                                percent: 0,
                                              });
                                            }}
                                          />
                                        </div>
                                        <div class="fi-icon">Rp</div>
                                      </div>

                                      <div class="form-container lg">
                                        <div class="title">Total Harga</div>
                                        <div class="auto-form-container fi disable">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            value={formatRupiah(
                                              productToAdd.price *
                                                productToAdd.quantity -
                                                (productToAdd.flat ||
                                                  (productToAdd.price *
                                                    productToAdd.quantity *
                                                    productToAdd.percent) /
                                                    100)
                                            )}
                                          />
                                        </div>
                                        <div class="fi-icon">Rp</div>
                                        <div class="icon-form-date icon-biaya_lain_ic"></div>
                                      </div>

                                      {/* <SearchInput
                                        className="form-container lg"
                                        title="Gudang"
                                        placeholder="Cari gudang"
                                        value={productToAdd.gudang}
                                        setValue={(e) =>
                                          setProductToAdd({
                                            ...productToAdd,
                                            gudang: e,
                                          })
                                        }
                                        searchData={dataGudang}
                                      /> */}
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}

                            {modalTab === 1 && (
                              <div class="content-wrap">
                                <div class="content-nav-module">
                                  <form id="" class="">
                                    <div class="col-fulltab-50">
                                      <div class="form-wrap">
                                        <div class="form-container">
                                          <div class="auto-form-container w-400px">
                                            <textarea
                                              id=""
                                              name=""
                                              rows="4"
                                              cols="50"
                                              value={productToAdd.remarks}
                                              onChange={(e) =>
                                                setProductToAdd({
                                                  ...productToAdd,
                                                  remarks: e.target.value,
                                                })
                                              }
                                              placeholder="Tuliskan keterangan di sini"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </div>
                          <div class="window-bottom">
                            <div
                              class="button btn-blue flex-center"
                              onClick={handleAddTableProduct}
                            >
                              Simpan
                            </div>
                            {editProduct && (
                              <div
                                class="button btn-red flex-center"
                                onClick={handleDeleteTableProduct}
                              >
                                Hapus
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {tab === 1 && (
                <div
                  class="content-wrap"
                  style={{ height: "300px", overflow: "auto" }}
                >
                  <div class="content-nav-module">
                    <form id="" class="">
                      <div class="col-fulltab-50">
                        <div class="form-wrap w-full">
                          <div class="section-title">Info Lainnya</div>

                          <div className="form-container">
                            <div class="title">No Faktur #</div>
                            <div class="auto-form-container">
                              <input
                                name=""
                                type="text"
                                placeholder="Masukkan nomor faktur"
                                required
                                {...register("noFakturFisik", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>

                          <SearchInput
                            title="Syarat Pembayaran"
                            placeholder="Cari syarat pembayaran"
                            value={watch("syartPembayaran")}
                            setValue={(e) => setValue("syartPembayaran", e)}
                            searchData={syaratPembayaranList}
                          />

                          <SearchInput
                            title="Alamat Kirim"
                            placeholder="Cari gudang"
                            value={watch("gudangPenerima")}
                            setValue={(e) => {
                              const selectedGudang = dataGudang.find(
                                (gudang) => gudang.id === e
                              );
                              setValue("gudangPenerima", e);
                              setValue(
                                "alamatPenerima",
                                `${selectedGudang.alamat}, ${selectedGudang.kota}, ${selectedGudang.provinsi} \n${selectedGudang.kodepos}`
                              );
                            }}
                            searchData={dataGudang}
                          />

                          {watch("alamatPenerima") !== undefined && (
                            <div class="form-container">
                              <div class="auto-form-container w-400px disable">
                                <textarea
                                  id=""
                                  name=""
                                  rows="4"
                                  cols="50"
                                  disabled
                                  placeholder="Tuliskan catatan di sini"
                                  {...register("alamatPenerima")}
                                ></textarea>
                              </div>
                            </div>
                          )}

                          <div class="form-container">
                            <div class="title">Keterangan</div>

                            <div class="auto-form-container w-400px">
                              <textarea
                                id=""
                                name=""
                                rows="4"
                                cols="50"
                                placeholder="Tuliskan catatan di sini"
                                {...register("keterangan")}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-fulltab-50">
                        <div class="form-wrap w-full">
                          <div class="section-title">Info Pajak</div>

                          <div class="form-container flex-center w-100">
                            <div class="ppn-desc">
                              <div>Total Termasuk Pajak</div>
                              <div>{watch("isTotPajak") ? "Ya" : "Tidak"}</div>
                            </div>
                            <div
                              class={`toggle-btn ${
                                watch("isTotPajak") ? "enable" : "disable"
                              }`}
                              onClick={() =>
                                setValue("isTotPajak", !watch("isTotPajak"))
                              }
                            ></div>
                          </div>

                          <DropdownInput
                            title="Jenis Dokumen"
                            setOpen={(e) => setShowType(e)}
                          >
                            {showType && (
                              <div class="popup-wrap gudang-wrap">
                                <ul>
                                  {[
                                    "Faktur Pajak",
                                    "Dokumen Dipersamakan FP",
                                    "Dokumen PIB dan SSP",
                                    "Dokumen PIB",
                                    "Dokumen SSP",
                                    "Dokumen PMSE",
                                    "Dokumen SKP",
                                    "Digunggung",
                                  ]?.map((row, i) => (
                                    <li key={i}>
                                      <div
                                        class="text-list"
                                        onClick={() => {
                                          setValue("jenisDokumen", row);
                                          setShowType(false);
                                        }}
                                      >
                                        {row}
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            <input
                              name=""
                              type="text"
                              placeholder="Pilih Jenis Dokumen"
                              value={watch("jenisDokumen")}
                              onClick={() => setShowType(!showType)}
                            />
                          </DropdownInput>

                          <DropdownInput
                            title="Jenis Transaksi"
                            setOpen={(e) => setShowType2(e)}
                          >
                            {showType2 && (
                              <div class="popup-wrap gudang-wrap">
                                <ul>
                                  {[
                                    "01 - Perolehan dalam negeri - Bukan pemungut PPN",
                                  ]?.map((row, i) => (
                                    <li key={i}>
                                      <div
                                        class="text-list"
                                        onClick={() => {
                                          setValue("jenisTransaksi", row);
                                          setShowType2(false);
                                        }}
                                      >
                                        {row}
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            <input
                              name=""
                              type="text"
                              placeholder="Pilih Jenis Transaksi"
                              value={watch("jenisTransaksi")}
                              onClick={() => setShowType2(!showType2)}
                            />
                          </DropdownInput>

                          <div className="form-container">
                            <div className="title">Tgl Faktur Pajak</div>
                            <input
                              ref={datePickerFakturRef}
                              className="form-date-input"
                              type="text"
                              placeholder="Pilh tanggal"
                              value={watch("tglFakturPajak")}
                              onChange={(e) =>
                                setValue("tglFakturPajak", e.target.value)
                              }
                            />
                            <div className="icon-form-date icon-date_ic"></div>
                          </div>

                          <div className="form-container">
                            <div class="title">Nomor Faktur Pajak</div>
                            <div class="auto-form-container">
                              <input
                                name=""
                                type="text"
                                placeholder="Masukkan nomor faktur"
                                required
                                value={watch("nomorFakturPajak")}
                                // value={formatNsfp(watch("nomorFakturPajak"))}
                                onChange={(e) =>
                                  setValue("nomorFakturPajak", e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div class="section-title">Info Pengiriman</div>

                          <div className="form-container">
                            <div className="title">Tgl Pengiriman</div>
                            <input
                              ref={datePickerSendRef}
                              className="form-date-input"
                              type="text"
                              placeholder="Pilh tanggal"
                              value={watch("tglPengiriman")}
                              onChange={(e) =>
                                setValue("tglPengiriman", e.target.value)
                              }
                            />
                            <div className="icon-form-date icon-date_ic"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {tab === 2 && (
                <div className="content-wrap">
                  <div className="content-nav-module">
                    <form id="" className="">
                      <div className="form-wrap">
                        <SearchInput
                          title="Akun Perkiraan"
                          placeholder="Cari/Pilih Akun Perkiraan"
                          setValue={(e) => {
                            const selectedBeban = coaList?.find(
                              (coa) => coa.id === e
                            );
                            setBebanToAdd({
                              ...bebanToAdd,
                              code: selectedBeban.id,
                              name: selectedBeban.name,
                            });
                            setShow({ ...show, bebanModal: true });
                          }}
                          searchData={coaList}
                        />
                      </div>

                      <div className="form-right-side">
                        <div className="form-wrap"></div>
                      </div>

                      <div className="clear"></div>
                    </form>
                  </div>
                  <div className="main-content-module">
                    <table id="all-data-table" class="detail-page m-b-30">
                      <tr>
                        <th>Kode #</th>
                        <th>Nama</th>
                        <th>Jumlah</th>
                      </tr>
                      {bebanList.map((row, i) => (
                        <tr key={i} onClick={() => handleOpenBebanDetail(i)}>
                          <td>{row.code}</td>
                          <td>{row.name}</td>
                          <td>{formatRupiah(row.jumlah)}</td>
                        </tr>
                      ))}
                    </table>
                  </div>

                  {show.bebanModal && (
                    <>
                      {/* <!--POPUP PRODUCT --> */}
                      <div class="window-overlay">
                        <div class="window-container product-window">
                          <div class="window-nav">
                            {/* <!--CONTENT TAB--> */}
                            <div class="tab_content_module">
                              {["Biaya Lainnya"].map((tab, i) => (
                                <div
                                  class={`tab_menu_content ${
                                    bebanModalTab === i && "active"
                                  }`}
                                  onClick={() => setBebanModalTab(i)}
                                >
                                  {tab}
                                </div>
                              ))}
                            </div>
                            <div
                              class="close-btn flex-center"
                              onClick={() => {
                                setShow({ ...show, bebanModal: false });
                                setProductToAdd({
                                  code: "",
                                  name: "",
                                  jumlah: 0,
                                  mode: "c",
                                });
                              }}
                            >
                              <span class="icon icon-close_ic"></span>
                            </div>
                          </div>
                          <div class="window-content">
                            {bebanModalTab === 0 && (
                              <div class="form-side">
                                <form id="" class="">
                                  <div class="form-wrap">
                                    <div className="form-container lg">
                                      <div class="title">Kode#</div>

                                      <b className="text-desc">
                                        {bebanToAdd.code}
                                      </b>
                                    </div>

                                    <div class="form-container lg">
                                      <div class="title">Nama</div>
                                      <div class="auto-form-container disable">
                                        <input
                                          name=""
                                          type="text"
                                          placeholder="Cari gudang"
                                          disabled
                                          value={bebanToAdd.name}
                                        />
                                      </div>
                                    </div>

                                    <div class="form-container lg">
                                      <div class="title">Harga</div>
                                      <div class="auto-form-container fi">
                                        <input
                                          name=""
                                          type="text"
                                          placeholder="Masukkan Harga"
                                          value={formatRupiah(
                                            bebanToAdd.jumlah
                                          )}
                                          onChange={(e) =>
                                            setBebanToAdd({
                                              ...bebanToAdd,
                                              jumlah: preventZero(e),
                                            })
                                          }
                                        />
                                      </div>
                                      <div class="fi-icon">Rp</div>
                                      <div class="icon-form-date icon-biaya_lain_ic"></div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            )}

                            {/* {bebanModalTab === 1 && (
                              <div class="content-wrap">
                                <div class="content-nav-module">
                                  <form id="" class="">
                                    <div class="col-fulltab-50">
                                      <div class="form-wrap">
                                        <div class="form-container">
                                          <div class="auto-form-container w-400px">
                                            <textarea
                                              id=""
                                              name=""
                                              rows="4"
                                              cols="50"
                                              value={productToAdd.remarks}
                                              onChange={(e) =>
                                                setProductToAdd({
                                                  ...productToAdd,
                                                  remarks: e.target.value,
                                                })
                                              }
                                              placeholder="Tuliskan keterangan di sini"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )} */}
                          </div>
                          <div class="window-bottom">
                            <div
                              class="button btn-blue flex-center"
                              onClick={handleAddTableBeban}
                            >
                              Simpan
                            </div>
                            {editBeban && (
                              <div
                                class="button btn-red flex-center"
                                onClick={handleDeleteTableBeban}
                              >
                                Hapus
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {tab === 3 && (
                <div class="content-wrap">
                  <div class="content-nav-module full-height">
                    <form id="" class="">
                      <div class="col-content">
                        <div class="section-title">Informasi Faktur</div>
                        <div class="row">
                          <div class="label">Total</div>
                          <div class="label bold txt-right">
                            Rp{" "}
                            {formatRupiah(
                              subTotalValue +
                                bebanValue -
                                separatedDiscount +
                                ppnValue
                            )}
                          </div>
                        </div>
                        <div class="row">
                          <div class="label">Uang Muka</div>
                          <div class="label bold txt-right">Rp 0</div>
                        </div>
                        <div class="row">
                          <div class="label">Pembayaran</div>
                          <div class="label bold txt-right">Rp 0</div>
                        </div>
                        <div class="row">
                          <div class="label">Bayar PPN</div>
                          <div class="label bold txt-right">Rp 0</div>
                        </div>
                        <div class="row">
                          <div class="label">Retur</div>
                          <div class="label bold txt-right">Rp 0</div>
                        </div>
                        <div class="row">
                          <div class="label">Retur PPN</div>
                          <div class="label bold txt-right">Rp 0</div>
                        </div>
                        <div class="row">
                          <div class="label">Utang</div>
                          <div class="label bold txt-right">Rp 0</div>
                        </div>
                        <div class="row">
                          <div class="label">Utang Pajak</div>
                          <div class="label bold txt-right">Rp 0</div>
                        </div>
                        <div class="row">
                          <div class="label">Status</div>
                          <div class="label txt-right">
                            <div class="lbl unpaid float-r"></div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="label">Cetak/email</div>
                          <div class="label bold txt-right">
                            Belum cetak/email
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              <div class="summary-section">
                <div class="summary-list-wrap">
                  <div class="summary-list">
                    <div class="summary-indicator blue"></div>
                    <div class="title">Sub Total</div>
                    <div class="nominal">
                      {formatRupiah(subTotalValue + bebanValue)}
                    </div>
                  </div>
                  <div class="summary-list">
                    <div class="summary-indicator green"></div>
                    <div class="title">Diskon</div>
                    <input
                      type="text"
                      value={formatRupiah(separatedDiscount)}
                      style={{
                        fontFamily: "poppins-regular",
                        fontSize: "14px",
                        fontWeight: 700,
                        textAlign: "right",
                        border: 0,
                        padding: 0,
                        width: "100%",
                        background: "none",
                        height: "14px",
                      }}
                      onChange={(e) => setSeparatedDiscount(preventZero(e))}
                    />
                  </div>
                  <div class="summary-list">
                    <div class="summary-indicator orange"></div>
                    <div class="title">PPN 11%</div>
                    <div class="nominal">{formatRupiah(ppnValue)}</div>
                  </div>
                  <div class="summary-list">
                    <div class="summary-indicator red"></div>
                    <div class="title">Total</div>
                    <div class="nominal">
                      {formatRupiah(
                        subTotalValue +
                          bebanValue -
                          separatedDiscount +
                          ppnValue
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${
                (loading || loadingUpdate) && "disabled"
              }`}
              onClick={handleSubmit(handleSaveRequest)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>
            <div className="data-action-button purple-bg">
              <span className="icon icon-cetaik_ic"></span>
              Cetak
            </div>
            <div className="data-action-button purple-bg">
              <span className="icon icon-attach_ic"></span>
              Lampiran
            </div>
            <div className="data-action-button blue-bg">
              <span className="icon icon-more_ic"></span>
              Lainnya
            </div>
            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Pesanan
              pembelian {choosenData?.nomor_pesanan}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewPurchaseInvoice;
