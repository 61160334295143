import { gql } from "@apollo/client";
import { CHILD_SUPPLIER_CATEGORY_FRAGMENT } from "./SupplierCategoryAction";
import { SYARAT_PEMBAYARAN_FRAGMENT } from "./SyaratPembayaranAction";

export const SUPPLIER_FRAGMENT = gql`
  fragment Supplier on mPemasok {
    kode_supplier
    nama
    kategori_pemasok {
      ...ChildSupplierCategory
    }
    id_pemasok
    no_tel
    email
    website
    alamat
    kota
    provinsi
    kodepos
    is_pph
    tipe_pemasok
    kontak {
      nama
      jabatan
      notel
      email
      catatan
    }
    syarat_pembayaran {
      ...SyaratPembayaran
    }
    default_diskon
    default_deskripsi
    akun_utang
    akun_uang_muka
    rekening {
      nama_bank
      no_rekening
      atas_nama
    }
    is_include_pajak
    npwp
    nama_npwp
    diskon
    jenis_dokumen
    jenis_transaksi
    is_alamat_sama
    alamat_pajak
    kota_pajak
    provinsi_pajak
    kodepos_pajak
    saldo {
      tanggal_transaksi
      nomor
      syarat_pembayaran {
        ...SyaratPembayaran
      }
      saldo_awal
      keterangan
    }
    is_faktur
    catatan
  }
  ${CHILD_SUPPLIER_CATEGORY_FRAGMENT}
  ${SYARAT_PEMBAYARAN_FRAGMENT}
`;

export const SUPPLIER_LIST = gql`
  query getSupplierList($input: PemasokPaginationInput!) {
    Pemasok {
      findAll(input: $input) {
        ...Supplier
      }
    }
  }
  ${SUPPLIER_FRAGMENT}
`;

export const CREATE_SUPPLIER = gql`
  mutation createPemasok($input: CreatePemasokInput!) {
    Pemasok {
      create(input: $input) {
        ...Supplier
      }
    }
  }
  ${SUPPLIER_FRAGMENT}
`;

export const UPDATE_SUPPLIER = gql`
  mutation updatePemasok($input: UpdatePemasokInput!) {
    Pemasok {
      update(input: $input) {
        ...Supplier
      }
    }
  }
  ${SUPPLIER_FRAGMENT}
`;

export const DELETE_SUPPLIER = gql`
  mutation deletePemasok($input: String!) {
    Pemasok {
      delete(input: $input)
    }
  }
`;
