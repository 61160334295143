import { useHistory } from "react-router-dom";

const CheckLogin = (props) => {
  const history = useHistory();

  const isLogin = window.sessionStorage.getItem("login") === "true";

  if (isLogin) {
    return props.children;
  } else {
    history.push("/login");
  }
};

export default CheckLogin;
