import { gql } from "@apollo/client";

export const CATEGORY_FRAGMENT = gql`
  fragment Category on mKategoriBarang {
    kategori_barang_id
    category_name
    level
    parent_id
    is_default
    is_have_coa
    coa_persediaan
    coa_beban
    coa_penjualan
    coa_retur_penjualan
    coa_diskon_penjualan
    coa_barang_terkirim
    coa_bahan_pokok_penjualan
    coa_retur_pembelian
    coa_pembelian_belum_tagih
    default_prefix
    child {
      kategori_barang_id
      category_name
      level
      parent_id
      is_default
      is_have_coa
      coa_persediaan
      coa_beban
      coa_penjualan
      coa_retur_penjualan
      coa_diskon_penjualan
      coa_barang_terkirim
      coa_bahan_pokok_penjualan
      coa_retur_pembelian
      coa_pembelian_belum_tagih
      default_prefix
    }
  }
`;

export const CATEGORY_FRAGMENT_2 = gql`
  fragment Category2 on mKategori {
    kategori_barang_id
    category_name
    level
    parent_id
    is_default
    is_have_coa
    coa_persediaan
    coa_beban
    coa_penjualan
    coa_retur_penjualan
    coa_diskon_penjualan
    coa_barang_terkirim
    coa_bahan_pokok_penjualan
    coa_retur_pembelian
    coa_pembelian_belum_tagih
    default_prefix
  }
`;

export const CATEGORY_LIST = gql`
  query getListCategory {
    barang {
      getKategoriBarang {
        ...Category
      }
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory($input: NewKategoriBarangInput!) {
    barang {
      createKategoriBarang(input: $input) {
        ...Category
      }
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const UPDATE_CATEGORY = gql`
  mutation editCategory($input: UpdateKategoriBarangInput!) {
    barang {
      updateKategoriBarang(input: $input) {
        ...Category
      }
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($input: Float!) {
    barang {
      deleteKategoriBarang(input: $input)
    }
  }
`;
