import React from "react";
import Header from "./header";
import Sidebar from "./sidebar";

const Layout = (props) => {
  const { children } = props;

  return (
    <div className="main-container">
      <Sidebar />

      <Header />

      <div className="main-content-wrap">{children}</div>
    </div>
  );
};

export default Layout;
