import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import {
  CREATE_SYARAT_PEMBAYARAN,
  DELETE_SYARAT_PEMBAYARAN,
  UPDATE_SYARAT_PEMBAYARAN,
} from "../../../actions/SyaratPembayaranAction";

const AddNewSyaratPembayaran = (props) => {
  const {
    detail,
    choosenData,
    dataPerTabs,
    setChoosenData,
    tabs,
    setTabs,
    tabOpen,
    setTabOpen,
    refetch,
  } = props;

  const [tab, setTab] = useState(0);
  const [isDefault, setIsDefault] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [name, setName] = useState("");

  const validationSchema = Yup.object().shape({
    masa_jto: Yup.number()
      .default(0)
      .moreThan(1, "Jatuh tempo tidak boleh dibawah 1")
      .required("Masa jatuh tempo"),
    keterangan: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = dataPerTabs.filter(
      (tab) => tab.idTab !== choosenData.idTab
    );
    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    if (detail) {
      setValue("keterangan", choosenData.keterangan);
      setValue("masa_jto", choosenData.masa_jto);
      setIsDefault(choosenData.is_default);
      if (choosenData.is_bawaan) {
        setName(choosenData.nama_syarat_pembayaran);
      }
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const [createSyarat, { loading }] = useMutation(CREATE_SYARAT_PEMBAYARAN, {
    onCompleted: () => {
      toast.success("Syarat pembayaran berhasil ditambahkan");
      setTabOpen(0);
      refetch();
    },
    onError: (resp) => {
      toast.error(resp.message);
    },
  });

  const [deleteSyarat] = useMutation(DELETE_SYARAT_PEMBAYARAN, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus syarat");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
  });

  const [ubahSyarat, { loading: loadingUpdate }] = useMutation(
    UPDATE_SYARAT_PEMBAYARAN,
    {
      onCompleted: (resp) => {
        setTabOpen(0);
        toast.success("Sukses mengubah syarat");
        setTabs(tabs.filter((tab) => tab.id !== tabOpen));
        handleUpdateTabData();
        refetch();
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  const onSubmit = (data) => {
    const inputs = {
      nama: choosenData?.is_bawaan ? name : `net ${data.masa_jto}`,
      masa_jto: parseFloat(data.masa_jto),
      keterangan: data.keterangan,
      is_default: isDefault,
    };
    if (detail) {
      ubahSyarat({
        variables: {
          input: {
            id: choosenData.syarat_pembayaran_id,
            ...inputs,
          },
        },
      });
    } else {
      createSyarat({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteSyarat({
      variables: {
        input: choosenData.syarat_pembayaran_id,
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="left-content-module">
          <div className="main-content-module detail-page full-tab">
            <div className="flex-center">
              <div
                className={`tab-list ${tab === 0 && "active"}`}
                onClick={() => setTab(0)}
              >
                Syarat Pembayaran
              </div>
            </div>

            {tab === 0 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <form id="" className="">
                    <div className="form-wrap">
                      {choosenData?.is_bawaan && (
                        <div className="form-container">
                          <div className="title">Nama</div>

                          <div className="auto-form-container">
                            <input
                              required
                              type="text"
                              placeholder="Masukkan Nama Syarat"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                      )}

                      {!choosenData?.is_bawaan && (
                        <div className="form-container">
                          <div className="title">Masa Jatuh Tempo (Hari)</div>

                          <div className="auto-form-container">
                            <input
                              required
                              type="number"
                              placeholder="Masukkan Masa Jatuh Tempo"
                              {...register("masa_jto", { required: true })}
                            />
                          </div>
                        </div>
                      )}

                      {!choosenData?.is_bawaan && (
                        <div className="form-container">
                          <div className="title">Keterangan</div>

                          <div className="auto-form-container">
                            <textarea
                              rows="4"
                              cols="50"
                              placeholder="Tuliskan tambahan informasi untuk syarat tersebut"
                              {...register("keterangan")}
                            ></textarea>
                          </div>
                        </div>
                      )}

                      <div className="form-container flex-center w-100">
                        <div className="ppn-desc">
                          <div>Default Syarat Pembayaran</div>
                          {isDefault ? <div>Ya</div> : <div>Tidak</div>}
                        </div>
                        <div
                          className={`toggle-btn ${
                            isDefault ? "enable" : "disable"
                          }`}
                          onClick={() => setIsDefault(!isDefault)}
                        ></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${
                (loading || loadingUpdate) && "disabled"
              }`}
              onClick={handleSubmit(onSubmit)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>

            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Syarat{" "}
              {choosenData?.nama_syarat_pembayaran}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewSyaratPembayaran;
