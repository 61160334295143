import { gql } from "@apollo/client";
import { CABANG_FRAGMENT } from "./CabangAction";
import { GUDANG_FRAGMENT } from "./GudangAction";
import { GOODS_AND_SERVICE_FRAGMENT } from "./BarangAction";
import { SATUAN_FRAGMENT } from "./SatuanBarangAction";

export const REQUEST_DETAIL_FRAGMENT = gql`
  fragment RequestDetail on mDetailPermintaan {
    detail_permintaan_id
    barang {
      ...GoodsAndService
    }
    tgl_kirim
    qty
    satuan {
      ...Satuan
    }
    keterangan
  }
  ${GOODS_AND_SERVICE_FRAGMENT}
  ${SATUAN_FRAGMENT}
`;

export const REQUEST_ORDER_FRAGMENT = gql`
  fragment RequestOrder on mPermintaanBarang {
    input_dt
    update_dt
    nomor_permintaan
    tgl_permintaan
    tipe_permintaan
    keterangan
    status
    cabang {
      ...Cabang
    }
    gudangPenerima {
      ...Gudang
    }
    gudangPengirim {
      ...Gudang
    }
    detail {
      ...RequestDetail
    }
  }
  ${CABANG_FRAGMENT}
  ${GUDANG_FRAGMENT}
  ${REQUEST_DETAIL_FRAGMENT}
`;

export const REQUEST_ORDER_LIST = gql`
  query getRequestOrderList {
    PermintaanBarang {
      findAll {
        ...RequestOrder
      }
    }
  }
  ${REQUEST_ORDER_FRAGMENT}
`;

export const CREATE_REQUEST_ORDER = gql`
  mutation createRequestOrder($input: NewPermintaanBarangInput!) {
    PermintaanBarang {
      create(input: $input) {
        ...RequestOrder
      }
    }
  }
  ${REQUEST_ORDER_FRAGMENT}
`;

export const UPDATE_REQUEST_ORDER = gql`
  mutation editRequestOrder($input: UpdatePermintaanBarangInput!) {
    PermintaanBarang {
      update(input: $input) {
        ...RequestOrder
      }
    }
  }
  ${REQUEST_ORDER_FRAGMENT}
`;

export const DELETE_REQUEST_ORDER = gql`
  mutation deleteRequestOrder($input: String!) {
    PermintaanBarang {
      delete(input: $input) {
        ...RequestOrder
      }
    }
  }
  ${REQUEST_ORDER_FRAGMENT}
`;
