export const gudangOption = [
  {
    id: 1,
    name: "Legok",
    address:
      "Jl. Raya Legok - Karawaci No.17, Babakan, Kec. Legok, Kabupaten Tangerang, Banten 15820",
  },
  {
    id: 2,
    name: "Surabaya",
    address:
      "Jl. Raya Legok - Karawaci No.17, Babakan, Kec. Legok, Kabupaten Tangerang, Banten 15820",
  },
];

export const unitOption = [
  { id: 1, name: "PCS" },
  { id: 2, name: "BOX" },
];

export const employeeOption = [
  { id: 1, name: "Muhammad Nizar", position: "Finance" },
  { id: 2, name: "Alesandro Nizaro", position: "Helper" },
  { id: 3, name: "Son Heung Min", position: "Production" },
  { id: 4, name: "C. Ronaldo", position: "Admin" },
  { id: 5, name: "Alex Del Piero", position: "Legal" },
];

export const productOption = [
  { id: 1, name: "ABON SAPI", code: "RT-0083" },
  { id: 2, name: "ABON AYAM CHICK LOSS 1 KG", code: "BP-000" },
];

export const requestTypeOption = [
  { id: 1, label: "Beli Barang" },
  { id: 2, label: "Kirim Barang" },
  // { id: 3, label: "Pesanan" },
];

export const tipeProsesOption = [
  { id: "TERIMA_BARANG", label: "Terima Barang" },
  { id: "KIRIM_BARANG", label: "Kirim Barang" },
];

export const projectOption = [
  { id: 1, name: "HUT RI" },
  { id: 2, name: "Bpk Harian" },
];

export const groupEmployeeOption = [
  { id: 1, name: "Group Gudang Sampang" },
  { id: 2, name: "Group Gudang Surabaya" },
];

export const accountTypeOption = [
  { id: "KAS_BANK", name: "Kas & Bank" },
  { id: "PIUTANG", name: "Piutang Usaha" },
  { id: "PERSEDIAAN", name: "Persediaan" },
  { id: "ASET_LANCAR", name: "Aset Lancar lainnya" },
  { id: "ASET_TETAP", name: "Aset Tetap" },
  { id: "AKUMULASI_PENYUSUTAN", name: "Akumulasi Penyusutan" },
  { id: "ASET_LAINNYA", name: "Aset Lainnya" },
  { id: "UTANG_USAHA", name: "Utang Usaha" },
];

export const supplierOption = [
  { id: 1, name: "ABC Swalayan Grosir", desc: "210001.A028" },
  { id: 2, name: "ABC Toko Kelontong", desc: "210001.A028" },
];
