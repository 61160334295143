import React from "react";
import Container from "../../common/container";
import TabContent from "../../common/tabContent";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { SUPPLIER_CATEGORY_LIST } from "../../../actions/SupplierCategoryAction";
import AddNewSupplierCategory from "./addNew";
import SupplierCategoryList from "./list";

const SupplierCategoryModul = () => {
  const [tabSession, setTabSession] = React.useState([
    { label: "Lihat Data", type: "list", id: 0 },
    { label: "Buat Baru", type: "add-new", id: 1 },
  ]);
  const [activeTabId, setActiveTabId] = React.useState(1);
  const [choosenData, setChoosenData] = React.useState([]);

  const {
    loading,
    error,
    data: dataCategory,
    refetch,
  } = useQuery(SUPPLIER_CATEGORY_LIST);

  if (error) {
    toast.error(error.message);
  }

  const activeData = tabSession.find((tab) => tab.id === activeTabId);

  return (
    <div>
      <TabContent
        active={activeTabId}
        setActive={setActiveTabId}
        tabs={tabSession}
        setTabs={setTabSession}
      />

      <Container>
        {activeData.type === "list" ? (
          <SupplierCategoryList
            tabs={tabSession}
            setTabs={setTabSession}
            setActive={setActiveTabId}
            choosenData={choosenData}
            setChoosenData={setChoosenData}
            dataCategory={dataCategory}
            loading={loading}
          />
        ) : activeData.type === "add-new" ? (
          <AddNewSupplierCategory
            setTabOpen={setActiveTabId}
            refetch={refetch}
          />
        ) : (
          <AddNewSupplierCategory
            detail={true}
            choosenData={choosenData.find(
              (choosen) => choosen.idTab === activeTabId
            )}
            dataPerTabs={choosenData}
            setChoosenData={setChoosenData}
            tabs={tabSession}
            setTabs={setTabSession}
            tabOpen={activeTabId}
            setTabOpen={setActiveTabId}
            refetch={refetch}
          />
        )}
      </Container>
    </div>
  );
};

export default SupplierCategoryModul;
