import React from "react";

const Status = (props) => {
  const { isPaid } = props;

  const getStatus = () => {
    switch (isPaid) {
      case "DIAJUKAN":
        return "wait-for-process";
      case "MENUNGGU_DIPROSES":
        return "wait-for-process";
      case "DRAF":
        return "wait-for-process";
      case "SEDANG_KIRIM":
        return "partly-process";
      case "SELESAI":
        return "done";
      case "DITERIMA":
        return "done";
      case "TERPROSES":
        return "processed";
      case "DITOLAK":
        return "rejected";
      case "SEBAGIAN":
        return "partly-process";
      case "DIFAKTUR":
        return "partly-process";
      case "DIFAKTUR_SEBAGIAN":
        return "partly-process";
      case "LUNAS":
        return "paid";
      case "BELUM_LUNAS":
        return "unpaid";
      default:
        break;
    }
  };

  return (
    <div className={`lbl ${getStatus()}`}>{isPaid.split("_").join(" ")}</div>
  );
};

export default Status;
