import React, { useEffect, useMemo, useRef, useState } from "react";
import $ from "jquery";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchInput from "../../common/searchInput";
import { useMutation, useQuery } from "@apollo/client";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import { toast } from "react-toastify";
import { formatRupiah } from "../../utils";
import { COA_KAS_BANK_LIST, COA_LIST } from "../../../actions/CoaBarangAction";
import {
  CREATE_PURCHASE_PAYMENT,
  DELETE_PURCHASE_PAYMENT,
  UPDATE_PURCHASE_PAYMENT,
} from "../../../actions/PurchasePaymentAction";
import { format } from "date-fns";
import DropdownInput from "../../common/dropdownInput";
import { PURCHASE_INVOICE_LIST } from "../../../actions/PurchaseInvoiceAction";
// import { CABANG_LIST } from "../../../actions/CabangAction";

const AddNewPurchasePayment = (props) => {
  const {
    detail,
    choosenData,
    dataPerTabs,
    setChoosenData,
    tabs,
    setTabs,
    tabOpen,
    setTabOpen,
    refetch,
    supplierData,
  } = props;

  const datePickerRef = useRef(null);
  const datePickerFakturRef = useRef(null);

  const redirectingData = JSON.parse(localStorage.getItem("redirectingData"));

  const [tab, setTab] = useState(0);
  const [modalTab, setModalTab] = useState(0);
  const [show, setShow] = useState({
    menu: false,
    bebanModal: false,
    modal: false,
    ambilPesanan: false,
    ambilPenerimaan: false,
    ambilOption: false,
  });
  const [diskonToAdd, setDiskonToAdd] = useState({
    diskonId: 0,
    akun: null,
    price: 0,
    keterangan: "",
  });
  const [diskonList, setDiskonList] = useState([]);
  const [fakturToAdd, setFakturToAdd] = useState({
    itemId: 0,
    noForm: "",
    noFaktur: "",
    tglFaktur: "",
    totalFaktur: 0,
    terhutang: 0,
    bayar: "0",
    diskon: diskonList,
  });
  const [productList, setProductList] = useState([]);
  const [terhutangList, setTerhutangList] = useState([]);
  const [choosenId, setChoosenId] = useState(0);
  const [editProduct, setEditProduct] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [showType, setShowType] = useState(false);
  const [listRekening, setListRekening] = useState([]);
  const [metodeOption, setMetodeOption] = useState(["Cash"]);
  const [fakturOption, setFakturOption] = useState([]);
  const [coaBalance, setCoaBalance] = useState(0);

  const validationSchema = Yup.object().shape({
    pemasok: Yup.string().required("Pemasok"),
    akun: Yup.string().required("Akun Kas/Bank"),
    tglBayar: Yup.string().required("Tanggal Bayar"),
    nilaiPembayaran: Yup.string().default("0").required("Nilai Pembayaran"),
    noBukti: Yup.string().nullable(),
    metodeBayar: Yup.string().required("Metode Bayar"),
    detail:
      productList.length > 0
        ? Yup.string().nullable()
        : Yup.string().required("Detail Bayar"),
    noGiro: Yup.string().nullable(),
    noRek: Yup.string().nullable(),
    tglGiro: Yup.string().nullable(),
    // cabang: Yup.string().required(),
    keterangan: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nilaiPembayaran: "0",
      pemasok: redirectingData
        ? redirectingData.kodeSupplier
        : detail
        ? choosenData.kodeSupplier
        : "",
    },
    resolver: yupResolver(validationSchema),
  });

  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = [...dataPerTabs];
    updatedList.splice(tabOpen, 1);

    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (productList.length === 0) {
      setValue("nomorPermintaan", "");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    $(datePickerRef.current).datepicker({
      onSelect: (dateText) => {
        setValue("tglBayar", dateText);
      },
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tab === 1 && watch("metodeBayar") === "Cek / Giro") {
      $(datePickerFakturRef.current).datepicker({
        onSelect: (dateText) => {
          setValue("tglGiro", dateText);
        },
      });
    }
    // eslint-disable-next-line
  }, [tab, watch("metodeBayar")]);

  // const { data: cabangData } = useQuery(CABANG_LIST, {
  //   variables: {
  //     input: {},
  //   },
  // });
  const { data: dataCoa } = useQuery(COA_LIST);
  const { data: dataCashBank } = useQuery(COA_KAS_BANK_LIST);
  const { data: dataPurchaseInvoice } = useQuery(PURCHASE_INVOICE_LIST, {
    variables: {
      input: {},
    },
  });
  const [createPurchasePayment, { loading }] = useMutation(
    CREATE_PURCHASE_PAYMENT,
    {
      onCompleted: (resp) => {
        toast.success("Pesanan pembelian berhasil dibuat");
        setTabOpen(0);
        refetch();
      },
      onError: (resp) => {
        toast.error(resp.message);
      },
      // update(cache, { data }) {
      //   const list = cache.readQuery({
      //     query: CATEGORY_LIST,
      //   });
      //   cache.writeQuery({
      //     query: CATEGORY_LIST,
      //     data: {
      //       barang: {
      //         getKategoriBarang: [
      //           ...list.barang.getKategoriBarang,
      //           data.barang.createKategoriBarang,
      //         ],
      //       },
      //     },
      //   });
      // },
    }
  );

  const [deleteRequestPayment] = useMutation(DELETE_PURCHASE_PAYMENT, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   const updatedList = list.barang.getKategoriBarang.filter(
    //     (category) =>
    //       category.kategori_barang_id !== choosenData.kategori_barang_id
    //   );
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: updatedList,
    //       },
    //     },
    //   });
    // },
  });

  const [ubahRequestPayment, { loading: loadingUpdate }] = useMutation(
    UPDATE_PURCHASE_PAYMENT,
    {
      onCompleted: (resp) => {
        setTabOpen(0);
        toast.success("Sukses mengubah data");
        setTabs(tabs.filter((tab) => tab.id !== tabOpen));
        handleUpdateTabData();
        refetch();
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  const supplierList = supplierData?.Pemasok.findAll.map((pemasok) => ({
    id: pemasok.kode_supplier,
    name: `${pemasok.nama}`,
    desc: pemasok.kode_supplier,
    rekening: pemasok.rekening,
  }));
  // const dataCabang = cabangData?.cabang.findAll.map((cabang) => ({
  //   id: cabang.kode_cabang,
  //   name: `Gudang ${cabang.nama_cabang}`,
  //   desc: cabang.alamat,
  //   ...cabang,
  // }));
  // const allowedCoaTypes = [
  //   "1060",
  //   "6011",
  //   "4010",
  //   "5012",
  //   "5010",
  //   "4011",
  //   "5011",
  // ];
  const coaList = dataCoa?.coa.findAll.map((coa) => ({
    id: coa.coa_code.toString(),
    name: coa.coa_name,
    desc: coa.coa_code,
    type: coa.coa_type,
  }));
  const casnBankList = dataCashBank?.coa.listCoaKasBank.map((coa) => ({
    id: coa.kode,
    name: coa.nama,
    desc: formatRupiah(coa.balance, "Rp"),
    type: coa.tipe,
    balance: coa.balance,
  }));
  const fakturPembelianList = dataPurchaseInvoice?.FakturPembelian.findAll.map(
    (purchase) => ({
      id: purchase.noFaktur,
      name: purchase.noFakturFisik,
      desc: `${format(new Date(purchase.tglFaktur), "dd/MM/yyyy")}`,
      ...purchase,
    })
  );
  const balance = useMemo(() => {
    if (detail) {
      return casnBankList?.find((cashBank) => cashBank.id === choosenData.bank)
        .balance;
    }
    // eslint-disable-next-line
  }, [detail, casnBankList]);

  useEffect(() => {
    if (redirectingData !== null && fakturPembelianList) {
      const rekeningList = supplierList
        ?.find((pemasok) => pemasok.id === redirectingData.kodeSupplier)
        ?.rekening.map((rek) => ({
          id: rek.no_rekening,
          name: `${rek.nama_bank} - ${rek.no_rekening}`,
          desc: rek.atas_nama,
        }));
      const fpList = fakturPembelianList?.filter(
        (faktur) =>
          faktur.pemasok.kode_supplier === redirectingData.kodeSupplier
      );
      setListRekening(rekeningList);
      setFakturOption(fpList);
      setProductList([redirectingData.detailFaktur]);

      localStorage.removeItem("redirectingData");
    }
    // eslint-disable-next-line
  }, [fakturPembelianList]);

  useEffect(() => {
    if (detail && choosenData) {
      const rekeningList = supplierList
        ?.find((pemasok) => pemasok.id === choosenData.kodeSupplier)
        ?.rekening.map((rek) => ({
          id: rek.no_rekening,
          name: `${rek.nama_bank} - ${rek.no_rekening}`,
          desc: rek.atas_nama,
        }));
      const fpList = fakturPembelianList?.filter(
        (faktur) => faktur.pemasok.kode_supplier === choosenData.kodeSupplier
      );
      setCoaBalance(balance);
      // setValue("pemasok", choosenData.kodeSupplier);
      setValue("noRek", choosenData?.noRek);
      setValue("akun", choosenData.bank);
      setValue(
        "tglBayar",
        format(new Date(choosenData.tglPembayaran), "MM/dd/yyyy")
      );
      setValue("noBukti", choosenData.noPembayaran);
      setValue("metodeBayar", choosenData.metodeBayar);
      setValue("noGiro", choosenData.noGiro);
      setValue("tglGiro", choosenData.tglGiro);
      // setValue("cabang", choosenData.kodeCabang);
      setValue("keterangan", choosenData?.keterangan);
      setProductList(
        choosenData.detail.map((faktur) => ({
          ...faktur,
          noForm: faktur.noFakturFisik,
          tglFaktur: format(new Date(faktur.tglFaktur), "MM/dd/yyyy"),
          bayar: faktur.nilaiBayar,
          diskon: faktur.infoDiskon.map((diskon) => ({
            ...diskon,
            akun: diskon.kode_diskon,
            price: diskon.nilai,
            mode: "e",
          })),
          mode: "e",
        }))
      );
      setListRekening(rekeningList);
      setFakturOption(fpList);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData, balance]);

  const handleOpenDetail = (index) => {
    const choosenProduct = productList[index];
    setFakturToAdd({
      ...choosenProduct,
    });
    setDiskonList(choosenProduct.diskon);
    setShow({ ...show, modal: true });
    setEditProduct(true);
    setChoosenId(index);
  };

  const handleAddDiskonList = () => {
    setDiskonList([...diskonList, diskonToAdd]);
    setDiskonToAdd({
      diskonId: 0,
      akun: null,
      price: 0,
      keterangan: "",
      mode: "c",
    });
  };

  const handleDeleteDiskonList = (index) => {
    const temp = [...diskonList];
    temp[index].mode = "d";
    setDiskonList([...temp]);
  };

  const handleAddTableProduct = () => {
    const faktur = {
      ...fakturToAdd,
      diskon: diskonList,
    };
    if (editProduct) {
      const temp = productList;
      productList[choosenId] = faktur;
      setProductList([...temp]);
    } else {
      setProductList([...productList, faktur]);
    }
    setModalTab(0);
    setDiskonList([]);
    setFakturToAdd({
      itemId: 0,
      noForm: "",
      noFaktur: "",
      tglFaktur: "",
      totalFaktur: 0,
      terhutang: 0,
      bayar: "0",
      diskon: diskonList,
      mode: "c",
    });
    setDiskonToAdd({
      diskonId: 0,
      akun: null,
      price: 0,
      keterangan: "",
      mode: "c",
    });
    setShow({ ...show, modal: false });
    setEditProduct(false);
  };

  const handleDeleteTableProduct = () => {
    const temp = [...productList];
    temp[choosenId].mode = "d";
    setProductList([...temp]);
    setEditProduct(false);
    setModalTab(0);
    setFakturToAdd({
      itemId: 0,
      noForm: "",
      noFaktur: "",
      tglFaktur: "",
      totalFaktur: 0,
      terhutang: 0,
      bayar: "0",
      diskon: diskonList,
      mode: "c",
    });
    setShow({ ...show, modal: false });
  };

  const handleSum = (list) => {
    const summary = list.reduce(
      (total, product) =>
        total +
        (product.price > 0
          ? product.price
          : parseInt(product.price.replaceAll(".", ""))),
      0
    );

    return summary || 0;
  };

  const nilaiPembayaranValue =
    productList.length > 0
      ? productList?.reduce(
          (total, product) =>
            total + parseInt(product.bayar.toString().replaceAll(".", "")),
          0
        )
      : 0;
  const fakturDibayarValue =
    productList.length > 0
      ? productList?.reduce(
          (total, product) =>
            total +
            (parseInt(product.bayar.toString().replaceAll(".", "")) +
              handleSum(product.diskon)),
          0
        )
      : 0;
  const diskonValue =
    productList.length > 0
      ? productList?.reduce(
          (total, product) => total + handleSum(product.diskon),
          0
        )
      : 0;

  const handleSaveRequest = (dataSubmit) => {
    const inputs = {
      tglBayar: format(new Date(dataSubmit.tglBayar), "yyyy/MM/dd"),
      pembayaranKe: dataSubmit.pemasok,
      bank: dataSubmit.akun,
      keterangan: dataSubmit.keterangan ?? "",
      metodeBayar: dataSubmit.metodeBayar,
      noGiro:
        dataSubmit.metodeBayar === "Cek / Giro" ? dataSubmit.noGiro : null,
      noRek: dataSubmit.metodeBayar === "Transfer" ? dataSubmit.noRek : null,
      tglGiro:
        dataSubmit.metodeBayar === "Cek / Giro"
          ? format(new Date(dataSubmit.tglGiro), "yyyy/MM/dd")
          : null,
      // cabang: dataSubmit.cabang,
      detail: productList.map((product) => ({
        noFaktur: product.noFaktur,
        noFakturFisik: product.noForm,
        tglFaktur: format(new Date(product.tglFaktur), "yyyy/MM/dd"),
        totalFaktur: parseFloat(product.totalFaktur),
        nilaiBayar: parseFloat(product.bayar.toString().replaceAll(".", "")),
        totalDiskon: parseFloat(handleSum(product.diskon)),
        terhutang: parseFloat(product.terhutang),
        itemId: parseFloat(product.itemId),
        mode: product.mode,
        infoDiskon: product.diskon.map((diskon) => ({
          kodeDiskon: diskon.akun,
          namaDiskon: coaList.find((coa) => coa.id === diskon.akun).name,
          nilai: parseFloat(diskon.price.toString().replaceAll(".", "")),
          keterangan: diskon.keterangan,
          diskonId: parseFloat(diskon.diskonId),
          mode: diskon.mode,
        })),
      })),
    };

    const totalPayment = nilaiPembayaranValue - (choosenData?.total_bayar || 0);

    if (coaBalance >= totalPayment) {
      if (detail) {
        ubahRequestPayment({
          variables: {
            input: {
              ...inputs,
              noPembayaran: choosenData.noPembayaran,
            },
          },
        });
      } else {
        createPurchasePayment({
          variables: {
            input: inputs,
          },
        });
      }
    } else {
      toast.error("Saldo tidak mencukupi untuk melakukan pembayaran ini.");
    }
  };

  const confirmDelete = () => {
    deleteRequestPayment({
      variables: {
        input: choosenData.noPembayaran,
      },
    });
  };

  const handleTerhutangCheckbox = (value, i) => {
    let temp = terhutangList[i];
    temp.selected = value;
    terhutangList[i] = temp;
    setTerhutangList([...terhutangList]);
  };

  const handleAmbilTerhutang = () => {
    if (terhutangList.length > 0) {
      const products = productList.concat(
        terhutangList
          .filter((htg) => htg.selected)
          .map((htg) => ({
            noForm: htg.noForm,
            noFaktur: htg.noFaktur,
            tglFaktur: htg.tglFaktur,
            totalFaktur: htg.totalFaktur,
            terhutang: htg.terhutang,
            bayar: htg.bayar,
            diskon: diskonList,
          }))
      );
      setProductList(products);
      setShow({
        ...show,
        ambil: false,
      });
      setTerhutangList([]);
    } else {
      toast.error("Pilih salah satu nomor permintaan untuk melanjutkan");
    }
  };

  const handleAddTerhutang = () => {
    const list = fakturOption
      .filter((faktur) => faktur.totalHutang > 0)
      .map((faktur) => ({
        selected: true,
        noForm: faktur.noFakturFisik,
        noFaktur: faktur.noFaktur,
        tglFaktur: format(new Date(faktur.tglFaktur), "MM/dd/yyyy"),
        jatuhTempo: format(
          new Date(
            new Date(faktur.tglFaktur) +
              faktur.syartPembayaran.masa_jto * 24 * 60 * 60 * 1000
          ),
          "MM/dd/yyyy"
        ),
        totalFaktur: faktur.totalTerbayar + faktur.totalHutang,
        terhutang: faktur.totalHutang,
        bayar: "0",
        diskon: diskonList,
      }));
    setTerhutangList(list);
    setShow({ ...show, ambil: true });
  };

  // const distributePay = () => {
  //   if (productList.length === 0) {
  //     toast.error("Isi list faktur terlebih dahulu.");
  //   } else {
  //     const terhutang = productList?.reduce(
  //       (total, product) => total + parseInt(product.terhutang),
  //       0
  //     );
  //     let remainingAmount = parseInt(
  //       watch("nilaiPembayaran").replaceAll(".", "")
  //     );
  //     if (terhutang >= remainingAmount) {
  //       const updatedData = productList.map((item) => {
  //         if (remainingAmount > 0) {
  //           const payAmount = Math.min(
  //             item.terhutang - handleSum(item.diskon),
  //             remainingAmount
  //           );
  //           remainingAmount -= payAmount;
  //           return { ...item, bayar: formatRupiah(payAmount) };
  //         }

  //         return item;
  //       });
  //       setProductList(updatedData);
  //     } else {
  //       toast.error("Harus sesuai terhutang atau dibawah");
  //       setValue("nilaiPembayaran", formatRupiah(terhutang));
  //     }
  //   }
  // };

  return (
    <div>
      <form onSubmit={handleSubmit(handleSaveRequest)}>
        <div className="left-content-module">
          <div className="content-nav-module">
            <form id="" className="">
              <div className="form-wrap">
                <SearchInput
                  title="Pembayaran Ke"
                  placeholder="Cari/Pilih Pemasok"
                  value={watch("pemasok")}
                  setValue={(e) => {
                    setValue("pemasok", e);
                    setValue("noRek", null);
                    const rekeningList = supplierList
                      ?.find((pemasok) => pemasok.id === e)
                      ?.rekening.map((rek) => ({
                        id: rek.no_rekening,
                        name: `${rek.nama_bank} - ${rek.no_rekening}`,
                        desc: rek.atas_nama,
                      }));
                    const fpList = fakturPembelianList?.filter(
                      (faktur) => faktur.pemasok.kode_supplier === e
                    );
                    setListRekening(rekeningList);
                    setFakturOption(fpList);
                  }}
                  searchData={supplierList}
                />
              </div>

              <div className="form-wrap">
                <SearchInput
                  title="Akun Kas/Bank"
                  placeholder="Cari/Pilih Akun"
                  value={watch("akun")}
                  setValue={(e) => {
                    const choosenAkun = casnBankList.find(
                      (cashBank) => cashBank.id === e
                    );
                    setValue("akun", e);
                    setCoaBalance(choosenAkun.balance);
                    if (choosenAkun.type === "Kas") {
                      setMetodeOption(["Cash"]);
                      setValue("metodeBayar", "Cash");
                    } else {
                      setMetodeOption(["Transfer", "Cek / Giro"]);
                      setValue("metodeBayar", "Transfer");
                    }
                  }}
                  searchData={casnBankList}
                />
              </div>

              <div className="form-wrap">
                <div className="form-container">
                  <div className="title">Tanggal Bayar</div>
                  <input
                    ref={datePickerRef}
                    className="form-date-input"
                    type="text"
                    placeholder="Pilh tanggal"
                    value={watch("tglBayar")}
                    onChange={(e) => setValue("tglBayar", e.target.value)}
                  />
                  <div className="icon-form-date icon-date_ic"></div>
                </div>
              </div>

              {/* <div className="form-wrap">
                <div className="form-container">
                  <div className="title">Nilai Pembayaran</div>
                  <div className="auto-form-container">
                    <input
                      name=""
                      type="text"
                      placeholder="Nilai Pembayaran"
                      value={formatRupiah(watch("nilaiPembayaran"))}
                      onChange={(e) =>
                        setValue("nilaiPembayaran", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div class="nav-setting-wrap">
                <div class="list-setting">
                  <div class="setting-btn" onClick={distributePay}>
                    <span class="icon-input_ic"></span>
                  </div>
                </div>
              </div> */}

              <div className="form-right-side">
                {/* <!--RIGHT SIDE--> */}
                <div className="form-wrap">
                  <div className="form-container w-250px">
                    <div className="title">No Bukti#</div>
                    <div className="auto-form-container disable w-fluid">
                      <input
                        name=""
                        type="text"
                        placeholder="Cari gudang"
                        value={watch("noBukti") || "-"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="clear"></div>
            </form>
          </div>
          <div className="main-content-module detail-page full-side-tab">
            <div className="detail-data-wrap">
              <div className="wrap">
                <div
                  className={`tab-menu ${tab === 0 && "active"}`}
                  onClick={() => setTab(0)}
                >
                  <span className="icon-input_ic"></span>
                </div>
                <div
                  className={`tab-menu ${tab === 1 && "active"}`}
                  onClick={() => setTab(1)}
                >
                  <span className="icon-info_ic"></span>
                </div>
              </div>

              {tab === 0 && (
                <div className="content-wrap">
                  <div className="content-nav-module">
                    <form id="" className="">
                      <div className="form-wrap">
                        <SearchInput
                          title="Faktur"
                          placeholder="Cari/Pilih Faktur"
                          setValue={(e) => {
                            setShow({
                              ...show,
                              modal: true,
                            });
                            const choosen = fakturPembelianList?.find(
                              (faktur) => faktur.id === e
                            );
                            setFakturToAdd({
                              ...fakturToAdd,
                              noForm: choosen.noFakturFisik,
                              noFaktur: choosen.noFaktur,
                              tglFaktur: format(
                                new Date(choosen.tglFaktur),
                                "MM/dd/yyyy"
                              ),
                              totalFaktur:
                                choosen.totalTerbayar + choosen.totalHutang,
                              terhutang: choosen.totalHutang,
                            });
                          }}
                          searchData={fakturOption}
                        />

                        <div className="nav-setting-wrap">
                          <div className="list-setting">
                            <div
                              class="setting-btn more-btn"
                              onClick={handleAddTerhutang}
                            >
                              Ambil
                            </div>
                          </div>
                        </div>

                        {show.ambil && (
                          <div class="window-overlay">
                            <div class="window-container">
                              <div class="window-nav">
                                <div class="title flex-center">
                                  Faktur Belum Lunas
                                </div>
                                <div class="close-btn flex-center">
                                  <span
                                    class="icon icon-close_ic"
                                    onClick={() => {
                                      setShow({
                                        ...show,
                                        ambil: false,
                                      });
                                      setTerhutangList([]);
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <div class="window-content">
                                <div class="main-content-module">
                                  <div class="tableFixHead">
                                    <table
                                      id="all-data-table"
                                      class="detail-page"
                                    >
                                      <thead>
                                        <tr>
                                          <th>
                                            <label class="checkbox">
                                              <input
                                                type="checkbox"
                                                checked={
                                                  terhutangList?.filter(
                                                    (request) =>
                                                      request.selected
                                                  ).length > 0
                                                }
                                                onClick={() => {
                                                  let temp = terhutangList;
                                                  let a = temp.map(
                                                    (request) => ({
                                                      ...request,
                                                      selected:
                                                        !request.selected,
                                                    })
                                                  );
                                                  setTerhutangList([...a]);
                                                }}
                                              />
                                              <span class="checkmark"></span>
                                            </label>
                                          </th>
                                          <th>No Faktur #</th>
                                          <th>No Form</th>
                                          <th>Tanggal Faktur</th>
                                          <th>Jatuh Tempo</th>
                                          <th>Terutang</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {terhutangList?.map((request, i) => (
                                          <tr>
                                            <td>
                                              <label class="checkbox">
                                                <input
                                                  type="checkbox"
                                                  checked={request.selected}
                                                  onClick={() =>
                                                    handleTerhutangCheckbox(
                                                      !request.selected,
                                                      i
                                                    )
                                                  }
                                                />
                                                <span class="checkmark"></span>
                                              </label>
                                            </td>
                                            <td>{request.noForm}</td>
                                            <td>{request.noFaktur}</td>
                                            <td>{request.tglFaktur}</td>
                                            <td>{request.jatuhTempo}</td>
                                            <td>
                                              {formatRupiah(request.terhutang)}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div class="window-bottom">
                                <div
                                  class="button btn-blue flex-center"
                                  onClick={handleAmbilTerhutang}
                                >
                                  Lanjutkan
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="form-right-side">
                        <div className="form-wrap"></div>
                      </div>

                      <div className="clear"></div>
                    </form>
                  </div>
                  <div className="main-content-module">
                    <table id="all-data-table" className="detail-page m-b-30">
                      <tr>
                        {/* <th width="45">PJK</th> */}
                        <th>No Faktur</th>
                        <th>Tgl Faktur</th>
                        <th>Total Faktur</th>
                        <th>Terhutang</th>
                        <th>Bayar</th>
                        <th>Diskon</th>
                        <th>Pembayaran</th>
                      </tr>
                      {productList
                        .filter((product) => product.mode !== "d")
                        .map((row, i) => (
                          <tr key={i}>
                            {/* <td>
                            <label
                              className="checkbox"
                              onChange={() => handleCheckbox(!row.kenaPajak, i)}
                            >
                              <input type="checkbox" checked={row.kenaPajak} />
                              <span className="checkmark"></span>
                            </label>
                          </td> */}
                            <td onClick={() => handleOpenDetail(i)}>
                              {row.noForm}
                            </td>
                            <td onClick={() => handleOpenDetail(i)}>
                              {format(new Date(row.tglFaktur), "dd MMMM yyyy")}
                            </td>
                            <td
                              className="txt-right"
                              onClick={() => handleOpenDetail(i)}
                            >
                              {formatRupiah(row.totalFaktur)}
                            </td>
                            <td
                              className="txt-right"
                              onClick={() => handleOpenDetail(i)}
                            >
                              {formatRupiah(row.terhutang)}
                            </td>
                            <td
                              className="txt-right"
                              onClick={() => handleOpenDetail(i)}
                            >
                              {formatRupiah(
                                parseInt(
                                  row.bayar.toString().replaceAll(".", "")
                                ) + handleSum(row.diskon)
                              )}
                            </td>
                            <td
                              className="txt-right"
                              onClick={() => handleOpenDetail(i)}
                            >
                              {formatRupiah(handleSum(row.diskon))}
                            </td>
                            <td
                              className="txt-right"
                              onClick={() => handleOpenDetail(i)}
                            >
                              {formatRupiah(row.bayar)}
                            </td>
                          </tr>
                        ))}
                    </table>
                  </div>

                  {show.modal && (
                    <div className="window-overlay">
                      <div className="window-container product-window small-popup">
                        <div className="window-nav">
                          <div className="tab_content_module">
                            <div
                              className={`tab_menu_content ${
                                modalTab === 0 && "active"
                              }`}
                              onClick={() => setModalTab(0)}
                            >
                              Faktur
                            </div>
                            <div
                              className={`tab_menu_content ${
                                modalTab === 1 && "active"
                              }`}
                              onClick={() => setModalTab(1)}
                            >
                              Informasi Diskon
                            </div>
                          </div>
                          <div
                            className="close-btn flex-center"
                            onClick={() => {
                              setShow({ ...show, modal: false });
                              setDiskonList([]);
                              setFakturToAdd({
                                itemId: 0,
                                noForm: "",
                                noFaktur: "",
                                tglFaktur: "",
                                totalFaktur: 0,
                                terhutang: 0,
                                bayar: "0",
                                diskon: diskonList,
                                mode: "c",
                              });
                              setDiskonToAdd({
                                diskonId: 0,
                                akun: null,
                                price: 0,
                                keterangan: "",
                                mode: "c",
                              });
                            }}
                          >
                            <span className="icon icon-close_ic"></span>
                          </div>
                        </div>

                        {modalTab === 0 && (
                          <div className="window-content">
                            <div className="form-side">
                              <form id="" className="">
                                <div className="form-wrap">
                                  <div className="form-container hlf">
                                    <div className="title">No Form#</div>
                                    <div className="bold">
                                      {fakturToAdd.noFaktur}
                                    </div>
                                  </div>

                                  <div className="form-container hlf">
                                    <div className="title">No. Faktur</div>
                                    <div className="bold">
                                      {fakturToAdd.noForm}
                                    </div>
                                  </div>

                                  <div className="form-container lg">
                                    <div className="title">Tgl Faktur</div>
                                    <div className="auto-form-container disable">
                                      <input
                                        name=""
                                        type="text"
                                        placeholder="Masukkan nama bank"
                                        value={fakturToAdd.tglFaktur}
                                      />
                                    </div>
                                  </div>

                                  <div className="form-container lg">
                                    <div className="title">Terhutang</div>
                                    <div className="auto-form-container disable">
                                      <input
                                        name=""
                                        type="text"
                                        placeholder="Masukkan no rekening"
                                        value={formatRupiah(
                                          fakturToAdd.terhutang
                                        )}
                                      />
                                    </div>
                                  </div>

                                  <div className="form-container lg">
                                    <div className="title">Bayar</div>
                                    <div className="auto-form-container">
                                      <input
                                        name=""
                                        type="text"
                                        placeholder="Masukkan atas nama rekening"
                                        value={formatRupiah(fakturToAdd.bayar)}
                                        onChange={(e) =>
                                          setFakturToAdd({
                                            ...fakturToAdd,
                                            bayar: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="clear"></div>
                          </div>
                        )}

                        {modalTab === 1 && (
                          <div className="window-content">
                            <div className="form-side">
                              <form id="" className="">
                                <div className="form-wrap">
                                  <SearchInput
                                    className="lg"
                                    title="Akun Diskon"
                                    placeholder="Cari/Pilih"
                                    value={diskonToAdd.akun}
                                    setValue={(e) =>
                                      setDiskonToAdd({
                                        ...diskonToAdd,
                                        akun: e,
                                      })
                                    }
                                    searchData={coaList}
                                  />

                                  <div className="form-container lg">
                                    <div className="title">Diskon</div>
                                    <div className="auto-form-container">
                                      <input
                                        name=""
                                        type="text"
                                        placeholder="Masukkan nominal diskon"
                                        value={formatRupiah(diskonToAdd.price)}
                                        onChange={(e) =>
                                          setDiskonToAdd({
                                            ...diskonToAdd,
                                            price: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="form-container lg">
                                    <div className="title">
                                      Keterangan Diskon
                                    </div>
                                    <div className="auto-form-container">
                                      <textarea
                                        id=""
                                        name=""
                                        rows="4"
                                        cols="50"
                                        placeholder="Tulisan tambahan informasi"
                                        value={diskonToAdd.keterangan}
                                        onChange={(e) =>
                                          setDiskonToAdd({
                                            ...diskonToAdd,
                                            keterangan: e.target.value,
                                          })
                                        }
                                      ></textarea>
                                    </div>
                                    <div className="icon-form-date icon-search_ic"></div>
                                  </div>

                                  <div
                                    className="form-container lg txt-right border-b p-b-20"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div
                                      className="txt-link"
                                      onClick={handleAddDiskonList}
                                    >
                                      Tambahkan +
                                    </div>
                                  </div>

                                  <div className="uploaded-wrap">
                                    {diskonList
                                      .filter((diskon) => diskon.mode !== "d")
                                      .map((diskon, i) => (
                                        <div className="uploaded-list">
                                          <div className="file-detail">
                                            <div className="file-size">
                                              Akun Diskon
                                            </div>
                                            <div className="file-name">
                                              {
                                                coaList.find(
                                                  (coa) =>
                                                    coa.id === diskon.akun
                                                )?.name
                                              }
                                            </div>
                                          </div>

                                          <div className="file-action w-115px">
                                            <div className="file-detail">
                                              <div className="file-size">
                                                Diskon
                                              </div>
                                              <div className="file-name">
                                                {formatRupiah(diskon.price)}
                                              </div>
                                            </div>
                                            <div
                                              className="action-wrap"
                                              onClick={() =>
                                                handleDeleteDiskonList(i)
                                              }
                                            >
                                              <div className="icon icon-hapus_ic"></div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="clear"></div>
                          </div>
                        )}

                        <div className="window-bottom">
                          <div
                            className="button btn-red flex-center"
                            onClick={handleDeleteTableProduct}
                          >
                            Hapus
                          </div>

                          <div
                            className="button btn-blue flex-center"
                            onClick={handleAddTableProduct}
                          >
                            Simpan
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {tab === 1 && (
                <div
                  className="content-wrap"
                  style={{ height: "300px", overflow: "auto" }}
                >
                  <div className="content-nav-module">
                    <form id="" className="">
                      <div className="col-fulltab-50">
                        <div className="form-wrap w-full">
                          <div className="section-title">Info Lainnya</div>

                          <DropdownInput
                            title="Metode Bayar"
                            setOpen={(e) => setShowType(e)}
                          >
                            {showType && (
                              <div className="popup-wrap gudang-wrap">
                                <ul>
                                  {metodeOption.map((row, i) => (
                                    <li
                                      key={i}
                                      onClick={() => {
                                        setValue("metodeBayar", row);
                                        setShowType(false);
                                      }}
                                    >
                                      <div className="text-list">{row}</div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            <input
                              name=""
                              type="text"
                              placeholder="Pilih Metode Bayar"
                              value={watch("metodeBayar")}
                              onClick={() => setShowType(!showType)}
                            />
                          </DropdownInput>

                          {watch("metodeBayar") === "Transfer" && (
                            <SearchInput
                              title="Bank"
                              placeholder="Cari/Pilih Bank dari Pemasok"
                              value={watch("noRek")}
                              setValue={(e) => {
                                setValue("noRek", e);
                              }}
                              searchData={listRekening}
                            />
                          )}

                          {watch("metodeBayar") === "Cek / Giro" && (
                            <>
                              <div class="form-container">
                                <div class="title">Nomor Giro</div>

                                <div class="auto-form-container">
                                  <input
                                    name=""
                                    type="text"
                                    placeholder="Masukkan Nomor Giro"
                                    {...register("noGiro")}
                                  />
                                </div>
                              </div>

                              <div class="form-container">
                                <div class="title">Tanggal Giro</div>
                                <div class="auto-form-container">
                                  <input
                                    ref={datePickerFakturRef}
                                    class="form-date-input"
                                    type="text"
                                    placeholder="Pilh tanggal"
                                    value={watch("tglGiro")}
                                    onChange={(e) =>
                                      setValue("tglGiro", e.target.value)
                                    }
                                  />
                                </div>
                                <div class="icon-form-date icon-date_ic"></div>
                              </div>
                            </>
                          )}

                          <div className="form-container">
                            <div className="title">Keterangan</div>

                            <div className="auto-form-container w-400px">
                              <textarea
                                id=""
                                name=""
                                rows="4"
                                cols="50"
                                placeholder="Tuliskan catatan di sini"
                                {...register("keterangan")}
                              ></textarea>
                            </div>
                          </div>

                          {/* <SearchInput
                            title="Cabang"
                            placeholder="Cari/Pilih Cabang"
                            value={watch("cabang")}
                            setValue={(e) => {
                              setValue("cabang", e);
                            }}
                            searchData={dataCabang}
                          /> */}

                          {detail && (
                            <div className="form-container">
                              <div class="ppn-desc">
                                <div>Rekonsiliasi</div>
                                <div>
                                  {choosenData?.isRekon ? "Ya" : "Tidak"}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              <div className="summary-section">
                <div className="summary-list-wrap">
                  <div className="summary-list">
                    <div className="summary-indicator blue"></div>
                    <div className="title">Nilai Pembayaran</div>
                    <div className="nominal">
                      {formatRupiah(nilaiPembayaranValue)}
                    </div>
                  </div>
                  <div className="summary-list">
                    <div className="summary-indicator green"></div>
                    <div className="title">Faktur Dibayar</div>
                    <div className="nominal">
                      {formatRupiah(fakturDibayarValue)}
                    </div>
                  </div>
                  <div className="summary-list">
                    <div className="summary-indicator red"></div>
                    <div className="title">Diskon</div>
                    <div className="nominal">{formatRupiah(diskonValue)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${
                (loading || loadingUpdate) && "disabled"
              }`}
              onClick={handleSubmit(handleSaveRequest)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>
            <div className="data-action-button purple-bg">
              <span className="icon icon-cetaik_ic"></span>
              Cetak
            </div>
            <div className="data-action-button purple-bg">
              <span className="icon icon-attach_ic"></span>
              Lampiran
            </div>
            <div className="data-action-button blue-bg">
              <span className="icon icon-more_ic"></span>
              Lainnya
            </div>
            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Pesanan
              pembelian {choosenData?.nomor_pesanan}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewPurchasePayment;
