import { gql } from "@apollo/client";
import { GUDANG_FRAGMENT } from "./GudangAction";

export const CABANG_FRAGMENT = gql`
  fragment Cabang on mCabang {
    kode_cabang
    nama_cabang
    alamat
    kota
    provinsi
    kodepos
    negara
    is_non_aktif
    list_gudang {
      ...Gudang
    }
  }
  ${GUDANG_FRAGMENT}
`;

export const CABANG_LIST = gql`
  query getCabangList($input: CabangPaginationInput!) {
    cabang {
      findAll(input: $input) {
        ...Cabang
      }
    }
  }
  ${CABANG_FRAGMENT}
`;

export const CREATE_CABANG = gql`
  mutation createCabang($input: CreateCabangInput!) {
    cabang {
      create(input: $input) {
        ...Cabang
      }
    }
  }
  ${CABANG_FRAGMENT}
`;

export const UPDATE_CABANG = gql`
  mutation editCabang($input: UpdateCabangInput!) {
    cabang {
      update(input: $input) {
        ...Cabang
      }
    }
  }
  ${CABANG_FRAGMENT}
`;

export const DELETE_CABANG = gql`
  mutation deleteCabang($input: String!) {
    cabang {
      delete(input: $input) {
        ...Cabang
      }
    }
  }
  ${CABANG_FRAGMENT}
`;
