import { createContext, useState, useContext, useEffect } from "react";

const StateContext = createContext();

export const useStateContext = () => useContext(StateContext);

const Provider = ({ children }) => {
  const [state, setState] = useState({
    idTabModule: 0,
    menuTabs: [],
  });

  useEffect(() => {
    const lastUpdateItems = localStorage.getItem("lastUpdate");
    if (!lastUpdateItems) {
      localStorage.setItem(
        "lastUpdate",
        JSON.stringify({
          idTabModule: 0,
          menuTabs: [],
        })
      );
    } else {
      setState(JSON.parse(lastUpdateItems));
    }
  }, []);

  return (
    <StateContext.Provider value={[state, setState]}>
      {children}
    </StateContext.Provider>
  );
};

export default Provider;
