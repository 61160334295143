import React from "react";

const ModalConfirm = (props) => {
  const { children, show } = props;

  if (show)
    return (
      <div className="window-overlay">
        <div className="window-container dialog-box">{children}</div>
      </div>
    );
};

export default ModalConfirm;
