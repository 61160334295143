import React from "react";
import { useHistory } from "react-router-dom";

const LoginPage = () => {
  const history = useHistory();

  const handleLogin = () => {
    history.push("/");
    window.sessionStorage.setItem("login", true);
  };

  return (
    <div className="main-container login-section">
      <div className="sign-sidebar">
        <div className="photo-section">
          <div className="image"></div>
        </div>
      </div>
      <div className="sign-content">
        <div className="main-content">
          <div className="auth-content">
            <div className="title-login">
              Masuk ke
              <br />
              Smart Inventory
            </div>
            <form id="" className="">
              <div className="form-wrap">
                <div className="form-container">
                  <div className="title">Email</div>
                  <input
                    id="datepicker_from"
                    className="form-login"
                    type="text"
                    placeholder="Masukkan email"
                  />
                  <div className="icon-form-date icon-login_ic"></div>
                </div>
              </div>
              <div className="form-wrap">
                <div className="form-container">
                  <div className="title">Password</div>
                  <input
                    id="datepicker_from"
                    className="form-login"
                    type="password"
                    placeholder="Masukkan password"
                  />
                  <div className="icon-form-date icon-password_ic"></div>
                </div>
              </div>
              <div className="forgot-pass">
                <a href="/">Lupa Password?..</a>
              </div>
              <input
                className="form-sub"
                type="submit"
                value="Masuk"
                onClick={handleLogin}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
