import React, { useEffect, useState } from "react";

const SatuanList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    getSatuanList,
    loading,
    dataSatuan,
  } = props;

  const [search, setSearch] = useState("");

  useEffect(() => {
    getSatuanList({
      variables: {
        input: {
          nama: search || "",
        },
      },
    });
    // eslint-disable-next-line
  }, [search]);

  const satuanList = dataSatuan?.barang.getSatuanBarang.data;
  const countSatuan = dataSatuan?.barang.getSatuanBarang.count;

  const handleOpenDetail = (index) => {
    if (tabs.find((tab) => tab.id === satuanList[index].uom_name)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: satuanList[index].uom_name,
          type: "detail",
          id: satuanList[index].uom_name,
        },
      ]);
    }
    setActive(satuanList[index].uom_name);
    setChoosenData([
      ...choosenData,
      { idTab: satuanList[index].uom_name, ...satuanList[index] },
    ]);
  };

  return (
    <>
      <div class="content_nav_module">
        <form id="" class="">
          <div class="form-right-side">
            <div class="form-wrap m-l-10">
              <div class="form-container">
                <div class="title">Pencarian</div>
                <input
                  id=""
                  class="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div class="icon-form-date icon-search_ic"></div>
              </div>
            </div>

            <div class="data-total-wrap">
              <div>TOTAL DATA</div>
              <div class="value">{countSatuan}</div>
            </div>
          </div>

          <div class="clear"></div>
        </form>
      </div>
      <div class="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <tr>
              <th>Nama</th>
            </tr>
            {loading ? (
              <p>Loading...</p>
            ) : countSatuan === 0 ? (
              <tr>
                <td>Tidak ada data.</td>
              </tr>
            ) : (
              satuanList?.map((uom, i) => (
                <tr onClick={() => handleOpenDetail(i)}>
                  <td>{uom.uom_name}</td>
                </tr>
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default SatuanList;
