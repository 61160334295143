import React, { useState } from "react";
import { useEffect } from "react";
import Status from "../../common/status";
import { format } from "date-fns";
import { formatRupiah } from "../../utils";

const PurchaseOrderList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    loading,
    dataPurchaseOrder,
  } = props;

  const [search, setSearch] = useState("");
  const [filteredCategory, setFilteredCategory] = useState([]);

  useEffect(() => {
    if (search.length > 0) {
      const filtered = dataPurchaseOrder?.PesananPembelian.findAll.filter(
        (purchase) =>
          purchase.nomor_pesanan.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredCategory(filtered);
    } else {
      setFilteredCategory(dataPurchaseOrder?.PesananPembelian.findAll);
    }
  }, [search, dataPurchaseOrder]);

  const purchaseOrderList = filteredCategory;

  const handleOpenDetail = (choosen) => {
    if (tabs.find((tab) => tab.id === choosen.nomor_pesanan)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: choosen.nomor_pesanan,
          type: "detail",
          id: choosen.nomor_pesanan,
        },
      ]);
    }
    setActive(choosen.nomor_pesanan);
    setChoosenData([
      ...choosenData,
      { idTab: choosen.nomor_pesanan, ...choosen },
    ]);
  };

  const calculateCount = (bebanList, productList, termasukPajak) => {
    const subTotalCount = (
      price,
      quantity,
      flat,
      percent,
      kenaPajak,
      includeTax
    ) => {
      const taxMultiplier = includeTax ? 1.11 : 1;

      if (percent === 0 && flat === 0) {
        const subtotal = kenaPajak
          ? (price * quantity) / taxMultiplier
          : price * quantity;
        return Math.round(subtotal);
      }

      const discountMultiplier = 1 - percent / 100;
      const subtotalBeforeTax =
        flat === 0
          ? price * quantity * discountMultiplier
          : price * quantity - flat;

      const subtotal = kenaPajak
        ? subtotalBeforeTax / taxMultiplier
        : subtotalBeforeTax;

      return Math.round(subtotal);
    };

    const bebanValue =
      bebanList.length > 0
        ? bebanList?.reduce(
            (total, beban) => total + parseFloat(beban.nilai),
            0
          )
        : 0;
    const subTotalValue =
      productList.length > 0
        ? productList?.reduce(
            (total, product) =>
              total +
              subTotalCount(
                product.harga,
                product.qty,
                product.nilai_diskon,
                product.persen_diskon,
                product.kena_pajak,
                termasukPajak
              ),
            0
          )
        : 0;
    const subTotalWoTaxValue =
      productList.length > 0
        ? productList
            ?.filter((product) => product.kena_pajak === false)
            .reduce(
              (total, product) =>
                total +
                subTotalCount(
                  product.harga,
                  product.qty,
                  product.nilai_diskon,
                  product.persen_diskon,
                  product.kena_pajak,
                  termasukPajak
                ),
              0
            )
        : 0;
    const ppnValue = parseInt(
      Math.ceil(subTotalValue - subTotalWoTaxValue === 0
        ? 0
        : (subTotalValue - subTotalWoTaxValue) * 0.11
      )
    );

    return formatRupiah(subTotalValue + bebanValue + ppnValue);
  };

  return (
    <>
      <div className="content_nav_module">
        <form id="" className="">
          <div className="nav-setting-wrap">
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-filter_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-import_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-cetaik_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-pengatura_ic"></span>
              </div>
            </div>
          </div>

          <div className="form-right-side">
            <div className="form-wrap">
              <div className="form-container">
                <div className="title">Pencarian</div>
                <input
                  id=""
                  className="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="icon-form-date icon-search_ic"></div>
              </div>
            </div>

            <div class="data-total-wrap">
              <div>TOTAL DATA</div>
              <div class="value">{filteredCategory?.length}</div>
            </div>
          </div>

          <div className="clear"></div>
        </form>
      </div>
      <div className="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <tr>
              <th>Nomor</th>
              <th>Tanggal</th>
              <th>Pemasok</th>
              <th>Keterangan</th>
              <th>Status</th>
              <th>Total</th>
            </tr>
            {loading ? (
              <p>Loading...</p>
            ) : purchaseOrderList?.length === 0 ? (
              <tr>
                <td>Tidak ada data.</td>
              </tr>
            ) : (
              purchaseOrderList?.map((purchase, i) => (
                <tr key={i} onClick={() => handleOpenDetail(purchase)}>
                  <td>{purchase.nomor_pesanan}</td>
                  <td>
                    {format(new Date(purchase.tgl_pesanan), "dd/MM/yyyy")}
                  </td>
                  <td>{purchase.pemasok.nama}</td>
                  <td>{purchase.keterangan}</td>
                  <td>
                    <Status isPaid={purchase.status} />
                  </td>
                  <td>
                    {calculateCount(
                      purchase.beban,
                      purchase.detail_item,
                      purchase.is_tot_inc_pajak
                    )}
                  </td>
                </tr>
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrderList;
