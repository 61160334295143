import { gql } from "@apollo/client";
// import { CABANG_FRAGMENT } from "./CabangAction";

export const PURCHASE_PAYMENT_FRAGMENT = gql`
  fragment PurchasePayment on mPembayaran {
    input_dt
    noPembayaran
    tglPembayaran
    noGiro
    noRek
    tglGiro
    cabang
    kodeCabang
    totalBayar
    metodeBayar
    bank
    isRekon
    pembayaranKe
    kodeSupplier
    detail {
      itemId
      noFaktur
      noFakturFisik
      tglFaktur
      totalFaktur
      nilaiBayar
      totalDiskon
      terhutang
      infoDiskon {
        diskonId
        kode_diskon
        nama_diskon
        nilai
        keterangan
      }
    }
  }
`;

export const PURCHASE_PAYMENT_LIST = gql`
  query getPurchasePaymentList {
    Pembayaran {
      findAll {
        ...PurchasePayment
      }
    }
  }
  ${PURCHASE_PAYMENT_FRAGMENT}
`;

export const CREATE_PURCHASE_PAYMENT = gql`
  mutation createPurchasePayment($input: NewPembayaranInput!) {
    Pembayaran {
      create(input: $input) {
        ...PurchasePayment
      }
    }
  }
  ${PURCHASE_PAYMENT_FRAGMENT}
`;

export const UPDATE_PURCHASE_PAYMENT = gql`
  mutation editPurchasePayment($input: UpdatePembayaranInput!) {
    Pembayaran {
      update(input: $input) {
        ...PurchasePayment
      }
    }
  }
  ${PURCHASE_PAYMENT_FRAGMENT}
`;

export const DELETE_PURCHASE_PAYMENT = gql`
  mutation deletePurchasePayment($input: String!) {
    Pembayaran {
      delete(input: $input)
    }
  }
`;
