import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { menuList } from "../../../utils/MenuList";
import { useStateContext } from "../../../../context";

const Sidebar = () => {
  const wrapperRef = useRef(null);

  const [state, setState] = useStateContext();
  const [hoverIndex, setHoverIndex] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleSetItem = (value) => {
    setState(value);

    localStorage.setItem("lastUpdate", JSON.stringify(value));
  };

  const openMenuTab = (row) => {
    let menus = state.menuTabs;
    if (menus.find((menu) => menu.id === row.id)) {
    } else {
      menus.push(row);
    }
    handleSetItem({
      ...state,
      menuTabs: [...menus],
      idTabModule: row.id,
    });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setActiveIndex(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div ref={wrapperRef} className="left-module">
      <div className="anita-logo"></div>
      <div className="main-menu-module">
        <div className="menu-wrap">
          {menuList.map((menu, index) => (
            <div
              className={`menu-list flex-center ${
                activeIndex === index && "active"
              }`}
              onClick={() => {
                activeIndex === index
                  ? setActiveIndex(null)
                  : setActiveIndex(index);
              }}
              onMouseEnter={() => {
                setHoverIndex(index);
              }}
              onMouseLeave={() => {
                setHoverIndex(null);
              }}
            >
              <span className={menu.icon}></span>
              {hoverIndex === index && (
                <div className="tooltip">{menu.name}</div>
              )}
              {activeIndex === index && menu.child !== null && (
                <div class="menu-popup">
                  <div class="menu-title">{menu.name}</div>
                  <div class="popup-menu-wrap">
                    {menu.child.row1.length > 0 && (
                      <ul class="row">
                        {menu.child.row1.map((row) => (
                          <li
                            class={row.color}
                            onClick={() => {
                              openMenuTab(row);
                              setHoverIndex(null);
                            }}
                          >
                            <div class={`icon ${row.icon}`}></div>
                            <div class="text">{row.name}</div>
                          </li>
                        ))}
                      </ul>
                    )}

                    {menu.child.row2?.length > 0 && (
                      <ul class="row">
                        {menu.child.row2.map((row) => (
                          <li
                            class={row.color}
                            onClick={() => {
                              openMenuTab(row);
                              setHoverIndex(null);
                            }}
                          >
                            <div class={`icon ${row.icon}`}></div>
                            <div class="text">{row.name}</div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
