import React, { useState } from "react";
import { useEffect } from "react";

const SupplierCategoryList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    loading,
    dataCategory,
  } = props;

  const [search, setSearch] = useState("");
  const [filteredCategory, setFilteredCategory] = useState([]);

  useEffect(() => {
    if (search.length > 0) {
      const filtered = dataCategory?.Pemasok.listKategori.filter((kategori) =>
        kategori.nama_kategori.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredCategory(filtered);
    } else {
      setFilteredCategory(dataCategory?.Pemasok.listKategori);
    }
  }, [search, dataCategory]);

  const categoryList = filteredCategory;

  const handleOpenDetail = (choosen) => {
    if (tabs.find((tab) => tab.id === choosen.nama_kategori)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: choosen.nama_kategori,
          type: "detail",
          id: choosen.nama_kategori,
        },
      ]);
    }
    setActive(choosen.nama_kategori);
    setChoosenData([
      ...choosenData,
      { idTab: choosen.nama_kategori, ...choosen },
    ]);
  };

  return (
    <>
      <div className="content_nav_module">
        <form id="" className="">
          <div className="nav-setting-wrap">
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-filter_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-import_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-cetaik_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-pengatura_ic"></span>
              </div>
            </div>
          </div>

          <div className="form-right-side">
            <div className="form-wrap">
              <div className="form-container">
                <div className="title">Pencarian</div>
                <input
                  id=""
                  className="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="icon-form-date icon-search_ic"></div>
              </div>
            </div>
          </div>

          <div className="clear"></div>
        </form>
      </div>
      <div className="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <tr>
              <th>Nama</th>
              <th style={{ width: "200px" }}>Kategori Default</th>
            </tr>
            {loading ? (
              <p>Loading...</p>
            ) : categoryList?.length === 0 ? (
              <tr>
                <td>Tidak ada data.</td>
              </tr>
            ) : (
              categoryList?.map((category, i) => (
                <>
                  <tr key={i} onClick={() => handleOpenDetail(category)}>
                    <td class="ktg-utama">{category.nama_kategori}</td>
                    <td>{category.is_default ? "Ya" : "Tidak"}</td>
                  </tr>
                  {category?.child?.length > 0 &&
                    category?.child?.map((childCategory) => (
                      <>
                        <tr onClick={() => handleOpenDetail(childCategory)}>
                          <td class="sub-ktg">{childCategory.nama_kategori}</td>
                          <td>{childCategory.is_default ? "Ya" : "Tidak"}</td>
                        </tr>
                        {childCategory.child?.length > 0 &&
                          childCategory.child.map((grandChild) => (
                            <tr onClick={() => handleOpenDetail(grandChild)}>
                              <td class="sub-sub-ktg">
                                {grandChild.nama_kategori}
                              </td>
                              <td>{grandChild.is_default ? "Ya" : "Tidak"}</td>
                            </tr>
                          ))}
                      </>
                    ))}
                </>
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default SupplierCategoryList;
