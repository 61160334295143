import { gql } from "@apollo/client";
import { SATUAN_FRAGMENT } from "./SatuanBarangAction";
import { CATEGORY_FRAGMENT_2 } from "./KategoriBarangAction";
import { LOOKUP_FRAGMENT } from "./LookupAction";

export const GOODS_AND_SERVICE_FRAGMENT = gql`
  fragment GoodsAndService on mBarang {
    kode_barang
    is_aktif
    nama_barang
    coa_persediaan
    coa_beban
    coa_penjualan
    coa_retur_penjualan
    coa_diskon_penjualan
    coa_barang_terkirim
    coa_bahan_pokok_penjualan
    coa_retur_pembelian
    coa_pembelian_belum_tagih
    catatan
    is_bahan_baku
    is_ppn
    is_pph
    is_summary_price
    ppn_value
    is_bahan_produksi
    upc_barcode
    minimum_beli
    low_pcs
    detail {
      input_dt
      detail_barang_id
      kode_barang
      kode_gudang
      nama_barang
      pcs
      uom_id
      price_per_uom
    }
    detail_harga {
      harga_id
      uom {
        ...Satuan
      }
      harga_jual_per_uom
      uom_per_parent
      is_parent
    }
    kategori_barang {
      ...Category2
    }
    base_uom {
      ...Satuan
    }
    beli_uom {
      ...Satuan
    }
    jenis_barang {
      ...Lookup
    }
  }
  ${SATUAN_FRAGMENT}
  ${CATEGORY_FRAGMENT_2}
  ${LOOKUP_FRAGMENT}
`;

export const GOODS_AND_SERVICE_LIST = gql`
  query getGoodsAndServiceList($input: BarangPaginationInput) {
    barang {
      findAll(input: $input) {
        count
        data {
          ...GoodsAndService
        }
      }
    }
  }
  ${GOODS_AND_SERVICE_FRAGMENT}
`;

export const JENIS_BARANG_LIST = gql`
  query jenisBarangList {
    barang {
      getJenisBarang {
        lv_code
        deskripsi
        lv_value
      }
    }
  }
`;

export const CREATE_BARANG_JASA = gql`
  mutation createBarangJasa($input: NewBarangInput!) {
    barang {
      create(input: $input) {
        ...GoodsAndService
      }
    }
  }
  ${GOODS_AND_SERVICE_FRAGMENT}
`;

export const UPDATE_BARANG_JASA = gql`
  mutation editBarangJasa($input: UpdateBarangInput!) {
    barang {
      update(input: $input) {
        ...GoodsAndService
      }
    }
  }
  ${GOODS_AND_SERVICE_FRAGMENT}
`;

export const DELETE_BARANG_JASA = gql`
  mutation deleteBarangJasa($input: String!) {
    barang {
      delete(input: $input) {
        ...GoodsAndService
      }
    }
  }
  ${GOODS_AND_SERVICE_FRAGMENT}
`;
