import { gql } from "@apollo/client";
// import { CABANG_FRAGMENT } from "./CabangAction";
import { GUDANG_FRAGMENT } from "./GudangAction";
import { SUPPLIER_FRAGMENT } from "./SupplierAction";
import { SYARAT_PEMBAYARAN_FRAGMENT } from "./SyaratPembayaranAction";

export const PURCHASE_DETAIL_FRAGMENT = gql`
  fragment PurchaseDetail on mDetailFakturPembelian {
    kodeBarang
    namaBarang
    pcs
    uom
    satuan
    harga
    persenDiskon
    nilaiDiskon
    kenaPajak
    remarks
  }
`;

export const PURCHASE_INVOICE_FRAGMENT = gql`
  fragment PurchaseInvoice on mFakturPembelian {
    noFaktur
    noFakturFisik
    nomorPesanan
    nomorPenerimaan
    pemasok {
      kode_supplier
      nama
    }
    tglFaktur
    syartPembayaran {
      ...SyaratPembayaran
    }
    gudangPenerima {
      ...Gudang
    }
    status
    alamatPenerima
    keterangan
    isKenaPajak
    isTotPajak
    jenisDokumen
    jenisTransaksi
    tglFakturPajak
    nomorFakturPajak
    totalTerbayar
    totalHutang
    tglPengiriman
    detail {
      ...PurchaseDetail
    }
    beban {
      kodeCoa
      nilai
    }
  }
  ${GUDANG_FRAGMENT}
  ${SYARAT_PEMBAYARAN_FRAGMENT}
  ${PURCHASE_DETAIL_FRAGMENT}
`;

export const FAKTUR_AMBIL_FRAGMENT = gql`
  fragment FakturAmbilItem on mFakturAmbil {
    noBatch
    pemasok {
      kode
      nama
    }
    syartPembayaran {
      ...SyaratPembayaran
    }
    gudangPenerima {
      ...Gudang
    }
    alamatPenerima
    tglPengiriman
    isTotPajak
    detail {
      ...PurchaseDetail
    }
    beban {
      kodeCoa
      nilai
    }
  }
  ${GUDANG_FRAGMENT}
  ${SYARAT_PEMBAYARAN_FRAGMENT}
  ${PURCHASE_DETAIL_FRAGMENT}
`;

export const PURCHASE_INVOICE_FIND_ORDER_LIST = gql`
  query getPurchaseInvoiceFindOrder {
    FakturPembelian {
      findPO {
        ...FakturAmbilItem
      }
      findRI {
        ...FakturAmbilItem
      }
    }
  }
  ${FAKTUR_AMBIL_FRAGMENT}
`;

export const PURCHASE_INVOICE_LIST = gql`
  query getPurchaseInvoiceList($input: FakturPembelianPaginationInput!) {
    FakturPembelian {
      findAll(input: $input) {
        ...PurchaseInvoice
      }
    }
  }
  ${PURCHASE_INVOICE_FRAGMENT}
`;

export const CREATE_PURCHASE_INVOICE = gql`
  mutation createPurchaseInvoice($input: CreateFakturPembelianInput!) {
    FakturPembelian {
      create(input: $input) {
        ...PurchaseInvoice
      }
    }
  }
  ${PURCHASE_INVOICE_FRAGMENT}
`;

export const UPDATE_PURCHASE_INVOICE = gql`
  mutation editPurchaseInvoice($input: UpdateFakturPembelianInput!) {
    FakturPembelian {
      update(input: $input) {
        ...PurchaseInvoice
      }
    }
  }
  ${PURCHASE_INVOICE_FRAGMENT}
`;

export const DELETE_PURCHASE_INVOICE = gql`
  mutation deletePurchaseInvoice($input: String!) {
    FakturPembelian {
      delete(input: $input)
    }
  }
`;
