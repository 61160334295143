import React from "react";
import Container from "../common/container";
import TabContent from "../common/tabContent";
import AddNewGoodsAndServices from "./addNew";
import { useQuery } from "@apollo/client";
import {
  GOODS_AND_SERVICE_LIST,
  JENIS_BARANG_LIST,
} from "../../actions/BarangAction";
import { toast } from "react-toastify";
import { CATEGORY_LIST } from "../../actions/KategoriBarangAction";
import GoodsAndServicesList from "./list";

const GoodsAndServicesModul = () => {
  const [tabSession, setTabSession] = React.useState([
    { label: "Lihat Data", type: "list", id: 0 },
    { label: "Buat Baru", type: "add-new", id: 1 },
  ]);
  const [activeTabId, setActiveTabId] = React.useState(1);
  const [choosenData, setChoosenData] = React.useState([]);

  const {
    loading,
    error,
    data: dataBarangJasa,
    refetch,
  } = useQuery(GOODS_AND_SERVICE_LIST);

  const { data: dataCategory } = useQuery(CATEGORY_LIST);

  const { data: dataBarangType } = useQuery(JENIS_BARANG_LIST);

  if (error) {
    toast.error(error.message);
  }

  const categoryList = dataCategory?.barang.getKategoriBarang.flatMap(
    (category) => {
      const parentCategory = {
        id: category.kategori_barang_id.toString(),
        name: category.category_name,
        ...category,
      };
      const childCategory = category.child.map((child) => ({
        id: child.kategori_barang_id.toString(),
        name: `- ${child.category_name}`,
        prefix: category.default_prefix,
        ...child,
      }));
      return [parentCategory, ...childCategory];
    }
  );

  const barangTypeList = dataBarangType?.barang.getJenisBarang.map((type) => ({
    id: type.lv_value,
    name: type.deskripsi,
  }));

  const barangJasaList = dataBarangJasa?.barang.findAll.data.map((barang) => ({
    id: barang.kode_barang,
    name: barang.nama_barang,
    ...barang,
  }));

  const activeData = tabSession.find((tab) => tab.id === activeTabId);

  return (
    <div>
      <TabContent
        active={activeTabId}
        setActive={setActiveTabId}
        tabs={tabSession}
        setTabs={setTabSession}
      />

      <Container>
        {activeData.type === "list" ? (
          <GoodsAndServicesList
            tabs={tabSession}
            setTabs={setTabSession}
            activeId={activeTabId}
            setActive={setActiveTabId}
            choosenData={choosenData}
            setChoosenData={setChoosenData}
            dataBarangJasa={dataBarangJasa}
            loading={loading}
            dataCategory={categoryList}
            dataBarangType={barangTypeList}
          />
        ) : activeData.type === "add-new" ? (
          <AddNewGoodsAndServices
            setTabOpen={setActiveTabId}
            refetch={refetch}
            dataCategory={categoryList}
            dataBarangType={barangTypeList}
            dataBarangJasa={barangJasaList}
          />
        ) : (
          <AddNewGoodsAndServices
            detail={true}
            choosenData={choosenData.find(
              (choosen) => choosen.idTab === activeTabId
            )}
            dataPerTabs={choosenData}
            setChoosenData={setChoosenData}
            tabs={tabSession}
            setTabs={setTabSession}
            tabOpen={activeTabId}
            setTabOpen={setActiveTabId}
            refetch={refetch}
            dataCategory={categoryList}
            dataBarangType={barangTypeList}
            dataBarangJasa={barangJasaList}
          />
        )}
      </Container>
    </div>
  );
};

export default GoodsAndServicesModul;
