import React, { useState } from "react";
import { useEffect } from "react";
import { format } from "date-fns";
import { formatRupiah } from "../../utils";

const PurchasePaymentList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    loading,
    dataPurchasePayment,
    supplierData,
  } = props;

  const [search, setSearch] = useState("");
  const [filteredCategory, setFilteredCategory] = useState([]);

  useEffect(() => {
    if (search.length > 0) {
      const filtered = dataPurchasePayment?.Pembayaran.findAll.filter(
        (purchase) =>
          purchase.noPembayaran.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredCategory(filtered);
    } else {
      setFilteredCategory(dataPurchasePayment?.Pembayaran.findAll);
    }
  }, [search, dataPurchasePayment]);

  const purchasePaymentList = filteredCategory;

  const handleOpenDetail = (choosen) => {
    if (tabs.find((tab) => tab.id === choosen.noPembayaran)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: choosen.noPembayaran,
          type: "detail",
          id: choosen.noPembayaran,
        },
      ]);
    }
    setActive(choosen.noPembayaran);
    setChoosenData([
      ...choosenData,
      { idTab: choosen.noPembayaran, ...choosen },
    ]);
  };

  return (
    <>
      <div className="content_nav_module">
        <form id="" className="">
          <div className="nav-setting-wrap">
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-filter_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-import_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-cetaik_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-pengatura_ic"></span>
              </div>
            </div>
          </div>

          <div className="form-right-side">
            <div className="form-wrap">
              <div className="form-container">
                <div className="title">Pencarian</div>
                <input
                  id=""
                  className="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="icon-form-date icon-search_ic"></div>
              </div>
            </div>

            <div class="data-total-wrap">
              <div>TOTAL DATA</div>
              <div class="value">{filteredCategory?.length}</div>
            </div>
          </div>

          <div className="clear"></div>
        </form>
      </div>
      <div className="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <tr>
              <th>Nomor #</th>
              <th>Tanggal</th>
              <th>Pemasok</th>
              <th>Keterangan</th>
              <th>Total Bayar</th>
            </tr>
            {loading ? (
              <p>Loading...</p>
            ) : purchasePaymentList?.length === 0 ? (
              <tr>
                <td colSpan={8}>Tidak ada data.</td>
              </tr>
            ) : (
              purchasePaymentList?.map((purchase, i) => (
                <tr key={i} onClick={() => handleOpenDetail(purchase)}>
                  <td>{purchase.noPembayaran}</td>
                  <td>
                    {format(new Date(purchase.tglPembayaran), "dd/MM/yyyy")}
                  </td>
                  <td>{purchase.pembayaranKe}</td>
                  <td>{purchase.keterangan}</td>
                  <td>{formatRupiah(purchase.totalBayar)}</td>
                </tr>
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default PurchasePaymentList;
