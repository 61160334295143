import React, { useEffect, useState } from "react";
import { useRef } from "react";

const SearchInput = (props) => {
  const {
    title,
    placeholder,
    value,
    setValue,
    searchData = [],
    className = "",
    onClose = () => ({}),
  } = props;

  const wrapperRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [searchTerms, setSearchTerms] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (searchTerms.length > 0) {
      const filteredOption = searchData.filter((data) =>
        data.name.toLowerCase().includes(searchTerms.toLowerCase())
      );
      setOpen(true);

      setSearchResults(filteredOption);
    } else {
      setSearchResults(searchData);
      setOpen(false);
    }

    // eslint-disable-next-line
  }, [searchTerms, searchData]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChooseOption = (choosen) => {
    setValue(choosen.id);
    setOpen(!open);
    setSearchTerms("");
  };

  return (
    <div ref={wrapperRef} className={`form-container satuan ${className}`}>
      {title && <div className="title">{title}</div>}

      <div className="auto-form-container">
        {value && (
          <ul className="input-label" style={{ display: "block" }}>
            <li>
              <label>
                {searchData.find((result) => result.id === value)?.name}
              </label>
              <span
                onClick={() => {
                  setValue(null);
                  onClose();
                }}
              >
                <i className="icon icon-close_ic"></i>
              </span>
            </li>
          </ul>
        )}

        {open && (
          <div className="popup-wrap lg-wrap">
            <ul className="main-list">
              {searchResults.map((result, i) => (
                <li key={i} onClick={() => handleChooseOption(result)}>
                  <div className="text-list">{result.name}</div>
                  {result.desc && (
                    <div className="text-desc">{result.desc}</div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

        {!value && (
          <input
            required
            type="text"
            placeholder={placeholder}
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          />
        )}
      </div>
      <div
        className="icon-form-date icon-search_ic"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      ></div>
    </div>
  );
};

export default SearchInput;
