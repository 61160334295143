import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import {
  CREATE_CABANG,
  DELETE_CABANG,
  UPDATE_CABANG,
} from "../../../actions/CabangAction";
import MultipleSearchInput from "../../common/multipleSearchInput";
import { GUDANG_LIST } from "../../../actions/GudangAction";

const AddNewCabang = (props) => {
  const {
    detail,
    choosenData,
    dataPerTabs,
    setChoosenData,
    tabs,
    setTabs,
    tabOpen,
    setTabOpen,
    refetch,
  } = props;

  const [tab, setTab] = useState(0);
  const [isNonActive, setIsNonActive] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [selectedGudang, setSelectedGudang] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nama"),
    kode: Yup.string().max(3).required("Kode barang"),
    address: Yup.string().required("Alamat"),
    city: Yup.string().required("Kota"),
    province: Yup.string().required("Provinsi"),
    postcode: Yup.string().required("Kodepos"),
    gudang:
      selectedGudang.length === 0
        ? Yup.string().required("Gudang")
        : Yup.string().nullable(),
    // group_branch: Yup.string().nullable(),
    // user_access: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = dataPerTabs.filter(
      (tab) => tab.idTab !== choosenData.idTab
    );
    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    if (detail) {
      const gudangList = choosenData.list_gudang?.map(
        (gudang) => gudang.kode_gudang
      );
      setValue("postcode", choosenData.kodepos);
      setValue("kode", choosenData.kode_cabang);
      setValue("address", choosenData.alamat);
      setValue("city", choosenData.kota);
      setValue("group_branch", choosenData.group?.kode_group);
      setValue("name", choosenData.nama_cabang);
      setValue("province", choosenData.provinsi);
      setValue("user_access", choosenData.pengguna?.username);
      // setIsAllAccess(choosenData.is_all_access);
      setIsNonActive(choosenData.is_non_aktif);
      setSelectedGudang(gudangList);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const { data: gudangData, refetch: refetchGudang } = useQuery(GUDANG_LIST, {
    variables: {
      input: {},
    },
  });

  const [createCabang, { loading }] = useMutation(CREATE_CABANG, {
    onCompleted: () => {
      toast.success("Data cabang berhasil ditambahkan");
      setTabOpen(0);
      refetch({
        variables: {
          input: {},
        },
      });
      refetchGudang({
        variables: {
          input: {},
        },
      });
    },
    onError: (resp) => {
      toast.error(resp.message);
    },
  });

  const [deleteCabang] = useMutation(DELETE_CABANG, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch({
        variables: {
          input: {},
        },
      });
      refetchGudang({
        variables: {
          input: {},
        },
      });
    },
    onError: (resp) => {
      toast.error(resp);
    },
  });

  const [ubahCabang, { loading: loadingUpdate }] = useMutation(UPDATE_CABANG, {
    onCompleted: (resp) => {
      setTabOpen(0);
      toast.success("Sukses mengubah data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch({
        variables: {
          input: {},
        },
      });
      refetchGudang({
        variables: {
          input: {},
        },
      });
    },
    onError: (resp) => {
      toast.error(resp);
    },
  });

  const dataGudangFull = gudangData?.Gudang.findAll.map((gudang) => ({
    id: gudang.kode_gudang,
    name: `Gudang ${gudang.nama_gudang}`,
    desc: gudang.alamat,
  }));
  const dataGudang = gudangData?.Gudang.findAll
    .filter((gudang) => gudang.kode_cabang === null)
    .map((gudang) => ({
      id: gudang.kode_gudang,
      name: `Gudang ${gudang.nama_gudang}`,
      desc: gudang.alamat,
    }));

  const onSubmit = (data) => {
    const inputs = {
      kode: data.kode,
      nama: data.name,
      deskripsi: null,
      alamat: data.address,
      kota: data.city,
      provinsi: data.province,
      kodePos: data.postcode,
      // isAllAkses: isAllAccess,
      isNonAktif: isNonActive,
      listGudang: selectedGudang,
      // group: data.group_branch || null,
      // pengguna: data.user_access || null,
    };
    if (detail) {
      ubahCabang({
        variables: {
          input: {
            kodeLama: choosenData.kode_cabang,
            ...inputs,
          },
        },
      });
    } else {
      createCabang({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteCabang({
      variables: {
        input: choosenData.kode_cabang,
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="left-content-module">
          <div className="main-content-module detail-page full-tab">
            <div className="flex-center">
              <div
                className={`tab-list ${tab === 0 && "active"}`}
                onClick={() => setTab(0)}
              >
                Cabang
              </div>
              <div
                className={`tab-list ${tab === 1 && "active"}`}
                onClick={() => setTab(1)}
              >
                Hak Akses Gudang
              </div>
            </div>

            {tab === 0 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <form id="" className="">
                    <div className="form-wrap">
                      <div className="form-container">
                        <div className="title">Nama Cabang</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan Nama Cabang"
                            {...register("name", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Kode Cabang</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan Kode Cabang"
                            {...register("kode", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Jalan</div>

                        <div className="auto-form-container">
                          <textarea
                            required
                            rows="4"
                            cols="50"
                            placeholder="Tuliskan jalan di sini"
                            {...register("address", { required: true })}
                          ></textarea>
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Kota</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan Kota"
                            {...register("city", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Kode Pos</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="number"
                            placeholder="Masukkan Kode Pos"
                            {...register("postcode", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Provinsi</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan Provinsi"
                            {...register("province", { required: true })}
                          />
                        </div>
                      </div>

                      {/* <div className="form-container">
                        <div className="title">Negara</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan Negara"
                            {...register("country", { required: true })}
                          />
                        </div>
                      </div> */}

                      <div className="form-container flex-center w-100">
                        <div className="ppn-desc">
                          <div>Non Aktif</div>
                          {isNonActive ? <div>Ya</div> : <div>Tidak</div>}
                        </div>
                        <div
                          className={`toggle-btn ${isNonActive ? "enable" : "disable"
                            }`}
                          onClick={() => setIsNonActive(!isNonActive)}
                        ></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {tab === 1 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="form-wrap">
                    <MultipleSearchInput
                      title="Gudang"
                      placeholder="Cari gudang"
                      value={selectedGudang}
                      setValue={setSelectedGudang}
                      searchData={dataGudang}
                      dataList={dataGudangFull}
                    />

                    <div className="form-container"></div>
                    <div className="form-container"></div>
                    <div className="form-container"></div>
                    <div className="form-container"></div>
                    <div className="form-container"></div>
                    <div className="form-container"></div>
                    <div className="form-container"></div>
                    <div className="form-container"></div>
                    <div className="form-container"></div>
                    <div className="form-container"></div>
                    <div className="form-container"></div>
                    <div className="form-container"></div>
                    <div className="form-container"></div>
                  </div>
                </div>
              </div>
            )}

            {/* {tab === 1 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <div className="form-container flex-center w-100">
                        <div className="ppn-desc">
                          <div>Akses Semua Pengguna</div>
                          {isAllAccess ? <div>Ya</div> : <div>Tidak</div>}
                        </div>
                        <div
                          className={`toggle-btn ${
                            isAllAccess ? "enable" : "disable"
                          }`}
                          onClick={() => setIsAllAccess(!isAllAccess)}
                        ></div>
                      </div>
                    </div>

                    {!isAllAccess && (
                      <div className="form-wrap">
                        <div className="section-title">
                          Hak akses pengguna Cabang
                        </div>

                        <SearchInput
                          title="Group"
                          placeholder="Cari group"
                          value={watch("group_branch")}
                          setValue={(e) => setValue("group_branch", e)}
                          searchData={accountGroupOption}
                        />

                        <SearchInput
                          title="Pengguna"
                          placeholder="Cari nama"
                          value={watch("user_access")}
                          setValue={(e) => setValue("user_access", e)}
                          searchData={accountOption}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${(loading || loadingUpdate) && "disabled"
                }`}
              onClick={handleSubmit(onSubmit)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>

            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Cabang{" "}
              {choosenData?.nama_cabang}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewCabang;
