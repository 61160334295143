import React, { useEffect, useRef, useState } from "react";
import { requestTypeOption } from "../../data";
import $ from "jquery";
import * as Yup from "yup";
import DropdownInput from "../../common/dropdownInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchInput from "../../common/searchInput";
import { CABANG_LIST } from "../../../actions/CabangAction";
import { useMutation, useQuery } from "@apollo/client";
import { SATUAN_LIST } from "../../../actions/SatuanBarangAction";
import { GOODS_AND_SERVICE_LIST } from "../../../actions/BarangAction";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import {
  CREATE_REQUEST_ORDER,
  DELETE_REQUEST_ORDER,
  UPDATE_REQUEST_ORDER,
} from "../../../actions/RequestOrderAction";
import { toast } from "react-toastify";
import { GUDANG_LIST } from "../../../actions/GudangAction";
import { format } from "date-fns";

const AddNewRequestOrder = (props) => {
  const {
    detail,
    choosenData,
    dataPerTabs,
    setChoosenData,
    tabs,
    setTabs,
    tabOpen,
    setTabOpen,
    refetch,
  } = props;

  const datePickerRef = useRef(null);
  const datePickerModalRef = useRef(null);

  const [tab, setTab] = useState(0);
  const [modalTab, setModalTab] = useState(0);
  const [requestType, setRequestType] = useState("");
  const [showType, setShowType] = useState(false);
  const [show, setShow] = useState({
    menu: false,
    employees: false,
    modal: false,
  });
  const [productToAdd, setProductToAdd] = useState({
    code: "",
    name: "",
    tglDiminta: "",
    quantity: 0,
    satuan: null,
    remarks: "",
    mode: "c",
  });
  const [productPhoto, setProductPhoto] = useState(null);
  const [productList, setProductList] = useState([]);
  const [choosenId, setChoosenId] = useState(0);
  const [editProduct, setEditProduct] = useState(false);
  const [gudangOption, setGudangOption] = useState([]);
  const [satuanOption, setSatuanOption] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);

  const validationSchema = Yup.object().shape({
    tgl_permintaan: Yup.string().required("Tanggal Permintaan"),
    cabang: Yup.string().required("Cabang"),
    gudangPenerima: Yup.string().required("Gudang penerima"),
    gudangPengirim:
      requestType === "Kirim Barang"
        ? Yup.string().required("Gudang pengirim")
        : Yup.string().nullable(),
    keterangan: Yup.string().nullable(),
    detail:
      productList.length > 0
        ? Yup.string().nullable()
        : Yup.string().required("Detail barang"),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      parent_id: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = [...dataPerTabs];
    updatedList.splice(tabOpen, 1);

    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    $(datePickerRef.current).datepicker({
      onSelect: (dateText) => {
        setValue("tgl_permintaan", dateText);
      },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (show.modal) {
      $(datePickerModalRef.current).datepicker({
        onSelect: (dateText) => {
          setProductToAdd({ ...productToAdd, tglDiminta: dateText });
        },
      });
    }
    // eslint-disable-next-line
  }, [show.modal]);

  const { data: dataCabang } = useQuery(CABANG_LIST, {
    variables: {
      input: {},
    },
  });
  const { data: satuanData } = useQuery(SATUAN_LIST, {
    variables: {
      input: {
        nama: "",
      },
    },
  });
  const { data: gudangData } = useQuery(GUDANG_LIST, {
    variables: {
      input: {},
    },
  });
  const { data: dataBarangJasa } = useQuery(GOODS_AND_SERVICE_LIST);
  const [createRequestOrder, { loading }] = useMutation(CREATE_REQUEST_ORDER, {
    onCompleted: (resp) => {
      toast.success("Permintaan barang berhasil dibuat");
      setTabOpen(0);
      refetch();
    },
    onError: (resp) => {
      toast.error(resp.message);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: [
    //           ...list.barang.getKategoriBarang,
    //           data.barang.createKategoriBarang,
    //         ],
    //       },
    //     },
    //   });
    // },
  });

  const [deleteRequestOrder] = useMutation(DELETE_REQUEST_ORDER, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   const updatedList = list.barang.getKategoriBarang.filter(
    //     (category) =>
    //       category.kategori_barang_id !== choosenData.kategori_barang_id
    //   );
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: updatedList,
    //       },
    //     },
    //   });
    // },
  });

  const [ubahRequestOrder, { loading: loadingUpdate }] = useMutation(
    UPDATE_REQUEST_ORDER,
    {
      onCompleted: (resp) => {
        setTabOpen(0);
        toast.success("Sukses mengubah data");
        setTabs(tabs.filter((tab) => tab.id !== tabOpen));
        handleUpdateTabData();
        refetch();
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  const cabangList = dataCabang?.cabang.findAll.map((cabang) => ({
    id: cabang.kode_cabang,
    name: cabang.nama_cabang,
    gudang: cabang.list_gudang,
  }));
  const dataSatuan = satuanData?.barang.getSatuanBarang.data.map((satuan) => ({
    id: satuan.uom_id,
    name: `${satuan.uom_alias} - ${satuan.uom_name}`,
    alias: satuan.uom_name,
  }));
  const barangJasaList = dataBarangJasa?.barang.findAll.data.map((barang) => ({
    id: barang.kode_barang,
    name: barang.nama_barang,
    ...barang,
  }));
  const dataGudang = gudangData?.Gudang.findAll.map((gudang) => ({
    id: gudang.kode_gudang,
    name: `Gudang ${gudang.nama_gudang}`,
    desc: gudang.alamat,
  }));

  useEffect(() => {
    if (detail && choosenData) {
      const gudangDetailList = cabangList.find(
        (gudang) => gudang.id === choosenData.cabang.kode_cabang
      );
      setValue("tgl_permintaan", choosenData.tgl_permintaan.slice(0, 10));
      setValue("cabang", choosenData.cabang.kode_cabang);
      setValue(
        "gudangPengirim",
        choosenData.gudangPengirim !== null
          ? choosenData.gudangPengirim.kode_gudang
          : null
      );
      setValue("gudangPenerima", choosenData.gudangPenerima.kode_gudang);
      setValue("keterangan", choosenData.keterangan);
      setRequestType(choosenData.tipe_permintaan);
      setProductList(
        choosenData.detail.map((product) => ({
          code: product.barang.kode_barang,
          name: product.barang.nama_barang,
          tglDiminta: product.tgl_kirim.slice(0, 10),
          quantity: product.qty,
          satuan: product.satuan.uom_id,
          remarks: product.keterangan,
          mode: "e",
        }))
      );
      setGudangOption(
        gudangDetailList.gudang.map((gudang) => ({
          id: gudang.kode_gudang,
          name: `Gudang ${gudang.nama_gudang}`,
          desc: gudang.alamat,
        }))
      );
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const handleGetSatuanOption = (selectedBarang) => {
    const tempSatuanOption = selectedBarang.detail_harga.map((harga) => ({
      id: harga.uom.uom_id,
      name: `${harga.uom.uom_alias} - ${harga.uom.uom_name}`,
      alias: harga.uom.uom_name,
    }));
    if (
      !tempSatuanOption.find(
        (option) => option.id === selectedBarang.base_uom.uom_id
      )
    ) {
      tempSatuanOption.push({
        id: selectedBarang.base_uom.uom_id,
        name: `${selectedBarang.base_uom.uom_alias} - ${selectedBarang.base_uom.uom_name}`,
        alias: selectedBarang.base_uom.uom_name,
      });
    }
    setSatuanOption(tempSatuanOption);
  };

  const handleOpenDetail = (index) => {
    const choosenProduct = productList[index];
    const selectedBarang = barangJasaList?.find(
      (barang) => barang.id === choosenProduct.code
    );
    handleGetSatuanOption(selectedBarang);
    setProductToAdd({
      code: choosenProduct.code,
      name: choosenProduct.name,
      quantity: choosenProduct.quantity,
      satuan: choosenProduct.satuan,
      tglDiminta: choosenProduct.tglDiminta,
      remarks: choosenProduct.remarks,
      mode: choosenProduct.mode,
    });
    setShow({ ...show, modal: true });
    setEditProduct(true);
    setChoosenId(index);

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}barang/upload/${choosenProduct.code}`
    )
      .then((res) => res.blob())
      .then((data) => setProductPhoto(data));
  };

  const handleAddTableProduct = () => {
    if (editProduct) {
      const temp = productList;
      productList[choosenId] = productToAdd;
      setProductList([...temp]);
    } else {
      setProductList([...productList, productToAdd]);
    }
    setModalTab(0);
    setProductToAdd({
      code: "",
      name: "",
      tglDiminta: "",
      quantity: 0,
      satuan: null,
      remarks: "",
      mode: "c",
    });
    setSatuanOption([]);
    setShow({ ...show, modal: false });
    setEditProduct(false);
  };

  const handleDeleteTableProduct = () => {
    const temp = [...productList];
    temp.splice(choosenId, 1);
    setProductList([...temp]);
    setModalTab(0);
    setProductToAdd({
      code: "",
      name: "",
      tglDiminta: "",
      quantity: 0,
      satuan: null,
      remarks: "",
      mode: "c",
    });
    setSatuanOption([]);
    setShow({ ...show, modal: false });
    setEditProduct(false);
  };

  const handleSaveRequest = (dataSubmit) => {
    const inputs = {
      tipePermintaan: requestType,
      tglPermintaan: dataSubmit.tgl_permintaan,
      cabang: dataSubmit.cabang,
      gudangPenerima: dataSubmit.gudangPenerima,
      gudangPengirim:
        requestType === "Kirim Barang" ? dataSubmit.gudangPengirim : null,
      keterangan: dataSubmit.keterangan,
    };
    if (detail) {
      ubahRequestOrder({
        variables: {
          input: {
            ...inputs,
            detail: productList.map((product) => ({
              kodeBarang: product.code,
              tglDiminta: product.tglDiminta,
              pcs: parseFloat(product.quantity),
              uom: product.satuan,
              remarks: product.remarks,
              mode: product.mode,
            })),
            nomorPermintaan: choosenData.nomor_permintaan,
          },
        },
      });
    } else {
      createRequestOrder({
        variables: {
          input: {
            ...inputs,
            detail: productList.map((product) => ({
              kodeBarang: product.code,
              tglDiminta: product.tglDiminta,
              pcs: parseFloat(product.quantity),
              uom: product.satuan,
              remarks: product.remarks,
              mode: "c",
            })),
          },
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteRequestOrder({
      variables: {
        input: choosenData.nomor_permintaan,
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleSaveRequest)}>
        <div className="left-content-module">
          <div className="content-nav-module">
            <form id="" className="">
              <div className="form-wrap">
                <div className="form-container">
                  <div className="title">Tanggal</div>
                  <input
                    id="datepicker_from"
                    ref={datePickerRef}
                    className="form-date-input"
                    type="text"
                    placeholder="Pilh tanggal"
                    value={watch("tgl_permintaan")}
                    onChange={(e) => setValue("tgl_permintaan", e.target.value)}
                  />
                  <div className="icon-form-date icon-date_ic"></div>
                </div>
              </div>

              {/* <!--TIPE PERMINTAAN--> */}
              <div className="form-wrap">
                <DropdownInput
                  title="Tipe Permintaan"
                  setOpen={(e) => setShowType(e)}
                >
                  {showType && (
                    <div class="popup-wrap gudang-wrap">
                      <ul>
                        {requestTypeOption?.map((row, i) => (
                          <li key={i}>
                            <div
                              class="text-list"
                              onClick={() => {
                                setRequestType(row.label);
                                setShowType(false);
                                // setType(parseInt(row.id.slice(-1)));
                              }}
                            >
                              {row.label}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <input
                    name=""
                    type="text"
                    placeholder="Pilih Jenis Barang"
                    value={requestType}
                    onClick={() => setShowType(!showType)}
                  />
                </DropdownInput>
              </div>

              {requestType !== "" && (
                <>
                  <div className="form-wrap">
                    <SearchInput
                      title="Cabang"
                      placeholder="Cari/Pilih Cabang"
                      value={watch("cabang")}
                      setValue={(e) => {
                        setValue("cabang", e);
                        setGudangOption(
                          cabangList
                            .find((cabang) => cabang.id === e)
                            ?.gudang.map((gudang) => ({
                              id: gudang.kode_gudang,
                              name: `Gudang ${gudang.nama_gudang}`,
                              desc: gudang.alamat,
                            }))
                        );
                        setValue("gudangPenerima", "");
                        setValue("gudangPengirim", "");
                      }}
                      searchData={cabangList}
                    />

                    {requestType === "Kirim Barang" && (
                      <SearchInput
                        title="Gudang Pengirim"
                        placeholder="Cari/Pilih Gudang"
                        value={watch("gudangPengirim")}
                        setValue={(e) => {
                          setValue("gudangPengirim", e);
                        }}
                        searchData={dataGudang}
                      />
                    )}

                    <SearchInput
                      title="Gudang Penerima"
                      placeholder="Cari/Pilih Gudang"
                      value={watch("gudangPenerima")}
                      setValue={(e) => {
                        setValue("gudangPenerima", e);
                      }}
                      searchData={gudangOption}
                    />
                  </div>
                </>
              )}

              <div className="form-right-side">
                {/* <!--RIGHT SIDE--> */}
                <div className="nav-setting-wrap">
                  <div className="list-setting">
                    {/* <div
                      className="setting-btn"
                      onClick={() => setShow({ ...show, menu: !show.menu })}
                    >
                      <span className="icon-menu_ic"></span>
                    </div> */}
                    {show.menu && (
                      <div className="popup-setting popup-right">
                        <ul>
                          <li>
                            <span className="icon-search_ic"></span> Ambil dari
                            Favorite
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="clear"></div>
            </form>
          </div>
          <div className="main-content-module detail-page">
            <div className="detail-data-wrap">
              <div className="wrap">
                <div
                  className={`tab-menu ${tab === 0 && "active"}`}
                  onClick={() => setTab(0)}
                >
                  <span className="icon-input_ic"></span>
                </div>
                <div
                  className={`tab-menu ${tab === 1 && "active"}`}
                  onClick={() => setTab(1)}
                >
                  <span className="icon-info_ic"></span>
                </div>
              </div>

              {tab === 0 && (
                <div className="content-wrap">
                  <div className="content-nav-module">
                    <form id="" className="">
                      <div className="form-wrap">
                        <SearchInput
                          title="Barang &amp; Jasa"
                          placeholder="Cari/Pilih Barang & Jasa"
                          setValue={(e) => {
                            const selectedBarang = barangJasaList?.find(
                              (barang) => barang.id === e
                            );
                            handleGetSatuanOption(selectedBarang);
                            setProductToAdd({
                              ...productToAdd,
                              code: selectedBarang.id,
                              name: selectedBarang.name,
                              satuan: selectedBarang.base_uom.uom_id,
                            });
                            setShow({ ...show, modal: true });
                            fetch(
                              `${process.env.REACT_APP_BACKEND_URL}barang/upload/${selectedBarang.id}`
                            )
                              .then((res) => res.blob())
                              .then((data) => setProductPhoto(data));
                          }}
                          searchData={barangJasaList}
                        />
                      </div>

                      <div className="form-right-side">
                        <div className="form-wrap">
                          {productList.length > 0 && (
                            <div class="data-total-wrap">
                              <div class="value">
                                {productList.length} barang (
                                {productList.reduce(
                                  (total, product) =>
                                    total + parseInt(product.quantity),
                                  0
                                )}
                                )
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="clear"></div>
                    </form>
                  </div>
                  <div className="main-content-module">
                    <table id="all-data-table" class="detail-page m-b-30">
                      <tr>
                        <th>Nama Barang</th>
                        <th>Kode #</th>
                        <th>Kuantitas</th>
                        <th>Satuan</th>
                        <th>Tgl Diminta</th>
                      </tr>
                      {productList.map((row, i) => (
                        <tr key={i} onClick={() => handleOpenDetail(i)}>
                          <td>{row.name}</td>
                          <td>{row.code}</td>
                          <td class="txt-right">{row.quantity}</td>
                          <td class="txt-right">
                            {
                              dataSatuan?.find(
                                (satuan) => satuan.id === row.satuan
                              ).name
                            }
                          </td>
                          <td>
                            {format(new Date(row.tglDiminta), "dd/MM/yyyy")}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>

                  {show.modal && (
                    <>
                      {/* <!--POPUP PRODUCT --> */}
                      <div class="window-overlay">
                        <div class="window-container product-window">
                          <div class="window-nav">
                            {/* <!--CONTENT TAB--> */}
                            <div class="tab_content_module">
                              {["Rincian Barang", "Keterangan"].map(
                                (tab, i) => (
                                  <div
                                    class={`tab_menu_content ${
                                      modalTab === i && "active"
                                    }`}
                                    onClick={() => setModalTab(i)}
                                  >
                                    {tab}
                                  </div>
                                )
                              )}
                            </div>
                            <div
                              class="close-btn flex-center"
                              onClick={() => {
                                setShow({ ...show, modal: false });
                                setProductToAdd({
                                  code: "",
                                  name: "",
                                  tglDiminta: "",
                                  quantity: 0,
                                  satuan: null,
                                  remarks: "",
                                  mode: "c",
                                });
                                setEditProduct(false);
                              }}
                            >
                              <span class="icon icon-close_ic"></span>
                            </div>
                          </div>
                          <div class="window-content">
                            {modalTab === 0 && (
                              <>
                                <div className="product-img">
                                  {productPhoto !== null && (
                                    <img
                                      src={URL.createObjectURL(productPhoto)}
                                      alt={`${productToAdd.code} - ${productToAdd.name}`}
                                    />
                                  )}
                                </div>

                                <div class="form-side">
                                  <form id="" class="">
                                    <div class="form-wrap">
                                      <div className="form-container lg">
                                        <h3 className="text-list">
                                          {productToAdd.name}
                                        </h3>
                                        <p className="text-desc">
                                          {productToAdd.code}
                                        </p>
                                      </div>

                                      <div className="form-container lg">
                                        <div className="title">
                                          Tanggal diminta
                                        </div>
                                        <div className="auto-form-container">
                                          <input
                                            ref={datePickerModalRef}
                                            className="form-date-input"
                                            type="text"
                                            placeholder="Pilh tanggal"
                                            value={productToAdd.tglDiminta}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                tglDiminta: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                        <div className="icon-form-date icon-date_ic"></div>
                                      </div>

                                      <div class="form-container md">
                                        <div class="title">Kuantitas</div>
                                        <div class="auto-form-container">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            value={productToAdd.quantity}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                quantity: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                        <div class="icon-form-date icon-penjualan_ic"></div>
                                      </div>

                                      <SearchInput
                                        className="sm padding-l"
                                        title="Satuan"
                                        placeholder="Cari satuan"
                                        value={productToAdd.satuan}
                                        setValue={(e) =>
                                          setProductToAdd({
                                            ...productToAdd,
                                            satuan: e,
                                          })
                                        }
                                        searchData={satuanOption}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}

                            {modalTab === 1 && (
                              <div class="content-wrap">
                                <div class="content-nav-module">
                                  <form id="" class="">
                                    <div class="col-fulltab-50">
                                      <div class="form-wrap">
                                        <div class="form-container">
                                          <div class="auto-form-container w-400px">
                                            <textarea
                                              id=""
                                              name=""
                                              rows="4"
                                              cols="50"
                                              value={productToAdd.remarks}
                                              onChange={(e) =>
                                                setProductToAdd({
                                                  ...productToAdd,
                                                  remarks: e.target.value,
                                                })
                                              }
                                              placeholder="Tuliskan keterangan di sini"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </div>
                          <div class="window-bottom">
                            <div
                              class="button btn-blue flex-center"
                              onClick={handleAddTableProduct}
                            >
                              Simpan
                            </div>
                            {editProduct && (
                              <div
                                class="button btn-red flex-center"
                                onClick={handleDeleteTableProduct}
                              >
                                Hapus
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {tab === 1 && (
                <div class="content-wrap">
                  <div class="content-nav-module">
                    <form id="" class="">
                      <div class="section-title">Info Lainnya</div>
                      <div class="col-fulltab-50">
                        <div class="form-wrap">
                          <div class="form-container">
                            <div class="title">Catatan</div>

                            <div class="auto-form-container w-400px">
                              <textarea
                                id=""
                                name=""
                                rows="4"
                                cols="50"
                                placeholder="Tuliskan catatan di sini"
                                {...register("keterangan")}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${
                (loading || loadingUpdate) && "disabled"
              }`}
              onClick={handleSubmit(handleSaveRequest)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>
            <div className="data-action-button purple-bg">
              <span className="icon icon-cetaik_ic"></span>
              Cetak
            </div>
            <div className="data-action-button purple-bg">
              <span className="icon icon-attach_ic"></span>
              Lampiran
            </div>
            <div className="data-action-button blue-bg">
              <span className="icon icon-more_ic"></span>
              Lainnya
            </div>
            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Permintaan
              barang {choosenData?.nomor_permintaan}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewRequestOrder;
