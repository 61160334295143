import React from "react";
import { useState } from "react";
import SearchInput from "../../common/searchInput";
import { useEffect } from "react";
import $ from "jquery";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { SATUAN_LIST } from "../../../actions/SatuanBarangAction";
import { GUDANG_LIST } from "../../../actions/GudangAction";
import { COA_LIST } from "../../../actions/CoaBarangAction";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import {
  CREATE_BARANG_JASA,
  DELETE_BARANG_JASA,
  UPDATE_BARANG_JASA,
} from "../../../actions/BarangAction";
import { toast } from "react-toastify";
import { formatRupiah } from "../../utils";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DropdownInput from "../../common/dropdownInput";

const AddNewGoodsAndServices = (props) => {
  const {
    detail,
    choosenData,
    dataPerTabs,
    setChoosenData,
    tabs,
    setTabs,
    tabOpen,
    setTabOpen,
    refetch,
    dataCategory,
    dataBarangType,
    dataBarangJasa,
  } = props;

  const datePickerModalRef = React.useRef(null);

  // const [type, setType] = useState(1);
  const [tab, setTab] = useState(0);
  // const [satuan, setSatuan] = useState(null);
  // const [addSatuan, setAddSatuan] = useState(false);
  // const [satuanToAdd, setSatuanToAdd] = useState({
  //   name: "",
  //   alias: "",
  // });
  const [selectType, setSelectType] = useState({
    bahanBaku: false,
    diproduksi: false,
  });
  const [show, setShow] = useState({
    modalBarang: false,
    modalProduct: false,
    satuan: false,
  });
  const [showType, setShowType] = useState(false);
  const [satuanChild, setSatuanChild] = useState([
    { id: null, satuan: null, value: 1, price: 0 },
  ]);
  const [tax, setTax] = useState(null);
  const [taxPph, setTaxPph] = useState(null);
  const [dataToAdd, setDataToAdd] = useState({
    id: null,
    gudang: null,
    date: "",
    quantity: 1,
    satuan: null,
    cost: 0,
  });
  const [productToAdd, setProductToAdd] = useState({
    code: 0,
    name: "",
    quantity: 0,
    satuan: null,
    mode: "c",
  });
  const [type, setType] = useState(1);
  const [tableStock, setTableStock] = useState([]);
  const [tableProduct, setTableProduct] = useState([]);
  const [editStock, setEditStock] = useState(false);
  const [editProduct, setEditProduct] = useState(false);
  const [choosenId, setChoosenId] = useState(1);
  const [photo, setPhoto] = useState(null);
  const [productPhoto, setProductPhoto] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [status, setStatus] = useState(true);
  const [isSummary, setIsSummary] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);

  const validationSchema = Yup.object().shape({
    nama: Yup.string().required("Nama"),
    jenisBarang: Yup.string().required("Jenis barang"),
    prefix: Yup.string().nullable(),
    kodeBarang: Yup.string().nullable(),
    barcode: Yup.string().nullable(),
    kategoriBarang: Yup.string().required("Kategori barang"),
    minimumPcsBeli:
      type === 1 && type === 2
        ? Yup.string().required("Min pembelian")
        : Yup.string().nullable(),
    lowPcs:
      type === 1
        ? Yup.string().required("Stok terendah")
        : Yup.string().nullable(),
    coaBeban:
      type === 2
        ? Yup.string().required("Akun beban")
        : Yup.string().nullable(),
    coaPersediaan: Yup.string().required("Akun persediaan"),
    coaPenjualan: Yup.string().required("Akun penjualan"),
    coaReturPenjualan: Yup.string().required("Akun retur penjualan"),
    coaDiskonPenjualan: Yup.string().required("Akun diskon penjualan"),
    coaBarangTerkirim: Yup.string().required("Akun barang terkirim"),
    coaBahanPokokPenjualan: Yup.string().required("Akun bahan pokok penjualan"),
    coaReturPembelian: Yup.string().required("Akun retur pembelian"),
    coaPembelianBelumTagih: Yup.string().required(
      "Akun pembelian belum tertagih"
    ),
    note: Yup.string().nullable(),
    listHarga:
      satuanChild.length === 0
        ? Yup.string().required("Daftar satuan")
        : Yup.string().nullable(),
    product_table:
      tableProduct.length === 0
        ? type === 4
          ? Yup.string().required("Tabel produk")
          : Yup.string().nullable()
        : Yup.string().nullable(),
    stock_table:
      tableStock.length === 0
        ? type === 1
          ? Yup.string().required("Tabel stock")
          : Yup.string().nullable()
        : Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nama: "",
      jenisBarang: "IT001",
      prefix: "",
      kodeBarang: "",
      barcode: "",
      kategoriBarang: "",
      // kodeSupplier: "",
      minimumPcsBeli: "",
      lowPcs: "",
      coaBeban: "",
      coaPersediaan: "",
      coaPenjualan: "",
      coaReturPenjualan: "",
      coaDiskonPenjualan: "",
      coaBarangTerkirim: "",
      coaBahanPokokPenjualan: "",
      coaReturPembelian: "",
      coaPembelianBelumTagih: "",
      note: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = [...dataPerTabs];
    updatedList.splice(tabOpen, 1);

    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
  }, [errors]);

  const { data: satuanData } = useQuery(SATUAN_LIST, {
    variables: {
      input: {
        nama: "",
      },
    },
  });

  const { data: gudangData } = useQuery(GUDANG_LIST, {
    variables: {
      input: {},
    },
  });

  const { data: dataCoa } = useQuery(COA_LIST);

  const [createBarangJasa, { loading }] = useMutation(CREATE_BARANG_JASA, {
    onCompleted: (resp) => {
      let formData = new FormData();

      formData.append("file", photo);

      fetch(
        `${process.env.REACT_APP_BACKEND_URL}barang/upload/${resp.barang.create.kode_barang}`,
        {
          method: "POST",
          body: formData,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          toast.success("Data berhasil dibuat");
          setTabOpen(0);
          refetch();
        })
        .catch(() => toast.error("Data gagal dibuat"));
    },
    onError: (resp) => {
      toast.error(resp.message);
    },
  });

  const [ubahBarangJasa] = useMutation(UPDATE_BARANG_JASA, {
    onCompleted: (resp) => {
      setTabOpen(0);
      toast.success("Sukses mengubah data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
  });

  const [deleteBarangJasa] = useMutation(DELETE_BARANG_JASA, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
  });

  useEffect(() => {
    $(datePickerModalRef.current).datepicker({
      onSelect: (dateText) => {
        setDataToAdd({ ...dataToAdd, date: dateText });
      },
    });
    // eslint-disable-next-line
  }, [show.modalBarang]);

  useEffect(() => {
    if (detail && choosenData && satuanData && gudangData && dataCoa) {
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}barang/upload/${choosenData.kode_barang}`
      )
        .then((res) => res.blob())
        .then((data) => setPhoto(data));

      setValue("nama", choosenData.nama_barang);
      setValue("jenisBarang", choosenData.jenis_barang.lv_value);
      setValue("prefix", choosenData.kategori_barang.default_prefix);
      setValue("kodeBarang", choosenData.kode_barang);
      setValue("barcode", choosenData.upc_barcode);
      setValue(
        "kategoriBarang",
        choosenData.kategori_barang.kategori_barang_id.toString()
      );
      setValue("minimumPcsBeli", choosenData.minimum_beli);
      setValue("lowPcs", choosenData.low_pcs);
      setValue("coaPersediaan", choosenData.coa_persediaan);
      setValue("coaBeban", choosenData.coa_beban);
      setValue("coaPenjualan", choosenData.coa_penjualan);
      setValue("coaReturPenjualan", choosenData.coa_retur_penjualan);
      setValue("coaDiskonPenjualan", choosenData.coa_diskon_penjualan);
      setValue("coaBarangTerkirim", choosenData.coa_barang_terkirim);
      setValue("coaBahanPokokPenjualan", choosenData.coa_bahan_pokok_penjualan);
      setValue("coaReturPembelian", choosenData.coa_retur_pembelian);
      setValue("coaPembelianBelumTagih", choosenData.coa_pembelian_belum_tagih);
      setValue("note", choosenData.catatan);
      setTax(choosenData.is_ppn ? 1 : null);
      setTaxPph(choosenData.is_pph ? 1 : null);
      setIsSummary(choosenData.is_summary_price);
      setSatuanChild(
        choosenData.detail_harga.map((price) => ({
          id: price.harga_id,
          satuan: price.uom.uom_id,
          value: price.uom_per_parent,
          price: price.harga_jual_per_uom,
          mode: "u",
        }))
      );
      setTableProduct(
        choosenData.detail.map((product) => ({
          id: product.detail_barang_id,
          code: product.kode_barang,
          name: product.nama_barang,
          quantity: product.pcs,
          satuan: product.uom_id,
          mode: "u",
        }))
      );
      setTableStock(
        choosenData.detail.map((stock) => ({
          id: stock.detail_barang_id,
          gudang: stock.kode_gudang,
          date: stock.input_dt.slice(0, 10),
          quantity: stock.pcs,
          satuan: stock.uom_id,
          cost: stock.price_per_uom,
          mode: "u",
        }))
      );
      setStatus(choosenData.is_aktif === "true");
      setSelectType({
        bahanBaku: choosenData.is_bahan_baku,
        diproduksi: choosenData.is_bahan_produksi,
      });
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData, satuanData, gudangData, dataCoa]);

  // const handleChooseOption = (choosen) => {
  //   setSatuan(choosen);
  //   setSearchTerms("");
  // };

  const handleChangeSatuanChild = (e, index) => {
    let temp = satuanChild[index];
    temp.satuan = e;
    satuanChild[index] = temp;
    setSatuanChild([...satuanChild]);
  };

  const handleDeleteSatuanField = (index) => {
    let deleted = satuanChild;
    deleted.splice(index, 1);
    setSatuanChild([...deleted]);
  };

  const handleChangeValueSatuanChild = (e, index) => {
    let temp = satuanChild[index];
    temp.value = e;
    satuanChild[index] = temp;
    setSatuanChild([...satuanChild]);
  };

  const handleChangePriceSatuanChild = (e, index) => {
    let temp = satuanChild[index];
    temp.price = e;
    satuanChild[index] = temp;
    setSatuanChild([...satuanChild]);
  };

  const handleAddTable = () => {
    if (editStock) {
      const temp = tableStock;
      tableStock[choosenId] = dataToAdd;
      setTableStock([...temp]);
    } else {
      setTableStock([...tableStock, dataToAdd]);
    }
    setDataToAdd({
      id: null,
      gudang: null,
      date: "",
      quantity: 1,
      satuan: null,
      cost: 0,
      mode: "c",
    });
    setShow({ ...show, modalBarang: false });
  };

  const handleAddTableProduct = () => {
    if (editProduct) {
      const temp = tableProduct;
      tableProduct[choosenId] = productToAdd;
      setTableProduct([...temp]);
    } else {
      setTableProduct([...tableProduct, productToAdd]);
    }
    setProductToAdd({
      code: 0,
      name: "",
      quantity: 0,
      satuan: null,
    });
    setShow({ ...show, modalProduct: false });
  };

  const handleOpenEdit = (index) => {
    const choosenData = tableStock[index];
    setDataToAdd({
      gudang: choosenData.gudang,
      date: choosenData.date,
      quantity: choosenData.quantity,
      satuan: choosenData.satuan,
      cost: choosenData.cost,
    });
    setShow({ ...show, modalBarang: true });
    setEditStock(true);
    setChoosenId(index);
  };

  const handleOpenEditProduct = (index) => {
    const choosenData = tableProduct[index];
    setProductToAdd({
      code: choosenData.code,
      name: choosenData.name,
      quantity: choosenData.quantity,
      satuan: choosenData.satuan,
    });
    setShow({ ...show, modalProduct: true });
    setEditProduct(true);
    setChoosenId(index);

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}barang/upload/${choosenData.code}`
    )
      .then((res) => res.blob())
      .then((data) => setProductPhoto(data));
  };

  const handleFileProgress = (event) => {
    if (event.lengthComputable) {
      const percentLoaded = (event.loaded / event.total) * 100;
      setProgress(percentLoaded);
      if (percentLoaded >= 100) {
        setIsUploading(false);
      }
    }
  };

  const handleUploadPhoto = (files) => {
    if (files && files.length > 0) {
      setProgress(0);
      setPhoto(files[0]);

      const reader = new FileReader();
      reader.addEventListener("progress", handleFileProgress, false);
      reader.readAsDataURL(files[0]);
      setIsUploading(true);
    }
  };

  const onSubmit = (dataSubmit) => {
    const inputs = {
      nama: dataSubmit.nama,
      jenisBarang: dataSubmit.jenisBarang,
      isAktif: status,
      isPPN: tax !== null,
      isPPH: taxPph !== null,
      prefix: dataSubmit.prefix,
      kategoriBarang: parseFloat(dataSubmit.kategoriBarang),
      isBahanBaku: selectType.bahanBaku,
      isBahanProduksi: selectType.diproduksi,
      baseUom: satuanChild[0].satuan.toString(),
      minimumPcsBeli: parseFloat(
        dataSubmit.minimumPcsBeli.toString().replaceAll(".", "")
      ),
      lowPcs: parseFloat(dataSubmit.lowPcs.toString().replaceAll(".", "")),
      coaBeban: dataSubmit.coaBeban,
      coaPersediaan: dataSubmit.coaPersediaan,
      coaPenjualan: dataSubmit.coaPenjualan,
      coaReturPenjualan: dataSubmit.coaReturPenjualan,
      coaDiskonPenjualan: dataSubmit.coaDiskonPenjualan,
      coaBarangTerkirim: dataSubmit.coaBarangTerkirim,
      coaBahanPokokPenjualan: dataSubmit.coaBahanPokokPenjualan,
      coaReturPembelian: dataSubmit.coaReturPembelian,
      coaPembelianBelumTagih: dataSubmit.coaPembelianBelumTagih,
      catatan: dataSubmit.note,
      isSummaryPrice: isSummary,
      saldo:
        type === 4
          ? tableProduct.map((product) => ({
              detail_barang_id: parseFloat(product.id) || null,
              kode_barang: product.code,
              nama: product.name,
              pcs: parseFloat(product.quantity.toString().replaceAll(".", "")),
              uom: parseFloat(product.satuan),
              mode: detail ? product.mode : "c",
            }))
          : tableStock.map((stock) => ({
              detail_barang_id: parseFloat(stock.id) || null,
              gudang: stock.gudang,
              uom: parseFloat(stock.satuan),
              pcs: parseFloat(stock.quantity.toString().replaceAll(".", "")),
              haragaPerUom: parseFloat(
                stock.cost.toString().replaceAll(".", "")
              ),
              tanggalTransaksi: stock.date,
              mode: detail ? stock.mode : "c",
            })),
      listHarga: satuanChild
        .filter((satuan) => satuan.satuan !== null)
        .map((satuan) => ({
          harga_id: parseFloat(satuan.id) || null,
          uom: parseFloat(satuan.satuan),
          pcsPerBaseUom: parseFloat(
            satuan.value.toString().replaceAll(".", "")
          ),
          hargaPerUom: parseFloat(satuan.price.toString().replaceAll(".", "")),
          mode: detail ? satuan.mode : "c",
        })),
    };
    if (detail) {
      ubahBarangJasa({
        variables: {
          input: {
            ...inputs,
            kode_barang: choosenData.kode_barang,
          },
        },
      });
    } else {
      createBarangJasa({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteBarangJasa({
      variables: {
        input: choosenData.kode_barang,
      },
    });
  };

  const dataSatuan = satuanData?.barang.getSatuanBarang.data.map((satuan) => ({
    id: satuan.uom_id,
    name: `${satuan.uom_alias} - ${satuan.uom_name}`,
    alias: satuan.uom_name,
  }));
  const filterDataset = satuanChild?.map((satuan) => {
    return satuan.satuan;
  });
  const dataSatuanModal = dataSatuan?.filter((satuan) =>
    filterDataset?.includes(satuan.id)
  );
  // const dataSatuanModal = satuanData?.barang.getSatuanBarang.data.map(
  //   (satuan) => ({
  //     id: satuan.uom_id,
  //     name: satuan.uom_name,
  //     alias: satuan.uom_alias,
  //   })
  // );
  const dataGudang = gudangData?.Gudang.findAll.map((gudang) => ({
    id: gudang.kode_gudang,
    name: `Gudang ${gudang.nama_gudang}`,
    desc: gudang.alamat,
  }));
  const coaList = dataCoa?.coa.findAll.map((coa) => ({
    id: coa.coa_code.toString(),
    name: coa.coa_name,
  }));

  const handleChooseCategory = (category) => {
    setValue("kategoriBarang", category.id);
    setValue("prefix", category.default_prefix);
    setValue("coaBahanPokokPenjualan", category.coa_bahan_pokok_penjualan);
    setValue("coaBarangTerkirim", category.coa_barang_terkirim);
    setValue("coaBeban", category.coa_beban);
    setValue("coaDiskonPenjualan", category.coa_diskon_penjualan);
    setValue("coaPembelianBelumTagih", category.coa_pembelian_belum_tagih);
    setValue("coaPenjualan", category.coa_penjualan);
    setValue("coaPersediaan", category.coa_persediaan);
    setValue("coaReturPembelian", category.coa_retur_pembelian);
    setValue("coaReturPenjualan", category.coa_retur_penjualan);
  };

  const sumPcsTimesUomPerParent = tableStock?.reduce((total, price) => {
    const uom = satuanChild.find((u) => u.satuan === price.satuan);
    if (uom) {
      return total + price.quantity.toString().replaceAll(".", "") * uom.value;
    }
    return total;
  }, 0);

  const sumPcsTimesPriceTimesUomPerParent = tableStock?.reduce(
    (total, price) => {
      const uom = satuanChild.find((u) => u.satuan === price.satuan);
      if (uom) {
        return (
          total +
          price.quantity.toString().replaceAll(".", "") *
            price.cost.toString().replaceAll(".", "") *
            uom.value
        );
      }
      return total;
    },
    0
  );

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="left-content-module">
          <div class="main-content-module detail-page full-tab">
            {/* <!--menu tab tambahkan active class pada div yg sedang terbuka--> */}
            <div class="flex-center">
              <div
                class={`tab-list ${tab === 0 && "active"}`}
                onClick={() => setTab(0)}
              >
                Umum
              </div>
              <div
                class={`tab-list ${tab === 1 && "active"}`}
                onClick={() => setTab(1)}
              >
                Penjualan/Pembelian
              </div>
              {type === 1 && (
                <div
                  class={`tab-list ${tab === 2 && "active"}`}
                  onClick={() => setTab(2)}
                >
                  Stok
                </div>
              )}

              {type === 4 && (
                <div
                  class={`tab-list ${tab === 3 && "active"}`}
                  onClick={() => setTab(3)}
                >
                  Rincian Group
                </div>
              )}

              <div
                class={`tab-list ${tab === 4 && "active"}`}
                onClick={() => setTab(4)}
              >
                Akun
              </div>

              {type !== 3 && (
                <div
                  class={`tab-list ${tab === 5 && "active"}`}
                  onClick={() => setTab(5)}
                >
                  Foto
                </div>
              )}

              <div
                class={`tab-list ${tab === 6 && "active"}`}
                onClick={() => setTab(6)}
              >
                Lain - lain
              </div>
            </div>

            {/* <!--detail menu tab pertama Umum--> */}
            {tab === 0 && (
              <div class="detail-data-wrap">
                <div class="content-wrap">
                  <form id="" class="">
                    <div class="form-wrap">
                      <div class="section-title">
                        Informasi Barang &amp; Jasa
                      </div>

                      <div class="form-container">
                        <div class="title">Nama Barang</div>

                        <div class="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan Nama Barang"
                            {...register("nama", { required: true })}
                          />
                        </div>
                      </div>

                      <SearchInput
                        title="Kategori Barang"
                        placeholder="Cari kategori barang"
                        value={watch("kategoriBarang")}
                        setValue={(e) =>
                          handleChooseCategory(
                            dataCategory?.find((category) => category.id === e)
                          )
                        }
                        searchData={dataCategory}
                      />

                      <DropdownInput
                        title="Jenis Barang"
                        setOpen={(e) => setShowType(e)}
                      >
                        {showType && (
                          <div class="popup-wrap gudang-wrap">
                            <ul>
                              {dataBarangType?.map((row, i) => (
                                <li key={i}>
                                  <div
                                    class="text-list"
                                    onClick={() => {
                                      setValue("jenisBarang", row.id);
                                      setShowType(false);
                                      setType(parseInt(row.id.slice(-1)));
                                    }}
                                  >
                                    {row.name}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        <input
                          name=""
                          type="text"
                          placeholder="Pilih Jenis Barang"
                          value={
                            dataBarangType?.find(
                              (row) => row.id === watch("jenisBarang")
                            )?.name
                          }
                          onClick={() => setShowType(!showType)}
                        />
                      </DropdownInput>

                      {type === 1 && (
                        <div class="form-container">
                          <div class="title">Tipe Persediaan</div>

                          <div class="flex-bottom select-wrap">
                            <label class="checkbox">
                              Bahan Baku
                              <input
                                type="checkbox"
                                checked={selectType.bahanBaku}
                                onChange={(e) =>
                                  setSelectType({
                                    ...selectType,
                                    bahanBaku: e.target.checked,
                                  })
                                }
                              />
                              <span class="checkmark"></span>
                            </label>
                            <label class="checkbox">
                              Diproduksi
                              <input
                                type="checkbox"
                                checked={selectType.diproduksi}
                                onChange={(e) =>
                                  setSelectType({
                                    ...selectType,
                                    diproduksi: e.target.checked,
                                  })
                                }
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      )}

                      <div class="form-container">
                        <div class="title">Kode Barang</div>

                        <div class="auto-form-container disable">
                          <input
                            disabled
                            type="text"
                            placeholder="Masukkan Kode Barang"
                            {...register("kodeBarang")}
                          />
                        </div>
                      </div>

                      {(type === 1 || type === 4) && (
                        <div class="form-container">
                          <div class="title">UPC/Barcode</div>

                          <div class="auto-form-container">
                            <input
                              type="text"
                              placeholder="Masukkan UPC/Barcode"
                              {...register("barcode")}
                            />
                          </div>
                        </div>
                      )}

                      {/* <div class="form-container">
                      <div class="title">Satuan</div>

                      <div class="auto-form-container">
                        {satuan && (
                          <ul class="input-label">
                            <li>
                              <label>{satuan.name}</label>{" "}
                              <span onClick={() => setSatuan(null)}>
                                <i className="icon icon-close_ic"></i>
                              </span>
                            </li>
                          </ul>
                        )}

                        {searchTerms.length > 0 && (
                          <>
                            {searchResults.length > 0 ? (
                              <div class="popup-wrap lg-wrap">
                                <ul className="main-list">
                                  {searchResults.map((result) => (
                                    <li
                                      onClick={() => handleChooseOption(result)}
                                    >
                                      <div className="text-list">
                                        {result.name}
                                      </div>
                                      {result.desc && (
                                        <div className="text-desc">
                                          {result.desc}
                                        </div>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              <>
                                <!--Tampilkan div berikut dibawah ini apabila satuan yang ingin ditambahakan tidak tersedia -->
                                <div class="popup-wrap lg-wrap add-new-satuan">
                                  <div class="desc">Data tidak ditemukan</div>

                                  <!--Tampilkan form berikut apabila user ingin menyimpan satuan baru-->
                                  {addSatuan && (
                                    <div class="form-wrap">
                                      <div class="form-container">
                                        <div class="title">Nama Satuan</div>

                                        <div class="auto-form-container">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Masukkan Nama Satuan"
                                            value={satuanToAdd.name}
                                            onChange={(e) =>
                                              setSatuanToAdd({
                                                ...satuanToAdd,
                                                name: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div class="form-container">
                                        <div class="title">Alias</div>

                                        <div class="auto-form-container">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Masukkan Alias Satuan"
                                            value={satuanToAdd.alias}
                                            onChange={(e) =>
                                              setSatuanToAdd({
                                                ...satuanToAdd,
                                                alias: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <!--Tombol simpan apabila user ok untuk simpan apada tombol yang sebelumnya-->
                                  {addSatuan ? (
                                    <div class="button btn-green w-100 txt-center">
                                      Simpan
                                    </div>
                                  ) : (
                                    <div
                                      class="button btn-green w-100 txt-center"
                                      onClick={() => {
                                        setAddSatuan(true);
                                        setSatuanToAdd({
                                          ...satuanToAdd,
                                          name: searchTerms,
                                        });
                                      }}
                                    >
                                      Simpan{" "}
                                      <span class="bold">{searchTerms}</span>{" "}
                                      sebagai satuan baru
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )}

                        {!satuan && (
                          <input
                            type="text"
                            placeholder="Masukkan satuan"
                            value={searchTerms}
                            onChange={(e) => {
                              setSearchTerms(e.target.value);
                              setAddSatuan(false);
                            }}
                          />
                        )}

                        <div class="icon-form-date icon-search_ic"></div>
                      </div>
                    </div> */}

                      <SearchInput
                        title="Satuan"
                        placeholder="Cari satuan barang"
                        value={satuanChild[0].satuan}
                        setValue={(e) => {
                          handleChangeSatuanChild(e, 0);
                          if (e !== null && satuanChild.length <= 1) {
                            setSatuanChild([
                              ...satuanChild,
                              { satuan: null, value: 0, price: 0 },
                            ]);
                          }
                        }}
                        searchData={dataSatuan}
                        onClose={() =>
                          setSatuanChild([
                            {
                              id: null,
                              satuan: null,
                              value: 0,
                              price: 0,
                              mode: "c",
                            },
                          ])
                        }
                      />

                      {satuanChild.length > 1 &&
                        satuanChild.slice(1).map((row, i) => (
                          <div class="flex-between satuan-equal">
                            <SearchInput
                              placeholder="Cari/Pilih Satuan"
                              value={row.satuan}
                              setValue={(e) => {
                                handleChangeSatuanChild(e, i + 1);
                                if (i !== 3) {
                                  setSatuanChild([
                                    ...satuanChild,
                                    { satuan: null, value: 0, price: 0 },
                                  ]);
                                }
                              }}
                              searchData={dataSatuan}
                              onClose={() =>
                                satuanChild.slice(1).length > 1 &&
                                handleDeleteSatuanField(i + 1)
                              }
                            />

                            <div class="form-container nominal">
                              <div
                                class={`auto-form-container ${
                                  row.satuan === null && "disable"
                                }`}
                              >
                                <input
                                  name=""
                                  type="text"
                                  placeholder="0"
                                  value={formatRupiah(row.value)}
                                  onChange={(e) =>
                                    handleChangeValueSatuanChild(
                                      e.target.value,
                                      i + 1
                                    )
                                  }
                                />
                                <div class="satuan-form">
                                  {" "}
                                  /
                                  {
                                    dataSatuan?.find(
                                      (satuan) =>
                                        satuan.id === satuanChild[0].satuan
                                    )?.alias
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* <!--detail menu tab kedua Penjualan/Pembelian--> */}
            {tab === 1 && (
              <div class="detail-data-wrap">
                <div class="content-wrap">
                  <form id="" class="">
                    <div class="col-fulltab-50">
                      <div class="form-wrap">
                        <div class="section-title">Informasi Penjualan</div>
                        <div class="form-container">
                          <div class="title">Def. Hrg. Jual Satuan #1</div>

                          {type === 4 && (
                            <div class="auto-form-container toggle-wrap">
                              <div class="form-container flex-center w-100">
                                <div class="ppn-desc">
                                  <div>Harga dihitung dari rincian grup</div>
                                  <div>{isSummary ? "Ya" : "Tidak"}</div>
                                </div>
                                <div
                                  class={`toggle-btn ${
                                    isSummary ? "enable" : "disable"
                                  }`}
                                  onClick={() => setIsSummary(!isSummary)}
                                ></div>
                              </div>
                            </div>
                          )}

                          {!isSummary && (
                            <>
                              <div class="auto-form-container">
                                <input
                                  class="pdd-r"
                                  required
                                  type="text"
                                  placeholder="Harga Jual Satuan"
                                  value={formatRupiah(satuanChild[0].price)}
                                  onChange={(e) =>
                                    handleChangePriceSatuanChild(
                                      e.target.value,
                                      0
                                    )
                                  }
                                />
                              </div>

                              <div class="satuan-form">
                                {" "}
                                /
                                {
                                  dataSatuan?.find(
                                    (satuan) =>
                                      satuan.id === satuanChild[0].satuan
                                  )?.alias
                                }
                              </div>
                            </>
                          )}
                        </div>

                        {!isSummary &&
                          satuanChild
                            .slice(1)
                            .filter((satuan) => satuan.satuan !== null)
                            .map((row, i) => (
                              <div class="form-container">
                                <div class="title">
                                  Def. Hrg. Jual Satuan #{i + 2}
                                </div>

                                <div class="auto-form-container">
                                  <input
                                    class="pdd-r"
                                    name=""
                                    type="text"
                                    placeholder="Harga Jual Satuan"
                                    value={formatRupiah(row.price)}
                                    onChange={(e) =>
                                      handleChangePriceSatuanChild(
                                        e.target.value,
                                        i + 1
                                      )
                                    }
                                  />
                                </div>

                                <div class="satuan-form">
                                  /
                                  {
                                    dataSatuan?.find(
                                      (satuan) => satuan.id === row.satuan
                                    )?.alias
                                  }
                                </div>
                              </div>
                            ))}
                      </div>
                    </div>

                    <div class="col-fulltab-50">
                      <div class="form-wrap">
                        {(type === 1 || type === 2) && (
                          <>
                            <div class="section-title">
                              Informasi Pembelian{" "}
                            </div>
                            <div class="form-container">
                              <div class="title">Minimum Beli</div>

                              <div class="auto-form-container">
                                <input
                                  required
                                  type="text"
                                  value={formatRupiah(watch("minimumPcsBeli"))}
                                  placeholder="Mininum pembelian"
                                  {...register("minimumPcsBeli", {
                                    required: true,
                                  })}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {type === 1 && (
                          <div class="form-container">
                            <div class="title">Batas Minimum Stok</div>

                            <div class="auto-form-container">
                              <input
                                required
                                type="text"
                                value={formatRupiah(watch("lowPcs"))}
                                placeholder="Tuliskan batas minimum stok"
                                {...register("lowPcs", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>
                        )}

                        <div class="section-title">
                          Pajak{" "}
                          {(type === 3 || type === 4) &&
                            "Penjualan dan Pembelian"}
                        </div>

                        <SearchInput
                          title="PPN"
                          placeholder="Cari/Pilih"
                          value={tax}
                          setValue={setTax}
                          searchData={[
                            {
                              id: 1,
                              name: "Pajak Pertambahan Nilai",
                              desc: "PPN 11%",
                            },
                            {
                              id: 2,
                              name: "Pajak Penjualan",
                              desc: "PPN 11%",
                            },
                          ]}
                        />

                        {type === 3 && (
                          <SearchInput
                            title="PPh"
                            placeholder="Cari/Pilih"
                            value={taxPph}
                            setValue={setTaxPph}
                            searchData={[
                              {
                                id: 1,
                                name: "PPh Jasa 23",
                                desc: "Tarif 2%",
                              },
                              {
                                id: 2,
                                name: "Pajak Penjualan",
                                desc: "PPN 11%",
                              },
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* <!--detail menu tab ketiga stok--> */}
            {tab === 2 && (
              <div class="detail-data-wrap">
                <div class="content-wrap">
                  <div class="section-title">Stok Awal</div>

                  <div
                    class="button btn-blue flex-center m-b-15 bold"
                    onClick={() => setShow({ ...show, modalBarang: true })}
                  >
                    + Tambah
                  </div>

                  <table id="all-data-table" class="detail-page m-b-30">
                    <tr>
                      <th>Tanggal</th>
                      <th>Kuantitas</th>
                      <th>Satuan</th>
                      <th>Biaya Satuan</th>
                      <th>Gudang</th>
                    </tr>
                    {tableStock.map((row, i) => (
                      <tr onClick={() => handleOpenEdit(i)}>
                        <td>{row.date}</td>
                        <td class="txt-right">
                          {row.quantity.toLocaleString("id")}
                        </td>
                        <td>
                          {
                            dataSatuanModal?.find(
                              (satuan) => satuan.id === row.satuan
                            ).name
                          }
                        </td>
                        <td class="txt-right">
                          {formatRupiah(
                            row.cost.toString().replaceAll(".", "")
                          )}
                        </td>
                        <td>
                          {
                            dataGudang?.find(
                              (gudang) => gudang.id === row.gudang
                            ).name
                          }
                        </td>
                      </tr>
                    ))}
                  </table>

                  <form id="" class="">
                    <div class="col-fulltab-50">
                      <div class="form-wrap">
                        <div class="section-title">Stok (Semua Gudang)</div>
                        <div class="form-container">
                          <div class="title">Kuantitas</div>

                          <div class="auto-form-container disable">
                            <input
                              name=""
                              type="text"
                              placeholder="Jumlah Kuantitas"
                              value={formatRupiah(sumPcsTimesUomPerParent)}
                            />
                          </div>

                          <div class="satuan-form">
                            {" "}
                            /
                            {
                              dataSatuan?.find(
                                (satuan) => satuan.id === satuanChild[0].satuan
                              )?.alias
                            }
                          </div>
                        </div>

                        <div class="form-container">
                          <div class="title">Nilai Satuan</div>

                          <div class="auto-form-container disable">
                            <input
                              name=""
                              type="text"
                              placeholder="Jumlah nilai satuan"
                              value={formatRupiah(
                                sumPcsTimesPriceTimesUomPerParent /
                                  sumPcsTimesUomPerParent || 0
                              )}
                            />
                          </div>
                        </div>

                        <div class="form-container">
                          <div class="title">Beban Pokok</div>

                          <div class="auto-form-container disable">
                            <input
                              name=""
                              type="text"
                              placeholder="Beban pokok"
                              value={formatRupiah(
                                sumPcsTimesPriceTimesUomPerParent
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                {show.modalBarang && (
                  <>
                    {/* <!--POPUP PRODUCT --> */}
                    <div class="window-overlay">
                      <div class="window-container product-window small-popup">
                        <div class="window-nav">
                          {/* <!--CONTENT TAB--> */}
                          <div class="tab_content_module">
                            <div class="tab_menu_content active">
                              Rincian Barang
                            </div>
                          </div>
                          <div
                            class="close-btn flex-center"
                            onClick={() =>
                              setShow({ ...show, modalBarang: false })
                            }
                          >
                            <span class="icon icon-close_ic"></span>
                          </div>
                        </div>
                        <div class="window-content">
                          <div class="form-side">
                            <form id="" class="">
                              <div class="form-wrap">
                                <div class="form-container lg">
                                  <div class="title">Tanggal</div>
                                  <div class="auto-form-container">
                                    <input
                                      ref={datePickerModalRef}
                                      name=""
                                      type="text"
                                      placeholder="Masukkan Tanggal"
                                      value={dataToAdd.date}
                                      onChange={(e) =>
                                        setDataToAdd({
                                          ...dataToAdd,
                                          date: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div class="icon-form-date icon-date_ic"></div>
                                </div>

                                <SearchInput
                                  title="Gudang"
                                  placeholder="Cari gudang"
                                  value={dataToAdd.gudang}
                                  setValue={(e) =>
                                    setDataToAdd({
                                      ...dataToAdd,
                                      gudang: e,
                                    })
                                  }
                                  searchData={dataGudang}
                                />

                                <div class="form-container md">
                                  <div class="title">Kuantitas</div>
                                  <div class="auto-form-container">
                                    <input
                                      name=""
                                      type="text"
                                      placeholder="Cari gudang"
                                      value={formatRupiah(dataToAdd.quantity)}
                                      onChange={(e) =>
                                        setDataToAdd({
                                          ...dataToAdd,
                                          quantity: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div class="icon-form-date icon-penjualan_ic"></div>
                                </div>

                                <SearchInput
                                  className="sm padding-l"
                                  title="Satuan"
                                  placeholder="Cari satuan"
                                  value={dataToAdd.satuan}
                                  setValue={(e) =>
                                    setDataToAdd({
                                      ...dataToAdd,
                                      satuan: e,
                                    })
                                  }
                                  searchData={dataSatuanModal}
                                />

                                <div class="form-container lg">
                                  <div class="title">Biaya</div>
                                  <div class="auto-form-container fi">
                                    <input
                                      name=""
                                      type="text"
                                      placeholder="Cari gudang"
                                      value={formatRupiah(dataToAdd.cost)}
                                      onChange={(e) =>
                                        setDataToAdd({
                                          ...dataToAdd,
                                          cost: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div class="fi-icon">Rp</div>
                                  <div class="icon-form-date icon-biaya_lain_ic"></div>
                                </div>

                                <div class="form-container lg">
                                  <div class="title">Total Biaya</div>
                                  <div class="auto-form-container fi disable">
                                    <input
                                      name=""
                                      type="text"
                                      placeholder="Cari gudang"
                                      value={formatRupiah(
                                        parseFloat(
                                          dataToAdd.quantity
                                            .toString()
                                            .replaceAll(".", "")
                                        ) *
                                          parseFloat(
                                            dataToAdd.cost
                                              .toString()
                                              .replaceAll(".", "")
                                          )
                                      )}
                                    />
                                  </div>
                                  <div class="fi-icon">Rp</div>
                                  <div class="icon-form-date icon-biaya_lain_ic"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div class="window-bottom">
                          <div
                            class="button btn-blue flex-center"
                            onClick={handleAddTable}
                          >
                            Simpan
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            {tab === 3 && (
              <div class="detail-data-wrap">
                <div class="content-wrap">
                  <div class="section-title">Rincian Group</div>

                  <div class="form-wrap">
                    <SearchInput
                      title="Barang &amp; Jasa"
                      placeholder="Cari/Pilih Barang & Jasa"
                      setValue={(e) => {
                        const selectedBarang = dataBarangJasa?.find(
                          (barang) => barang.id === e
                        );
                        setProductToAdd({
                          ...productToAdd,
                          code: selectedBarang.id,
                          name: selectedBarang.name,
                          satuan: selectedBarang.base_uom.uom_id,
                        });
                        setShow({ ...show, modalProduct: true });
                        fetch(
                          `${process.env.REACT_APP_BACKEND_URL}barang/upload/${selectedBarang.id}`
                        )
                          .then((res) => res.blob())
                          .then((data) => setProductPhoto(data));
                      }}
                      searchData={dataBarangJasa}
                    />
                  </div>

                  <table id="all-data-table" class="detail-page m-b-30">
                    <tr>
                      <th>Kode</th>
                      <th>Nama</th>
                      <th>Kuantitas</th>
                      <th>Satuan</th>
                    </tr>
                    {tableProduct.map((row, i) => (
                      <tr key={i} onClick={() => handleOpenEditProduct(i)}>
                        <td>{row.code}</td>
                        <td>{row.name}</td>
                        <td class="txt-right">{row.quantity}</td>
                        <td class="txt-right">
                          {
                            dataSatuan?.find(
                              (satuan) => satuan.id === row.satuan
                            ).name
                          }
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>

                {show.modalProduct && (
                  <>
                    {/* <!--POPUP PRODUCT --> */}
                    <div class="window-overlay">
                      <div class="window-container product-window">
                        <div class="window-nav">
                          {/* <!--CONTENT TAB--> */}
                          <div class="tab_content_module">
                            <div class="tab_menu_content active">
                              Rincian Barang
                            </div>
                          </div>
                          <div
                            class="close-btn flex-center"
                            onClick={() => {
                              setShow({ ...show, modalProduct: false });
                              setTableProduct(false);
                            }}
                          >
                            <span class="icon icon-close_ic"></span>
                          </div>
                        </div>
                        <div class="window-content">
                          <div className="product-img">
                            {productPhoto !== null && (
                              <img
                                src={URL.createObjectURL(productPhoto)}
                                alt={`${productToAdd.code} - ${productToAdd.name}`}
                              />
                            )}
                          </div>

                          <div class="form-side">
                            <form id="" class="">
                              <div class="form-wrap">
                                <div className="form-container lg">
                                  <h3 className="text-list">
                                    {productToAdd.name}
                                  </h3>
                                  <p className="text-desc">
                                    {productToAdd.code}
                                  </p>
                                </div>

                                <div class="form-container md">
                                  <div class="title">Kuantitas</div>
                                  <div class="auto-form-container">
                                    <input
                                      name=""
                                      type="text"
                                      placeholder="Cari gudang"
                                      value={productToAdd.quantity}
                                      onChange={(e) =>
                                        setProductToAdd({
                                          ...productToAdd,
                                          quantity: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div class="icon-form-date icon-penjualan_ic"></div>
                                </div>

                                <SearchInput
                                  className="sm padding-l"
                                  title="Satuan"
                                  placeholder="Cari satuan"
                                  value={productToAdd.satuan}
                                  setValue={(e) =>
                                    setProductToAdd({
                                      ...productToAdd,
                                      satuan: e,
                                    })
                                  }
                                  searchData={dataSatuan}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div class="window-bottom">
                          <div
                            class="button btn-blue flex-center"
                            onClick={handleAddTableProduct}
                          >
                            Simpan
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            {/* <!--detail menu tab keempat Akun--> */}
            {tab === 4 && (
              <div class="detail-data-wrap">
                <div class="content-wrap">
                  <div class="form-wrap">
                    {(type === 2 || type === 3) && (
                      <SearchInput
                        title="Beban"
                        placeholder="Cari akun beban"
                        value={watch("coaBeban")}
                        setValue={(e) => setValue("coaBeban", e)}
                        searchData={coaList}
                      />
                    )}

                    {type === 1 && (
                      <SearchInput
                        title="Persediaan"
                        placeholder="Cari akun persediaan"
                        value={watch("coaPersediaan")}
                        setValue={(e) => setValue("coaPersediaan", e)}
                        searchData={coaList}
                      />
                    )}

                    <SearchInput
                      title="Penjualan"
                      placeholder="Cari akun penjualan"
                      value={watch("coaPenjualan")}
                      setValue={(e) => setValue("coaPenjualan", e)}
                      searchData={coaList}
                    />

                    {type !== 3 && (
                      <SearchInput
                        title="Retur Penjualan"
                        placeholder="Cari akun retur penjualan"
                        value={watch("coaReturPenjualan")}
                        setValue={(e) => setValue("coaReturPenjualan", e)}
                        searchData={coaList}
                      />
                    )}

                    <SearchInput
                      title="Diskon Penjualan"
                      placeholder="Cari akun diskon penjualan"
                      value={watch("coaDiskonPenjualan")}
                      setValue={(e) => setValue("coaDiskonPenjualan", e)}
                      searchData={coaList}
                    />

                    {type === 1 && (
                      <SearchInput
                        title="Barang Terkirim"
                        placeholder="Cari akun barang terkirim"
                        value={watch("coaBarangTerkirim")}
                        setValue={(e) => setValue("coaBarangTerkirim", e)}
                        searchData={coaList}
                      />
                    )}

                    {type === 1 && (
                      <SearchInput
                        title="Beban Pokok Penjualan"
                        placeholder="Cari akun beban pokok penjualan"
                        value={watch("coaBahanPokokPenjualan")}
                        setValue={(e) => setValue("coaBahanPokokPenjualan", e)}
                        searchData={coaList}
                      />
                    )}

                    {(type === 1 || type === 2) && (
                      <SearchInput
                        title="Retur Pembelian"
                        placeholder="Cari akun retur pembelian"
                        value={watch("coaReturPembelian")}
                        setValue={(e) => setValue("coaReturPembelian", e)}
                        searchData={coaList}
                      />
                    )}

                    {type !== 4 && (
                      <SearchInput
                        title="Pembelian Belum Tertagih"
                        placeholder="Cari akun pembelian belum tertagih"
                        value={watch("coaPembelianBelumTagih")}
                        setValue={(e) => setValue("coaPembelianBelumTagih", e)}
                        searchData={coaList}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* <!--detail menu tab kelima Foto--> */}
            {tab === 5 && (
              <div class="detail-data-wrap">
                <div class="content-wrap">
                  <div class="upload-img-wrap">
                    {/* <!--pertama upload button--> */}
                    {!photo && (
                      <>
                        <label htmlFor="dropzone-file">
                          <div class="upload-btn-wrap">
                            <span class="icon-import_ic"></span>
                            <div class="desc">Klik Untuk upload gambar</div>
                          </div>
                        </label>

                        <input
                          accept="image/png, image/jpeg, image/jpg"
                          id="dropzone-file"
                          type="file"
                          style={{ visibility: "hidden" }}
                          onChange={(e) => handleUploadPhoto(e.target.files)}
                        />
                      </>
                    )}

                    {/* <!--kedua progress upload--> */}
                    {isUploading && (
                      <div class="upload-btn-wrap">
                        <div class="title-progress">Uploading</div>
                        <div className="progress-bar">
                          <div
                            className="progress"
                            style={{ width: `${progress}%` }}
                          />
                        </div>
                        <p>{progress.toFixed(0)}%</p>
                      </div>
                    )}

                    {/* <!--ketiga progress upload--> */}
                    {photo && !isUploading && (
                      <div class="upload-btn-wrap w-100">
                        <div class="upload-done flex-center">
                          <div class="photo">
                            <img
                              src={URL.createObjectURL(photo)}
                              alt="product preview"
                            />
                          </div>

                          <div class="product-name">{photo.name}</div>
                          <div class="dlt-btn" onClick={() => setPhoto(null)}>
                            Hapus
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* <!--detail menu tab keenam Lain lain--> */}
            {tab === 6 && (
              <div class="detail-data-wrap">
                <div class="content-wrap">
                  <div class="col-fulltab-50">
                    <div class="form-wrap">
                      <div class="form-container">
                        <div class="title">Catatan</div>

                        <div class="auto-form-container">
                          <textarea
                            id=""
                            name=""
                            rows="4"
                            cols="50"
                            placeholder="Tuliskan catatan di sini"
                            {...register("note")}
                          ></textarea>
                        </div>
                      </div>
                      <div class="form-container flex-center w-100">
                        <div class="ppn-desc">
                          <div>Status Barang</div>
                          <div>{status ? "Aktif" : "Nonaktif"}</div>
                        </div>
                        <div
                          class={`toggle-btn ${status ? "enable" : "disable"}`}
                          onClick={() => setStatus(!status)}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div class="right-content-module full-tab">
          <div class="data-action-wrap">
            <div
              className={`data-action-button green-bg ${loading && "disabled"}`}
              onClick={handleSubmit(onSubmit)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>

            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div class="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Barang{" "}
              {choosenData?.nama_barang}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewGoodsAndServices;
