import React, { useState } from "react";
import { useEffect } from "react";

const SupplierList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    loading,
    dataSupplier,
  } = props;

  const [search, setSearch] = useState("");
  const [filteredCategory, setFilteredCategory] = useState([]);

  useEffect(() => {
    if (search.length > 0) {
      const filtered = dataSupplier?.Pemasok.findAll.filter((pemasok) =>
        pemasok.nama.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredCategory(filtered);
    } else {
      setFilteredCategory(dataSupplier?.Pemasok.findAll);
    }
  }, [search, dataSupplier]);

  const supplierList = filteredCategory;

  const handleOpenDetail = (choosen) => {
    if (tabs.find((tab) => tab.id === choosen.nama)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: choosen.nama,
          type: "detail",
          id: choosen.nama,
        },
      ]);
    }
    setActive(choosen.nama);
    setChoosenData([...choosenData, { idTab: choosen.nama, ...choosen }]);
  };

  return (
    <>
      <div className="content_nav_module">
        <form id="" className="">
          <div className="nav-setting-wrap">
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-filter_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-import_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-cetaik_ic"></span>
              </div>
            </div>
            <div className="list-setting">
              <div className="setting-btn">
                <span className="icon-pengatura_ic"></span>
              </div>
            </div>
          </div>

          <div className="form-right-side">
            <div className="form-wrap">
              <div className="form-container">
                <div className="title">Pencarian</div>
                <input
                  id=""
                  className="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="icon-form-date icon-search_ic"></div>
              </div>
            </div>

            <div class="data-total-wrap">
              <div>TOTAL DATA</div>
              <div class="value">{filteredCategory?.length}</div>
            </div>
          </div>

          <div className="clear"></div>
        </form>
      </div>
      <div className="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <tr>
              <th>Nama</th>
              <th>Kontak Utama</th>
              <th>ID Pemasok</th>
              <th>Saldo</th>
            </tr>
            {loading ? (
              <p>Loading...</p>
            ) : supplierList?.length === 0 ? (
              <tr>
                <td>Tidak ada data.</td>
              </tr>
            ) : (
              supplierList?.map((supplier, i) => (
                <tr key={i} onClick={() => handleOpenDetail(supplier)}>
                  <td>{supplier.nama}</td>
                  <td>
                    {supplier.kontak.length > 0
                      ? supplier.kontak[0].nama
                      : "Tidak ada kontak"}
                  </td>
                  <td>{supplier.id_pemasok}</td>
                  <td>
                    {supplier.saldo
                      .reduce((total, price) => total + price.saldo_awal, 0)
                      .toLocaleString("id")}
                  </td>
                </tr>
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default SupplierList;
