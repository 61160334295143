import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CheckLogin from "./components/checkLogin";
import Home from "./components/home";
import LoginPage from "./components/loginPage";
import { useCookies } from "react-cookie";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import Provider from "./context";

function App() {
  const [cookie] = useCookies();

  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    cache: new InMemoryCache(),
    headers: {
      Authorization: `${
        cookie.access_token ? `Bearer ${cookie.access_token}` : ""
      }`,
    },
  });

  return (
    <ApolloProvider client={client}>
      <Provider>
        <Router>
          <Switch>
            <Route exact path="/login" component={LoginPage} />

            <CheckLogin>
              <Route exact path="/" component={Home} />
            </CheckLogin>
          </Switch>
        </Router>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
