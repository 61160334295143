import React, { useState } from "react";
import profPic from "../../../../img/profil.jpeg";
import { useStateContext } from "../../../../context";
import { useRef } from "react";
import { useEffect } from "react";
// import { menuList } from "../../../utils/MenuList";

const Header = () => {
  const wrapperRef = useRef(null);

  const [show, setShow] = useState(false);
  const [state, setState] = useStateContext();
  const idTab = state.idTabModule;
  const menuList = state.menuTabs;

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleSetItem = (value) => {
    setState(value);

    localStorage.setItem("lastUpdate", JSON.stringify(value));
  };

  const handleChangeTab = (id) => {
    handleSetItem({ ...state, idTabModule: id });
  };
  const handleCloseTab = (id) => {
    const menus = menuList.filter((tab) => tab.id !== id);

    handleSetItem({
      ...state,
      menuTabs: menus,
      idTabModule: menus.length > 0 ? menus[menus.length - 1].id : 0,
    });
  };

  return (
    <div className="right-module">
      <div className="main-tab-module">
        <div className="tab-menus-container">
          {menuList?.map((menu) => (
            <div className={`tab-menu ${idTab === menu.id && "active"}`}>
              <div onClick={() => handleChangeTab(menu.id)}>{menu.name}</div>
              <div className="close" onClick={() => handleCloseTab(menu.id)}>
                <span className="icon-close_ic"></span>
              </div>
            </div>
          ))}
        </div>
        <div className="account-module">
          <div className="notif-wrap">
            <div className="show-notif"></div>
            <span className="icon-notif_ic"></span>
          </div>
          <div
            ref={wrapperRef}
            className="account-profile"
            onClick={() => setShow(!show)}
          >
            <div className="photo">
              <img src={profPic} alt="profpic" />
            </div>
            <div className="name flex-center">Muhammad Nizar</div>
            <div className="toggle-btn">
              <span className="icon-down_ic"></span>
            </div>
            {show && (
              <div className="popup-profile">
                <div className="profile-wrap">
                  <div className="photo">
                    <img src={profPic} alt="profpic" />
                  </div>
                  <div className="name-profil">
                    <div>Muhammad Nizar</div>
                    <div className="email-add">nizar@email.com</div>
                  </div>
                </div>
                <ul>
                  <li>Profil Saya</li>
                  <li>Ganti Password</li>
                  <li>Keluar</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
