import React from "react";
import { useState } from "react";
import DropdownInput from "../../common/dropdownInput";
import { useRef } from "react";
import { useEffect } from "react";

const GoodsAndServicesList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    dataBarangJasa,
    loading,
    dataCategory,
    dataBarangType,
  } = props;
  const wrapperRef = useRef(null);

  const theadOption = ["Nama Barang", "Kode Barang", "Jenis Barang", "Satuan"];

  const [showStatus, setShowStatus] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [choosenTh, setChoosenTh] = React.useState([
    "Nomor",
    "Tanggal",
    "Tipe Permintaan",
    "Keterangan",
    "Status",
    "Cabang",
  ]);
  const [choosenStatus, setChoosenStatus] = useState();
  const [choosenType, setChoosenType] = useState([]);
  const [choosenCategory, setChoosenCategory] = useState([]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSetting(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleOpenDetail = (choosen) => {
    if (tabs.find((tab) => tab.id === choosen.kode_barang)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: choosen.nama_barang,
          type: "detail",
          id: choosen.kode_barang,
        },
      ]);
    }
    setActive(choosen.kode_barang);
    setChoosenData([
      ...choosenData,
      { idTab: choosen.kode_barang, ...choosen },
    ]);
  };

  const handleCheckbox = (option) => {
    const existedThead = choosenTh.find((thead) => thead === option);

    if (existedThead) {
      const deletedThead = choosenTh.filter((thead) => thead !== option);
      setChoosenTh(deletedThead);
    } else {
      setChoosenTh([...choosenTh, option]);
    }
  };

  const handleCategoryCheckbox = (option) => {
    const existedType = choosenCategory.find((type) => type === option);

    if (existedType) {
      const deletedType = choosenCategory.filter((type) => type !== option);
      setChoosenCategory(deletedType);
    } else {
      setChoosenCategory([...choosenCategory, option]);
    }
  };

  const handleTypeCheckbox = (option) => {
    const existedType = choosenType.find((type) => type === option);

    if (existedType) {
      const deletedType = choosenType.filter((type) => type !== option);
      setChoosenType(deletedType);
    } else {
      setChoosenType([...choosenType, option]);
    }
  };

  const barangJasaList = dataBarangJasa?.barang.findAll.data;

  return (
    <>
      <div className="content_nav_module">
        <form id="" class="">
          <div class="form-wrap">
            <DropdownInput title="Non-Aktif" setOpen={(e) => setShowStatus(e)}>
              {showStatus && (
                <div class="popup-wrap gudang-wrap">
                  <div class="m-b-10">
                    <span class="reset-list" onClick={() => setChoosenStatus()}>
                      Reset Filter
                    </span>
                  </div>

                  <label class="radio-wrap">
                    Ya
                    <input
                      type="radio"
                      name="radio"
                      checked={choosenStatus === 0}
                      onChange={() => setChoosenStatus(0)}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-wrap">
                    Tidak
                    <input
                      type="radio"
                      name="radio"
                      checked={choosenStatus === 1}
                      onChange={() => setChoosenStatus(1)}
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              )}

              {choosenStatus === undefined ? (
                <input
                  name=""
                  type="text"
                  placeholder="Pilih di sini"
                  value=""
                  onClick={() => setShowStatus(!showStatus)}
                />
              ) : (
                <ul
                  class="input-label"
                  onClick={() => setShowStatus(!showStatus)}
                >
                  <li>
                    <label>Lihat Filter</label>
                  </li>
                </ul>
              )}
            </DropdownInput>
          </div>

          <div class="form-wrap">
            <DropdownInput
              title="Kategori Barang"
              setOpen={(e) => setShowCategory(e)}
            >
              {showCategory && (
                <div class="popup-wrap gudang-wrap">
                  {dataCategory.map((category, i) => (
                    <label key={i} class="checkbox">
                      {category.name}
                      <input
                        type="checkbox"
                        checked={choosenCategory.find(
                          (choosen) => choosen === category.id
                        )}
                        onChange={() => handleCategoryCheckbox(category.id)}
                      />
                      <span class="checkmark"></span>
                    </label>
                  ))}
                </div>
              )}

              {choosenCategory.length > 0 ? (
                choosenCategory.length === dataCategory.length ? (
                  <ul
                    class="input-label"
                    onClick={() => setShowCategory(!showCategory)}
                  >
                    <li>
                      <label>Semua</label>
                    </li>
                  </ul>
                ) : (
                  <ul
                    class="input-label"
                    category
                    onClick={() => setShowCategory(!showCategory)}
                  >
                    <li>
                      <label>Lihat Filter</label>
                    </li>
                  </ul>
                )
              ) : (
                <input
                  name=""
                  type="text"
                  placeholder="Pilih di sini"
                  value=""
                  onClick={() => setShowCategory(!showCategory)}
                />
              )}
            </DropdownInput>
          </div>

          <div class="form-wrap">
            <DropdownInput title="Jenis Barang" setOpen={(e) => setShowType(e)}>
              {showType && (
                <div class="popup-wrap gudang-wrap">
                  {dataBarangType.map((type, i) => (
                    <label key={i} class="checkbox">
                      {type.name}
                      <input
                        type="checkbox"
                        checked={choosenType.find(
                          (choosen) => choosen === type.id
                        )}
                        onChange={() => handleTypeCheckbox(type.id)}
                      />
                      <span class="checkmark"></span>
                    </label>
                  ))}
                </div>
              )}

              {choosenType.length > 0 ? (
                choosenType.length === dataBarangType.length ? (
                  <ul
                    class="input-label"
                    onClick={() => setShowType(!showType)}
                  >
                    <li>
                      <label>Semua</label>
                    </li>
                  </ul>
                ) : (
                  <ul
                    class="input-label"
                    onClick={() => setShowType(!showType)}
                  >
                    <li>
                      <label>Lihat Filter</label>
                    </li>
                  </ul>
                )
              ) : (
                <input
                  name=""
                  type="text"
                  placeholder="Pilih di sini"
                  value=""
                  onClick={() => setShowType(!showType)}
                />
              )}
            </DropdownInput>
          </div>

          {/* <!--SETTING MENUS--> */}
          <div class="nav-setting-wrap">
            <div class="list-setting">
              <div class="setting-btn">
                <span class="icon-filter_ic"></span>
              </div>
            </div>
            <div class="list-setting">
              <div class="setting-btn">
                <span class="icon-import_ic"></span>
              </div>
            </div>
            <div class="list-setting">
              <div class="setting-btn">
                <span class="icon-cetaik_ic"></span>
              </div>
            </div>
            <div ref={wrapperRef} class="list-setting">
              <div
                class="setting-btn"
                onClick={() => setShowSetting(!showSetting)}
              >
                <span class="icon-pengatura_ic"></span>
              </div>
              {/* <!--POPUP MENU--> */}
              {showSetting && (
                <div class="popup-setting pengaturan">
                  {theadOption.map((opt, i) => (
                    <label
                      class="checkbox"
                      key={i}
                      onChange={() => handleCheckbox(opt)}
                    >
                      {opt}
                      <input
                        type="checkbox"
                        checked={choosenTh?.find((th) => th === opt)}
                      />
                      <span class="checkmark"></span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div class="form-right-side">
            <div class="form-wrap">
              <div class="form-container">
                <div class="title">Pencarian</div>
                <input
                  id=""
                  class="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                />
                <div class="icon-form-date icon-search_ic"></div>
              </div>
            </div>
          </div>

          <div class="clear"></div>
        </form>
      </div>
      <div className="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <tr>
              <th>Nama Barang</th>
              <th>Kode Barang</th>
              <th>Jenis Barang</th>
              <th>Satuan</th>
            </tr>
            {loading ? (
              <p>Loading...</p>
            ) : barangJasaList?.length === 0 ? (
              <tr>
                <td>Tidak ada data.</td>
              </tr>
            ) : (
              barangJasaList?.map((row, i) => (
                <tr key={i} onClick={() => handleOpenDetail(row)}>
                  <td>{row.nama_barang}</td>
                  <td>{row.kode_barang}</td>
                  <td>{row.jenis_barang.deskripsi}</td>
                  <td>{row.base_uom.uom_name}</td>
                </tr>
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default GoodsAndServicesList;
