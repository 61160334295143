import CategoryModul from "../category";
import SupplierCategoryModul from "../supplier/category";
import GoodsAndServicesModul from "../goodsAndServices";
import CoaModul from "../chartOfAccount";
import RequestOrderModul from "../requestOrder";
import PurchaseOrderModul from "../purchaseOrder";
import SatuanModul from "../satuan";
import GudangModul from "../gudang";
import CabangModul from "../cabang";
import SyaratPembayaranModul from "../syaratPembayaran";
import SupplierModul from "../supplier";
import ReceiveOrderModul from "../receiveOrder";
import PurchaseInvoiceModul from "../purchaseInvoice";
import PurchasePaymentModul from "../purchasePayment";
import GoodsTransferModul from "../goodsTransfer";
import GoodsPerWarehouse from "../goodsPerWarehouse";

// 16

export const menuList = [
  {
    name: "Dashboard",
    icon: "icon-home_ic",
    child: null,
  },
  {
    name: "Pengaturan",
    icon: "icon-pengaturan_ic",
    child: {
      row1: [
        {
          id: 6,
          icon: "icon-home_ic",
          name: "Cabang",
          color: "menu-green",
        },
        {
          id: 8,
          icon: "icon-kas_bank_ic",
          name: "Syarat Pembayaran",
          color: "menu-green",
        },
      ],
    },
  },
  {
    name: "Buku Besar",
    icon: "icon-buku_besar_ic",
    child: {
      row1: [
        {
          id: 1,
          icon: "icon-notif_ic",
          name: "Akun Perkiraan",
          color: "menu-green",
        },
      ],
    },
  },
  { name: "Kas & Bank", icon: "icon-kas_bank_ic", child: null },
  { name: "Penjualan", icon: "icon-penjualan_ic", child: null },
  {
    name: "Pembelian",
    icon: "icon-pembelian_ic",
    child: {
      row1: [
        {
          id: 7,
          icon: "icon-import_ic",
          name: "Kategori Pemasok",
          color: "menu-green",
        },
        {
          id: 10,
          icon: "icon-notif_ic",
          name: "Pemasok",
          color: "menu-green",
        },
        {
          id: 12,
          icon: "icon-kas_bank_ic",
          name: "Pesanan Pembelian",
          color: "menu-green",
        },
      ],
      row2: [
        {
          id: 13,
          icon: "icon-persedian_ic",
          name: "Penerimaan Barang",
          color: "menu-green",
        },
        {
          id: 14,
          icon: "icon-pembelian_ic",
          name: "Faktur Pembelian",
          color: "menu-purple",
        },
        {
          id: 15,
          icon: "icon-penjualan_ic",
          name: "Pembayaran Pembelian",
          color: "menu-purple",
        },
      ],
    },
  },
  {
    name: "Persediaan",
    icon: "icon-persedian_ic",
    child: {
      row1: [
        {
          id: 2,
          icon: "icon-persedian_ic",
          name: "Satuan Barang",
          color: "menu-green",
        },
        {
          id: 3,
          icon: "icon-import_ic",
          name: "Kategori Barang",
          color: "menu-green",
        },
        {
          id: 4,
          icon: "icon-kas_bank_ic",
          name: "Barang & Jasa",
          color: "menu-green",
        },
      ],
      row2: [
        {
          id: 5,
          icon: "icon-doc_ic",
          name: "Gudang",
          color: "menu-green",
        },
        {
          id: 11,
          icon: "icon-buku_besar_ic",
          name: "Permintaan Barang",
          color: "menu-green",
        },
        {
          id: 16,
          icon: "icon-penjualan_ic",
          name: "Pemindahan Barang",
          color: "menu-purple",
        },
      ],
    },
  },
  {
    name: "Laporan",
    icon: "icon-laporan_ic",
    child: {
      row1: [
        {
          id: 17,
          icon: "icon-doc_ic",
          name: "Barang Per Gudang",
          color: "menu-green",
        },
      ],
    },
  },
];

export const menuComponents = [
  { id: 1, component: <CoaModul /> },
  { id: 2, component: <SatuanModul /> },
  { id: 3, component: <CategoryModul /> },
  { id: 4, component: <GoodsAndServicesModul /> },
  { id: 5, component: <GudangModul /> },
  { id: 6, component: <CabangModul /> },
  { id: 7, component: <SupplierCategoryModul /> },
  { id: 8, component: <SyaratPembayaranModul /> },
  { id: 10, component: <SupplierModul /> },
  { id: 11, component: <RequestOrderModul /> },
  { id: 12, component: <PurchaseOrderModul /> },
  { id: 13, component: <ReceiveOrderModul /> },
  { id: 14, component: <PurchaseInvoiceModul /> },
  { id: 15, component: <PurchasePaymentModul /> },
  { id: 16, component: <GoodsTransferModul /> },
  { id: 17, component: <GoodsPerWarehouse /> },
];
