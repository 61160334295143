import React from "react";

const TabContent = (props) => {
  const { active, setActive, tabs, setTabs } = props;

  const delTab = (index) => {
    const updatedTab = tabs.filter((tab) => tab.id !== index);

    setTabs(updatedTab);
    setActive(updatedTab[updatedTab.length - 1].id);
  };

  const handleTab = (index) => {
    setActive(index);
  };

  return (
    <div className="tab_content_module">
      {tabs.map((tab, i) => (
        <div className={`tab_menu_content ${active === tab.id && "active"}`}>
          <span onClick={() => handleTab(tab.id)}>{tab.label} </span>
          {i !== 0 && (
            <div className="close" onClick={() => delTab(tab.id)}>
              <span className="icon-close_ic"></span>
            </div>
          )}
        </div>
      ))}
      <div
        className="new_btn_wrap"
        onClick={() => {
          setTabs([
            ...tabs,
            { label: "Buat Baru", type: "add-new", id: tabs.length },
          ]);
          setActive(tabs.length);
        }}
      >
        <div className="new_btn" title="tambah baru">
          +
        </div>
      </div>
    </div>
  );
};

export default TabContent;
