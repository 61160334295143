import { gql } from "@apollo/client";

export const GOODS_PER_WAREHOUSE_FRAGMENT = gql`
  fragment GoodsWarehouse on mBarangGudang {
    gudang
    alamat
    barang
    kodeBarang
    stok
    history {
      tanggal
      noSumber
      tipeTransaksi
      masuk
      keluar
      saldo
    }
  }
`;

export const GOODS_PER_WAREHOUSE_LIST = gql`
  query goodsPerWarehouseList($input: BarangPerGudangInput!) {
    barang {
      barangPerGudang(input: $input) {
        ...GoodsWarehouse
      }
    }
  }
  ${GOODS_PER_WAREHOUSE_FRAGMENT}
`;
