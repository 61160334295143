import React from "react";
import { useState } from "react";
import SearchInput from "../../common/searchInput";
import { useEffect } from "react";
import $ from "jquery";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import { SUPPLIER_CATEGORY_LIST } from "../../../actions/SupplierCategoryAction";
import DropdownInput from "../../common/dropdownInput";
import { formatNpwp, formatRupiah, listBank } from "../../utils";
import { SYARAT_PEMBAYARAN_LIST } from "../../../actions/SyaratPembayaranAction";
import { COA_LIST } from "../../../actions/CoaBarangAction";
import {
  CREATE_SUPPLIER,
  DELETE_SUPPLIER,
  UPDATE_SUPPLIER,
} from "../../../actions/SupplierAction";

const AddNewSupplier = (props) => {
  const {
    tabOpen,
    setTabOpen,
    detail,
    tabs,
    setTabs,
    dataPerTabs,
    setChoosenData,
    choosenData,
    refetch,
  } = props;

  const datePickerModalRef = React.useRef(null);

  const [contactForm, setContactForm] = useState({
    nama: "",
    jabatan: "",
    notel: "",
    email: "",
    catatan: "",
  });
  const [rekeningForm, setRekeningForm] = useState({
    nama_bank: "",
    no_rekening: "",
    atas_nama: "",
  });
  const [saldoForm, setSaldoForm] = useState({
    tanggal_transaksi: "",
    nomor: "",
    syarat_pembayaran: "",
    saldo_awal: 0,
    keterangan: "",
  });
  const [contacts, setContacts] = useState([]);
  const [rekening, setRekening] = useState([]);
  const [isPph, setIsPph] = useState(false);
  const [isIncludePajak, setIsIncludePajak] = useState(false);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [isFaktur, setIsFaktur] = useState(false);
  const [saldo, setSaldo] = useState([]);
  const [showType, setShowType] = useState(false);
  const [showType2, setShowType2] = useState(false);
  const [tab, setTab] = useState(0);
  const [show, setShow] = useState({
    contact: false,
    rekening: false,
    saldo: false,
  });
  const [editContact, setEditContact] = useState(false);
  const [editRekening, setEditRekening] = useState(false);
  const [editSaldo, setEditSaldo] = useState(false);
  const [choosenId, setChoosenId] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);

  const validationSchema = Yup.object().shape({
    nama: Yup.string().required("Nama pemasok"),
    kategori_pemasok: Yup.string().required("Kategori pemasok"),
    id_pemasok: Yup.string().required("Kode pemasok"),
    no_tel: Yup.string().required("Nomor telepon"),
    email: Yup.string().required("Email"),
    website: Yup.string().required("Website"),
    alamat: Yup.string().required("Alamat"),
    kota: Yup.string().required("Kota"),
    provinsi: Yup.string().required("Provinsi"),
    kodepos: Yup.string().required("Kode pos"),
    tipe_pemasok: Yup.string().required("Tipe pemasok"),
    kontak:
      contacts.length === 0
        ? Yup.string().required("Kontak")
        : Yup.string().nullable(),
    syarat_pembelian: Yup.string().required("Syarat pembayaran"),
    default_diskon_pembelian: Yup.string().required("Default diskon pembelian"),
    default_deskripsi_pembelian: Yup.string().nullable(),
    akun_utang: Yup.string().required("Akun utang"),
    akun_uang_muka: Yup.string().required("Akun uang muka"),
    rekening:
      rekening.length === 0
        ? Yup.string().required("Daftar rekening")
        : Yup.string().nullable(),
    npwp: Yup.string().required("NPWP").length(21),
    nama_npwp: Yup.string().required("Wajib Pajak"),
    jenis_dokumen: Yup.string().required("Jenis dokumen"),
    jenis_transaksi: Yup.string().required("Jenis transaksi"),
    alamat_yang_sama: !isSameAddress
      ? Yup.string().required("Alamat yang sama")
      : Yup.string().nullable(),
    kota_yang_sama: !isSameAddress
      ? Yup.string().required("Kota yang sama")
      : Yup.string().nullable(),
    provinsi_yang_sama: !isSameAddress
      ? Yup.string().required("Provinsi yang sama")
      : Yup.string().nullable(),
    kodepos_yang_sama: !isSameAddress
      ? Yup.string().required("Kodepos yang sama")
      : Yup.string().nullable(),
    saldo:
      saldo.length === 0
        ? Yup.string().required("Daftar saldo")
        : Yup.string().nullable(),
    catatan: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      parent_id: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = dataPerTabs.filter(
      (tab) => tab.idTab !== choosenData.idTab
    );
    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  const { data: dataCategory } = useQuery(SUPPLIER_CATEGORY_LIST);
  const { data: dataSyarat } = useQuery(SYARAT_PEMBAYARAN_LIST);
  const { data: dataCoa } = useQuery(COA_LIST);

  const [createPemasok, { loading }] = useMutation(CREATE_SUPPLIER, {
    onCompleted: (resp) => {
      toast.success("Kategori berhasil dibuat");
      setTabOpen(0);
      refetch();
    },
    onError: (resp) => {
      toast.error(resp.message);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: [
    //           ...list.barang.getKategoriBarang,
    //           data.barang.createKategoriBarang,
    //         ],
    //       },
    //     },
    //   });
    // },
  });

  const [deletePemasok] = useMutation(DELETE_SUPPLIER, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   const updatedList = list.barang.getKategoriBarang.filter(
    //     (category) =>
    //       category.kategori_barang_id !== choosenData.kategori_barang_id
    //   );
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: updatedList,
    //       },
    //     },
    //   });
    // },
  });

  const [ubahPemasok, { loading: loadingUpdate }] = useMutation(
    UPDATE_SUPPLIER,
    {
      onCompleted: (resp) => {
        setTabOpen(0);
        toast.success("Sukses mengubah data");
        setTabs(tabs.filter((tab) => tab.id !== tabOpen));
        handleUpdateTabData();
        refetch();
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  const parentCategoriesList = dataCategory?.Pemasok.listKategori.flatMap(
    (category) => {
      const parentCategories = {
        id: category.kategori_pemasok_id,
        name: category.nama_kategori,
      };
      const childCategories = category.child.flatMap((categoryChild) => {
        const parentChildCategories = {
          id: categoryChild.kategori_pemasok_id,
          name: categoryChild.nama_kategori,
        };
        const grandChildCategories = categoryChild.child.map(
          (categoryGrandChild) => ({
            id: categoryGrandChild.kategori_pemasok_id,
            name: categoryGrandChild.nama_kategori,
          })
        );
        return [parentChildCategories, ...grandChildCategories];
      });
      return [parentCategories, ...childCategories];
    }
  );
  const syaratPembayaranList = dataSyarat?.Pemasok.listSyarat?.map(
    (syarat) => ({
      id: syarat.syarat_pembayaran_id,
      name: syarat.nama_syarat_pembayaran,
    })
  );
  const utangCoaList = dataCoa?.coa.findAll
    ?.filter((coa) => coa.coa_type === "2001")
    ?.map((coa) => ({
      id: coa.coa_code.toString(),
      name: coa.coa_name,
    }));
  const uangMukaCoaList = dataCoa?.coa.findAll
    ?.filter((coa) => coa.coa_type === "1010")
    ?.map((coa) => ({
      id: coa.coa_code.toString(),
      name: coa.coa_name,
    }));

  useEffect(() => {
    if (detail && choosenData) {
      setValue("nama", choosenData.nama);
      setValue(
        "kategori_pemasok",
        choosenData.kategori_pemasok.kategori_pemasok_id
      );
      setValue("id_pemasok", choosenData.id_pemasok);
      setValue("no_tel", choosenData.no_tel);
      setValue("email", choosenData.email);
      setValue("website", choosenData.website);
      setValue("alamat", choosenData.alamat);
      setValue("kota", choosenData.kota);
      setValue("provinsi", choosenData.provinsi);
      setValue("kodepos", choosenData.kodepos);
      setIsPph(choosenData.is_pph);
      setValue("tipe_pemasok", choosenData.tipe_pemasok);
      setContacts(
        choosenData.kontak.map((kontak) => ({
          nama: kontak.nama,
          jabatan: kontak.jabatan,
          notel: kontak.notel,
          email: kontak.email,
          catatan: kontak.catatan,
        }))
      );
      setValue(
        "syarat_pembelian",
        choosenData.syarat_pembayaran.syarat_pembayaran_id
      );
      setValue("default_diskon_pembelian", choosenData.default_diskon);
      setValue("default_deskripsi_pembelian", choosenData.default_deskripsi);
      setValue("akun_utang", choosenData.akun_utang);
      setValue("akun_uang_muka", choosenData.akun_uang_muka);
      setRekening(
        choosenData.rekening.map((rekening) => ({
          nama_bank: rekening.nama_bank,
          no_rekening: rekening.no_rekening,
          atas_nama: rekening.atas_nama,
        }))
      );
      setIsIncludePajak(choosenData.is_include_pajak);
      setValue("npwp", choosenData.npwp);
      setValue("nama_npwp", choosenData.nama_npwp);
      setValue("jenis_dokumen", choosenData.jenis_dokumen);
      setValue("jenis_transaksi", choosenData.jenis_transaksi);
      setIsSameAddress(choosenData.is_alamat_sama);
      setValue("alamat_yang_sama", choosenData.alamat_pajak);
      setValue("kota_yang_sama", choosenData.kota_pajak);
      setValue("provinsi_yang_sama", choosenData.provinsi_pajak);
      setValue("kodepos_yang_sama", choosenData.kodepos_pajak);
      setSaldo(
        choosenData.saldo.map((saldo) => ({
          tanggal_transaksi: saldo.tanggal_transaksi.slice(0, 10),
          nomor: saldo.nomor,
          syarat_pembayaran: saldo.syarat_pembayaran.syarat_pembayaran_id,
          saldo_awal: saldo.saldo_awal,
          keterangan: saldo.keterangan,
        }))
      );
      setIsFaktur(choosenData.is_faktur);
      setValue("catatan", choosenData.catatan);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  useEffect(() => {
    $(datePickerModalRef.current).datepicker({
      onSelect: (dateText) => {
        setSaldoForm({ ...saldoForm, tanggal_transaksi: dateText });
      },
    });
    // eslint-disable-next-line
  }, [show.saldo]);

  const onSubmit = (data) => {
    const inputs = {
      umum: {
        nama: data.nama,
        kategori_pemasok: parseInt(data.kategori_pemasok),
        id_pemasok: data.id_pemasok,
        no_tel: data.no_tel,
        email: data.email,
        website: data.website,
        alamat: data.alamat,
        kota: data.kota,
        provinsi: data.provinsi,
        kodepos: data.kodepos,
        is_pph: isPph,
        tipe_pemasok: data.tipe_pemasok,
      },
      kontak: contacts,
      pembelian: {
        syarat_pembelian: parseInt(data.syarat_pembelian),
        default_diskon_pembelian: data.default_diskon_pembelian,
        default_deskripsi_pembelian: data.default_deskripsi_pembelian,
        akun_utang: data.akun_utang,
        akun_uang_muka: data.akun_uang_muka,
        rekening: rekening,
      },
      pajak: {
        is_include_pajak: isIncludePajak,
        npwp: data.npwp,
        nama_npwp: data.nama_npwp,
        jenis_dokumen: data.jenis_dokumen,
        jenis_transaksi: data.jenis_transaksi,
        is_alamat_sama: isSameAddress,
        alamat: data.alamat_yang_sama,
        kota: data.kota_yang_sama,
        provinsi: data.provinsi_yang_sama,
        kodepos: data.kodepos_yang_sama,
      },
      saldo: saldo.map((saldo) => ({
        ...saldo,
        syarat_pembayaran: parseInt(saldo.syarat_pembayaran),
        saldo_awal: parseInt(saldo.saldo_awal.toString().replaceAll(".", "")),
      })),
      is_faktur: isFaktur,
      catatan: data.catatan,
    };
    if (detail) {
      ubahPemasok({
        variables: {
          input: {
            kode_supplier: choosenData.kode_supplier,
            ...inputs,
          },
        },
      });
    } else {
      createPemasok({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deletePemasok({
      variables: {
        input: choosenData.kode_supplier,
      },
    });
  };

  const handleAddTableSaldo = () => {
    if (editSaldo) {
      const temp = saldo;
      saldo[choosenId] = saldoForm;
      setSaldo([...temp]);
    } else {
      setSaldo([...saldo, saldoForm]);
    }
    setSaldoForm({
      tanggal_transaksi: "",
      nomor: "",
      syarat_pembayaran: "",
      saldo_awal: 0,
      keterangan: "",
    });
    setShow({ ...show, saldo: false });
  };

  const handleOpenEditSaldo = (index) => {
    const choosenData = saldo[index];
    setSaldoForm({
      tanggal_transaksi: choosenData.tanggal_transaksi,
      nomor: choosenData.nomor,
      syarat_pembayaran: choosenData.syarat_pembayaran,
      saldo_awal: choosenData.saldo_awal,
      keterangan: choosenData.keterangan,
    });
    setShow({ ...show, saldo: true });
    setEditSaldo(true);
    setChoosenId(index);
  };

  const handleAddTable = () => {
    if (editContact) {
      const temp = contacts;
      contacts[choosenId] = contactForm;
      setContacts([...temp]);
    } else {
      setContacts([...contacts, contactForm]);
    }
    setContactForm({
      nama: "",
      jabatan: "",
      notel: "",
      email: "",
      catatan: "",
    });
    setShow({ ...show, contact: false });
  };

  const handleOpenEdit = (index) => {
    const choosenData = contacts[index];
    setContactForm({
      nama: choosenData.nama,
      jabatan: choosenData.jabatan,
      notel: choosenData.notel,
      email: choosenData.email,
      catatan: choosenData.catatan,
    });
    setShow({ ...show, contact: true });
    setEditContact(true);
    setChoosenId(index);
  };

  const handleAddTableRekening = () => {
    if (editRekening) {
      const temp = rekening;
      rekening[choosenId] = rekeningForm;
      setRekening([...temp]);
    } else {
      setRekening([...rekening, rekeningForm]);
    }
    setRekeningForm({
      nama_bank: "",
      atas_nama: "",
      no_rekening: "",
    });
    setShow({ ...show, rekening: false });
  };

  const handleOpenEditRekening = (index) => {
    const choosenData = rekening[index];
    setRekeningForm({
      nama_bank: choosenData.nama_bank,
      atas_nama: choosenData.atas_nama,
      no_rekening: choosenData.no_rekening,
    });
    setShow({ ...show, rekening: true });
    setEditRekening(true);
    setChoosenId(index);
  };

  const handleDeleteRekening = (row) => {
    const removeItem = rekening.filter((rek) => rek !== row);
    setRekening(removeItem);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="left-content-module">
          <div className="main-content-module detail-page full-tab">
            {/* <!--menu tab tambahkan active class pada div yg sedang terbuka--> */}
            <div className="flex-center">
              {[
                "Umum",
                "Kontak",
                "Pembelian",
                "Pajak",
                "Saldo Utang",
                "Lain-lain",
              ].map((label, i) => (
                <div
                  className={`tab-list ${tab === i && "active"}`}
                  onClick={() => setTab(i)}
                >
                  {label}
                </div>
              ))}
            </div>

            {tab === 0 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <div class="section-title">Informasi Umum</div>
                      <div className="form-container">
                        <div className="title">Nama Pemasok</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan nama"
                            {...register("nama", { required: true })}
                          />
                        </div>
                      </div>

                      <SearchInput
                        title="Kategori"
                        placeholder="Cari/Pilih Kategori"
                        value={watch("kategori_pemasok")}
                        setValue={(e) => setValue("kategori_pemasok", e)}
                        searchData={parentCategoriesList}
                      />

                      <div className="form-container">
                        <div className="title">ID Pemasok</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan kode pemasok"
                            {...register("id_pemasok", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Kontak</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="number"
                            placeholder="Masukkan no. WA"
                            {...register("no_tel", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Email</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan alamat email"
                            {...register("email", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Website</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan alamat website"
                            {...register("website", { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <div class="section-title">Informasi Lainnya</div>
                      <div class="form-container">
                        <div class="title">Alamat Pembayaran</div>

                        <div class="auto-form-container">
                          <textarea
                            id=""
                            name=""
                            rows="4"
                            cols="50"
                            placeholder="Tuliskan alamat lengkap di sini"
                            {...register("alamat")}
                          ></textarea>
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Kota/Kabupaten</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan kota"
                            {...register("kota", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Provinsi</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan Provinsi"
                            {...register("provinsi", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Kode Pos</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan Kode Pos"
                            {...register("kodepos", { required: true })}
                          />
                        </div>
                      </div>

                      <div class="form-container flex-center w-100">
                        <div class="ppn-desc">
                          <div>Penjual Jasa Dikenakan PPh 21</div>
                          <div>
                            {isPph
                              ? "Ya, Penjual jasa orang pribadi (Dikenakan PPh 21)"
                              : "Tidak, penjual jasa perusahaan"}
                          </div>
                        </div>
                        <div
                          class={`toggle-btn ${isPph ? "enable" : "disable"}`}
                          onClick={() => setIsPph(!isPph)}
                        ></div>
                      </div>

                      <DropdownInput
                        title="Tipe Pemasok"
                        setOpen={(e) => setShowType(e)}
                      >
                        {showType && (
                          <div class="popup-wrap gudang-wrap">
                            <ul>
                              {["Perorangan", "Perusahaan", "Pemerintah"]?.map(
                                (row, i) => (
                                  <li key={i}>
                                    <div
                                      class="text-list"
                                      onClick={() => {
                                        setValue("tipe_pemasok", row);
                                        setShowType(false);
                                      }}
                                    >
                                      {row}
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}

                        <input
                          name=""
                          type="text"
                          placeholder="Pilih Tipe Pemasok"
                          value={watch("tipe_pemasok")}
                          onClick={() => setShowType(!showType)}
                        />
                      </DropdownInput>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tab === 1 && (
              <div class="detail-data-wrap">
                <div class="content-wrap">
                  <div
                    class="button btn-blue flex-center m-b-15 bold"
                    onClick={() => setShow({ ...show, contact: true })}
                  >
                    + Tambah
                  </div>

                  <table id="all-data-table" class="detail-page m-b-30">
                    <tr>
                      <th>Nama Lengkap</th>
                      <th>Posisi Jabatan</th>
                      <th>Email</th>
                      <th>No. Handphone</th>
                    </tr>
                    {contacts.map((row, i) => (
                      <tr onClick={() => handleOpenEdit(i)}>
                        <td>{row.nama}</td>
                        <td>{row.jabatan}</td>
                        <td>{row.email}</td>
                        <td>{row.notel}</td>
                      </tr>
                    ))}
                  </table>
                </div>

                {show.contact && (
                  <>
                    {/* <!--POPUP PRODUCT --> */}
                    <div class="window-overlay">
                      <div class="window-container product-window small-popup">
                        <div class="window-nav">
                          {/* <!--CONTENT TAB--> */}
                          <div class="tab_content_module">
                            <div class="tab_menu_content active">Kontak</div>
                          </div>
                          <div
                            class="close-btn flex-center"
                            onClick={() => setShow({ ...show, contact: false })}
                          >
                            <span class="icon icon-close_ic"></span>
                          </div>
                        </div>
                        <div class="window-content">
                          <div class="form-side">
                            <form id="" class="">
                              <div class="form-wrap">
                                <div class="form-container lg">
                                  <div class="title">Nama Lengkap</div>
                                  <div class="auto-form-container">
                                    <input
                                      name=""
                                      type="text"
                                      placeholder="Masukkan Nama"
                                      value={contactForm.nama}
                                      onChange={(e) =>
                                        setContactForm({
                                          ...contactForm,
                                          nama: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div class="form-container lg">
                                  <div class="title">Posisi Jabatan</div>
                                  <div class="auto-form-container">
                                    <input
                                      name=""
                                      type="text"
                                      placeholder="Masukkan Jabatan"
                                      value={contactForm.jabatan}
                                      onChange={(e) =>
                                        setContactForm({
                                          ...contactForm,
                                          jabatan: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div class="form-container lg">
                                  <div class="title">No. WA/Handphone</div>
                                  <div class="auto-form-container">
                                    <input
                                      name=""
                                      type="text"
                                      placeholder="Masukkan Nama"
                                      value={contactForm.notel}
                                      onChange={(e) =>
                                        setContactForm({
                                          ...contactForm,
                                          notel: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div class="form-container lg">
                                  <div class="title">Email</div>
                                  <div class="auto-form-container">
                                    <input
                                      name=""
                                      type="text"
                                      placeholder="Masukkan Email"
                                      value={contactForm.email}
                                      onChange={(e) =>
                                        setContactForm({
                                          ...contactForm,
                                          email: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div class="form-container lg">
                                  <div class="title">Catatan</div>

                                  <div class="auto-form-container">
                                    <textarea
                                      id=""
                                      name=""
                                      rows="4"
                                      cols="50"
                                      placeholder="Tambahkan catatan di sini"
                                      value={contactForm.catatan}
                                      onChange={(e) =>
                                        setContactForm({
                                          ...contactForm,
                                          catatan: e.target.value,
                                        })
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div
                          class="button btn-blue flex-center"
                          onClick={handleAddTable}
                          style={{ float: "right", margin: "1.5rem" }}
                        >
                          Simpan
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            {tab === 2 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <div class="section-title">Pembelian</div>
                      <SearchInput
                        title="Syarat Pembayaran"
                        placeholder="Cari/Pilih"
                        value={watch("syarat_pembelian")}
                        setValue={(e) => setValue("syarat_pembelian", e)}
                        searchData={syaratPembayaranList}
                      />

                      <div className="form-container">
                        <div className="title">Default Diskon</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="number"
                            placeholder="Masukkan nama"
                            {...register("default_diskon_pembelian", {
                              required: true,
                            })}
                          />
                        </div>
                      </div>

                      <div class="form-container">
                        <div class="title">Default Deskripsi</div>

                        <div class="auto-form-container">
                          <textarea
                            id=""
                            name=""
                            rows="4"
                            cols="50"
                            placeholder="Tuliskan deskripsi di sini"
                            {...register("default_deskripsi_pembelian")}
                          ></textarea>
                        </div>
                      </div>

                      <div class="section-title">Akun Pembelian</div>
                      <SearchInput
                        title="Akun Utang"
                        placeholder="Cari/Pilih"
                        value={watch("akun_utang")}
                        setValue={(e) => setValue("akun_utang", e)}
                        searchData={utangCoaList}
                      />

                      <SearchInput
                        title="Akun Uang Muka"
                        placeholder="Cari/Pilih"
                        value={watch("akun_uang_muka")}
                        setValue={(e) => setValue("akun_uang_muka", e)}
                        searchData={uangMukaCoaList}
                      />
                    </div>
                  </div>

                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <div class="section-title">Rekening Pembayaran</div>
                      {rekening.length > 0 ? (
                        <>
                          <div
                            class="button btn-blue flex-center m-b-15 bold"
                            onClick={() => setShow({ ...show, rekening: true })}
                          >
                            + Tambah Rekening
                          </div>

                          <div class="phone-wrap">
                            {rekening.map((rek, i) => (
                              <div class="phone-container list">
                                <div class="account-num">{rek.no_rekening}</div>
                                <div class="bank-name">{rek.nama_bank}</div>
                                <div class="account-name">{rek.atas_nama}</div>
                                <div class="action-button-wrap">
                                  <div
                                    class="action-button edit"
                                    onClick={() => handleOpenEditRekening(i)}
                                  >
                                    <span class="icon-input_ic"></span>
                                  </div>
                                  <div
                                    class="action-button delete"
                                    onClick={() => handleDeleteRekening(rek)}
                                  >
                                    <span class="icon-hapus_ic"></span>
                                  </div>
                                  <div class="clear"></div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div class="phone-wrap">
                          <div
                            class="phone-container"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            Data Rekening Kosong
                            <div>
                              <div
                                class="button btn-blue flex-center m-b-15 bold"
                                onClick={() =>
                                  setShow({ ...show, rekening: true })
                                }
                              >
                                + Tambah Rekening
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {show.rekening && (
                  <>
                    {/* <!--POPUP PRODUCT --> */}
                    <div class="window-overlay">
                      <div class="window-container product-window small-popup">
                        <div class="window-nav">
                          {/* <!--CONTENT TAB--> */}
                          <div class="tab_content_module">
                            <div class="tab_menu_content active">
                              Rekening Bank
                            </div>
                          </div>
                          <div
                            class="close-btn flex-center"
                            onClick={() =>
                              setShow({ ...show, rekening: false })
                            }
                          >
                            <span class="icon icon-close_ic"></span>
                          </div>
                        </div>
                        <div class="window-content">
                          <div class="form-side">
                            <form id="" class="">
                              <div class="form-wrap">
                                <DropdownInput
                                  title="Nama Bank"
                                  setOpen={(e) => setShowType(e)}
                                >
                                  {showType && (
                                    <div class="popup-wrap lg-wrap">
                                      <ul>
                                        {listBank?.map((row, i) => (
                                          <li key={i}>
                                            <div
                                              class="text-list"
                                              onClick={() => {
                                                setRekeningForm({
                                                  ...rekeningForm,
                                                  nama_bank: row.name,
                                                });
                                                setShowType(false);
                                              }}
                                            >
                                              {row.name}
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}

                                  <input
                                    name=""
                                    type="text"
                                    placeholder="Masukkan Nama Bank"
                                    value={rekeningForm.nama_bank}
                                    onClick={() => setShowType(!showType)}
                                  />
                                </DropdownInput>

                                <div class="form-container lg">
                                  <div class="title">Nomor Rekening</div>
                                  <div class="auto-form-container">
                                    <input
                                      name=""
                                      type="number"
                                      placeholder="Masukkan Nomor Rekening"
                                      value={rekeningForm.no_rekening}
                                      onChange={(e) =>
                                        setRekeningForm({
                                          ...rekeningForm,
                                          no_rekening: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div class="form-container lg">
                                  <div class="title">Atas Nama</div>
                                  <div class="auto-form-container">
                                    <input
                                      name=""
                                      type="text"
                                      placeholder="Masukkan Atas Nama"
                                      value={rekeningForm.atas_nama}
                                      onChange={(e) =>
                                        setRekeningForm({
                                          ...rekeningForm,
                                          atas_nama: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div
                          class="button btn-blue flex-center"
                          onClick={handleAddTableRekening}
                          style={{ float: "right", margin: "1.5rem" }}
                        >
                          Simpan
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            {tab === 3 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <div class="section-title">Pajak</div>

                      <div class="form-container flex-center w-100">
                        <div class="ppn-desc">
                          <div>Default Faktur sudah Termasuk Pajak?</div>
                          <div>{isIncludePajak ? "Ya" : "Tidak"}</div>
                        </div>
                        <div
                          class={`toggle-btn ${
                            isIncludePajak ? "enable" : "disable"
                          }`}
                          onClick={() => setIsIncludePajak(!isIncludePajak)}
                        ></div>
                      </div>

                      <div className="form-container">
                        <div className="title">Nomor# NPWP</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan nomor NPWP"
                            value={formatNpwp(watch("npwp"))}
                            onChange={(e) => setValue("npwp", e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Wajib Pajak</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan nama wajib pajak"
                            {...register("nama_npwp", { required: true })}
                          />
                        </div>
                      </div>

                      <DropdownInput
                        title="Jenis Dokumen"
                        setOpen={(e) => setShowType(e)}
                      >
                        {showType && (
                          <div class="popup-wrap gudang-wrap">
                            <ul>
                              {[
                                "Faktur Pajak",
                                "Dokumen Dipersamakan FP",
                                "Dokumen PIB dan SSP",
                                "Dokumen PIB",
                                "Dokumen SSP",
                                "Dokumen PMSE",
                                "Dokumen SKP",
                                "Digunggung",
                              ]?.map((row, i) => (
                                <li key={i}>
                                  <div
                                    class="text-list"
                                    onClick={() => {
                                      setValue("jenis_dokumen", row);
                                      setShowType(false);
                                    }}
                                  >
                                    {row}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        <input
                          name=""
                          type="text"
                          placeholder="Pilih Jenis Dokumen"
                          value={watch("jenis_dokumen")}
                          onClick={() => setShowType(!showType)}
                        />
                      </DropdownInput>

                      <DropdownInput
                        title="Jenis Transaksi"
                        setOpen={(e) => setShowType2(e)}
                      >
                        {showType2 && (
                          <div class="popup-wrap gudang-wrap">
                            <ul>
                              {[
                                "01 - Perolehan dalam negeri - Bukan pemungut PPN",
                              ]?.map((row, i) => (
                                <li key={i}>
                                  <div
                                    class="text-list"
                                    onClick={() => {
                                      setValue("jenis_transaksi", row);
                                      setShowType2(false);
                                    }}
                                  >
                                    {row}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        <input
                          name=""
                          type="text"
                          placeholder="Pilih Jenis Transaksi"
                          value={watch("jenis_transaksi")}
                          onClick={() => setShowType2(!showType2)}
                        />
                      </DropdownInput>
                    </div>
                  </div>

                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <div class="section-title">Alamat Pajak</div>
                      <div class="form-container flex-center w-100">
                        <div class="ppn-desc">
                          <div>Alamat pajak sama dengan alamat pembayaran?</div>
                          <div>{isSameAddress ? "Ya" : "Tidak"}</div>
                        </div>
                        <div
                          class={`toggle-btn ${
                            isSameAddress ? "enable" : "disable"
                          }`}
                          onClick={() => setIsSameAddress(!isSameAddress)}
                        ></div>
                      </div>

                      {!isSameAddress && (
                        <>
                          <div class="form-container">
                            <div class="title">Alamat Pembayaran</div>

                            <div class="auto-form-container">
                              <textarea
                                id=""
                                name=""
                                rows="4"
                                cols="50"
                                placeholder="Tuliskan alamat lengkap di sini"
                                {...register("alamat_yang_sama")}
                              ></textarea>
                            </div>
                          </div>

                          <div className="form-container">
                            <div className="title">Kota/Kabupaten</div>

                            <div className="auto-form-container">
                              <input
                                required
                                type="text"
                                placeholder="Masukkan kota"
                                {...register("kota_yang_sama", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>

                          <div className="form-container">
                            <div className="title">Provinsi</div>

                            <div className="auto-form-container">
                              <input
                                required
                                type="text"
                                placeholder="Masukkan Provinsi"
                                {...register("provinsi_yang_sama", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>

                          <div className="form-container">
                            <div className="title">Kode Pos</div>

                            <div className="auto-form-container">
                              <input
                                required
                                type="text"
                                placeholder="Masukkan Kode Pos"
                                {...register("kodepos_yang_sama", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tab === 4 && (
              <div class="detail-data-wrap">
                <div class="content-wrap">
                  <div class="section-title">Utang Awal</div>
                  <div
                    class="button btn-blue flex-center m-b-15 bold"
                    onClick={() => setShow({ ...show, saldo: true })}
                  >
                    + Tambah
                  </div>

                  <table id="all-data-table" class="detail-page m-b-30">
                    <tr>
                      <th>Tanggal</th>
                      <th>Jumlah</th>
                      <th>Syarat Pembayaran</th>
                      <th>Nomor#</th>
                      <th>Keterangan</th>
                    </tr>

                    {saldo.map((row, i) => (
                      <tr onClick={() => handleOpenEditSaldo(i)}>
                        <td>{row.tanggal_transaksi}</td>
                        <td>
                          {formatRupiah(
                            row.saldo_awal.toString().replaceAll(".", "")
                          )}
                        </td>
                        <td>
                          {
                            syaratPembayaranList?.find(
                              (syarat) => syarat.id === row.syarat_pembayaran
                            ).name
                          }
                        </td>
                        <td>{row.nomor}</td>
                        <td>{row.keterangan}</td>
                      </tr>
                    ))}
                  </table>
                </div>

                {show.saldo && (
                  <>
                    {/* <!--POPUP PRODUCT --> */}
                    <div class="window-overlay">
                      <div class="window-container product-window small-popup">
                        <div class="window-nav">
                          {/* <!--CONTENT TAB--> */}
                          <div class="tab_content_module">
                            <div class="tab_menu_content active">
                              Saldo Awal
                            </div>
                          </div>
                          <div
                            class="close-btn flex-center"
                            onClick={() => setShow({ ...show, saldo: false })}
                          >
                            <span class="icon icon-close_ic"></span>
                          </div>
                        </div>
                        <div class="window-content">
                          <div class="form-side">
                            <form id="" class="">
                              <div class="form-wrap">
                                <div class="form-container lg">
                                  <div class="title">Tanggal</div>
                                  <div class="auto-form-container">
                                    <input
                                      ref={datePickerModalRef}
                                      name=""
                                      type="text"
                                      placeholder="Masukkan Tanggal"
                                      value={saldoForm.tanggal_transaksi}
                                      onChange={(e) =>
                                        setSaldoForm({
                                          ...saldoForm,
                                          tanggal_transaksi: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div class="icon-form-date icon-date_ic"></div>
                                </div>

                                <div class="form-container lg">
                                  <div class="title">Nomor#</div>
                                  <div class="auto-form-container">
                                    <input
                                      name=""
                                      type="text"
                                      placeholder="Masukkan Nomor"
                                      value={saldoForm.nomor}
                                      onChange={(e) =>
                                        setSaldoForm({
                                          ...saldoForm,
                                          nomor: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <SearchInput
                                  title="Syarat Pembayaran"
                                  placeholder="Cari/Pilih"
                                  value={saldoForm.syarat_pembayaran}
                                  setValue={(e) =>
                                    setSaldoForm({
                                      ...saldoForm,
                                      syarat_pembayaran: e,
                                    })
                                  }
                                  searchData={syaratPembayaranList}
                                />

                                <div class="form-container lg">
                                  <div class="title">Jumlah</div>
                                  <div class="auto-form-container fi">
                                    <input
                                      name=""
                                      type="text"
                                      placeholder=""
                                      value={formatRupiah(saldoForm.saldo_awal)}
                                      onChange={(e) =>
                                        setSaldoForm({
                                          ...saldoForm,
                                          saldo_awal: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div class="fi-icon">Rp</div>
                                  <div class="icon-form-date icon-biaya_lain_ic"></div>
                                </div>

                                <div class="form-container lg">
                                  <div class="title">Keterangan</div>

                                  <div class="auto-form-container">
                                    <textarea
                                      id=""
                                      name=""
                                      rows="4"
                                      cols="50"
                                      placeholder="Tambahkan keterangan di sini"
                                      value={saldoForm.keterangan}
                                      onChange={(e) =>
                                        setSaldoForm({
                                          ...saldoForm,
                                          keterangan: e.target.value,
                                        })
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div
                          class="button btn-blue flex-center"
                          onClick={handleAddTableSaldo}
                          style={{ float: "right", margin: "1.5rem" }}
                        >
                          Simpan
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            {tab === 5 && (
              <div class="detail-data-wrap">
                <div class="content-wrap">
                  <div class="col-fulltab-50">
                    <div class="form-wrap">
                      <div class="section-title">Lain-lain</div>
                      <div class="form-container flex-center w-100">
                        <div class="ppn-desc">
                          <div>
                            Pemasok memberikan nomor faktur pada tagihan?
                          </div>
                          <div>{isFaktur ? "Ya" : "Tidak"}</div>
                        </div>
                        <div
                          class={`toggle-btn ${
                            isFaktur ? "enable" : "disable"
                          }`}
                          onClick={() => setIsFaktur(!isFaktur)}
                        ></div>
                      </div>

                      <div class="form-container">
                        <div class="title">Catatan</div>

                        <div class="auto-form-container">
                          <textarea
                            id=""
                            name=""
                            rows="4"
                            cols="50"
                            placeholder="Tuliskan catatan di sini"
                            {...register("note")}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${
                (loading || loadingUpdate) && "disabled"
              }`}
              onClick={handleSubmit(onSubmit)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>

            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Kategori{" "}
              {choosenData?.nama_kategori}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewSupplier;
