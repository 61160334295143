import React from "react";
import { useState } from "react";
import SearchInput from "../../common/searchInput";
import { useEffect } from "react";
import $ from "jquery";
import { useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  COA_LIST,
  CREATE_COA,
  DELETE_COA,
  UPDATE_COA,
} from "../../../actions/CoaBarangAction";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import { formatRupiah } from "../../utils";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const AddNewCoa = (props) => {
  const {
    tabOpen,
    setTabOpen,
    detail,
    tabs,
    setTabs,
    dataPerTabs,
    setChoosenData,
    choosenData,
    coaTypeList,
    getCoaList,
  } = props;

  const datePicker = useRef(null);
  const [isSubAccount, setIsSubAccount] = useState(false);

  const validationSchema = Yup.object().shape({
    coa_code: Yup.string().required("Kode akun"),
    coa_description: Yup.string().nullable(),
    coa_name: Yup.string().required("Nama akun"),
    coa_type: Yup.string().required("Tipe akun"),
    coa_statement: Yup.string().nullable(),
    coa_balance: Yup.string().required("Saldo"),
    coa_date: Yup.string().required("Tanggal"),
    header_coa: isSubAccount
      ? Yup.string().required("Akun induk")
      : Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      coa_code: "",
      coa_description: "",
      coa_name: "",
      coa_type: "",
      coa_statement: "",
      coa_balance: "",
      coa_date: "",
      header_coa: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [tab, setTab] = useState(0);

  const [autoPrefix, setAutoPrefix] = useState(false);
  // const [allAccess, setAllAccess] = useState(true);
  // const [group, setGroup] = useState(null);
  // const [user, setUser] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = dataPerTabs.filter(
      (tab) => tab.idTab !== choosenData.idTab
    );
    setChoosenData(updatedList);
  };

  const { data: dataCoa } = useQuery(COA_LIST);

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
  }, [errors]);

  useEffect(() => {
    if (autoPrefix && watch("header_coa") !== "") {
      const choosenCoa = dataCoa?.coa.findAll
        .flatMap((account) => {
          const parentAccount = {
            ...account,
          };
          const childAccounts = account.child.map((coaChild) => ({
            ...coaChild,
          }));
          return [parentAccount, ...childAccounts];
        })
        .find((coa) => coa.coa_id === watch("header_coa"));
      setValue("coa_code", choosenCoa?.coa_code);
    } else {
      if (!detail) {
        setValue("coa_code", "");
      }
    }
    // eslint-disable-next-line
  }, [autoPrefix, watch("header_coa")]);

  const [createCoa, { loading }] = useMutation(CREATE_COA, {
    onCompleted: () => {
      toast.success("Akun perkiraan berhasil dibuat");
      setTabOpen(0);
      getCoaList({
        variables: {
          input: {},
        },
      });
    },
    onError: (resp) => {
      toast.error(resp.message);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: COA_LIST,
    //   });
    //   cache.writeQuery({
    //     query: COA_LIST,
    //     data: {
    //       coa: {
    //         findAll: [...list.coa.findAll, data.coa.create],
    //       },
    //     },
    //   });
    // },
  });

  const [deleteCoa] = useMutation(DELETE_COA, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      getCoaList({
        variables: {
          input: {},
        },
      });
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: COA_LIST,
    //   });
    //   cache.writeQuery({
    //     query: COA_LIST,
    //     data: {
    //       coa: {
    //         findAll: [...list.coa.findAll],
    //       },
    //     },
    //   });
    // },
  });

  const [ubahCoa] = useMutation(UPDATE_COA, {
    onCompleted: (resp) => {
      setTabOpen(0);
      toast.success("Sukses mengubah data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      getCoaList({
        variables: {
          input: {},
        },
      });
    },
    onError: (resp) => {
      toast.error(resp);
    },
  });

  const parentAccountsList = dataCoa?.coa.findAll.flatMap((account) => {
    const parentAccount = {
      id: account.coa_code,
      name: `[${account.coa_code}] - ${account.coa_name}`,
    };
    const childAccounts = account.child.map((coaChild) => ({
      id: coaChild.coa_code,
      name: `[${coaChild.coa_code}] - ${coaChild.coa_name}`,
    }));
    return [parentAccount, ...childAccounts];
  });
  const parentAccountsOption = detail
    ? parentAccountsList?.filter((acc) => acc.id !== choosenData.coa_id)
    : parentAccountsList;

  useEffect(() => {
    if (tab === 1) {
      $(datePicker.current).datepicker({
        onSelect: (dateText) => {
          setValue("coa_date", dateText);
        },
      });
    }
    // eslint-disable-next-line
  }, [tab]);

  useEffect(() => {
    if (detail && dataCoa) {
      setValue("coa_balance", choosenData.coa_balance);
      setValue("coa_code", choosenData.coa_code);
      setValue("coa_date", choosenData.coa_date?.slice(0, 10));
      setValue("coa_description", choosenData.coa_description);
      setValue("coa_name", choosenData.coa_name);
      setValue("coa_statement", choosenData.coa_statement);
      setValue("coa_type", choosenData.coa_type);
      setValue(
        "header_coa",
        choosenData.header_coa === 0 ? "" : choosenData.header_coa
      );
      setIsSubAccount(choosenData.is_sub);
      setAutoPrefix(choosenData.is_auto_prefix);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, dataCoa, choosenData]);

  const handleSwitch = () => {
    if (parentAccountsOption.length > 0) {
      setIsSubAccount(!isSubAccount);
      // setAutoPrefix(isSubAccount ? false : true);
    } else {
      alert("belum ada data akun utama");
    }
  };

  const onSubmit = (data) => {
    const inputs = {
      coa_code: data.coa_code,
      coa_description: data.coa_description,
      coa_name: data.coa_name,
      coa_type: data.coa_type,
      coa_statement: "",
      coa_balance: parseFloat(data.coa_balance.toString().replaceAll(".", "")),
      coa_date: data.coa_date,
      is_global: true,
      is_auto_prefix: autoPrefix,
      // is_global: allAccess,
      is_sub: isSubAccount,
      header_coa: parseFloat(data.header_coa || 0),
    };
    if (detail) {
      ubahCoa({
        variables: {
          input: {
            coa_id: parseFloat(choosenData.coa_id),
            ...inputs,
          },
        },
      });
    } else {
      createCoa({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteCoa({
      variables: {
        input: parseFloat(choosenData.coa_id),
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="left-content-module">
          <div className="main-content-module detail-page full-tab">
            {/* <!--menu tab tambahkan active class pada div yg sedang terbuka--> */}
            {detail ? (
              <div className="flex-center">
                <div
                  className={`tab-list ${tab === 0 && "active"}`}
                  onClick={() => setTab(0)}
                >
                  Informasi Umum
                </div>
                {isSubAccount && (
                  <div
                    className={`tab-list ${tab === 1 && "active"}`}
                    onClick={() => setTab(1)}
                  >
                    Saldo
                  </div>
                )}

                <div
                  className={`tab-list ${tab === 2 && "active"}`}
                  onClick={() => setTab(2)}
                >
                  Lain - lain
                </div>

                {!isSubAccount && (
                  <div
                    className={`tab-list ${tab === 3 && "active"}`}
                    onClick={() => setTab(3)}
                  >
                    Akun Anak
                  </div>
                )}
              </div>
            ) : (
              <div className="flex-center">
                <div
                  className={`tab-list ${tab === 0 && "active"}`}
                  onClick={() => setTab(0)}
                >
                  Informasi Umum
                </div>
                <div
                  className={`tab-list ${tab === 1 && "active"}`}
                  onClick={() => setTab(1)}
                >
                  Saldo
                </div>

                <div
                  className={`tab-list ${tab === 2 && "active"}`}
                  onClick={() => setTab(2)}
                >
                  Lain - lain
                </div>
              </div>
            )}

            {tab === 0 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="form-wrap">
                    <SearchInput
                      title="Tipe Akun"
                      placeholder="Pilih Tipe Akun"
                      value={watch("coa_type")}
                      setValue={(e) => setValue("coa_type", e)}
                      searchData={coaTypeList}
                    />

                    <div className="form-container flex-center w-100">
                      <div className="ppn-desc">
                        <div>Sub Akun</div>
                        {isSubAccount ? <div>Ya</div> : <div>Tidak</div>}
                      </div>
                      <div
                        className={`toggle-btn ${
                          isSubAccount ? "enable" : "disable"
                        }`}
                        onClick={handleSwitch}
                      ></div>
                    </div>

                    {/* <!--TAMPILKAN FORM PILIH "AKUN PERKIRAAN UTAMA" JIKA SUB AKUN AKTIF/DIPILIH--> */}
                    {isSubAccount && (
                      <>
                        <SearchInput
                          title="Akun Perkiraan Utama"
                          placeholder="Cari/pilih Akun Perkiraan Utama"
                          value={watch("header_coa")}
                          setValue={(e) => setValue("header_coa", e)}
                          searchData={parentAccountsOption}
                        />

                        <div className="form-container">
                          <label className="checkbox">
                            Pengkodean otomatis dengan prefix kode akun induk
                            <input
                              type="checkbox"
                              checked={autoPrefix}
                              onChange={() => setAutoPrefix(!autoPrefix)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </>
                    )}

                    {/*                                         
                                        <!--HIDE INPUT KODE JIKA SUB AKUN DIAKTIFKAN-->
                                        <!--JIKA PENGKODEAN OTOMATIS DINONAKTIFKAN MAKA TAMPILKAN FORM INPUT KODE--> */}
                    <div className="form-container">
                      <div className="title">Kode Perkiraan</div>

                      <div className="auto-form-container">
                        <input
                          required
                          type="text"
                          placeholder="Masukkan kode perkiraan"
                          {...register("coa_code", { required: true })}
                        />
                      </div>
                    </div>

                    <div className="form-container">
                      <div className="title">Nama</div>

                      <div className="auto-form-container">
                        <input
                          required
                          type="text"
                          placeholder="Masukkan nama akun"
                          {...register("coa_name", { required: true })}
                        />
                      </div>
                    </div>

                    {!isSubAccount && detail && (
                      <div className="form-container">
                        <div className="title">Saldo</div>

                        <div className="bold">
                          {(choosenData.coa_balance || 0).toLocaleString("id", {
                            currency: "IDR",
                            style: "currency",
                            maximumFractionDigits: 0,
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {tab === 1 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <div className="section-title">Saldo Awal</div>

                      <div className="form-container">
                        <div className="title">Nilai</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder=""
                            value={formatRupiah(watch("coa_balance"))}
                            {...register("coa_balance", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Dari Tanggal</div>
                        <input
                          id="datepicker_from"
                          ref={datePicker}
                          className="form-date-input w-100"
                          type="text"
                          placeholder="Pilh tanggal"
                          value={watch("coa_date")}
                        />
                        <div className="icon-form-date icon-date_ic"></div>
                      </div>

                      {isSubAccount && detail && (
                        <div className="form-container">
                          <div className="title">Saldo</div>

                          <div className="bold">
                            {choosenData.coa_balance.toLocaleString("id", {
                              currency: "IDR",
                              style: "currency",
                              maximumFractionDigits: 0,
                            }) || "-"}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tab === 2 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <div className="form-container">
                        <div className="title">Catatan</div>

                        <div className="auto-form-container">
                          <textarea
                            id=""
                            rows="4"
                            cols="50"
                            placeholder="Tulisakan catatan di sini"
                            required
                            {...register("coa_description", { required: true })}
                          ></textarea>
                        </div>
                      </div>

                      {/* <div className="form-container flex-center w-100">
                      <div className="ppn-desc">
                        <div>Akses Semua Pengguna</div>
                        {allAccess ? <div>Ya</div> : <div>Tidak</div>}
                      </div>
                      <div
                        className={`toggle-btn ${allAccess ? "enable" : "disable"}`}
                        onClick={() => setAllAccess(!allAccess)}
                      ></div>
                    </div>

                    {!allAccess && (
                      <>
                        <SearchInput
                          title="Group/Cabang"
                          placeholder="Cari/pilih akun group"
                          value={group}
                          setValue={setGroup}
                          searchData={groupEmployeeOption}
                        />

                        <SearchInput
                          title="Pengguna"
                          placeholder="Cari/pilih akun pengguna"
                          value={user}
                          setValue={setUser}
                          searchData={employeeOption}
                        />
                      </>
                    )} */}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tab === 3 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <div className="section-title">Daftar Akun</div>
                      <div className="akun-tree-wrap">
                        <div className="akun-parent-wrap flex-center">
                          <div className="akun-parent bold">
                            {choosenData?.coa_name}
                          </div>
                          <div className="akun-parent-kode bold">
                            {choosenData?.coa_code}
                          </div>
                        </div>

                        {choosenData?.child.length > 0 && (
                          <div className="akun-child-wrap">
                            {choosenData?.child.map((coa) =>
                              coa.child.length > 0 ? (
                                <div className="has-child">
                                  <div className="akun-child flex-center">
                                    <div className="name">{coa.coa_name}</div>
                                    <div className="kode">{coa.coa_code}</div>
                                  </div>
                                  <div className="akun-grand-child-wrap">
                                    {coa.child.map((grandChild) => (
                                      <div className="akun-grand-child flex-center">
                                        <div className="name">
                                          {grandChild.coa_name}
                                        </div>
                                        <div className="kode">
                                          {grandChild.coa_code}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <div className="akun-child flex-center">
                                  <div className="name">{coa.coa_name}</div>
                                  <div className="kode">{coa.coa_code}</div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${loading && "disabled"}`}
              onClick={handleSubmit(onSubmit)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>

            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Akun perkiraan{" "}
              {choosenData?.coa_name}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewCoa;
