import React from "react";
import Layout from "../common/layout";
import { menuComponents } from "../utils/MenuList";
import { useStateContext } from "../../context";

const Home = () => {
  const [state] = useStateContext();
  const idTab = state.idTabModule;

  return (
    <Layout>
      {menuComponents?.find((menu) => menu.id === idTab)?.component}
    </Layout>
  );
};

export default Home;
