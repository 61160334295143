import React, { useEffect } from "react";
import Container from "../common/container";
import TabContent from "../common/tabContent";
import AddNewReceiveOrder from "./addNew";
import ReceiveOrderList from "./list";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { RECEIVE_ORDER_LIST } from "../../actions/ReceiveOrderAction";

const ReceiveOrderModul = () => {
  const [tabSession, setTabSession] = React.useState([
    { label: "Lihat Data", type: "list", id: 0 },
    { label: "Buat Baru", type: "add-new", id: 1 },
  ]);
  const [activeTabId, setActiveTabId] = React.useState(1);
  const [choosenData, setChoosenData] = React.useState([]);

  const [
    getReceiveOrderList,
    { loading, error, data: dataReceiveOrder, refetch },
  ] = useLazyQuery(RECEIVE_ORDER_LIST);

  useEffect(() => {
    getReceiveOrderList();
    // eslint-disable-next-line
  }, []);

  if (error) {
    toast.error(error.message);
  }

  const activeData = tabSession.find((tab) => tab.id === activeTabId);

  return (
    <div>
      <TabContent
        active={activeTabId}
        setActive={setActiveTabId}
        tabs={tabSession}
        setTabs={setTabSession}
      />

      <Container>
        {activeData.type === "list" ? (
          <ReceiveOrderList
            tabs={tabSession}
            setTabs={setTabSession}
            setActive={setActiveTabId}
            choosenData={choosenData}
            setChoosenData={setChoosenData}
            dataReceiveOrder={dataReceiveOrder}
            loading={loading}
          />
        ) : activeData.type === "add-new" ? (
          <AddNewReceiveOrder setTabOpen={setActiveTabId} refetch={refetch} />
        ) : (
          <AddNewReceiveOrder
            detail={true}
            choosenData={choosenData.find(
              (choosen) => choosen.idTab === activeTabId
            )}
            dataPerTabs={choosenData}
            setChoosenData={setChoosenData}
            tabs={tabSession}
            setTabs={setTabSession}
            tabOpen={activeTabId}
            setTabOpen={setActiveTabId}
            refetch={refetch}
          />
        )}
      </Container>
    </div>
  );
};

export default ReceiveOrderModul;
