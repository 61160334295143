import { gql } from "@apollo/client";
import { CABANG_FRAGMENT } from "./CabangAction";
import { GUDANG_FRAGMENT } from "./GudangAction";
import { GOODS_AND_SERVICE_FRAGMENT } from "./BarangAction";
import { SATUAN_FRAGMENT } from "./SatuanBarangAction";
import { SUPPLIER_FRAGMENT } from "./SupplierAction";
import { SYARAT_PEMBAYARAN_FRAGMENT } from "./SyaratPembayaranAction";

export const PURCHASE_DETAIL_FRAGMENT = gql`
  fragment PurchaseDetail on mPesananItem {
    item_pesanan_id
    barang {
      ...GoodsAndService
    }
    qty
    qty_proses
    satuan {
      ...Satuan
    }
    keterangan
    harga
    nilai_diskon
    persen_diskon
    kena_pajak
    merek
  }
  ${GOODS_AND_SERVICE_FRAGMENT}
  ${SATUAN_FRAGMENT}
`;

export const PURCHASE_ORDER_FRAGMENT = gql`
  fragment PurchaseOrder on mPesananPembelian {
    input_dt
    update_dt
    nomor_pesanan
    tgl_pesanan
    tgl_kirim
    status
    pemasok {
      ...Supplier
    }
    syarat_pembayaran {
      ...SyaratPembayaran
    }
    keterangan
    cabang {
      ...Cabang
    }
    gudang_penerima {
      ...Gudang
    }
    alamat_penerima
    is_kena_pajak
    is_tot_inc_pajak
    detail_item {
      ...PurchaseDetail
    }
    beban {
      kode_coa
      nilai
    }
    nomor_permintaan
  }
  ${CABANG_FRAGMENT}
  ${SUPPLIER_FRAGMENT}
  ${GUDANG_FRAGMENT}
  ${PURCHASE_DETAIL_FRAGMENT}
  ${SYARAT_PEMBAYARAN_FRAGMENT}
`;

export const PURCHASE_ORDER_LIST = gql`
  query getPurchaseOrderList {
    PesananPembelian {
      findAll {
        ...PurchaseOrder
      }
    }
  }
  ${PURCHASE_ORDER_FRAGMENT}
`;

export const CREATE_PURCHASE_ORDER = gql`
  mutation createPurchaseOrder($input: NewPesananPembelianInput!) {
    PesananPembelian {
      create(input: $input) {
        ...PurchaseOrder
      }
    }
  }
  ${PURCHASE_ORDER_FRAGMENT}
`;

export const UPDATE_PURCHASE_ORDER = gql`
  mutation editPurchaseOrder($input: UpdatePesananPembelianInput!) {
    PesananPembelian {
      update(input: $input) {
        ...PurchaseOrder
      }
    }
  }
  ${PURCHASE_ORDER_FRAGMENT}
`;

export const DELETE_PURCHASE_ORDER = gql`
  mutation deletePurchaseOrder($input: String!) {
    PesananPembelian {
      delete(input: $input)
    }
  }
`;
