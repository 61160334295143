import { gql } from "@apollo/client";

export const CHILD_SUPPLIER_CATEGORY_FRAGMENT = gql`
  fragment ChildSupplierCategory on mKategoriPemasok {
    nama_kategori
    kategori_pemasok_id
    parent_id
    is_default
    is_sub
  }
`;

export const SUPPLIER_CATEGORY_FRAGMENT = gql`
  fragment SupplierCategory on listKategoriPemasok {
    nama_kategori
    kategori_pemasok_id
    parent_id
    is_default
    is_sub
    child {
      ...ChildSupplierCategory
      child {
        ...ChildSupplierCategory
      }
    }
  }
  ${CHILD_SUPPLIER_CATEGORY_FRAGMENT}
`;

export const SUPPLIER_CATEGORY_LIST = gql`
  query getListSupplierCategory {
    Pemasok {
      listKategori {
        ...SupplierCategory
      }
    }
  }
  ${SUPPLIER_CATEGORY_FRAGMENT}
`;

export const CREATE_SUPPLIER_CATEGORY = gql`
  mutation createSupplierCategory($input: CreateKategoriPemasokInput!) {
    Pemasok {
      createKategoriPemasok(input: $input) {
        ...ChildSupplierCategory
      }
    }
  }
  ${CHILD_SUPPLIER_CATEGORY_FRAGMENT}
`;

export const UPDATE_SUPPLIER_CATEGORY = gql`
  mutation editSupplierCategory($input: UpdateKategoriPemasokInput!) {
    Pemasok {
      updateKategoriPemasok(input: $input) {
        ...ChildSupplierCategory
      }
    }
  }
  ${CHILD_SUPPLIER_CATEGORY_FRAGMENT}
`;

export const DELETE_SUPPLIER_CATEGORY = gql`
  mutation deleteSupplierCategory($input: Float!) {
    Pemasok {
      deleteKategoriPemasok(input: $input) {
        ...ChildSupplierCategory
      }
    }
  }
  ${CHILD_SUPPLIER_CATEGORY_FRAGMENT}
`;
