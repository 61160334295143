import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchInput from "../../common/searchInput";
import { useMutation, useQuery } from "@apollo/client";
import { SATUAN_LIST } from "../../../actions/SatuanBarangAction";
import { GOODS_AND_SERVICE_LIST } from "../../../actions/BarangAction";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import { REQUEST_ORDER_LIST } from "../../../actions/RequestOrderAction";
import { toast } from "react-toastify";
import { GUDANG_LIST } from "../../../actions/GudangAction";
import { formatRupiah, preventZero } from "../../utils";
import { SYARAT_PEMBAYARAN_LIST } from "../../../actions/SyaratPembayaranAction";
import { COA_LIST } from "../../../actions/CoaBarangAction";
import { SUPPLIER_LIST } from "../../../actions/SupplierAction";
import {
  CREATE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
} from "../../../actions/PurchaseOrderAction";
import { format } from "date-fns";
import { menuList } from "../../utils/MenuList";
import { useStateContext } from "../../../context";

const AddNewPurchaseOrder = (props) => {
  const {
    detail,
    choosenData,
    dataPerTabs,
    setChoosenData,
    tabs,
    setTabs,
    tabOpen,
    setTabOpen,
    refetch,
  } = props;

  const datePickerRef = useRef(null);
  const datePickerSendRef = useRef(null);
  const hiddenButtonRef = useRef(null);

  const [state, setState] = useStateContext();

  const [tab, setTab] = useState(0);
  const [modalTab, setModalTab] = useState(0);
  const [bebanModalTab, setBebanModalTab] = useState(0);
  const [show, setShow] = useState({
    menu: false,
    bebanModal: false,
    modal: false,
    ambil: false,
    proses: false,
  });
  const [productToAdd, setProductToAdd] = useState({
    code: "",
    name: "",
    merek: "",
    quantity: 0,
    satuan: null,
    price: 0,
    percent: 0,
    flat: 0,
    kenaPajak: false,
    remarks: "",
    mode: "c",
  });
  const [bebanToAdd, setBebanToAdd] = useState({
    code: "",
    name: "",
    jumlah: 0,
    mode: "c",
  });
  const [productPhoto, setProductPhoto] = useState(null);
  const [productList, setProductList] = useState([]);
  const [permintaanList, setPermintaanList] = useState([]);
  const [bebanList, setBebanList] = useState([]);
  const [choosenId, setChoosenId] = useState(0);
  const [choosenBebanId, setChoosenBebanId] = useState(0);
  const [editProduct, setEditProduct] = useState(false);
  const [editBeban, setEditBeban] = useState(false);
  const [satuanOption, setSatuanOption] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [separatedDiscount, setSeparatedDiscount] = useState(0);
  const [redirectMode, setRedirectMode] = useState(false);
  const [redirectType, setRedirectType] = useState("");

  const validationSchema = Yup.object().shape({
    tglPesanan: Yup.string().required("Tanggal Pesanan"),
    tglKirim: Yup.string().required("Tanggal Kirim"),
    pemasok: Yup.string().required("Pemasok"),
    syaratPembayaran: Yup.string().required("Syarat Pembayaran"),
    gudang: Yup.string().required("Gudang"),
    alamatKirim: Yup.string().required("Alamat Kirim"),
    keterangan: Yup.string().nullable(),
    nomorPermintaan: Yup.string().nullable(),
    totalTermasukPajak: Yup.boolean().default(false),
    detail:
      productList.length > 0
        ? Yup.string().nullable()
        : Yup.string().required("Detail barang"),
    beban: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      parent_id: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = [...dataPerTabs];
    updatedList.splice(tabOpen, 1);

    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (productList.length === 0) {
      setValue("nomorPermintaan", "");
    }
    // eslint-disable-next-line
  }, [productList]);

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    $(datePickerRef.current).datepicker({
      onSelect: (dateText) => {
        setValue("tglPesanan", dateText);
      },
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tab === 1) {
      $(datePickerSendRef.current).datepicker({
        onSelect: (dateTexts) => {
          setValue("tglKirim", dateTexts);
        },
      });
    }
    // eslint-disable-next-line
  }, [tab]);

  const subTotalCount = (
    price,
    quantity,
    flat,
    percent,
    kenaPajak,
    includeTax
  ) => {
    const taxMultiplier = includeTax ? 1.11 : 1;

    if (percent === 0 && flat === 0) {
      const subtotal = kenaPajak
        ? (price * quantity) / taxMultiplier
        : price * quantity;
      return Math.round(subtotal);
    }

    const discountMultiplier = 1 - percent / 100;
    const subtotalBeforeTax =
      flat === 0
        ? price * quantity * discountMultiplier
        : price * quantity - flat;

    const subtotal = kenaPajak
      ? subtotalBeforeTax / taxMultiplier
      : subtotalBeforeTax;

    return Math.round(subtotal);
  };

  const bebanValue =
    bebanList.length > 0
      ? bebanList?.reduce((total, beban) => total + parseFloat(beban.jumlah), 0)
      : 0;
  const subTotalValue =
    productList.length > 0
      ? productList?.reduce(
          (total, product) =>
            total +
            subTotalCount(
              product.price,
              product.quantity,
              product.flat,
              product.percent,
              product.kenaPajak,
              watch("totalTermasukPajak")
            ),
          0
        )
      : 0;
  const subTotalWoTaxValue =
    productList.length > 0
      ? productList
          ?.filter((product) => product.kenaPajak === false)
          .reduce(
            (total, product) =>
              total +
              subTotalCount(
                product.price,
                product.quantity,
                product.flat,
                product.percent,
                product.kenaPajak,
                watch("totalTermasukPajak")
              ),
            0
          )
      : 0;
  const ppnValue = parseInt(
    Math.ceil(
      subTotalValue - subTotalWoTaxValue === 0
        ? 0
        : (subTotalValue - subTotalWoTaxValue - separatedDiscount) * 0.11
    )
  );
  const { data: satuanData } = useQuery(SATUAN_LIST, {
    variables: {
      input: {
        nama: "",
      },
    },
  });
  const { data: gudangData } = useQuery(GUDANG_LIST, {
    variables: {
      input: {},
    },
  });
  const { data: supplierData } = useQuery(SUPPLIER_LIST, {
    variables: {
      input: {},
    },
  });
  const { data: dataBarangJasa } = useQuery(GOODS_AND_SERVICE_LIST);
  const { data: dataSyarat } = useQuery(SYARAT_PEMBAYARAN_LIST);
  const { data: dataCoa } = useQuery(COA_LIST);
  const { data: dataRequestOrder } = useQuery(REQUEST_ORDER_LIST);
  const [createPurchaseOrder, { loading }] = useMutation(
    CREATE_PURCHASE_ORDER,
    {
      onCompleted: (resp) => {
        const createdData = resp.PesananPembelian.create;
        toast.success("Pesanan pembelian berhasil dibuat");
        refetch();

        if (redirectMode) {
          let menus = state.menuTabs;

          menus.push(
            redirectType === "penerimaan"
              ? menuList[5].child.row2[0]
              : menuList[5].child.row2[2]
          );

          setState({
            ...state,
            menuTabs: [...menus],
            idTabModule: redirectType === "penerimaan" ? 13 : 15,
          });

          localStorage.setItem(
            "redirectingData",
            JSON.stringify(
              redirectType === "penerimaan"
                ? {
                    kodeSupplier: createdData.pemasok.kode_supplier,
                    nomorPesanan: createdData.nomor_pesanan,
                    gudang: createdData.gudang_penerima.kode_gudang,
                    alamatTerima: `${createdData.cabang.alamat}, ${createdData.cabang.kota}, ${createdData.cabang.provinsi} \n${createdData.cabang.kodepos}`,
                    tanggalKirim: format(
                      new Date(createdData.tgl_kirim),
                      "MM/dd/yyyy"
                    ),
                    detailProduk: createdData.detail_item.map((item) => ({
                      code: item.barang.kode_barang,
                      name: item.barang.nama_barang,
                      quantity: item.qty,
                      satuan: item.satuan.uom_id,
                      gudang: createdData.gudang_penerima.kode_gudang,
                      remarks: item.keterangan,
                      mode: "c",
                    })),
                  }
                : {
                    kodeSupplier: createdData.pemasok.kode_supplier,
                    detailFaktur: {
                      itemId: 0,
                      noForm: createdData.nomor_pesanan,
                      noFaktur: createdData.nomor_pesanan,
                      tglFaktur: format(
                        new Date(createdData.tgl_pesanan),
                        "MM/dd/yyyy"
                      ),
                      totalFaktur:
                        subTotalValue +
                        bebanValue -
                        separatedDiscount +
                        ppnValue,
                      terhutang:
                        subTotalValue +
                        bebanValue -
                        separatedDiscount +
                        ppnValue,
                      bayar: "0",
                      diskon: [],
                    },
                  }
            )
          );
        } else {
          setTabOpen(0);
        }
      },
      onError: (resp) => {
        toast.error(resp.message);
      },
      // update(cache, { data }) {
      //   const list = cache.readQuery({
      //     query: CATEGORY_LIST,
      //   });
      //   cache.writeQuery({
      //     query: CATEGORY_LIST,
      //     data: {
      //       barang: {
      //         getKategoriBarang: [
      //           ...list.barang.getKategoriBarang,
      //           data.barang.createKategoriBarang,
      //         ],
      //       },
      //     },
      //   });
      // },
    }
  );

  const [deleteRequestOrder] = useMutation(DELETE_PURCHASE_ORDER, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   const updatedList = list.barang.getKategoriBarang.filter(
    //     (category) =>
    //       category.kategori_barang_id !== choosenData.kategori_barang_id
    //   );
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: updatedList,
    //       },
    //     },
    //   });
    // },
  });

  const [ubahRequestOrder, { loading: loadingUpdate }] = useMutation(
    UPDATE_PURCHASE_ORDER,
    {
      onCompleted: (resp) => {
        const updatedData = resp.PesananPembelian.update;
        toast.success("Sukses mengubah data");
        setTabs(tabs.filter((tab) => tab.id !== tabOpen));
        handleUpdateTabData();
        refetch();

        if (redirectMode) {
          let menus = state.menuTabs;

          menus.push(
            redirectType === "penerimaan"
              ? menuList[5].child.row2[0]
              : menuList[5].child.row2[2]
          );

          setState({
            ...state,
            menuTabs: [...menus],
            idTabModule: redirectType === "penerimaan" ? 13 : 15,
          });

          localStorage.setItem(
            "redirectingData",
            JSON.stringify(
              redirectType === "penerimaan"
                ? {
                    kodeSupplier: updatedData.pemasok.kode_supplier,
                    nomorPesanan: updatedData.nomor_pesanan,
                    gudang: updatedData.gudang_penerima.kode_gudang,
                    alamatTerima: `${updatedData.cabang.alamat}, ${updatedData.cabang.kota}, ${updatedData.cabang.provinsi} \n${updatedData.cabang.kodepos}`,
                    tanggalKirim: format(
                      new Date(updatedData.tgl_kirim),
                      "MM/dd/yyyy"
                    ),
                    detailProduk: updatedData.detail_item.map((item) => ({
                      code: item.barang.kode_barang,
                      name: item.barang.nama_barang,
                      quantity: item.qty,
                      satuan: item.satuan.uom_id,
                      gudang: updatedData.gudang_penerima.kode_gudang,
                      remarks: item.keterangan,
                      mode: "c",
                    })),
                  }
                : {
                    kodeSupplier: updatedData.pemasok.kode_supplier,
                    detailFaktur: {
                      itemId: 0,
                      noForm: updatedData.nomor_pesanan,
                      noFaktur: updatedData.nomor_pesanan,
                      tglFaktur: format(
                        new Date(updatedData.tgl_pesanan),
                        "MM/dd/yyyy"
                      ),
                      totalFaktur:
                        subTotalValue +
                        bebanValue -
                        separatedDiscount +
                        ppnValue,
                      terhutang:
                        subTotalValue +
                        bebanValue -
                        separatedDiscount +
                        ppnValue,
                      bayar: "0",
                      diskon: [],
                    },
                  }
            )
          );
        } else {
          setTabOpen(0);
        }
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  const supplierList = supplierData?.Pemasok.findAll.map((pemasok) => ({
    id: pemasok.kode_supplier,
    name: `${pemasok.nama}`,
    desc: pemasok.kode_supplier,
  }));
  const dataSatuan = satuanData?.barang.getSatuanBarang.data.map((satuan) => ({
    id: satuan.uom_id,
    name: `${satuan.uom_alias} - ${satuan.uom_name}`,
    alias: satuan.uom_name,
  }));
  const barangJasaList = dataBarangJasa?.barang.findAll.data.map((barang) => ({
    id: barang.kode_barang,
    name: barang.nama_barang,
    ...barang,
  }));
  const dataGudang = gudangData?.Gudang.findAll.map((gudang) => ({
    id: gudang.kode_gudang,
    name: `Gudang ${gudang.nama_gudang}`,
    desc: gudang.alamat,
    ...gudang,
  }));
  const syaratPembayaranList = dataSyarat?.Pemasok.listSyarat?.map(
    (syarat) => ({
      id: syarat.syarat_pembayaran_id,
      name: syarat.nama_syarat_pembayaran,
    })
  );
  const allowedCoaTypes = [
    "1060",
    "6011",
    "4010",
    "5012",
    "5010",
    "4011",
    "5011",
  ];
  const coaList = dataCoa?.coa.findAll
    .filter((coa) => allowedCoaTypes.includes(coa.coa_type))
    .map((coa) => ({
      id: coa.coa_code.toString(),
      name: coa.coa_name,
      desc: coa.coa_code,
      type: coa.coa_type,
    }));
  const requestOrderList = dataRequestOrder?.PermintaanBarang.findAll
    .filter((request) => request.tipe_permintaan === "Beli Barang")
    .map((request) => ({
      id: request.nomor_permintaan,
      name: request.nomor_permintaan,
      desc: `${format(new Date(request.tgl_permintaan), "dd/MM/yyyy")} - ${
        request.cabang.nama_cabang
      }`,
      detail: request.detail,
    }));

  useEffect(() => {
    if (detail && choosenData) {
      setValue(
        "tglPesanan",
        format(new Date(choosenData.tgl_pesanan), "MM/dd/yyyy")
      );
      setValue(
        "tglKirim",
        format(new Date(choosenData.tgl_kirim), "MM/dd/yyyy")
      );
      setValue("pemasok", choosenData.pemasok.kode_supplier);
      setValue(
        "syaratPembayaran",
        choosenData.syarat_pembayaran.syarat_pembayaran_id
      );
      setValue("gudang", choosenData.gudang_penerima.kode_gudang);
      setValue(
        "alamatKirim",
        `${choosenData.cabang.alamat}, ${choosenData.cabang.kota}, ${choosenData.cabang.provinsi} \n${choosenData.cabang.kodepos}`
      );
      setValue("keterangan", choosenData.keterangan);
      setValue("nomorPermintaan", choosenData.nomor_permintaan);
      setValue("totalTermasukPajak", choosenData.is_tot_inc_pajak);
      setProductList(
        choosenData.detail_item.map((item) => ({
          code: item.barang.kode_barang,
          name: item.barang.nama_barang,
          merek: item.merek === undefined ? "" : item.merek,
          quantity: item.qty,
          satuan: item.satuan.uom_id,
          price: item.harga,
          percent: item.persen_diskon,
          flat: item.nilai_diskon,
          kenaPajak: item.kena_pajak === undefined ? false : item.kena_pajak,
          remarks: item.keterangan,
          mode: "e",
        }))
      );
      setBebanList(
        choosenData.beban.map((beban) => ({
          code: beban.kode_coa,
          name: coaList?.find((coa) => coa.id === beban.kode_coa).name,
          jumlah: beban.nilai,
          mode: "e",
        }))
      );
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const handleCheckbox = (value, i) => {
    let temp = productList[i];
    temp.kenaPajak = value;
    productList[i] = temp;
    setProductList([...productList]);
  };

  const handleRequestCheckbox = (value, i) => {
    let temp = permintaanList[i];
    temp.selected = value;
    permintaanList[i] = temp;
    setPermintaanList([...permintaanList]);
  };

  const handleGetSatuanOption = (selectedBarang) => {
    let tempSatuanOption = selectedBarang.detail_harga.map((harga) => ({
      id: harga.uom.uom_id,
      name: `${harga.uom.uom_alias} - ${harga.uom.uom_name}`,
      alias: harga.uom.uom_name,
    }));
    if (!tempSatuanOption.includes(selectedBarang.base_uom)) {
      tempSatuanOption.push({
        id: selectedBarang.base_uom.uom_id,
        name: `${selectedBarang.base_uom.uom_alias} - ${selectedBarang.base_uom.uom_name}`,
        alias: selectedBarang.base_uom.uom_name,
      });
    }
    setSatuanOption(tempSatuanOption);
  };

  const handleOpenBebanDetail = (index) => {
    const choosenBeban = bebanList[index];
    setBebanToAdd({
      code: choosenBeban.code,
      name: choosenBeban.name,
      jumlah: choosenBeban.jumlah,
      mode: choosenBeban.mode,
    });
    setShow({ ...show, bebanModal: true });
    setEditBeban(true);
    setChoosenBebanId(index);
  };

  const handleOpenDetail = (index) => {
    const choosenProduct = productList[index];
    const selectedBarang = barangJasaList?.find(
      (barang) => barang.id === choosenProduct.code
    );
    handleGetSatuanOption(selectedBarang);
    setProductToAdd({
      code: choosenProduct.code,
      name: choosenProduct.name,
      merek: choosenProduct.merek,
      quantity: choosenProduct.quantity,
      satuan: choosenProduct.satuan,
      price: choosenProduct.price,
      percent: choosenProduct.percent,
      flat: choosenProduct.flat,
      kenaPajak: choosenProduct.kenaPajak,
      remarks: choosenProduct.remarks,
      mode: choosenProduct.mode,
    });
    setShow({ ...show, modal: true });
    setEditProduct(true);
    setChoosenId(index);

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}barang/upload/${choosenData.code}`
    )
      .then((res) => res.blob())
      .then((data) => setProductPhoto(data));
  };

  const handleAddTableBeban = () => {
    if (editBeban) {
      const temp = bebanList;
      bebanList[choosenBebanId] = bebanToAdd;
      setBebanList([...temp]);
    } else {
      setBebanList([...bebanList, bebanToAdd]);
    }
    setBebanModalTab(0);
    setBebanToAdd({
      code: "",
      name: "",
      jumlah: 0,
      mode: "c",
    });
    setShow({ ...show, bebanModal: false });
    setEditBeban(false);
  };

  const handleDeleteTableBeban = () => {
    const temp = [...bebanList];
    temp.splice(choosenBebanId, 1);
    setBebanList([...temp]);
    setBebanModalTab(0);
    setBebanToAdd({
      code: "",
      name: "",
      jumlah: 0,
      mode: "c",
    });
    setShow({ ...show, bebanModal: false });
    setEditBeban(false);
  };

  const handleAddTableProduct = () => {
    if (editProduct) {
      const temp = productList;
      productList[choosenId] = productToAdd;
      setProductList([...temp]);
    } else {
      setProductList([...productList, productToAdd]);
    }
    setModalTab(0);
    setProductToAdd({
      code: "",
      name: "",
      merek: "",
      quantity: 0,
      satuan: null,
      price: 0,
      percent: null,
      flat: null,
      kenaPajak: false,
      remarks: "",
      mode: "c",
    });
    setSatuanOption([]);
    setShow({ ...show, modal: false });
    setEditProduct(false);
  };

  const handleDeleteTableProduct = () => {
    const temp = [...productList];
    temp.splice(choosenId, 1);
    setProductList([...temp]);
    setEditProduct(false);
    setModalTab(0);
    setProductToAdd({
      code: "",
      name: "",
      merek: "",
      quantity: 0,
      satuan: null,
      price: 0,
      percent: null,
      flat: null,
      kenaPajak: false,
      remarks: "",
      mode: "c",
    });
    setSatuanOption([]);
    setShow({ ...show, modal: false });
  };

  const handleAmbilPermintaan = () => {
    if (permintaanList.length > 0) {
      const products = productList.concat(
        permintaanList.filter((request) => request.selected)
      );
      setProductList(products);
      setShow({
        ...show,
        ambil: false,
      });
      setPermintaanList([]);
    } else {
      toast.error("Pilih salah satu nomor permintaan untuk melanjutkan");
    }
  };

  const handleSaveRequest = (dataSubmit) => {
    const inputs = {
      tglPesanan: dataSubmit.tglPesanan,
      tglKirim: dataSubmit.tglKirim,
      pemasok: dataSubmit.pemasok,
      syaratPembayaran: parseFloat(dataSubmit.syaratPembayaran),
      gudang: dataSubmit.gudang,
      alamatKirim: dataSubmit.alamatKirim,
      keterangan: dataSubmit.keterangan,
      nomorPermintaan: dataSubmit.nomorPermintaan ?? null,
      totalTermasukPajak: dataSubmit.totalTermasukPajak,
      detail: productList.map((product) => ({
        kodeBarang: product.code,
        pcs: parseFloat(product.quantity),
        uom: parseFloat(product.satuan),
        harga: parseFloat(product.price),
        persenDiskon: parseFloat(product.percent),
        nilaiDiskon: parseFloat(product.flat),
        kenaPajak: product.kenaPajak,
        remarks: product.remarks,
        merek: product.merek,
      })),
      beban:
        bebanList.length > 0
          ? bebanList.map((beban) => ({
              kodeBeban: beban.code,
              nilai: parseFloat(beban.jumlah),
            }))
          : [],
    };
    if (detail) {
      ubahRequestOrder({
        variables: {
          input: {
            ...inputs,
            nomor_pesanan: choosenData.nomor_pesanan,
          },
        },
      });
    } else {
      createPurchaseOrder({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteRequestOrder({
      variables: {
        input: choosenData.nomor_pesanan,
      },
    });
  };

  const handleItemClick = (type) => {
    setRedirectMode(true);
    setRedirectType(type);

    if (hiddenButtonRef.current) {
      hiddenButtonRef.current.click();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleSaveRequest)}>
        <div className="left-content-module">
          <div className="content-nav-module">
            <button
              ref={hiddenButtonRef}
              type="submit"
              style={{ display: "none" }}
            >
              Hidden Button
            </button>
            <form id="" className="">
              <div className="form-wrap">
                <div className="form-container">
                  <div className="title">Tanggal</div>
                  <input
                    ref={datePickerRef}
                    className="form-date-input"
                    type="text"
                    placeholder="Pilh tanggal"
                    value={watch("tglPesanan")}
                    onChange={(e) => setValue("tglPesanan", e.target.value)}
                  />
                  <div className="icon-form-date icon-date_ic"></div>
                </div>
              </div>

              <div className="form-wrap">
                <SearchInput
                  title="Pemasok"
                  placeholder="Cari/Pilih Pemasok"
                  value={watch("pemasok")}
                  setValue={(e) => {
                    setValue("pemasok", e);
                  }}
                  searchData={supplierList}
                />
              </div>

              <div className="form-right-side">
                {/* <!--RIGHT SIDE--> */}
                <div class="form-wrap">
                  <div class="form-container w-250px">
                    <div class="title">Nomor#</div>
                    <div class="auto-form-container disable w-fluid">
                      <input
                        name=""
                        type="text"
                        placeholder="Cari gudang"
                        value={watch("nomorPermintaan") || "-"}
                      />
                    </div>
                  </div>
                </div>

                <div className="nav-setting-wrap">
                  <div className="list-setting">
                    <div
                      class="setting-btn more-btn"
                      onClick={() => setShow({ ...show, ambil: true })}
                    >
                      Ambil
                    </div>

                    <div
                      class="setting-btn more-btn"
                      onClick={() =>
                        setShow({
                          ...show,
                          proses: !show.proses,
                          ambil: false,
                        })
                      }
                    >
                      Proses <span class="icon-down_ic"></span>
                    </div>

                    {show.proses && (
                      <div class="popup-setting popup-right">
                        <ul>
                          <li
                            style={{
                              display: "block",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleItemClick("penerimaan")}
                          >
                            Penerimaan Barang
                          </li>

                          <li
                            style={{
                              display: "block",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleItemClick("pembayaran")}
                          >
                            Pembayaran Pembelian
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                {show.ambil && (
                  <div class="window-overlay">
                    <div class="window-container">
                      <div class="window-nav">
                        <div class="title flex-center">
                          Salin dari permintaan barang
                        </div>
                        <div class="close-btn flex-center">
                          <span
                            class="icon icon-close_ic"
                            onClick={() => {
                              setShow({
                                ...show,
                                ambil: false,
                              });
                              setPermintaanList([]);
                            }}
                          ></span>
                        </div>
                      </div>
                      <div class="window-content">
                        <form id="" class="">
                          <div class="form-wrap no-float">
                            <SearchInput
                              title="Pesanan"
                              placeholder="Cari/Pilih Nomor Pesanan"
                              setValue={(e) => {
                                const selectedDetail = requestOrderList.find(
                                  (requestOrder) => requestOrder.id === e
                                ).detail;
                                // setValue("nomorPermintaan", e);
                                const detailBarang = selectedDetail.map(
                                  (detail) => ({
                                    id: detail.detail_permintaan_id,
                                    selected: false,
                                    code: detail.barang.kode_barang,
                                    name: detail.barang.nama_barang,
                                    quantity: detail.qty,
                                    satuan: detail.satuan.uom_id,
                                    price: 0,
                                    percent: 0,
                                    flat: 0,
                                    kenaPajak: false,
                                    remarks: detail.keterangan,
                                    mode: "c",
                                  })
                                );
                                setPermintaanList(detailBarang);
                              }}
                              searchData={requestOrderList}
                            />
                            <div class="clear"></div>
                          </div>
                        </form>

                        <div class="main-content-module">
                          <div class="">
                            <table id="all-data-table" class="detail-page">
                              <thead>
                                <tr>
                                  <th>
                                    <label class="checkbox">
                                      <input
                                        type="checkbox"
                                        checked={
                                          permintaanList?.filter(
                                            (request) => request.selected
                                          ).length > 0
                                        }
                                        onClick={() => {
                                          let temp = permintaanList;
                                          let a = temp.map((request) => ({
                                            ...request,
                                            selected: !request.selected,
                                          }));
                                          setPermintaanList([...a]);
                                        }}
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </th>
                                  <th>Kode #</th>
                                  <th>Nama Barang</th>
                                  <th>Kuantitas</th>
                                  <th>Satuan</th>
                                  <th>Keterangan</th>
                                </tr>
                              </thead>
                              <tbody>
                                {permintaanList?.map((request, i) => (
                                  <tr>
                                    <td>
                                      <label class="checkbox">
                                        <input
                                          type="checkbox"
                                          checked={request.selected}
                                          onClick={() =>
                                            handleRequestCheckbox(
                                              !request.selected,
                                              i
                                            )
                                          }
                                        />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>{request.code}</td>
                                    <td>{request.name}</td>
                                    <td>{request.quantity}</td>
                                    <td>
                                      {
                                        dataSatuan?.find(
                                          (satuan) =>
                                            satuan.id === request.satuan
                                        ).name
                                      }
                                    </td>
                                    <td>{request.remarks}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="window-bottom">
                        <div
                          class="button btn-blue flex-center"
                          onClick={handleAmbilPermintaan}
                        >
                          Lanjutkan
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="clear"></div>
            </form>
          </div>
          <div className="main-content-module detail-page">
            <div className="detail-data-wrap">
              <div className="wrap">
                <div
                  className={`tab-menu ${tab === 0 && "active"}`}
                  onClick={() => setTab(0)}
                >
                  <span className="icon-input_ic"></span>
                </div>
                <div
                  className={`tab-menu ${tab === 1 && "active"}`}
                  onClick={() => setTab(1)}
                >
                  <span className="icon-info_ic"></span>
                </div>
                <div
                  className={`tab-menu ${tab === 2 && "active"}`}
                  onClick={() => setTab(2)}
                >
                  <span className="icon-biaya_lain_ic"></span>
                </div>
              </div>

              {tab === 0 && (
                <div className="content-wrap">
                  <div className="content-nav-module">
                    <form id="" className="">
                      <div className="form-wrap">
                        <SearchInput
                          title="Barang &amp; Jasa"
                          placeholder="Cari/Pilih Barang & Jasa"
                          setValue={(e) => {
                            const selectedBarang = barangJasaList?.find(
                              (barang) => barang.id === e
                            );
                            handleGetSatuanOption(selectedBarang);
                            setProductToAdd({
                              code: selectedBarang.id,
                              name: selectedBarang.name,
                              merek: "",
                              quantity: 0,
                              satuan: selectedBarang.base_uom.uom_id,
                              price: 0,
                              percent: 0,
                              flat: 0,
                              kenaPajak: false,
                              remarks: "",
                              mode: "c",
                            });
                            setShow({ ...show, modal: true });
                            fetch(
                              `${process.env.REACT_APP_BACKEND_URL}barang/upload/${selectedBarang.id}`
                            )
                              .then((res) => res.blob())
                              .then((data) => setProductPhoto(data));
                          }}
                          searchData={barangJasaList}
                        />
                      </div>

                      <div className="form-right-side">
                        <div className="form-wrap"></div>
                      </div>

                      <div className="clear"></div>
                    </form>
                  </div>
                  <div className="main-content-module">
                    <table id="all-data-table" class="detail-page m-b-30">
                      <tr>
                        <th width="45">PJK</th>
                        <th>Nama Barang</th>
                        <th>Kode #</th>
                        <th>Kuantitas</th>
                        <th>Satuan</th>
                        <th>@Harga</th>
                        <th>Diskon</th>
                        <th>Total Harga</th>
                      </tr>
                      {productList.map((row, i) => (
                        <tr key={i}>
                          <td>
                            <label
                              class="checkbox"
                              onChange={() => handleCheckbox(!row.kenaPajak, i)}
                            >
                              <input type="checkbox" checked={row.kenaPajak} />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {row.name}
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {row.code}
                          </td>
                          <td
                            class="txt-right"
                            onClick={() => handleOpenDetail(i)}
                          >
                            {row.quantity}
                          </td>
                          <td
                            class="txt-right"
                            onClick={() => handleOpenDetail(i)}
                          >
                            {
                              dataSatuan?.find(
                                (satuan) => satuan.id === row.satuan
                              ).name
                            }
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {formatRupiah(row.price)}
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {formatRupiah(
                              row.flat ||
                                (row.price * row.quantity * row.percent) / 100
                            )}
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {formatRupiah(
                              row.price * row.quantity -
                                (row.flat ||
                                  (row.price * row.quantity * row.percent) /
                                    100)
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>

                  {show.modal && (
                    <>
                      {/* <!--POPUP PRODUCT --> */}
                      <div class="window-overlay">
                        <div class="window-container product-window">
                          <div class="window-nav">
                            {/* <!--CONTENT TAB--> */}
                            <div class="tab_content_module">
                              {["Rincian Barang", "Keterangan"].map(
                                (tab, i) => (
                                  <div
                                    class={`tab_menu_content ${
                                      modalTab === i && "active"
                                    }`}
                                    onClick={() => setModalTab(i)}
                                  >
                                    {tab}
                                  </div>
                                )
                              )}
                            </div>
                            <div
                              class="close-btn flex-center"
                              onClick={() => {
                                setShow({ ...show, modal: false });
                                setProductToAdd({
                                  code: "",
                                  name: "",
                                  tglDiminta: "",
                                  quantity: 0,
                                  satuan: null,
                                  remarks: "",
                                  mode: "c",
                                });
                              }}
                            >
                              <span class="icon icon-close_ic"></span>
                            </div>
                          </div>
                          <div class="window-content">
                            {modalTab === 0 && (
                              <>
                                <div className="product-img">
                                  {productPhoto !== null && (
                                    <img
                                      src={URL.createObjectURL(productPhoto)}
                                      alt={`${productToAdd.code} - ${productToAdd.name}`}
                                    />
                                  )}
                                </div>

                                <div class="form-side">
                                  <form id="" class="">
                                    <div class="form-wrap">
                                      <div className="form-container lg">
                                        <div class="title">Kode#</div>

                                        <b className="text-desc">
                                          {productToAdd.code}
                                        </b>
                                      </div>

                                      <div class="form-container lg">
                                        <div class="title">Nama</div>
                                        <div class="auto-form-container disable">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            disabled
                                            value={productToAdd.name}
                                          />
                                        </div>
                                      </div>

                                      <div class="form-container lg">
                                        <div class="title">Merek</div>
                                        <div class="auto-form-container">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Masukkan merek barang"
                                            value={productToAdd.merek}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                merek: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div class="form-container md">
                                        <div class="title">Kuantitas</div>
                                        <div class="auto-form-container">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            value={productToAdd.quantity}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                quantity: preventZero(e),
                                              })
                                            }
                                          />
                                        </div>
                                        <div class="icon-form-date icon-penjualan_ic"></div>
                                      </div>

                                      <SearchInput
                                        className="sm padding-l"
                                        title="Satuan"
                                        placeholder="Cari satuan"
                                        value={productToAdd.satuan}
                                        setValue={(e) =>
                                          setProductToAdd({
                                            ...productToAdd,
                                            satuan: e,
                                          })
                                        }
                                        searchData={satuanOption}
                                      />

                                      <div class="form-container lg">
                                        <div class="title">Harga</div>
                                        <div class="auto-form-container fi">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Masukkan Harga"
                                            value={formatRupiah(
                                              productToAdd.price
                                            )}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                price: preventZero(e),
                                              })
                                            }
                                          />
                                        </div>
                                        <div class="fi-icon">Rp</div>
                                        <div class="icon-form-date icon-biaya_lain_ic"></div>
                                      </div>
                                      <div class="form-container hlf padding-r">
                                        <div class="title">Diskon</div>
                                        <div class="auto-form-container fi">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            value={productToAdd.percent}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                percent: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                        <div class="fi-icon">%</div>
                                      </div>
                                      <div class="form-container hlf">
                                        <div class="title"></div>
                                        <div class="auto-form-container fi">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Masukkan harga diskon"
                                            value={
                                              parseFloat(productToAdd.flat) !==
                                              0
                                                ? formatRupiah(
                                                    productToAdd.flat || 0
                                                  )
                                                : formatRupiah(
                                                    (productToAdd.price *
                                                      productToAdd.quantity *
                                                      productToAdd.percent) /
                                                      100
                                                  )
                                            }
                                            onChange={(e) => {
                                              setProductToAdd({
                                                ...productToAdd,
                                                flat: preventZero(e),
                                                percent: 0,
                                              });
                                            }}
                                          />
                                        </div>
                                        <div class="fi-icon">Rp</div>
                                      </div>

                                      <div class="form-container lg">
                                        <div class="title">Total Harga</div>
                                        <div class="auto-form-container fi disable">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            value={formatRupiah(
                                              productToAdd.price *
                                                productToAdd.quantity -
                                                (productToAdd.flat ||
                                                  (productToAdd.price *
                                                    productToAdd.quantity *
                                                    productToAdd.percent) /
                                                    100)
                                            )}
                                          />
                                        </div>
                                        <div class="fi-icon">Rp</div>
                                        <div class="icon-form-date icon-biaya_lain_ic"></div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}

                            {modalTab === 1 && (
                              <div class="content-wrap">
                                <div class="content-nav-module">
                                  <form id="" class="">
                                    <div class="col-fulltab-50">
                                      <div class="form-wrap">
                                        <div class="form-container">
                                          <div class="auto-form-container w-400px">
                                            <textarea
                                              id=""
                                              name=""
                                              rows="4"
                                              cols="50"
                                              value={productToAdd.remarks}
                                              onChange={(e) =>
                                                setProductToAdd({
                                                  ...productToAdd,
                                                  remarks: e.target.value,
                                                })
                                              }
                                              placeholder="Tuliskan keterangan di sini"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </div>
                          <div class="window-bottom">
                            <div
                              class="button btn-blue flex-center"
                              onClick={handleAddTableProduct}
                            >
                              Simpan
                            </div>
                            {editProduct && (
                              <div
                                class="button btn-red flex-center"
                                onClick={handleDeleteTableProduct}
                              >
                                Hapus
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {tab === 1 && (
                <div class="content-wrap">
                  <div class="content-nav-module full-height">
                    <form id="" class="">
                      <div class="col-fulltab-50">
                        <div class="form-wrap ">
                          <div class="section-title">Info Lainnya</div>

                          <SearchInput
                            title="Syarat Pembayaran"
                            placeholder="Cari syarat pembayaran"
                            value={watch("syaratPembayaran")}
                            setValue={(e) => setValue("syaratPembayaran", e)}
                            searchData={syaratPembayaranList}
                          />

                          <SearchInput
                            title="Alamat Kirim"
                            placeholder="Cari gudang"
                            value={watch("gudang")}
                            setValue={(e) => {
                              const selectedGudang = dataGudang.find(
                                (gudang) => gudang.id === e
                              );
                              setValue("gudang", e);
                              setValue(
                                "alamatKirim",
                                `${selectedGudang.alamat}, ${selectedGudang.kota}, ${selectedGudang.provinsi} \n${selectedGudang.kodepos}`
                              );
                            }}
                            searchData={dataGudang}
                          />

                          {watch("alamatKirim") !== undefined && (
                            <div class="form-container">
                              <div class="auto-form-container w-400px disable">
                                <textarea
                                  id=""
                                  name=""
                                  rows="4"
                                  cols="50"
                                  disabled
                                  placeholder="Tuliskan catatan di sini"
                                  {...register("alamatKirim")}
                                ></textarea>
                              </div>
                            </div>
                          )}

                          <div class="form-container">
                            <div class="title">Keterangan</div>

                            <div class="auto-form-container w-400px">
                              <textarea
                                id=""
                                name=""
                                rows="4"
                                cols="50"
                                placeholder="Tuliskan catatan di sini"
                                {...register("keterangan")}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-fulltab-50">
                        <div class="form-wrap ">
                          <div class="section-title">Info Pajak</div>

                          <div class="form-container flex-center w-100">
                            <div class="ppn-desc">
                              <div>Total Termasuk Pajak?</div>
                              <div>
                                {watch("totalTermasukPajak") ? "Ya" : "Tidak"}
                              </div>
                            </div>
                            <div
                              class={`toggle-btn ${
                                watch("totalTermasukPajak")
                                  ? "enable"
                                  : "disable"
                              }`}
                              onClick={() =>
                                setValue(
                                  "totalTermasukPajak",
                                  !watch("totalTermasukPajak")
                                )
                              }
                            ></div>
                          </div>

                          <div class="section-title">Info Pengiriman</div>

                          <div className="form-container">
                            <div className="title">Tgl Pengiriman</div>
                            <input
                              ref={datePickerSendRef}
                              className="form-date-input"
                              type="text"
                              placeholder="Pilh tanggal"
                              value={watch("tglKirim")}
                              onChange={(e) =>
                                setValue("tglKirim", e.target.value)
                              }
                            />
                            <div className="icon-form-date icon-date_ic"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {tab === 2 && (
                <div className="content-wrap">
                  <div className="content-nav-module">
                    <form id="" className="">
                      <div className="form-wrap">
                        <SearchInput
                          title="Akun Perkiraan"
                          placeholder="Cari/Pilih Akun Perkiraan"
                          setValue={(e) => {
                            const selectedBeban = coaList?.find(
                              (coa) => coa.id === e
                            );
                            setBebanToAdd({
                              ...bebanToAdd,
                              code: selectedBeban.id,
                              name: selectedBeban.name,
                            });
                            setShow({ ...show, bebanModal: true });
                          }}
                          searchData={coaList}
                        />
                      </div>

                      <div className="form-right-side">
                        <div className="form-wrap"></div>
                      </div>

                      <div className="clear"></div>
                    </form>
                  </div>
                  <div className="main-content-module">
                    <table id="all-data-table" class="detail-page m-b-30">
                      <tr>
                        <th>Kode #</th>
                        <th>Nama</th>
                        <th>Jumlah</th>
                      </tr>
                      {bebanList.map((row, i) => (
                        <tr key={i} onClick={() => handleOpenBebanDetail(i)}>
                          <td>{row.code}</td>
                          <td>{row.name}</td>
                          <td>{formatRupiah(row.jumlah)}</td>
                        </tr>
                      ))}
                    </table>
                  </div>

                  {show.bebanModal && (
                    <>
                      {/* <!--POPUP PRODUCT --> */}
                      <div class="window-overlay">
                        <div class="window-container product-window">
                          <div class="window-nav">
                            {/* <!--CONTENT TAB--> */}
                            <div class="tab_content_module">
                              {["Biaya Lainnya"].map((tab, i) => (
                                <div
                                  class={`tab_menu_content ${
                                    bebanModalTab === i && "active"
                                  }`}
                                  onClick={() => setBebanModalTab(i)}
                                >
                                  {tab}
                                </div>
                              ))}
                            </div>
                            <div
                              class="close-btn flex-center"
                              onClick={() => {
                                setShow({ ...show, bebanModal: false });
                                setProductToAdd({
                                  code: "",
                                  name: "",
                                  jumlah: 0,
                                  mode: "c",
                                });
                              }}
                            >
                              <span class="icon icon-close_ic"></span>
                            </div>
                          </div>
                          <div class="window-content">
                            {bebanModalTab === 0 && (
                              <div class="form-side">
                                <form id="" class="">
                                  <div class="form-wrap">
                                    <div className="form-container lg">
                                      <div class="title">Kode#</div>

                                      <b className="text-desc">
                                        {bebanToAdd.code}
                                      </b>
                                    </div>

                                    <div class="form-container lg">
                                      <div class="title">Nama</div>
                                      <div class="auto-form-container disable">
                                        <input
                                          name=""
                                          type="text"
                                          placeholder="Cari gudang"
                                          disabled
                                          value={bebanToAdd.name}
                                        />
                                      </div>
                                    </div>

                                    <div class="form-container lg">
                                      <div class="title">Harga</div>
                                      <div class="auto-form-container fi">
                                        <input
                                          name=""
                                          type="text"
                                          placeholder="Masukkan Harga"
                                          value={formatRupiah(
                                            bebanToAdd.jumlah
                                          )}
                                          onChange={(e) =>
                                            setBebanToAdd({
                                              ...bebanToAdd,
                                              jumlah: preventZero(e),
                                            })
                                          }
                                        />
                                      </div>
                                      <div class="fi-icon">Rp</div>
                                      <div class="icon-form-date icon-biaya_lain_ic"></div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            )}

                            {/* {bebanModalTab === 1 && (
                              <div class="content-wrap">
                                <div class="content-nav-module">
                                  <form id="" class="">
                                    <div class="col-fulltab-50">
                                      <div class="form-wrap">
                                        <div class="form-container">
                                          <div class="auto-form-container w-400px">
                                            <textarea
                                              id=""
                                              name=""
                                              rows="4"
                                              cols="50"
                                              value={productToAdd.remarks}
                                              onChange={(e) =>
                                                setProductToAdd({
                                                  ...productToAdd,
                                                  remarks: e.target.value,
                                                })
                                              }
                                              placeholder="Tuliskan keterangan di sini"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )} */}
                          </div>
                          <div class="window-bottom">
                            <div
                              class="button btn-blue flex-center"
                              onClick={handleAddTableBeban}
                            >
                              Simpan
                            </div>
                            {editBeban && (
                              <div
                                class="button btn-red flex-center"
                                onClick={handleDeleteTableBeban}
                              >
                                Hapus
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              <div class="summary-section">
                <div class="summary-list-wrap">
                  <div class="summary-list">
                    <div class="summary-indicator blue"></div>
                    <div class="title">Sub Total</div>
                    <div class="nominal">
                      {formatRupiah(subTotalValue + bebanValue)}
                    </div>
                  </div>
                  <div class="summary-list">
                    <div class="summary-indicator green"></div>
                    <div class="title">Diskon</div>
                    <input
                      type="text"
                      value={formatRupiah(separatedDiscount)}
                      style={{
                        fontFamily: "poppins-regular",
                        fontSize: "14px",
                        fontWeight: 700,
                        textAlign: "right",
                        border: 0,
                        padding: 0,
                        width: "100%",
                        background: "none",
                        height: "14px",
                      }}
                      onChange={(e) => setSeparatedDiscount(preventZero(e))}
                    />
                  </div>
                  <div class="summary-list">
                    <div class="summary-indicator orange"></div>
                    <div class="title">PPN 11%</div>
                    <div class="nominal">{formatRupiah(ppnValue)}</div>
                  </div>
                  <div class="summary-list">
                    <div class="summary-indicator red"></div>
                    <div class="title">Total</div>
                    <div class="nominal">
                      {formatRupiah(
                        subTotalValue +
                          bebanValue -
                          separatedDiscount +
                          ppnValue
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${
                (loading || loadingUpdate) && "disabled"
              }`}
              onClick={handleSubmit(handleSaveRequest)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>
            <div className="data-action-button purple-bg">
              <span className="icon icon-cetaik_ic"></span>
              Cetak
            </div>
            <div className="data-action-button purple-bg">
              <span className="icon icon-attach_ic"></span>
              Lampiran
            </div>
            <div className="data-action-button blue-bg">
              <span className="icon icon-more_ic"></span>
              Lainnya
            </div>
            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Pesanan
              pembelian {choosenData?.nomor_pesanan}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewPurchaseOrder;
