import React from "react";

const SyaratPembayaranList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    syaratList,
    loading,
  } = props;

  const [search, setSearch] = React.useState("");

  const handleOpenDetail = (choosen) => {
    if (tabs.find((tab) => tab.id === choosen.nama_syarat_pembayaran)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: choosen.nama_syarat_pembayaran,
          type: "detail",
          id: choosen.nama_syarat_pembayaran,
        },
      ]);
    }
    setActive(choosen.nama_syarat_pembayaran);
    setChoosenData([
      ...choosenData,
      { idTab: choosen.nama_syarat_pembayaran, ...choosen },
    ]);
  };

  return (
    <>
      <div className="content_nav_module">
        <form id="" class="">
          <div class="form-right-side">
            <div class="form-wrap">
              <div class="form-container">
                <div class="title">Pencarian</div>
                <input
                  class="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div class="icon-form-date icon-search_ic"></div>
              </div>
            </div>

            <div class="data-total-wrap">
              <div>TOTAL DATA</div>
              <div class="value">{syaratList?.length}</div>
            </div>
          </div>

          <div class="clear"></div>
        </form>
      </div>

      <div class="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <tr>
              <th>Nama</th>
              <th>Masa Jatuh Tempo (Hari)</th>
              <th>Keterangan</th>
              <th>Default</th>
            </tr>
            {loading ? (
              <p>Loading...</p>
            ) : syaratList?.length === 0 ? (
              <tr>
                <td>Tidak ada data.</td>
              </tr>
            ) : (
              syaratList
                ?.filter((syarat) =>
                  syarat.nama_syarat_pembayaran.toLowerCase().includes(search)
                )
                ?.map((syarat, i) => (
                  <>
                    <tr onClick={() => handleOpenDetail(syarat)}>
                      <td>{syarat.nama_syarat_pembayaran}</td>
                      <td>{syarat.masa_jto}</td>
                      <td>{syarat.keterangan}</td>
                      <td>{syarat.is_default ? "Ya" : "Tidak"}</td>
                    </tr>
                  </>
                ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default SyaratPembayaranList;
