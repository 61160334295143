import { gql } from "@apollo/client";
import { LOOKUP_FRAGMENT } from "./LookupAction";

export const COA_FRAGMENT = gql`
  fragment Coa on mdCoa {
    coa_id
    coa_code
    coa_description
    coa_name
    coa_type
    coa_statement
    coa_balance
    coa_date
    is_global
    is_sub
    is_auto_prefix
    header_coa
  }
`;

export const COA_LIST = gql`
  query getListCoa($input: CoaPaginationInput) {
    coa {
      findAll(input: $input) {
        ...Coa
        child {
          ...Coa
          child {
            ...Coa
            child {
              ...Coa
            }
          }
        }
      }
    }
  }
  ${COA_FRAGMENT}
`;

export const COA_KAS_BANK_LIST = gql`
  query listCoaKasBank {
    coa {
      listCoaKasBank {
        nama
        balance
        tipe
        kode
      }
    }
  }
`;

export const COA_TYPE_LIST = gql`
  query listCoaType {
    coa {
      listCoaType {
        ...Lookup
      }
    }
  }
  ${LOOKUP_FRAGMENT}
`;

export const CREATE_COA = gql`
  mutation createCoa($input: NewCoaInput!) {
    coa {
      create(input: $input) {
        ...Coa
        child {
          ...Coa
          child {
            ...Coa
            child {
              ...Coa
            }
          }
        }
      }
    }
  }
  ${COA_FRAGMENT}
`;

export const UPDATE_COA = gql`
  mutation updateCoa($input: UpdateCoaInput!) {
    coa {
      update(input: $input) {
        ...Coa
        child {
          ...Coa
          child {
            ...Coa
            child {
              ...Coa
            }
          }
        }
      }
    }
  }
  ${COA_FRAGMENT}
`;

export const DELETE_COA = gql`
  mutation deleteCoa($input: Float!) {
    coa {
      delete(input: $input) {
        ...Coa
        child {
          ...Coa
          child {
            ...Coa
            child {
              ...Coa
            }
          }
        }
      }
    }
  }
  ${COA_FRAGMENT}
`;
