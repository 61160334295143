import React from "react";
import Container from "../common/container";
import TabContent from "../common/tabContent";
import CoaList from "./list";
import AddNewCoa from "./addNew";
import { COA_LIST, COA_TYPE_LIST } from "../../actions/CoaBarangAction";
import { useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useEffect } from "react";

const CoaModul = () => {
  const [tabSession, setTabSession] = React.useState([
    { label: "Lihat Data", type: "list", id: 0 },
    { label: "Buat Baru", type: "add-new", id: 1 },
  ]);
  const [activeTabId, setActiveTabId] = React.useState(1);
  const [choosenData, setChoosenData] = React.useState([]);

  const [getCoaList, { loading, error, data: dataCoa }] =
    useLazyQuery(COA_LIST);

  useEffect(() => {
    getCoaList({
      variables: {
        input: {},
      },
    });
    // eslint-disable-next-line
  }, []);

  const { data: dataCoaType } = useQuery(COA_TYPE_LIST);

  const coaTypeList = dataCoaType?.coa.listCoaType.map((type) => ({
    id: type.lv_value,
    name: type.deskripsi,
  }));

  if (error) {
    toast.error(error.message);
  }

  const activeData = tabSession.find((tab) => tab.id === activeTabId);

  return (
    <div>
      <TabContent
        active={activeTabId}
        setActive={setActiveTabId}
        tabs={tabSession}
        setTabs={setTabSession}
      />

      <Container>
        {activeData.type === "list" ? (
          <CoaList
            tabs={tabSession}
            setTabs={setTabSession}
            setActive={setActiveTabId}
            choosenData={choosenData}
            setChoosenData={setChoosenData}
            coaTypeList={coaTypeList}
            dataCoa={dataCoa}
            getCoaList={getCoaList}
            loading={loading}
          />
        ) : activeData.type === "add-new" ? (
          <AddNewCoa
            setTabOpen={setActiveTabId}
            coaTypeList={coaTypeList}
            getCoaList={getCoaList}
          />
        ) : (
          <AddNewCoa
            detail={true}
            choosenData={choosenData.find(
              (choosen) => choosen.idTab === activeTabId
            )}
            dataPerTabs={choosenData}
            setChoosenData={setChoosenData}
            tabs={tabSession}
            setTabs={setTabSession}
            tabOpen={activeTabId}
            setTabOpen={setActiveTabId}
            coaTypeList={coaTypeList}
            getCoaList={getCoaList}
          />
        )}
      </Container>
    </div>
  );
};

export default CoaModul;
