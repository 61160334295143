import React from "react";
import {
  CREATE_SATUAN_LIST,
  DELETE_SATUAN_LIST,
  UPDATE_SATUAN_LIST,
} from "../../../actions/SatuanBarangAction";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import { useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";

const AddNewSatuan = (props) => {
  const {
    tabOpen,
    setTabOpen,
    detail,
    tabs,
    setTabs,
    dataPerTabs,
    setChoosenData,
    choosenData,
    refetch,
  } = props;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nama"),
    alias: Yup.string().required("Alias"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const requiredItems = Object.values(errors);

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
  }, [errors]);

  const handleUpdateTabData = () => {
    const updatedList = dataPerTabs.filter(
      (tab) => tab.idTab !== choosenData.idTab
    );
    setChoosenData(updatedList);
  };

  const [createSatuan, { loading }] = useMutation(CREATE_SATUAN_LIST, {
    onCompleted: (resp) => {
      setTabOpen(0);
      toast.success("Sukses menambahkan data");
      refetch({
        variables: {
          input: {
            nama: "",
          },
        },
      });
    },
    onError: (resp) => {
      toast.error(resp.message);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: SATUAN_LIST,
    //     variables: {
    //       input: {
    //         nama: "",
    //       },
    //     },
    //   });
    //   cache.writeQuery({
    //     query: SATUAN_LIST,
    //     variables: {
    //       input: {
    //         nama: "",
    //       },
    //     },
    //     data: {
    //       barang: {
    //         getSatuanBarang: {
    //           data: [
    //             ...list.barang.getSatuanBarang.data,
    //             data.barang.createSatuanBarang,
    //           ],
    //         },
    //       },
    //     },
    //   });
    // },
  });

  const [deleteSatuan] = useMutation(DELETE_SATUAN_LIST, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch({
        variables: {
          input: {
            nama: "",
          },
        },
      });
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: SATUAN_LIST,
    //     variables: {
    //       input: {
    //         nama: "",
    //       },
    //     },
    //   });
    //   const updatedList = list.barang.getSatuanBarang.data.filter(
    //     (uom) => uom.uom_id !== choosenData.uom_id
    //   );
    //   cache.writeQuery({
    //     query: SATUAN_LIST,
    //     variables: {
    //       input: {
    //         nama: "",
    //       },
    //     },
    //     data: {
    //       barang: {
    //         getSatuanBarang: {
    //           data: updatedList,
    //         },
    //       },
    //     },
    //   });
    // },
  });

  const [ubahSatuan, { loading: loadingUpdate }] = useMutation(
    UPDATE_SATUAN_LIST,
    {
      onCompleted: (resp) => {
        setTabOpen(0);
        toast.success("Sukses mengubah data");
        setTabs(tabs.filter((tab) => tab.id !== tabOpen));
        handleUpdateTabData();
        refetch({
          variables: {
            input: {
              nama: "",
            },
          },
        });
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  React.useEffect(() => {
    if (detail) {
      setValue("name", choosenData.uom_name);
      setValue("alias", choosenData.uom_alias);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const onSubmit = (data) => {
    if (detail) {
      ubahSatuan({
        variables: {
          input: {
            id: choosenData.uom_id,
            nama: data.name,
            alias: data.alias,
          },
        },
      });
    } else {
      createSatuan({
        variables: {
          input: {
            nama: data.name,
            alias: data.alias,
          },
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteSatuan({
      variables: {
        input: choosenData.uom_id,
      },
    });
  };

  return (
    <div>
      <div className="left-content-module">
        <div className="main-content-module detail-page full-tab">
          <div className="detail-data-wrap">
            <div className="content-wrap">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-wrap">
                  <div className="form-container">
                    <div className="title">Nama Satuan</div>

                    <div className="auto-form-container">
                      <input
                        type="text"
                        required
                        placeholder="Masukkan nama satuan"
                        {...register("name", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="form-container">
                    <div className="title">Alias</div>

                    <div className="auto-form-container">
                      <input
                        type="text"
                        required
                        placeholder="Masukkan nama alias"
                        {...register("alias", { required: true })}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!--CONTENT RIGHT--> */}
      <div className="right-content-module">
        <div className="data-action-wrap">
          <div
            className={`data-action-button green-bg ${
              (loading || loadingUpdate) && "disabled"
            }`}
            onClick={handleSubmit(onSubmit)}
          >
            <span className="icon icon-simpan_ic"></span>
            Simpan
          </div>

          {detail && (
            <div
              className="data-action-button red-bg"
              onClick={() => setOpenDelete(true)}
            >
              <span className="icon icon-hapus_ic"></span>
              Hapus
            </div>
          )}
        </div>
      </div>
      <div className="clear"></div>

      <ModalConfirm show={openDelete}>
        <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
        <ModalBody>
          <p>
            Apakah Anda yakin akan melakukan penghapusan data: Satuan{" "}
            {choosenData?.uom_name}?
          </p>
        </ModalBody>
        <ModalFooter>
          <div className="button btn-red flex-center" onClick={confirmDelete}>
            Hapus
          </div>
          <div
            className="button btn-transparent flex-center"
            onClick={() => setOpenDelete(false)}
          >
            Batal
          </div>
        </ModalFooter>
      </ModalConfirm>

      <ModalConfirm show={openError}>
        <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
        <ModalBody>
          <p>
            Masih memerlukan inputan ini :
            <ul>
              {requiredItems.map((te) => (
                <li>{te.message}</li>
              ))}
            </ul>
          </p>
        </ModalBody>
        <ModalFooter>
          <div
            className="button btn-transparent flex-center"
            onClick={() => setOpenError(false)}
          >
            Tutup
          </div>
        </ModalFooter>
      </ModalConfirm>
    </div>
  );
};

export default AddNewSatuan;
