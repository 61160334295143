import React from "react";
import { useState } from "react";
import SearchInput from "../../common/searchInput";
import { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import {
  CREATE_GUDANG,
  DELETE_GUDANG,
  UPDATE_GUDANG,
} from "../../../actions/GudangAction";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import {
  ACCOUNT_GROUP_LIST,
  ACCOUNT_LIST,
} from "../../../actions/AccountAction";

const AddNewGudang = (props) => {
  const {
    detail,
    choosenData,
    dataPerTabs,
    setChoosenData,
    tabs,
    setTabs,
    tabOpen,
    setTabOpen,
    refetch,
  } = props;

  const [tab, setTab] = useState(0);
  const [isStorage, setIsStorage] = useState(false);
  const [isNonActive, setIsNonActive] = useState(false);
  const [isAllAccess, setIsAllAccess] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);

  const validationSchema = Yup.object().shape({
    postcode: Yup.string().required("Kode gudang"),
    name: Yup.string().required("Nama"),
    address: Yup.string().required("Alamat"),
    description: Yup.string().nullable(),
    city: Yup.string().required("Kota"),
    province: Yup.string().required("Provinsi"),
    // pic: Yup.string().required("Penanggung Jawab"),
    // group_branch: Yup.string().nullable(),
    // user_access: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = dataPerTabs.filter(
      (tab) => tab.idTab !== choosenData.idTab
    );
    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    if (detail) {
      setValue("postcode", choosenData.kodepos);
      setValue("address", choosenData.alamat);
      setValue("city", choosenData.kota);
      setValue("description", choosenData.deskripsi);
      setValue("group_branch", choosenData.group?.kode_group);
      setValue("name", choosenData.nama_gudang);
      setValue("pic", choosenData.penanggung_jawab?.username);
      setValue("province", choosenData.provinsi);
      setValue("user_access", choosenData.pengguna?.username);
      setIsStorage(choosenData.is_gudang_rusak);
      // setIsAllAccess(choosenData.is_all_access);
      setIsNonActive(choosenData.is_non_aktif);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const { data: dataAkun } = useQuery(ACCOUNT_LIST);

  const { data: dataGrupAkun } = useQuery(ACCOUNT_GROUP_LIST);

  const [createGudang, { loading }] = useMutation(CREATE_GUDANG, {
    onCompleted: () => {
      toast.success("Data gudang berhasil ditambahkan");
      setTabOpen(0);
      refetch({
        variables: {
          input: {},
        },
      });
    },
    onError: (resp) => {
      toast.error(resp.message);
    },
  });

  const [deleteGudang] = useMutation(DELETE_GUDANG, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch({
        variables: {
          input: {},
        },
      });
    },
    onError: (resp) => {
      toast.error(resp);
    },
  });

  const [ubahGudang, { loading: loadingUpdate }] = useMutation(UPDATE_GUDANG, {
    onCompleted: (resp) => {
      setTabOpen(0);
      toast.success("Sukses mengubah data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch({
        variables: {
          input: {},
        },
      });
    },
    onError: (resp) => {
      toast.error(resp);
    },
  });

  const accountOption = dataAkun?.akun.findAll.map((akun) => ({
    id: akun.username,
    name: akun.nama_user,
  }));
  const accountGroupOption = dataGrupAkun?.groupakun.findAll.map((grup) => ({
    id: grup.kode_group,
    name: grup.deskripsi,
  }));

  const onSubmit = (data) => {
    const inputs = {
      nama: data.name,
      deskripsi: data.description,
      alamat: data.address,
      kota: data.city,
      provinsi: data.province,
      kodePos: data.postcode,
      isGudangRusak: isStorage,
      isNonAktif: isNonActive,
      // isAllAkses: isAllAccess,
      // penanggungJawab: data.pic,
      // group: data.group_branch,
      // pengguna: data.user_access,
    };
    if (detail) {
      ubahGudang({
        variables: {
          input: { kode: choosenData.kode_gudang, ...inputs },
        },
      });
    } else {
      createGudang({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteGudang({
      variables: {
        input: choosenData.kode_gudang,
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="left-content-module">
          <div className="main-content-module detail-page full-tab">
            <div className="flex-center">
              <div
                className={`tab-list ${tab === 0 && "active"}`}
                onClick={() => setTab(0)}
              >
                Gudang
              </div>
              <div
                className={`tab-list ${tab === 1 && "active"}`}
                onClick={() => setTab(1)}
              >
                Alamat
              </div>
              {/* <div
                className={`tab-list ${tab === 2 && "active"}`}
                onClick={() => setTab(2)}
              >
                Pengguna
              </div> */}
            </div>

            {tab === 0 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <form id="" className="">
                    <div className="form-wrap">
                      <div className="form-container">
                        <div className="title">Nama Gudang</div>

                        <div className="auto-form-container">
                          <input
                            required
                            type="text"
                            placeholder="Masukkan Nama Barang"
                            {...register("name", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="title">Deskripsi</div>

                        <div className="auto-form-container">
                          <textarea
                            rows="4"
                            cols="50"
                            placeholder="Tuliskan deskripsi di sini"
                            {...register("description")}
                          ></textarea>
                        </div>
                      </div>

                      {/* <SearchInput
                        title="Penanggung Jawab"
                        placeholder="Cari nama akun"
                        value={watch("pic")}
                        setValue={(e) => setValue("pic", e)}
                        searchData={accountOption}
                      /> */}

                      <div className="form-container flex-center w-100">
                        <div className="ppn-desc">
                          <div>Gudang Barang Rusak</div>
                          {isStorage ? <div>Ya</div> : <div>Tidak</div>}
                        </div>
                        <div
                          className={`toggle-btn ${
                            isStorage ? "enable" : "disable"
                          }`}
                          onClick={() => setIsStorage(!isStorage)}
                        ></div>
                      </div>

                      <div className="form-container flex-center w-100">
                        <div className="ppn-desc">
                          <div>Non Aktif</div>
                          {isNonActive ? <div>Ya</div> : <div>Tidak</div>}
                        </div>
                        <div
                          className={`toggle-btn ${
                            isNonActive ? "enable" : "disable"
                          }`}
                          onClick={() => setIsNonActive(!isNonActive)}
                        ></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {tab === 1 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="form-wrap">
                    <div className="form-container">
                      <div className="title">Alamat</div>

                      <div className="auto-form-container">
                        <textarea
                          required
                          rows="4"
                          cols="50"
                          placeholder="Tuliskan Alamat di sini"
                          {...register("address", { required: true })}
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-container">
                      <div className="title">Kota</div>

                      <div className="auto-form-container">
                        <input
                          required
                          type="text"
                          placeholder="Masukkan Kota"
                          {...register("city", { required: true })}
                        />
                      </div>
                    </div>

                    <div className="form-container">
                      <div className="title">Kode Pos</div>

                      <div className="auto-form-container">
                        <input
                          required
                          type="number"
                          placeholder="Masukkan Kode Pos"
                          {...register("postcode", { required: true })}
                        />
                      </div>
                    </div>

                    <div className="form-container">
                      <div className="title">Provinsi</div>

                      <div className="auto-form-container">
                        <input
                          required
                          type="text"
                          placeholder="Masukkan Provinsi"
                          {...register("province", { required: true })}
                        />
                      </div>
                    </div>

                    {/* <div className="form-container">
                    <div className="title">Negara</div>

                    <div className="auto-form-container">
                      <input
                        type="text"
                        placeholder="Masukkan Negara"
                        value={dataGudang.country}
                        onChange={(e) =>
                          setDataGudang({
                            ...dataGudang,
                            country: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            )}

            {tab === 2 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="col-fulltab-50">
                    <div className="form-wrap">
                      <div className="form-container flex-center w-100">
                        <div className="ppn-desc">
                          <div>Akses Semua Pengguna</div>
                          {isAllAccess ? <div>Ya</div> : <div>Tidak</div>}
                        </div>
                        <div
                          className={`toggle-btn ${
                            isAllAccess ? "enable" : "disable"
                          }`}
                          onClick={() => setIsAllAccess(!isAllAccess)}
                        ></div>
                      </div>
                    </div>

                    {!isAllAccess && (
                      <div className="form-wrap">
                        <div className="section-title">
                          Hak akses pengguna Gudang
                        </div>

                        <SearchInput
                          title="Group/Cabang"
                          placeholder="Cari group/cabang"
                          value={watch("group_branch")}
                          setValue={(e) => setValue("group_branch", e)}
                          searchData={accountGroupOption}
                        />

                        <SearchInput
                          title="Pengguna"
                          placeholder="Cari nama"
                          value={watch("user_access")}
                          setValue={(e) => setValue("user_access", e)}
                          searchData={accountOption}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${
                (loading || loadingUpdate) && "disabled"
              }`}
              onClick={handleSubmit(onSubmit)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>

            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Gudang{" "}
              {choosenData?.nama_gudang}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewGudang;
