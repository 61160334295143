import { gql } from "@apollo/client";

export const SATUAN_FRAGMENT = gql`
  fragment Satuan on mSatuanBarang {
    uom_id
    uom_name
    uom_alias
  }
`;

export const SATUAN_LIST = gql`
  query getListSatuan($input: SatuanBarangPaginationInput) {
    barang {
      getSatuanBarang(input: $input) {
        count
        data {
          ...Satuan
        }
      }
    }
  }
  ${SATUAN_FRAGMENT}
`;

export const CREATE_SATUAN_LIST = gql`
  mutation createSatuan($input: NewSatuanBarangInput!) {
    barang {
      createSatuanBarang(input: $input) {
        ...Satuan
      }
    }
  }
  ${SATUAN_FRAGMENT}
`;

export const UPDATE_SATUAN_LIST = gql`
  mutation updateSatuan($input: UpdateSatuanBarangInput!) {
    barang {
      updateSatuanBarang(input: $input) {
        ...Satuan
      }
    }
  }
  ${SATUAN_FRAGMENT}
`;

export const DELETE_SATUAN_LIST = gql`
  mutation deleteSatuan($input: Float!) {
    barang {
      deleteSatuanBarang(input: $input)
    }
  }
`;
