import React from "react";

const ModalHeader = (props) => {
  const { children, onClose } = props;

  return (
    <div className="window-nav">
      {children}

      <div className="close-btn flex-center" onClick={onClose}>
        <span className="icon icon-close_ic"></span>
      </div>
    </div>
  );
};

export default ModalHeader;
