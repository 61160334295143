import React from "react";
import TabContent from "../common/tabContent";
import Container from "../common/container";
import GudangList from "./list";
import AddNewGudang from "./addNew";
import { GUDANG_LIST } from "../../actions/GudangAction";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useEffect } from "react";

const GudangModul = () => {
  const [tabSession, setTabSession] = React.useState([
    { label: "Lihat Data", type: "list", id: 0 },
    { label: "Buat Baru", type: "add-new", id: 1 },
  ]);
  const [activeTabId, setActiveTabId] = React.useState(1);
  const [choosenData, setChoosenData] = React.useState([]);

  const [getGudangList, { loading, error, data: dataGudang, refetch }] =
    useLazyQuery(GUDANG_LIST);

  useEffect(() => {
    getGudangList({
      variables: {
        input: {},
      },
    });
    // eslint-disable-next-line
  }, []);

  if (error) {
    toast.error(error.message);
  }

  const gudangList = dataGudang?.Gudang.findAll;

  const activeData = tabSession.find((tab) => tab.id === activeTabId);

  return (
    <div>
      <TabContent
        active={activeTabId}
        setActive={setActiveTabId}
        tabs={tabSession}
        setTabs={setTabSession}
      />

      <Container>
        {activeData.type === "list" ? (
          <GudangList
            tabs={tabSession}
            setTabs={setTabSession}
            setActive={setActiveTabId}
            choosenData={choosenData}
            setChoosenData={setChoosenData}
            gudangList={gudangList}
            getGudangList={getGudangList}
            loading={loading}
          />
        ) : activeData.type === "add-new" ? (
          <AddNewGudang setTabOpen={setActiveTabId} refetch={refetch} />
        ) : (
          <AddNewGudang
            detail={true}
            choosenData={choosenData.find(
              (choosen) => choosen.idTab === activeTabId
            )}
            dataPerTabs={choosenData}
            setChoosenData={setChoosenData}
            tabs={tabSession}
            setTabs={setTabSession}
            tabOpen={activeTabId}
            setTabOpen={setActiveTabId}
            refetch={refetch}
          />
        )}
      </Container>
    </div>
  );
};

export default GudangModul;
