import { gql } from "@apollo/client";

export const TRANSFER_DETAIL_FRAGMENT = gql`
  fragment TransferDetail on mPemindahanItem {
    namaBarang
    kodeBarang
    qty
    satuan
    kodeSatuan
  }
`;

export const TRANSFER_GOODS_FRAGMENT = gql`
  fragment TransferGoods on mPemindahanBarang {
    input_dt
    noPemindahan
    tglPemindahan
    noKirim
    tipeProses
    gudangDari
    kodeGudangDari
    gudangKe
    kodeGudangKe
    status
    keterangan
    detail {
      ...TransferDetail
    }
  }
  ${TRANSFER_DETAIL_FRAGMENT}
`;

export const TRANSFER_GOODS_LIST = gql`
  query getTransferGoodsList {
    PemindahanBarang {
      findAll {
        ...TransferGoods
      }
    }
  }
  ${TRANSFER_GOODS_FRAGMENT}
`;

export const CREATE_TRANSFER_GOODS = gql`
  mutation createTransferGoods($input: NewPemindahanInput!) {
    PemindahanBarang {
      create(input: $input) {
        ...TransferGoods
      }
    }
  }
  ${TRANSFER_GOODS_FRAGMENT}
`;

export const UPDATE_TRANSFER_GOODS = gql`
  mutation editTransferGoods($input: UpdatePemindahanInput!) {
    PemindahanBarang {
      update(input: $input) {
        ...TransferGoods
      }
    }
  }
  ${TRANSFER_GOODS_FRAGMENT}
`;

export const DELETE_TRANSFER_GOODS = gql`
  mutation deleteTransferGoods($input: String!) {
    PemindahanBarang {
      delete(input: $input)
    }
  }
`;
