import React from "react";
import { useState } from "react";
import SearchInput from "../../../common/searchInput";
import { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalConfirm from "../../../common/modal/modalConfirm";
import ModalHeader from "../../../common/modal/modalHeader";
import ModalBody from "../../../common/modal/modalBody";
import ModalFooter from "../../../common/modal/modalFooter";
import {
  CREATE_SUPPLIER_CATEGORY,
  DELETE_SUPPLIER_CATEGORY,
  SUPPLIER_CATEGORY_LIST,
  UPDATE_SUPPLIER_CATEGORY,
} from "../../../../actions/SupplierCategoryAction";

const AddNewSupplierCategory = (props) => {
  const {
    tabOpen,
    setTabOpen,
    detail,
    tabs,
    setTabs,
    dataPerTabs,
    setChoosenData,
    choosenData,
    refetch,
  } = props;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nama barang"),
    parent_id: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      parent_id: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [tab, setTab] = useState(0);
  const [isDefault, setIsDefault] = useState(false);

  const [isSubCategory, setIsSubCategory] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = dataPerTabs.filter(
      (tab) => tab.idTab !== choosenData.idTab
    );
    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  const { data: dataCategory } = useQuery(SUPPLIER_CATEGORY_LIST);

  const [createCategory, { loading }] = useMutation(CREATE_SUPPLIER_CATEGORY, {
    onCompleted: (resp) => {
      toast.success("Kategori berhasil dibuat");
      setTabOpen(0);
      refetch();
    },
    onError: (resp) => {
      toast.error(resp.message);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: [
    //           ...list.barang.getKategoriBarang,
    //           data.barang.createKategoriBarang,
    //         ],
    //       },
    //     },
    //   });
    // },
  });

  const [deleteCategory] = useMutation(DELETE_SUPPLIER_CATEGORY, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   const updatedList = list.barang.getKategoriBarang.filter(
    //     (category) =>
    //       category.kategori_barang_id !== choosenData.kategori_barang_id
    //   );
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: updatedList,
    //       },
    //     },
    //   });
    // },
  });

  const [ubahCategory, { loading: loadingUpdate }] = useMutation(
    UPDATE_SUPPLIER_CATEGORY,
    {
      onCompleted: (resp) => {
        setTabOpen(0);
        toast.success("Sukses mengubah data");
        setTabs(tabs.filter((tab) => tab.id !== tabOpen));
        handleUpdateTabData();
        refetch();
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  const parentCategoriesList = dataCategory?.Pemasok.listKategori.flatMap(
    (category) => {
      const parentCategories = {
        id: category.kategori_pemasok_id,
        name: category.nama_kategori,
      };
      const childCategories = category.child.map((categoryChild) => ({
        id: categoryChild.kategori_pemasok_id,
        name: categoryChild.nama_kategori,
      }));
      return [parentCategories, ...childCategories];
    }
  );
  const parentCategoriesOption = detail
    ? parentCategoriesList.filter(
        (category) => category.id !== choosenData?.kategori_pemasok_id
      )
    : parentCategoriesList;

  useEffect(() => {
    if (detail) {
      setValue("name", choosenData.nama_kategori);
      setValue(
        "parent_id",
        choosenData.parent_id === (null || 0) ? "" : choosenData.parent_id
      );
      setIsDefault(choosenData.is_default);
      setIsSubCategory(choosenData.is_sub);
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const handleSwitch = () => {
    if (parentCategoriesOption.length > 0) {
      setIsSubCategory(!isSubCategory);
    } else {
      alert("belum ada data kategori utama");
    }
  };

  const onSubmit = (data) => {
    const inputs = {
      nama: data.name,
      is_default: isDefault,
      is_sub: isSubCategory,
      header_kategori: parseFloat(data.parent_id || 0),
    };
    if (detail) {
      ubahCategory({
        variables: {
          input: {
            kategori_pemasok_id: parseFloat(choosenData.kategori_pemasok_id),
            ...inputs,
          },
        },
      });
    } else {
      createCategory({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteCategory({
      variables: {
        input: parseFloat(choosenData.kategori_pemasok_id),
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="left-content-module">
          <div className="main-content-module detail-page full-tab">
            {/* <!--menu tab tambahkan active class pada div yg sedang terbuka--> */}
            <div className="flex-center">
              <div
                className={`tab-list ${tab === 0 && "active"}`}
                onClick={() => setTab(0)}
              >
                Kategori Pemasok
              </div>
            </div>

            {/* <!--detail menu tab pertama--> */}
            {tab === 0 && (
              <div className="detail-data-wrap">
                <div className="content-wrap">
                  <div className="form-wrap">
                    <div className="form-container">
                      <div className="title">Nama Kategori</div>

                      <div className="auto-form-container">
                        <input
                          required
                          type="text"
                          placeholder="Masukkan nama"
                          {...register("name", { required: true })}
                        />
                      </div>
                    </div>

                    <div className="form-container flex-center w-100">
                      <div className="ppn-desc">
                        <div>Kategori Default</div>
                        {isDefault ? <div>Ya</div> : <div>Tidak</div>}
                      </div>
                      <div
                        className={`toggle-btn ${
                          isDefault ? "enable" : "disable"
                        }`}
                        onClick={() => setIsDefault(!isDefault)}
                      ></div>
                    </div>

                    <div className="form-container flex-center w-100">
                      <div className="ppn-desc">
                        <div>Sub Kategori</div>
                        {isSubCategory ? <div>Ya</div> : <div>Tidak</div>}
                      </div>
                      <div
                        className={`toggle-btn ${
                          isSubCategory ? "enable" : "disable"
                        }`}
                        onClick={handleSwitch}
                      ></div>
                    </div>

                    {isSubCategory && (
                      <SearchInput
                        title="Kategori Utama"
                        placeholder="Cari kategori utama"
                        value={watch("parent_id")}
                        setValue={(e) => setValue("parent_id", e)}
                        searchData={parentCategoriesOption}
                      />
                    )}

                    <div className="form-container" />
                    <div className="form-container" />
                    <div className="form-container" />
                    <div className="form-container" />
                    <div className="form-container" />
                    <div className="form-container" />
                    <div className="form-container" />
                    <div className="form-container" />
                    <div className="form-container" />
                    <div className="form-container" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${
                (loading || loadingUpdate) && "disabled"
              }`}
              onClick={handleSubmit(onSubmit)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>

            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Kategori{" "}
              {choosenData?.nama_kategori}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewSupplierCategory;
