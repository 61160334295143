import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchInput from "../../common/searchInput";
import { useMutation, useQuery } from "@apollo/client";
import { SATUAN_LIST } from "../../../actions/SatuanBarangAction";
import { GOODS_AND_SERVICE_LIST } from "../../../actions/BarangAction";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import { toast } from "react-toastify";
import { GUDANG_LIST } from "../../../actions/GudangAction";
import { preventZero } from "../../utils";
import { SUPPLIER_LIST } from "../../../actions/SupplierAction";
import {
  CREATE_RECEIVE_ORDER,
  DELETE_RECEIVE_ORDER,
  UPDATE_RECEIVE_ORDER,
} from "../../../actions/ReceiveOrderAction";
import { format } from "date-fns";
import { PURCHASE_ORDER_LIST } from "../../../actions/PurchaseOrderAction";
import { useStateContext } from "../../../context";
import { menuList } from "../../utils/MenuList";

const AddNewReceiveOrder = (props) => {
  const {
    detail,
    choosenData,
    dataPerTabs,
    setChoosenData,
    tabs,
    setTabs,
    tabOpen,
    setTabOpen,
    refetch,
  } = props;

  const datePickerRef = useRef(null);
  const datePickerSendRef = useRef(null);
  const hiddenButtonRef = useRef(null);

  const redirectingData = JSON.parse(localStorage.getItem("redirectingData"));

  const [state, setState] = useStateContext();

  const [tab, setTab] = useState(0);
  const [modalTab, setModalTab] = useState(0);
  const [show, setShow] = useState({
    menu: false,
    bebanModal: false,
    modal: false,
    ambil: false,
    attachment: false,
    proses: false,
  });
  const [productToAdd, setProductToAdd] = useState({
    code: "",
    name: "",
    quantity: 0,
    satuan: null,
    gudang: null,
    remarks: "",
    mode: "c",
  });
  const [productPhoto, setProductPhoto] = useState(null);
  const [productList, setProductList] = useState([]);
  const [permintaanList, setPermintaanList] = useState([]);
  const [choosenId, setChoosenId] = useState(0);
  const [editProduct, setEditProduct] = useState(false);
  const [satuanOption, setSatuanOption] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [selectedReceiveOrder, setSelectedReceiveOrder] = useState({
    gudang: null,
    alamat: "",
    tanggal_kirim: "",
    pemasok: null,
  });
  const [photo, setPhoto] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [redirectMode, setRedirectMode] = useState(false);

  const validationSchema = Yup.object().shape({
    tglPenerimaan: Yup.string().required("Tanggal Penerimaan"),
    pemasok: Yup.string().required("Pemasok"),
    nomorPenerimaan: Yup.string().nullable(),
    nomorPesanan: Yup.string().nullable(),
    tglKirim: Yup.string().required("Tanggal Kirim"),
    gudang: Yup.string().required("Gudang"),
    alamatTerima: Yup.string().required("Alamat Terima"),
    keterangan: Yup.string().nullable(),
    detail:
      productList.length > 0
        ? Yup.string().nullable()
        : Yup.string().required("Detail barang"),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      parent_id: "",
      pemasok: redirectingData
        ? redirectingData.kodeSupplier
        : detail
        ? choosenData.pemasok.kode_supplier
        : "",
      nomorPesanan: redirectingData
        ? redirectingData.nomorPesanan
        : detail
        ? choosenData.nomor_pesanan
        : "",
      gudang: redirectingData
        ? redirectingData.gudang
        : detail
        ? choosenData.gudang_penerima.kode_gudang
        : "",
      alamatTerima: redirectingData
        ? redirectingData.alamatTerima
        : detail
        ? `${choosenData.gudang_penerima.alamat}, ${choosenData.gudang_penerima.kota}, ${choosenData.gudang_penerima.provinsi} \n${choosenData.gudang_penerima.kodepos}`
        : "",
      tglKirim: redirectingData
        ? redirectingData.tanggalKirim
        : detail
        ? format(new Date(choosenData.tgl_kirim), "MM/dd/yyyy")
        : "",
    },
    resolver: yupResolver(validationSchema),
  });

  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = [...dataPerTabs];
    updatedList.splice(tabOpen, 1);

    setChoosenData(updatedList);
  };

  // useEffect(() => {
  //   if (!redirectingData && productList.length === 0) {
  //     setValue("nomorPesanan", "");
  //     setValue("gudang", null);
  //     setValue("alamatTerima", null);
  //     setValue("tglKirim", null);
  //   }
  //   // eslint-disable-next-line
  // }, [productList]);

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    $(datePickerRef.current).datepicker({
      onSelect: (dateText) => {
        setValue("tglPenerimaan", dateText);
      },
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tab === 1) {
      $(datePickerSendRef.current).datepicker({
        onSelect: (dateTexts) => {
          setValue("tglKirim", dateTexts);
        },
      });
    }
    // eslint-disable-next-line
  }, [tab]);
  const { data: satuanData } = useQuery(SATUAN_LIST, {
    variables: {
      input: {
        nama: "",
      },
    },
  });
  const { data: gudangData } = useQuery(GUDANG_LIST, {
    variables: {
      input: {},
    },
  });
  const { data: supplierData } = useQuery(SUPPLIER_LIST, {
    variables: {
      input: {},
    },
  });
  const { data: dataBarangJasa } = useQuery(GOODS_AND_SERVICE_LIST);
  const { data: dataRequestOrder } = useQuery(PURCHASE_ORDER_LIST);
  const [createReceiveOrder, { loading }] = useMutation(CREATE_RECEIVE_ORDER, {
    onCompleted: (resp) => {
      let formData = new FormData();

      formData.append("file", photo);
      formData.append("id", resp.PenerimaanBarang.create.nomor_penerimaan);
      formData.append("deskripsi", documentName);

      fetch(`${process.env.REACT_APP_BACKEND_URL}ri/upload`, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          toast.success("Penerimaan barang berhasil dibuat");
          refetch();

          if (redirectMode) {
            let menus = state.menuTabs;

            menus.push(menuList[5].child.row2[1]);

            setState({
              ...state,
              menuTabs: [...menus],
              idTabModule: 14,
            });

            localStorage.setItem(
              "redirectingData",
              JSON.stringify({
                nomorPenerimaan: resp.PenerimaanBarang.create.nomor_penerimaan,
              })
            );
          } else {
            setTabOpen(0);
          }
        })
        .catch(() => toast.error("Data gagal dibuat"));
    },
    onError: (resp) => {
      toast.error(resp.message);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: [
    //           ...list.barang.getKategoriBarang,
    //           data.barang.createKategoriBarang,
    //         ],
    //       },
    //     },
    //   });
    // },
  });

  const [deleteRequestOrder] = useMutation(DELETE_RECEIVE_ORDER, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   const updatedList = list.barang.getKategoriBarang.filter(
    //     (category) =>
    //       category.kategori_barang_id !== choosenData.kategori_barang_id
    //   );
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: updatedList,
    //       },
    //     },
    //   });
    // },
  });

  const [ubahRequestOrder, { loading: loadingUpdate }] = useMutation(
    UPDATE_RECEIVE_ORDER,
    {
      onCompleted: (resp) => {
        toast.success("Sukses mengubah data");
        setTabs(tabs.filter((tab) => tab.id !== tabOpen));
        handleUpdateTabData();
        refetch();

        if (redirectMode) {
          let menus = state.menuTabs;

          menus.push(menuList[5].child.row2[1]);

          setState({
            ...state,
            menuTabs: [...menus],
            idTabModule: 14,
          });

          localStorage.setItem(
            "redirectingData",
            JSON.stringify({
              nomorPenerimaan: resp.PenerimaanBarang.update.nomor_penerimaan,
            })
          );
        } else {
          setTabOpen(0);
        }
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  const supplierList = supplierData?.Pemasok.findAll.map((pemasok) => ({
    id: pemasok.kode_supplier,
    name: `${pemasok.nama}`,
    desc: pemasok.kode_supplier,
  }));
  const dataSatuan = satuanData?.barang.getSatuanBarang.data.map((satuan) => ({
    id: satuan.uom_id,
    name: `${satuan.uom_alias} - ${satuan.uom_name}`,
    alias: satuan.uom_name,
  }));
  const barangJasaList = dataBarangJasa?.barang.findAll.data.map((barang) => ({
    id: barang.kode_barang,
    name: barang.nama_barang,
    ...barang,
  }));
  const dataGudang = gudangData?.Gudang.findAll.map((gudang) => ({
    id: gudang.kode_gudang,
    name: `Gudang ${gudang.nama_gudang}`,
    desc: gudang.alamat,
    ...gudang,
  }));
  const purchaseOrderList = dataRequestOrder?.PesananPembelian.findAll
    .filter((purchase) => purchase.nomor_pesanan !== watch("nomorPesanan"))
    .map((purchase) => ({
      id: purchase.nomor_pesanan,
      name: purchase.nomor_pesanan,
      desc: `${format(new Date(purchase.tgl_pesanan), "dd/MM/yyyy")} - ${
        purchase.cabang.nama_cabang
      }`,
      detail: purchase.detail_item,
      gudang_penerima: purchase.gudang_penerima,
      tgl_kirim: purchase.tgl_kirim,
      pemasok: purchase.pemasok,
    }));

  useEffect(() => {
    if (redirectingData !== null) {
      setProductList(redirectingData.detailProduk);

      localStorage.removeItem("redirectingData");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (detail && choosenData) {
      setValue(
        "tglPenerimaan",
        format(new Date(choosenData.tgl_terima), "MM/dd/yyyy")
      );
      // setValue("pemasok", choosenData.pemasok.kode_supplier);
      setValue("nomorPenerimaan", choosenData.nomor_terima);
      // setValue("nomorPesanan", choosenData.nomor_pesanan);
      // setValue(
      //   "tglKirim",
      //   format(new Date(choosenData.tgl_kirim), "MM/dd/yyyy")
      // );
      // setValue("gudang", choosenData.gudang_penerima.kode_gudang);
      // setValue(
      //   "alamatTerima",
      //   `${choosenData.gudang_penerima.alamat}, ${choosenData.gudang_penerima.kota}, ${choosenData.gudang_penerima.provinsi} \n${choosenData.gudang_penerima.kodepos}`
      // );
      setValue("keterangan", choosenData.keterangan);
      setProductList(
        choosenData.detail_item.map((item) => ({
          code: item.barang.kode_barang,
          name: item.barang.nama_barang,
          quantity: item.qty,
          satuan: item.satuan.uom_id,
          remarks: item.keterangan,
          gudang: item.gudang_penerima.kode_gudang,
          mode: "e",
        }))
      );

      // let formData = new FormData();

      // formData.append("id", choosenData.nomor_penerimaan);

      fetch(`${process.env.REACT_APP_BACKEND_URL}ri/upload/file`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: choosenData.nomor_penerimaan,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          const {
            data: { deskripsi, img },
          } = data;

          setDocumentName(deskripsi);

          fetch(`${process.env.REACT_APP_BACKEND_URL}${img}`)
            .then((res) => res.blob())
            .then((data) => setPhoto(data));
        })
        .catch(() => toast.error("Data gagal dibuat"));
    } else {
      reset();
      setDocumentName("");
      setPhoto(null);
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const handleRequestCheckbox = (value, i) => {
    let temp = permintaanList[i];
    temp.selected = value;
    permintaanList[i] = temp;
    setPermintaanList([...permintaanList]);
  };

  const handleGetSatuanOption = (selectedBarang) => {
    let tempSatuanOption = selectedBarang.detail_harga.map((harga) => ({
      id: harga.uom.uom_id,
      name: `${harga.uom.uom_alias} - ${harga.uom.uom_name}`,
      alias: harga.uom.uom_name,
    }));
    if (!tempSatuanOption.includes(selectedBarang.base_uom)) {
      tempSatuanOption.push({
        id: selectedBarang.base_uom.uom_id,
        name: `${selectedBarang.base_uom.uom_alias} - ${selectedBarang.base_uom.uom_name}`,
        alias: selectedBarang.base_uom.uom_name,
      });
    }
    setSatuanOption(tempSatuanOption);
  };

  const handleOpenDetail = (index) => {
    const choosenProduct = productList[index];
    const selectedBarang = barangJasaList?.find(
      (barang) => barang.id === choosenProduct.code
    );
    handleGetSatuanOption(selectedBarang);
    setProductToAdd({
      code: choosenProduct.code,
      name: choosenProduct.name,
      quantity: choosenProduct.quantity,
      satuan: choosenProduct.satuan,
      gudang: choosenProduct.gudang,
      remarks: choosenProduct.remarks,
      mode: choosenProduct.mode,
    });
    setShow({ ...show, modal: true });
    setEditProduct(true);
    setChoosenId(index);

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}barang/upload/${choosenData.code}`
    )
      .then((res) => res.blob())
      .then((data) => setProductPhoto(data));
  };

  const handleAddTableProduct = () => {
    if (editProduct) {
      const temp = productList;
      productList[choosenId] = productToAdd;
      setProductList([...temp]);
    } else {
      setProductList([...productList, productToAdd]);
    }
    setModalTab(0);
    setProductToAdd({
      code: "",
      name: "",
      quantity: 0,
      satuan: null,
      gudang: null,
      remarks: "",
      mode: "c",
    });
    setSatuanOption([]);
    setShow({ ...show, modal: false });
    setEditProduct(false);
  };

  const handleDeleteTableProduct = () => {
    const temp = [...productList];
    temp.splice(choosenId, 1);
    setProductList([...temp]);
    setEditProduct(false);
    setModalTab(0);
    setProductToAdd({
      code: "",
      name: "",
      quantity: 0,
      satuan: null,
      gudang: null,
      remarks: "",
      mode: "c",
    });
    setSatuanOption([]);
    setShow({ ...show, modal: false });
  };

  const handleAmbilPermintaan = () => {
    if (permintaanList.length > 0) {
      const { gudang, alamat, tanggal_kirim, pemasok } = selectedReceiveOrder;
      setValue("gudang", gudang);
      setValue("alamatTerima", alamat);
      setValue("tglKirim", tanggal_kirim);
      setValue("pemasok", pemasok);
      const products = productList?.concat(
        permintaanList.filter((request) => request.selected)
      );
      setProductList(products);
      setShow({
        ...show,
        ambil: false,
      });
      setPermintaanList([]);
    } else {
      toast.error("Pilih salah satu nomor permintaan untuk melanjutkan");
    }
  };

  const handleSaveRequest = (dataSubmit) => {
    const inputs = {
      tglPenerimaan: format(new Date(dataSubmit.tglPenerimaan), "yyyy/MM/dd"),
      pemasok: dataSubmit.pemasok,
      nomorPenerimaan: dataSubmit.nomorPenerimaan ?? null,
      nomorPesanan: dataSubmit.nomorPesanan ?? null,
      tglKirim: format(new Date(dataSubmit.tglKirim), "yyyy/MM/dd"),
      gudang: dataSubmit.gudang,
      alamatTerima: dataSubmit.alamatTerima,
      keterangan: dataSubmit.keterangan,
      detail: productList.map((product) => ({
        kodeBarang: product.code,
        pcs: parseFloat(product.quantity),
        uom: parseFloat(product.satuan),
        gudang: product.gudang || dataSubmit.gudang,
        remarks: product.remarks,
        mode: product.mode,
      })),
    };
    if (detail) {
      ubahRequestOrder({
        variables: {
          input: {
            ...inputs,
            id: choosenData.nomor_penerimaan,
          },
        },
      });
    } else {
      createReceiveOrder({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteRequestOrder({
      variables: {
        input: choosenData.nomor_penerimaan,
      },
    });
  };

  const handleUploadPhoto = (files) => {
    if (files && files.length > 0) {
      setPhoto(files[0]);
    }
  };

  const handleItemClick = () => {
    setRedirectMode(true);

    if (hiddenButtonRef.current) {
      hiddenButtonRef.current.click();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleSaveRequest)}>
        <div className="left-content-module">
          <div className="content-nav-module">
            <button
              ref={hiddenButtonRef}
              type="submit"
              style={{ display: "none" }}
            >
              Hidden Button
            </button>
            <form id="" className="">
              <div className="form-wrap">
                <SearchInput
                  title="Terima dari Pemasok"
                  placeholder="Cari/Pilih Pemasok"
                  value={watch("pemasok")}
                  setValue={(e) => {
                    setValue("pemasok", e);
                  }}
                  searchData={supplierList}
                />
              </div>

              <div className="form-wrap">
                <div className="form-container">
                  <div className="title">Tanggal</div>
                  <input
                    ref={datePickerRef}
                    className="form-date-input"
                    type="text"
                    placeholder="Pilh tanggal"
                    value={watch("tglPenerimaan")}
                    onChange={(e) => setValue("tglPenerimaan", e.target.value)}
                  />
                  <div className="icon-form-date icon-date_ic"></div>
                </div>
              </div>

              <div class="form-wrap">
                <div class="form-container">
                  <div class="title">No.Terima#</div>
                  <div class="auto-form-container w-fluid">
                    <input
                      name=""
                      type="text"
                      placeholder="Nomor Terima"
                      {...register("nomorPenerimaan")}
                    />
                  </div>
                </div>
              </div>

              <div className="form-right-side">
                {/* <!--RIGHT SIDE--> */}
                <div class="form-wrap">
                  <div class="form-container w-250px">
                    <div class="title">Nomor#</div>
                    <div class="auto-form-container disable w-fluid">
                      <input
                        name=""
                        type="text"
                        placeholder="Cari gudang"
                        value={watch("nomorPesanan") || "-"}
                      />
                    </div>
                  </div>
                </div>

                <div className="nav-setting-wrap">
                  <div className="list-setting">
                    <div
                      class="setting-btn more-btn"
                      onClick={() => setShow({ ...show, ambil: true })}
                    >
                      Ambil
                    </div>

                    <div
                      class="setting-btn more-btn"
                      onClick={() =>
                        setShow({
                          ...show,
                          proses: !show.proses,
                          ambil: false,
                        })
                      }
                    >
                      Proses <span class="icon-down_ic"></span>
                    </div>

                    {show.proses && (
                      <div class="popup-setting popup-right">
                        <ul>
                          <li
                            style={{
                              display: "block",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              cursor: "pointer",
                            }}
                            onClick={handleItemClick}
                          >
                            Faktur Pembelian
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                {show.ambil && (
                  <div class="window-overlay">
                    <div class="window-container">
                      <div class="window-nav">
                        <div class="title flex-center">
                          Salin dari Pesanan Pembelian
                        </div>
                        <div class="close-btn flex-center">
                          <span
                            class="icon icon-close_ic"
                            onClick={() => {
                              setShow({
                                ...show,
                                ambil: false,
                              });
                              setPermintaanList([]);
                            }}
                          ></span>
                        </div>
                      </div>
                      <div class="window-content">
                        <form id="" class="">
                          <div class="form-wrap no-float">
                            <SearchInput
                              title="Pesanan"
                              placeholder="Cari/Pilih Nomor Pesanan"
                              setValue={(e) => {
                                const choosenReceive = purchaseOrderList.find(
                                  (purchaseOrder) => purchaseOrder.id === e
                                );
                                const selectedDetail = choosenReceive.detail;
                                const selectedGudang =
                                  choosenReceive.gudang_penerima;
                                setSelectedReceiveOrder({
                                  gudang: selectedGudang.kode_gudang,
                                  alamat: `${selectedGudang.alamat}, ${selectedGudang.kota}, ${selectedGudang.provinsi} \n${selectedGudang.kodepos}`,
                                  tanggal_kirim: format(
                                    new Date(choosenReceive.tgl_kirim),
                                    "MM/dd/yyyy"
                                  ),
                                  pemasok: choosenReceive.pemasok.kode_supplier,
                                });
                                setValue("nomorPesanan", e);
                                const detailBarang = selectedDetail
                                  .filter((detail) => detail.qty_proses !== 0)
                                  .map((detail) => ({
                                    id: detail.item_pesanan_id,
                                    selected: false,
                                    code: detail.barang.kode_barang,
                                    name: detail.barang.nama_barang,
                                    quantity: detail.qty_proses,
                                    satuan: detail.satuan.uom_id,
                                    gudang: selectedGudang.kode_gudang,
                                    remarks: detail.keterangan,
                                    mode: "c",
                                  }));
                                setPermintaanList(detailBarang);
                              }}
                              searchData={purchaseOrderList}
                            />
                            <div class="clear"></div>
                          </div>
                        </form>

                        <div class="main-content-module">
                          <div class="">
                            <table id="all-data-table" class="detail-page">
                              <thead>
                                <tr>
                                  <th>
                                    <label class="checkbox">
                                      <input
                                        type="checkbox"
                                        checked={
                                          permintaanList?.filter(
                                            (request) => request.selected
                                          ).length > 0
                                        }
                                        onClick={() => {
                                          let temp = permintaanList;
                                          let a = temp.map((request) => ({
                                            ...request,
                                            selected: !request.selected,
                                          }));
                                          setPermintaanList([...a]);
                                        }}
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </th>
                                  <th>Kode #</th>
                                  <th>Nama Barang</th>
                                  <th>Kuantitas</th>
                                  <th>Satuan</th>
                                  <th>Keterangan</th>
                                </tr>
                              </thead>
                              <tbody>
                                {permintaanList?.map((request, i) => (
                                  <tr>
                                    <td>
                                      <label class="checkbox">
                                        <input
                                          type="checkbox"
                                          checked={request.selected}
                                          onClick={() =>
                                            handleRequestCheckbox(
                                              !request.selected,
                                              i
                                            )
                                          }
                                        />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>{request.code}</td>
                                    <td>{request.name}</td>
                                    <td>{request.quantity}</td>
                                    <td>
                                      {
                                        dataSatuan?.find(
                                          (satuan) =>
                                            satuan.id === request.satuan
                                        ).name
                                      }
                                    </td>
                                    <td>{request.remarks}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="window-bottom">
                        <div
                          class="button btn-blue flex-center"
                          onClick={handleAmbilPermintaan}
                        >
                          Lanjutkan
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="clear"></div>
            </form>
          </div>
          <div className="main-content-module detail-page">
            <div className="detail-data-wrap">
              <div className="wrap">
                <div
                  className={`tab-menu ${tab === 0 && "active"}`}
                  onClick={() => setTab(0)}
                >
                  <span className="icon-input_ic"></span>
                </div>
                <div
                  className={`tab-menu ${tab === 1 && "active"}`}
                  onClick={() => setTab(1)}
                >
                  <span className="icon-info_ic"></span>
                </div>
              </div>

              {tab === 0 && (
                <div className="content-wrap">
                  <div className="content-nav-module">
                    <form id="" className="">
                      <div className="form-wrap">
                        <SearchInput
                          title="Barang &amp; Jasa"
                          placeholder="Cari/Pilih Barang & Jasa"
                          setValue={(e) => {
                            const selectedBarang = barangJasaList?.find(
                              (barang) => barang.id === e
                            );
                            handleGetSatuanOption(selectedBarang);
                            setProductToAdd({
                              ...productToAdd,
                              code: selectedBarang.id,
                              name: selectedBarang.name,
                              satuan: selectedBarang.base_uom.uom_id,
                            });
                            setShow({ ...show, modal: true });
                            fetch(
                              `${process.env.REACT_APP_BACKEND_URL}barang/upload/${selectedBarang.id}`
                            )
                              .then((res) => res.blob())
                              .then((data) => setProductPhoto(data));
                          }}
                          searchData={barangJasaList}
                        />
                      </div>

                      <div className="form-right-side">
                        <div className="form-wrap"></div>
                      </div>

                      <div className="clear"></div>
                    </form>
                  </div>
                  <div className="main-content-module">
                    <table id="all-data-table" class="detail-page m-b-30">
                      <tr>
                        <th>Nama Barang</th>
                        <th>Kode #</th>
                        <th>Kuantitas</th>
                        <th>Satuan</th>
                        {/* <th>@Harga</th>
                        <th>Diskon</th>
                        <th>Total Harga</th> */}
                      </tr>
                      {productList.map((row, i) => (
                        <tr key={i}>
                          <td onClick={() => handleOpenDetail(i)}>
                            {row.name}
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {row.code}
                          </td>
                          <td
                            class="txt-right"
                            onClick={() => handleOpenDetail(i)}
                          >
                            {row.quantity}
                          </td>
                          <td
                            class="txt-right"
                            onClick={() => handleOpenDetail(i)}
                          >
                            {
                              dataSatuan?.find(
                                (satuan) => satuan.id === row.satuan
                              ).name
                            }
                          </td>
                          {/* <td onClick={() => handleOpenDetail(i)}>
                            {formatRupiah(row.price)}
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {formatRupiah(
                              row.flat ||
                                (row.price * row.quantity * row.percent) / 100
                            )}
                          </td>
                          <td onClick={() => handleOpenDetail(i)}>
                            {formatRupiah(
                              row.price * row.quantity -
                                (row.flat ||
                                  (row.price * row.quantity * row.percent) /
                                    100)
                            )}
                          </td> */}
                        </tr>
                      ))}
                    </table>
                  </div>

                  {show.modal && (
                    <>
                      {/* <!--POPUP PRODUCT --> */}
                      <div class="window-overlay">
                        <div class="window-container product-window">
                          <div class="window-nav">
                            {/* <!--CONTENT TAB--> */}
                            <div class="tab_content_module">
                              {["Rincian Barang", "Keterangan"].map(
                                (tab, i) => (
                                  <div
                                    class={`tab_menu_content ${
                                      modalTab === i && "active"
                                    }`}
                                    onClick={() => setModalTab(i)}
                                  >
                                    {tab}
                                  </div>
                                )
                              )}
                            </div>
                            <div
                              class="close-btn flex-center"
                              onClick={() => {
                                setShow({ ...show, modal: false });
                                setProductToAdd({
                                  code: "",
                                  name: "",
                                  tglDiminta: "",
                                  quantity: 0,
                                  satuan: null,
                                  remarks: "",
                                  mode: "c",
                                });
                              }}
                            >
                              <span class="icon icon-close_ic"></span>
                            </div>
                          </div>
                          <div class="window-content">
                            {modalTab === 0 && (
                              <>
                                <div className="product-img">
                                  {productPhoto !== null && (
                                    <img
                                      src={URL.createObjectURL(productPhoto)}
                                      alt={`${productToAdd.code} - ${productToAdd.name}`}
                                    />
                                  )}
                                </div>

                                <div class="form-side">
                                  <form id="" class="">
                                    <div class="form-wrap">
                                      <div className="form-container lg">
                                        <div class="title">Kode#</div>

                                        <b className="text-desc">
                                          {productToAdd.code}
                                        </b>
                                      </div>

                                      <div class="form-container lg">
                                        <div class="title">Nama</div>
                                        <div class="auto-form-container">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            value={productToAdd.name}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                name: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      {/* <div class="form-container lg">
                                        <div class="title">Merek</div>
                                        <div class="auto-form-container">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Masukkan merek barang"
                                            value={productToAdd.merek}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                merek: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div> */}

                                      <div class="form-container md">
                                        <div class="title">Kuantitas</div>
                                        <div class="auto-form-container">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            value={productToAdd.quantity}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                quantity: preventZero(e),
                                              })
                                            }
                                          />
                                        </div>
                                        <div class="icon-form-date icon-penjualan_ic"></div>
                                      </div>

                                      <SearchInput
                                        className="sm padding-l"
                                        title="Satuan"
                                        placeholder="Cari satuan"
                                        value={productToAdd.satuan}
                                        setValue={(e) =>
                                          setProductToAdd({
                                            ...productToAdd,
                                            satuan: e,
                                          })
                                        }
                                        searchData={satuanOption}
                                      />

                                      <SearchInput
                                        className="form-container lg"
                                        title="Gudang"
                                        placeholder="Cari gudang"
                                        value={productToAdd.gudang}
                                        setValue={(e) =>
                                          setProductToAdd({
                                            ...productToAdd,
                                            gudang: e,
                                          })
                                        }
                                        searchData={dataGudang}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}

                            {modalTab === 1 && (
                              <div class="content-wrap">
                                <div class="content-nav-module">
                                  <form id="" class="">
                                    <div class="col-fulltab-50">
                                      <div class="form-wrap">
                                        <div class="form-container">
                                          <div class="auto-form-container w-400px">
                                            <textarea
                                              id=""
                                              name=""
                                              rows="4"
                                              cols="50"
                                              value={productToAdd.remarks}
                                              onChange={(e) =>
                                                setProductToAdd({
                                                  ...productToAdd,
                                                  remarks: e.target.value,
                                                })
                                              }
                                              placeholder="Tuliskan keterangan di sini"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </div>
                          <div class="window-bottom">
                            <div
                              class="button btn-blue flex-center"
                              onClick={handleAddTableProduct}
                            >
                              Simpan
                            </div>
                            {editProduct && (
                              <div
                                class="button btn-red flex-center"
                                onClick={handleDeleteTableProduct}
                              >
                                Hapus
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {tab === 1 && (
                <div class="content-wrap">
                  <div class="content-nav-module full-height">
                    <form id="" class="">
                      <div class="col-fulltab-50">
                        <div class="form-wrap ">
                          <div class="section-title">Info Lainnya</div>

                          {/* <SearchInput
                            title="Syarat Pembayaran"
                            placeholder="Cari syarat pembayaran"
                            value={watch("syaratPembayaran")}
                            setValue={(e) => setValue("syaratPembayaran", e)}
                            searchData={syaratPembayaranList}
                          /> */}

                          <SearchInput
                            title="Alamat Kirim"
                            placeholder="Cari alamat kirim"
                            value={watch("gudang")}
                            setValue={(e) => {
                              const selectedGudang = dataGudang.find(
                                (gudang) => gudang.id === e
                              );
                              setValue("gudang", e);
                              setValue(
                                "alamatTerima",
                                `${selectedGudang.alamat}, ${selectedGudang.kota}, ${selectedGudang.provinsi} \n${selectedGudang.kodepos}`
                              );
                            }}
                            searchData={dataGudang}
                          />

                          {watch("alamatTerima") !== undefined && (
                            <div class="form-container">
                              <div class="auto-form-container w-400px disable">
                                <textarea
                                  id=""
                                  name=""
                                  rows="4"
                                  cols="50"
                                  disabled
                                  placeholder="Tuliskan catatan di sini"
                                  {...register("alamatTerima")}
                                ></textarea>
                              </div>
                            </div>
                          )}

                          <div class="form-container">
                            <div class="title">Keterangan</div>

                            <div class="auto-form-container w-400px">
                              <textarea
                                id=""
                                name=""
                                rows="4"
                                cols="50"
                                placeholder="Tuliskan catatan di sini"
                                {...register("keterangan")}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-fulltab-50">
                        <div class="form-wrap ">
                          <div class="section-title">Info Pengiriman</div>

                          <div className="form-container">
                            <div className="title">Tanggal Kirim</div>
                            <input
                              ref={datePickerSendRef}
                              className="form-date-input"
                              type="text"
                              placeholder="Pilh tanggal"
                              value={watch("tglKirim")}
                              onChange={(e) =>
                                setValue("tglKirim", e.target.value)
                              }
                            />
                            <div className="icon-form-date icon-date_ic"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${
                (loading || loadingUpdate) && "disabled"
              }`}
              onClick={handleSubmit(handleSaveRequest)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>
            <div className="data-action-button purple-bg">
              <span className="icon icon-cetaik_ic"></span>
              Cetak
            </div>
            <div
              className="data-action-button purple-bg"
              onClick={() => setShow({ ...show, attachment: !show.attachment })}
            >
              <span className="icon icon-attach_ic"></span>
              Lampiran
            </div>
            {show.attachment && (
              <div class="window-overlay">
                <div class="window-container product-window small-popup">
                  <div class="window-nav">
                    <div class="tab_content_module">
                      <div class="tab_menu_content active">
                        Dokumentasi Transaksi
                      </div>
                    </div>
                    <div
                      class="close-btn flex-center"
                      onClick={() => {
                        setShow({ ...show, attachment: false });
                        setPhoto(null);
                        setDocumentName("");
                      }}
                    >
                      <span class="icon icon-close_ic"></span>
                    </div>
                  </div>

                  <div class="window-content">
                    <div class="form-side">
                      <form id="" class="">
                        <div class="form-wrap">
                          <div class="form-container lg">
                            <div class="title">Nama Dokumen</div>
                            <div class="auto-form-container">
                              <input
                                name=""
                                type="text"
                                placeholder="Tuliskan nama dokumen"
                                value={documentName}
                                onChange={(e) =>
                                  setDocumentName(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          {!photo && (
                            <>
                              <label htmlFor="dropzone-file">
                                <div class="upload-img-wrap">
                                  <div class="upload-btn-wrap">
                                    <span class="icon-import_ic"></span>
                                    <div class="desc">
                                      Klik Untuk upload gambar
                                    </div>
                                  </div>
                                </div>
                              </label>

                              <input
                                accept="image/png, image/jpeg, image/jpg"
                                id="dropzone-file"
                                type="file"
                                style={{ visibility: "hidden" }}
                                onChange={(e) =>
                                  handleUploadPhoto(e.target.files)
                                }
                              />
                            </>
                          )}

                          <div class="uploaded-wrap">
                            {photo && (
                              <div class="uploaded-list">
                                <div class="file-detail">
                                  {/* <div class="file-name">{photo.name}</div>
                                  <div class="file-size">
                                    {formatBytes(photo.size, 0)}
                                  </div> */}
                                  <div class="photo">
                                    <img
                                      src={URL.createObjectURL(photo)}
                                      alt="product preview"
                                      style={{ maxWidth: "300px" }}
                                    />
                                  </div>
                                </div>
                                <div class="file-action">
                                  {/* <div class="time-info">
                                    <span>
                                      {format(
                                        new Date(photo.lastModifiedDate),
                                        "dd MMM yyyy HH:mm"
                                      )}
                                    </span>
                                  </div> */}
                                  <div
                                    class="action-wrap"
                                    onClick={() => setPhoto(null)}
                                  >
                                    <div class="icon icon-hapus_ic"></div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div class="clear"></div>
                        </div>
                      </form>
                    </div>
                    <div class="clear"></div>
                  </div>

                  <div
                    class="window-bottom"
                    onClick={() => setShow({ ...show, attachment: false })}
                  >
                    <div class="button btn-blue flex-center">Simpan</div>
                  </div>
                </div>
              </div>
            )}

            <div className="data-action-button blue-bg">
              <span className="icon icon-more_ic"></span>
              Lainnya
            </div>
            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Pesanan
              pembelian {choosenData?.nomor_pesanan}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewReceiveOrder;
