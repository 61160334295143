import { gql } from "@apollo/client";

export const SYARAT_PEMBAYARAN_FRAGMENT = gql`
  fragment SyaratPembayaran on mSyaratPembayaran {
    syarat_pembayaran_id
    masa_jto
    nama_syarat_pembayaran
    keterangan
    is_default
    is_bawaan
  }
`;

export const SYARAT_PEMBAYARAN_LIST = gql`
  query getSyaratPembayaranList {
    Pemasok {
      listSyarat {
        ...SyaratPembayaran
      }
    }
  }
  ${SYARAT_PEMBAYARAN_FRAGMENT}
`;

export const CREATE_SYARAT_PEMBAYARAN = gql`
  mutation createSyarat($input: CreateSyaratPembayaranInput!) {
    Pemasok {
      createSyarat(input: $input) {
        ...SyaratPembayaran
      }
    }
  }
  ${SYARAT_PEMBAYARAN_FRAGMENT}
`;

export const UPDATE_SYARAT_PEMBAYARAN = gql`
  mutation editSyarat($input: UpdateSyaratPembayaranInput!) {
    Pemasok {
      updateSyarat(input: $input) {
        ...SyaratPembayaran
      }
    }
  }
  ${SYARAT_PEMBAYARAN_FRAGMENT}
`;

export const DELETE_SYARAT_PEMBAYARAN = gql`
  mutation hapusSyarat($input: Float!) {
    Pemasok {
      deleteSyarat(input: $input) {
        ...SyaratPembayaran
      }
    }
  }
  ${SYARAT_PEMBAYARAN_FRAGMENT}
`;
