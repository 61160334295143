import React, { useEffect, useState } from "react";
import { useRef } from "react";

const MultipleSearchInput = (props) => {
  const {
    title,
    placeholder,
    value,
    setValue,
    searchData,
    dataList,
    className = "",
  } = props;

  const wrapperRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchTerms, setSearchTerms] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (searchTerms.length > 0) {
      const filteredOption = searchData.filter((data) =>
        data.name.toLowerCase().includes(searchTerms.toLowerCase())
      );
      setOpenSearch(true);

      setSearchResults(filteredOption);
    } else {
      setSearchResults(searchData);
      setOpenSearch(false);
    }

    // eslint-disable-next-line
  }, [searchTerms, searchData]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChooseOption = (choosen) => {
    setValue([...value, choosen.id]);
    setOpen(!open);
    setOpenSearch(!openSearch);
    setSearchTerms("");
  };

  const handleDelete = (id) => {
    const deletedItemList = value.filter((item) => item !== id);
    setValue(deletedItemList);
  };

  const handleReset = () => {
    setValue([]);
    setOpen(false);
  };

  return (
    <div ref={wrapperRef} className={`form-container satuan ${className}`}>
      {title && <div className="title">{title}</div>}

      <div className="auto-form-container">
        {value.length > 0 && (
          <ul className="input-label">
            <li onClick={() => setOpen(!open)}>
              <label>Lihat Mention</label>
            </li>
          </ul>
        )}

        {open && (
          <div className="popup-wrap lg-wrap">
            {/* <!--FORM PENCARIAN--> */}
            <div className="auto-form-container fluid-form">
              <input
                name=""
                type="text"
                placeholder={`Ketikkan ${title}`}
                value={searchTerms}
                onChange={(e) => setSearchTerms(e.target.value)}
              />
              <div
                className="icon-form-date icon-search_ic"
                style={{ cursor: "pointer" }}
                onClick={() => setOpenSearch(!openSearch)}
              ></div>
            </div>

            {/* <!--LIST AKUN--> */}
            {openSearch && (
              <ul className="main-list">
                {searchResults.map((result, i) => (
                  <li key={i} onClick={() => handleChooseOption(result)}>
                    <div className="text-list">{result.name}</div>
                    {result.desc && (
                      <div className="text-desc">{result.desc}</div>
                    )}
                  </li>
                ))}
              </ul>
            )}

            {value.length > 0 && (
              <>
                <div style={{ marginTop: "8px" }}>
                  Daftar Akun{" "}
                  <span
                    className="reset-list"
                    style={{ cursor: "pointer" }}
                    onClick={handleReset}
                  >
                    Reset Filter
                  </span>
                </div>
                {/* <!--LIST AKUN TER MENTION--> */}
                <ul className="input-label popup-inside">
                  {value.map((item) => (
                    <li>
                      <label>
                        {dataList.find((result) => result.id === item)?.name}
                      </label>
                      <span onClick={() => handleDelete(item)}>
                        <i className="icon icon-close_ic"></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        )}

        {value.length === 0 && (
          <input
            name=""
            type="text"
            placeholder={placeholder}
            value=""
            onClick={() => setOpenSearch(false)}
          />
        )}
      </div>
      <div
        className="icon-form-date icon-search_ic"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      ></div>
    </div>
  );
};

export default MultipleSearchInput;
