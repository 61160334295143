import React, { useEffect } from "react";
import DropdownInput from "../../common/dropdownInput";
import { useRef } from "react";

const GudangList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    gudangList,
    getGudangList,
    loading,
  } = props;
  const wrapperRef = useRef(null);

  const theadOption = ["Nama", "Deskripsi", "Alamat"];

  const [search, setSearch] = React.useState("");
  const [choosenStatus, setChoosenStatus] = React.useState();
  const [showStatus, setShowStatus] = React.useState(false);
  const [showSetting, setShowSetting] = React.useState(false);
  const [choosenTh, setChoosenTh] = React.useState([
    "Nama",
    "Deskripsi",
    "Alamat",
  ]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSetting(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    getGudangList({
      variables: {
        input: {
          nama: search || null,
          status: choosenStatus !== undefined ? choosenStatus === 1 : null,
        },
      },
    });
    // eslint-disable-next-line
  }, [search, choosenStatus]);

  const handleCheckbox = (option) => {
    const existedThead = choosenTh.find((thead) => thead === option);

    if (existedThead) {
      const deletedThead = choosenTh.filter((thead) => thead !== option);
      setChoosenTh(deletedThead);
    } else {
      setChoosenTh([...choosenTh, option]);
    }
  };

  // const selectedCol = tableData.filter((item) =>
  //   choosenTh.includes(item.label)
  // );

  const handleOpenDetail = (choosen) => {
    if (tabs.find((tab) => tab.id === choosen.kode_gudang)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: choosen.nama_gudang,
          type: "detail",
          id: choosen.kode_gudang,
        },
      ]);
    }
    setActive(choosen.kode_gudang);
    setChoosenData([
      ...choosenData,
      { idTab: choosen.kode_gudang, ...choosen },
    ]);
  };

  return (
    <>
      <div className="content_nav_module">
        <form id="" class="">
          <div class="form-wrap">
            <DropdownInput title="Non-Aktif" setOpen={(e) => setShowStatus(e)}>
              {showStatus && (
                <div class="popup-wrap gudang-wrap">
                  <div class="m-b-10">
                    <span class="reset-list" onClick={() => setChoosenStatus()}>
                      Reset Filter
                    </span>
                  </div>

                  <label class="radio-wrap">
                    Ya
                    <input
                      type="radio"
                      name="radio"
                      checked={choosenStatus === 0}
                      onChange={() => setChoosenStatus(0)}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-wrap">
                    Tidak
                    <input
                      type="radio"
                      name="radio"
                      checked={choosenStatus === 1}
                      onChange={() => setChoosenStatus(1)}
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              )}

              {choosenStatus === undefined ? (
                <input
                  name=""
                  type="text"
                  placeholder="Pilih di sini"
                  value=""
                  onClick={() => setShowStatus(!showStatus)}
                />
              ) : (
                <ul
                  class="input-label"
                  onClick={() => setShowStatus(!showStatus)}
                >
                  <li>
                    <label>Lihat Filter</label>
                  </li>
                </ul>
              )}
            </DropdownInput>
          </div>

          <div class="nav-setting-wrap">
            <div class="list-setting">
              <div class="setting-btn">
                <span class="icon-filter_ic"></span>
              </div>
            </div>

            <div class="list-setting">
              <div class="setting-btn">
                <span class="icon-cetaik_ic"></span>
              </div>
            </div>
            <div ref={wrapperRef} class="list-setting">
              <div
                class="setting-btn"
                onClick={() => setShowSetting(!showSetting)}
              >
                <span class="icon-pengatura_ic"></span>
              </div>
              {/* <!--POPUP MENU--> */}
              {showSetting && (
                <div class="popup-setting pengaturan">
                  {theadOption.map((opt, i) => (
                    <label
                      class="checkbox"
                      key={i}
                      onChange={() => handleCheckbox(opt)}
                    >
                      {opt}
                      <input
                        type="checkbox"
                        checked={choosenTh?.find((th) => th === opt)}
                      />
                      <span class="checkmark"></span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div class="form-right-side">
            <div class="form-wrap">
              <div class="form-container">
                <div class="title">Pencarian</div>
                <input
                  class="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                  value={search}
                  setValue={(e) => setSearch(e.target.value)}
                />
                <div class="icon-form-date icon-search_ic"></div>
              </div>
            </div>

            <div class="data-total-wrap">
              <div>TOTAL DATA</div>
              <div class="value">{gudangList?.length}</div>
            </div>
          </div>

          <div class="clear"></div>
        </form>
      </div>

      <div class="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <tr>
              <th>Nama</th>
              <th>Deskripsi</th>
              <th>Alamat</th>
              <th>Non-Aktif</th>
            </tr>
            {loading ? (
              <p>Loading...</p>
            ) : gudangList?.length === 0 ? (
              <tr>
                <td>Tidak ada data.</td>
              </tr>
            ) : (
              gudangList?.map((gudang, i) => (
                <>
                  <tr onClick={() => handleOpenDetail(gudang)}>
                    <td>{gudang.nama_gudang}</td>
                    <td>{gudang.deskripsi}</td>
                    <td>{gudang.alamat}</td>
                    <td>{gudang.is_non_aktif ? "Ya" : "Tidak"}</td>
                  </tr>
                </>
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default GudangList;
